import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PageTitle from "../../components/PageTitle";
import IntegrationCard from "./IntegrationCard";
import PayStack from "../../assets/images/paystack.jpeg";
import Stripe from "../../assets/images/stripe.png";
// import Flutterwave from "../../assets/images/flutterwave.jpeg";
import PayPal from "../../assets/images/paypal.jpeg";
// import MailChimp from "../../assets/images/mailchimp.jpeg";
import brevo from "../../assets/images/brevo.png";
// import aws from "../../assets/images/aws.png";
import mailjet from "../../assets/images/mailjet.jpeg";
import mailersend from "../../assets/images/mailersend.png";
import { ToastContainer, toast } from "react-toastify";
import { Grid } from "@mui/material";
import { Icon } from "@iconify/react/dist/iconify.js";
import Modal from "../../components/Modal/index";
import InputGroup from "../../components/FormComponent/InputGroup";
import Button from "../../components/Button";
import useStyles from "./styles";
import { httprequest } from "../../data/api";
import PopModal from "../../components/PopupModal/PopModal";
// import SelectGroup from "../../components/FormComponent/SelectGroup";

const SCHOOL_INTEGRATION = "/api/v1/integrations/school";

const Integration = () => {
  const [value, setValue] = useState("1");
  const classes = useStyles();
  const [integrateModal, setIntegrateModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState({ title: "" });
  // const [integratedCards, setIntegratedCards] = useState({});
  const [allIntegration, setAllIntegration] = useState([]);
  const [integration, setIntegration] = useState(
    { api_key: "",
      secret_key: "",
      email: "",
      name: "",
      public_key: "",
      encryption_key: "",
      client_id: ""
    });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [viewSecretKeys, setViewSecretKeys] = useState({
    secret_key: false,
    public_key: false,
    encryption_key: false,
    client_id: false,
    api_key: false
  });

  const toggleSecretKey = (key) => {
    setViewSecretKeys((prev) => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const handleIntegrateModal = (card) => {
    setSelectedCard(card);

    setViewSecretKeys({
      secret_key: false,
      public_key: false,
      encryption_key: false,
      client_id: false,
      api_key: false
    });

    setIntegration({
      api_key: "",
      secret_key: "",
      email: "",
      name: "",
      public_key: "",
      encryption_key: "",
      client_id: ""
    });

    setIntegrateModal(!integrateModal);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIntegration({
      ...integration, [name]: value
    });
  };

  const getIntegration = () => {
    httprequest.get(SCHOOL_INTEGRATION).then((res) => {
      setAllIntegration(res.data.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  useEffect(() => {
    getIntegration();
  }, []);

  const checkIsIntegrated = (title) => {
    const existingIntegration = allIntegration.find(
      (integration) => integration?.provider === title
    );

    return existingIntegration;
  };

  const handleSchIntegration = async (title) => {
    const existingIntegration = allIntegration.find(
      (integration) => integration?.provider === title
    );
    if (existingIntegration) {
      try {
        const res = await httprequest.delete(`${SCHOOL_INTEGRATION}/${existingIntegration?.id}`);
        if (res.status === 200) {
          toast.success("Integration removed successfully.");
          getIntegration();
          setIntegrateModal(false);
        } else {
          toast.error("Failed to remove integration.");
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || "Error removing integration.");
      }
    } else {
      // Define your credentials and remove fields based on the provider type dynamically
      const credentials = {
        api_key: integration.api_key,
        secret_key: integration.secret_key,
        email: integration.email,
        name: integration.name,
        public_key: integration.public_key,
        encryption_key: integration.encryption_key,
        client_id: integration.client_id
      };

      switch (selectedCard.title) {
      case "BREVO":
        delete credentials.secret_key;
        delete credentials.public_key;
        delete credentials.encryption_key;
        delete credentials.client_id;
        break;
      case "MAILJET":
        delete credentials.public_key;
        delete credentials.encryption_key;
        delete credentials.client_id;
        break;
      case "MAILERSEND":
        delete credentials.secret_key;
        delete credentials.public_key;
        delete credentials.encryption_key;
        delete credentials.client_id;
        break;
      case "PAYSTACK":
        delete credentials.email;
        delete credentials.api_key;
        delete credentials.name;
        delete credentials.public_key;
        delete credentials.encryption_key;
        delete credentials.client_id;
        break;
      case "STRIPE":
        delete credentials.email;
        delete credentials.api_key;
        delete credentials.name;
        delete credentials.public_key;
        delete credentials.encryption_key;
        delete credentials.client_id;
        break;
      case "PAYPAL":
        credentials.client_secret = credentials.secret_key;
        delete credentials.email;
        delete credentials.api_key;
        delete credentials.name;
        delete credentials.public_key;
        delete credentials.encryption_key;
        delete credentials.secret_key;
        break;
      case "FLUTTERWAVE":
        delete credentials.email;
        delete credentials.api_key;
        delete credentials.name;
        delete credentials.client_id;
        break;
      default:
        break;
      }

      const schInfo = {
        credentials,
        provider: selectedCard?.title,
        category: selectedCard?.type
      };

      try {
        const res = await httprequest.post(SCHOOL_INTEGRATION, schInfo);
        toast.success(res?.data?.message || "Failed to integrate");
        getIntegration();
        setIntegration({
          api_key: "",
          secret_key: "",
          email: "",
          name: "",
          public_key: "",
          encryption_key: "",
          client_id: ""
        });
        setIntegrateModal(false);
      } catch (err) {
        toast.error(err?.response?.data?.message || "Error integrating.");
      }
    }
  };

  const MarketCards = [
    {
      title: "PAYSTACK",
      logo: PayStack,
      type: "payment"
    },
    {
      title: "STRIPE",
      logo: Stripe,
      type: "payment"
    },
    // {
    //   title: "FLUTTERWAVE",
    //   logo: Flutterwave,
    //   type: "payment"
    // },
    {
      title: "PAYPAL",
      logo: PayPal,
      type: "payment"
    },
    {
      title: "MAILERSEND",
      logo: mailersend,
      type: "mail"
    },
    {
      title: "BREVO",
      logo: brevo,
      type: "mail"
    },
    {
      title: "MAILJET",
      logo: mailjet,
      type: "mail"
    }
    // {
    //   title: "AWS SES",
    //   logo: aws,
    //   type: "mail"
    // }
  ];

  const paymentCard = MarketCards.filter(card => card.type === "payment");
  const mailingCard = MarketCards.filter(card => card.type === "mail");

  return (
    <Layout children={
      <>
        <PageTitle
          title="Integrations"
          subtitle="View all Integrations."
          button={
            <PopModal modalKey="integration" />

          }
        />

        <div className="page-content white_bg">
          <TabContext value={value}>
            <div className="tablist-container padding-left-16">
              <Tabs
                indicatorColor="none"
                className="overide-tablist"
                onChange={handleChange}
                aria-label=""
              >
                <Tab label="All" value="1" />
                <Tab label="Payment" value="payment" />
                <Tab label="Mailing" value="mailing" />
              </Tabs>
            </div>
            <TabPanel value="1" className="tabpanel-overide">
              <div className={`${classes.maincont} margin-top text-align-left`}>
                <h4>Payment</h4>
                <Grid container spacing={2}>
                  {paymentCard.map((card, index) => (
                    <Grid item lg={3} md={6} sm={6} xs={12} key={index}>
                      <IntegrationCard
                        title={card.title}
                        logo={card.logo}
                        type={card.type}
                        integrate={() => handleIntegrateModal(card)}
                        isIntegrated={checkIsIntegrated(card.title)}
                        handleIntegrateModal={handleIntegrateModal}
                        handleSchIntegration={() => handleSchIntegration(card.title)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
              <div className={`${classes.maincont} margin-top text-align-left`}>
                <h4>Mailing</h4>
                <Grid container spacing={2}>
                  {mailingCard.map((card, index) => (
                    <Grid item lg={3} md={6} sm={6} xs={12} key={index}>
                      <IntegrationCard
                        title={card.title}
                        logo={card.logo}
                        type={card.type}
                        integrate={() => handleIntegrateModal(card)}
                        isIntegrated={checkIsIntegrated(card.title)}
                        handleIntegrateModal={handleIntegrateModal}
                        handleSchIntegration={() => handleSchIntegration(card.title)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </TabPanel>
            <TabPanel value="payment" className="tabpanel-overide">
              <div className={`${classes.maincont} margin-top text-align-left`}>
                <h4>Payment</h4>
                <Grid container spacing={1}>
                  {paymentCard.map((card, index) => (
                    <Grid item lg={3} md={6} sm={6} xs={12} key={index}>
                      <IntegrationCard
                        title={card.title}
                        logo={card.logo}
                        type={card.type}
                        integrate={() => handleIntegrateModal(card)}
                        isIntegrated={checkIsIntegrated(card.title)}
                        handleIntegrateModal={handleIntegrateModal}
                        handleSchIntegration={() => handleSchIntegration(card.title)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </TabPanel>
            <TabPanel value="mailing" className="tabpanel-overide">
              <div className={`${classes.maincont} margin-top text-align-left`}>
                <h4>Mailing</h4>
                <Grid container spacing={1}>
                  {mailingCard.map((card, index) => (
                    <Grid item lg={3} md={6} sm={6} xs={12} key={index}>
                      <IntegrationCard
                        title={card.title}
                        logo={card.logo}
                        type={card.type}
                        integrate={() => handleIntegrateModal(card)}
                        isIntegrated={checkIsIntegrated(card.title)}
                        handleIntegrateModal={handleIntegrateModal}
                        handleSchIntegration={() => handleSchIntegration(card.title)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </TabPanel>
          </TabContext>
          <ToastContainer />
        </div>
        <Modal
          title={`${selectedCard.title} Integration`}
          subtitle={ selectedCard.subtitle }
          modalContent={
            <>
              {selectedCard && (
                <>
                  <div style={{ width: "100%", overflowX: "hidden" }}>
                    {selectedCard.title === "PAYSTACK" ? (
                      <>
                        <Grid container className="input-padding">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Secret Key"
                              margin="normal"
                              placeHolder="Enter secret key"
                              inputName="secret_key"
                              onChange={handleInputChange}
                              inputType={viewSecretKeys.secret_key ? "text" : "password"}
                            />
                            <div className="password-eye-90" onClick={() => toggleSecretKey("secret_key")}>
                              {viewSecretKeys.secret_key ? (
                                <Icon icon="fluent:eye-16-regular" />
                              ) : (
                                <Icon icon="iconamoon:eye-off-light" />
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <>
                          <div className={`${classes.modalFooterBtn} flex gap-10 margin-top flex-wrap-items-426`}>
                            <Button variant="primaryoutline" buttonName="Cancel" buttonSize="full" color="btnfontprimary" onClick={handleIntegrateModal} />
                            <Button variant="primary" buttonName={`Integrate ${selectedCard.title}`} buttonSize="full" onClick={handleSchIntegration} color="btndefault" />
                          </div>
                        </>
                      </>
                    ) : selectedCard.title === "STRIPE" ? (
                      <>
                        <Grid container spacing={1} className="input-padding">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Secret Key"
                              margin="normal"
                              placeHolder="Enter secret key"
                              inputName="secret_key"
                              onChange={handleInputChange}
                              inputType={viewSecretKeys.secret_key ? "text" : "password"}
                            />
                            <div className="password-eye-90" onClick={() => toggleSecretKey("secret_key")}>
                              {viewSecretKeys.secret_key ? (
                                <Icon icon="fluent:eye-16-regular" />
                              ) : (
                                <Icon icon="iconamoon:eye-off-light" />
                              )}
                            </div>

                          </Grid>
                        </Grid>
                        <>
                          <div className={`${classes.modalFooterBtn} flex gap-10 margin-top flex-wrap-items-426`}>
                            <Button variant="primaryoutline" buttonName="Cancel" buttonSize="full" color="btnfontprimary" onClick={handleIntegrateModal} />
                            <Button variant="primary" buttonName={`Integrate ${selectedCard.title}`} buttonSize="full" onClick={handleSchIntegration} color="btndefault" />
                          </div>
                        </>
                      </>
                    ) : selectedCard.title === "FLUTTERWAVE" ? (
                      <>
                        <Grid container spacing={1} className="input-padding">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Secret Key"
                              margin="normal"
                              placeHolder="Enter secret key"
                              inputName="secret_key"
                              onChange={handleInputChange}
                              inputType={viewSecretKeys.secret_key ? "text" : "password"}
                            />
                            <div className="password-eye-90" onClick={() => toggleSecretKey("secret_key")}>
                              {viewSecretKeys.secret_key ? (
                                <Icon icon="fluent:eye-16-regular" />
                              ) : (
                                <Icon icon="iconamoon:eye-off-light" />
                              )}
                            </div>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Public Key"
                              margin="normal"
                              placeHolder="Enter public key"
                              inputName="public_key"
                              onChange={handleInputChange}
                              inputType={viewSecretKeys.public_key ? "text" : "password"}
                            />
                            <div className="password-eye-90" onClick={() => toggleSecretKey("public_key")}>
                              {viewSecretKeys.public_key ? (
                                <Icon icon="fluent:eye-16-regular" />
                              ) : (
                                <Icon icon="iconamoon:eye-off-light" />
                              )}
                            </div>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Encryption Key"
                              margin="normal"
                              placeHolder="Enter Encryption Key here"
                              inputName="encryption_key"
                              onChange={handleInputChange}
                              inputType={viewSecretKeys.encryption_key ? "text" : "password"}
                            />
                            <div className="password-eye-90" onClick={() => toggleSecretKey("encryption_key")}>
                              {viewSecretKeys.encryption_key ? (
                                <Icon icon="fluent:eye-16-regular" />
                              ) : (
                                <Icon icon="iconamoon:eye-off-light" />
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <>
                          <div className={`${classes.modalFooterBtn} flex gap-10 margin-top flex-wrap-items-426`}>
                            <Button variant="primaryoutline" buttonName="Cancel" buttonSize="full" color="btnfontprimary" onClick={handleIntegrateModal} />
                            <Button variant="primary" buttonName={`Integrate ${selectedCard.title}`} buttonSize="full" onClick={handleSchIntegration} color="btndefault" />
                          </div>
                        </>
                      </>
                    ) : selectedCard.title === "PAYPAL" ? (
                      <>
                        <Grid container className="input-padding">
                          <Grid item sx={{ height: "84px" }} lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Secret Key"
                              margin="normal"
                              placeHolder="Enter secret key"
                              inputName="secret_key"
                              value={integration.secret_key}
                              onChange={handleInputChange}
                              inputType={viewSecretKeys.secret_key ? "text" : "password"}
                            />
                            <div className="password-eye-90" onClick={() => toggleSecretKey("secret_key")}>
                              {viewSecretKeys.secret_key ? (
                                <Icon icon="fluent:eye-16-regular" />
                              ) : (
                                <Icon icon="iconamoon:eye-off-light" />
                              )}
                            </div>
                          </Grid>
                          <Grid item sx={{ height: "84px" }} lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Client Id"
                              margin="normal"
                              placeHolder="Enter Client ID"
                              inputName="client_id"
                              value={integration.client_id}
                              onChange={handleInputChange}
                              inputType={viewSecretKeys.client_id ? "text" : "password"}
                            />
                            <div className="password-eye-90" onClick={() => toggleSecretKey("client_id")}>
                              {viewSecretKeys.client_id ? (
                                <Icon icon="fluent:eye-16-regular" />
                              ) : (
                                <Icon icon="iconamoon:eye-off-light" />
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <>
                          <div className={`${classes.modalFooterBtn} flex gap-10 margin-top flex-wrap-items-426`}>
                            <Button variant="primaryoutline" buttonName="Cancel" buttonSize="full" color="btnfontprimary" onClick={handleIntegrateModal} />
                            <Button variant="primary" buttonName={`Integrate ${selectedCard.title}`} onClick={handleSchIntegration} buttonSize="full" color="btndefault" />
                          </div>
                        </>
                      </>
                    ) : selectedCard.title === "MAILJET" ? (
                      <>
                        <Grid container spacing={1} className="input-padding">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Name"
                              margin="normal"
                              placeHolder="Enter your name"
                              inputName="name"
                              value={integration.name}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Email"
                              margin="normal"
                              placeHolder="Enter your email"
                              inputName="email"
                              inputType="text"
                              value={integration.email}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid sx={{ height: "84px" }} item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="API Key"
                              margin="normal"
                              placeHolder="Enter API key"
                              inputName="api_key"
                              value={integration.api_key}
                              onChange={handleInputChange}
                              inputType={viewSecretKeys.api_key ? "text" : "password"}
                            />
                            <div className="password-eye-90" onClick={() => toggleSecretKey("api_key")}>
                              {viewSecretKeys.api_key ? (
                                <Icon icon="fluent:eye-16-regular" />
                              ) : (
                                <Icon icon="iconamoon:eye-off-light" />
                              )}
                            </div>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Secret Key"
                              margin="normal"
                              placeHolder="Enter secret key"
                              inputName="secret_key"
                              value={integration.secret_key}
                              onChange={handleInputChange}
                              inputType={viewSecretKeys.secret_key ? "text" : "password"}
                            />
                            <div className="password-eye-90" onClick={() => toggleSecretKey("secret_key")}>
                              {viewSecretKeys.secret_key ? (
                                <Icon icon="fluent:eye-16-regular" />
                              ) : (
                                <Icon icon="iconamoon:eye-off-light" />
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <>
                          <div className={`${classes.modalFooterBtn} flex gap-10 margin-top flex-wrap-items-426`}>
                            <Button variant="primaryoutline" buttonName="Cancel" buttonSize="full" color="btnfontprimary" />
                            <Button variant="primary" onClick={handleSchIntegration} buttonName="Integrate Mail-Jet" buttonSize="full" color="btndefault" />
                          </div>
                        </>
                      </>
                    ) : selectedCard.title === "MAILERSEND" ? (
                      <>
                        <Grid container spacing={1} className="input-padding">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Name"
                              margin="normal"
                              placeHolder="Enter your name"
                              inputName="name"
                              value={integration.name}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Email"
                              margin="normal"
                              placeHolder="Enter your email"
                              inputName="email"
                              inputType="text"
                              value={integration.email}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="API Key"
                              margin="normal"
                              placeHolder="Enter API Key"
                              inputName="api_key"
                              sx={{ height: "84px" }}
                              value={integration.api_key}
                              onChange={handleInputChange}
                              inputType={viewSecretKeys.api_key ? "text" : "password"}
                            />
                            <div className="password-eye-90" onClick={() => toggleSecretKey("api_key")}>
                              {viewSecretKeys.api_key ? (
                                <Icon icon="fluent:eye-16-regular" />
                              ) : (
                                <Icon icon="iconamoon:eye-off-light" />
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <>
                          <div className={`${classes.modalFooterBtn} flex gap-10 margin-top flex-wrap-items-426`}>
                            <Button variant="primaryoutline" buttonName="Cancel" buttonSize="full" color="btnfontprimary" />
                            <Button variant="primary" buttonName="Integrate MailerSend" buttonSize="full" onClick={handleSchIntegration} color="btndefault" />
                          </div>
                        </>
                      </>
                    ) : selectedCard.title === "BREVO" ? (
                      <>
                        <Grid container spacing={1} className="input-padding">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Name"
                              margin="normal"
                              placeHolder="Enter your name"
                              inputName="name"
                              value={integration.name}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="Email"
                              margin="normal"
                              placeHolder="Enter your email"
                              inputName="email"
                              inputType="text"
                              value={integration.email}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="API Key"
                              margin="normal"
                              placeHolder="Enter API key"
                              inputName="api_key"
                              style={{ height: "84px" }}
                              onChange={handleInputChange}
                              inputType={viewSecretKeys.api_key ? "text" : "password"}
                            />
                            <div className="password-eye-90" onClick={() => toggleSecretKey("api_key")}>
                              {viewSecretKeys.api_key ? (
                                <Icon icon="fluent:eye-16-regular" />
                              ) : (
                                <Icon icon="iconamoon:eye-off-light" />
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <>
                          <div className={`${classes.modalFooterBtn} flex gap-10 margin-top flex-wrap-items-426`}>
                            <Button variant="primaryoutline" buttonName="Cancel" buttonSize="full" color="btnfontprimary" />
                            <Button variant="primary" buttonName="Integrate Brevo" onClick={handleSchIntegration} buttonSize="full" color="btndefault" />
                          </div>
                        </>
                      </>
                    ) : selectedCard.title === "AWS SES" ? (
                      <>
                        <Grid container spacing={1} className="input-padding">
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <InputGroup
                              label="API Key"
                              margin="normal"
                              placeHolder="Enter API key"
                              inputName="api_key"
                              sx={{ height: "84px" }}
                              value={integration.api_key}
                              onChange={handleInputChange}
                              inputType={viewSecretKeys.api_key ? "text" : "password"}
                            />
                            <div className="password-eye-90" onClick={() => toggleSecretKey("api_key")}>
                              {viewSecretKeys.api_key ? (
                                <Icon icon="fluent:eye-16-regular" />
                              ) : (
                                <Icon icon="iconamoon:eye-off-light" />
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <>
                          <div className={`${classes.modalFooterBtn} flex gap-10 margin-top flex-wrap-items-426`}>
                            <Button variant="primaryoutline" buttonName="Cancel" buttonSize="full" color="btnfontprimary" />
                            <Button variant="primary" buttonName="Integrate Aws SES" buttonSize="full" onClick={handleSchIntegration} color="btndefault" />
                          </div>
                        </>
                      </>
                    ) : null}
                  </div>
                </>
              )}
            </>
          }
          isopen={integrateModal}
          closeModal={handleIntegrateModal}
        />
      </>
    }
    />
  );
};

export default Integration;
