import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  profilecontainer: {
    backgroundColor: "#F5F6F7",
    width: "100%",
    height: 258,
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 22
  },
  pickercontainer: {
    position: "absolute",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "25px",
    height: "25px",
    bottom: 0,
    right: 0,
    color: "white",
    borderRadius: "50%",
    zIndex: 20
  },
  camera: {
    color: "white",
    width: "14px!important"
  },
  profilepicker: {
    opacity: 0,
    width: 25,
    height: 25,
    cursor: "pointer",
    position: "absolute"
  },
  imageviewercontainer: {
    width: "150px",
    height: "150px",
    position: "relative"
  },
  imageviewer: {
    width: "160px",
    height: "160px",
    backgroundColor: "white",
    borderRadius: "50%",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  border: {
    border: "1px solid #E4E7EC",
    borderRadius: "8px",
    "& hr": {
      border: "1px solid #E4E7EC"
    }
  },
  foraccessbtn: {
    maxWidth: "150px",
    marginInline: "auto",
    marginTop: "20px"
  },
  foraccessbtnwide: {
    maxWidth: "200px",
    marginInline: "auto",
    marginTop: "20px"
  },
  codegenerator: {
    marginTop: "16px",
    background: "#FBFBFB",
    borderRadius: "4px",
    border: "1px solid #C5CAE4",
    paddingBlock: "25px",
    fontSize: "64px",
    fontWeight: 800,
    "& p": {
      marginBlock: "0px !important",
      textAlign: "center"
    },
    "@media (max-width: 499px)": {
      fontSize: "30px",
      paddingBlock: "15px"
    }
  },
  forcodeinput: {
    maxWidth: "500px",
    marginInline: "auto",
    marginTop: "20px",
    "& input": {
      background: "#FBFBFB",
      paddingBlock: "40px",
      fontSize: "32px",
      textAlign: "center"
    }
  },
  forcode: {
    maxWidth: "373px",
    fontWeight: 700,
    fontSize: "32px",
    paddingBlock: "17px",
    background: "#E6F0FF",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      marginBlock: "0px !important",
      color: "#0065FF"
    },
    "@media (max-width: 600px)": {
      fontSize: "20px",
      paddingBlock: "10px"
    }
  },
  dummyimgviewer: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "80px",
    border: "1px solid #E4E7EC",
    overflow: "hidden",
    color: "#E4E7EC"
  },
  accesscredentials: {
    maxWidth: "500px"
  },
  accessproperty: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#6C737F"
  },
  accessvalue: {
    width: 150,
    fontWeight: 500,
    fontSize: "14px",
    color: "#101928",
    textAlign: "left"
  },
  forerrorimg: {
    marginTop: "16px",
    margin: "auto",
    maxWidth: "245px"

  },
  nocodeheader: {
    fontWeight: 500,
    fontSize: "14px",
    textAlign: "center",
    color: "#000000",
    marginBlock: "0px"
  },
  nocodesentence: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#475367",
    textAlign: "center",
    marginBlock: "0px"
  },
  tagin: {
    width: 40,
    height: 40,
    fontSize: 20,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  centerbackbutton: {
    marginTop: 15,
    display: "flex",
    justifyContent: "center"
  }

}));
