import NotFounditem from "./NotFound";
import Layout from "../../components/Layout";
import Error500 from "../../assets/images/err500.svg";
import BreadCrumb from "../../components/BreadCrumb";

const getFormattedDate = () => {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const InternalServer = () => (
  <Layout children={
    <>
      <div>
        <div className="breadcrumb-container">
          <BreadCrumb
            link_one="/dashboard"
            link_one_name="Dashboard"
            active="Error 500"
            description=""
          />
        </div>
      </div>
      <NotFounditem img={Error500} title={
        <>
          <h1>500</h1>
          <h4>WHOOPS, SOMETHING WENT WRONG</h4>
        </>} subtitle={
        <>
          <p>We apologize for the inconvinience, it may be useful to try again in a few minutes. If the problem persists, contact the help desk</p>
          <p>Error 500 {getFormattedDate()}</p>
        </>
      }
      />
    </>
  }
  />
);

export default InternalServer;
