import { useState, useEffect } from "react";
// import Button from "../../../components/Button";
import PageTitle from "../../../components/PageTitle";
import { useUserState } from "../../../context/UserContext";
import Calendar from "../../../components/Calendar";
import useStyles from "../styles";
import Modal from "../../../components/Modal";
import { httprequest } from "../../../data/api";

const SCHOOL_PLAN = "/api/v1/school-plan";

const EmployeeSchoolPlan = () => {
  const { profile } = useUserState();
  const classes = useStyles();
  const [schoolPlans, setSchoolPlans] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({});
  const [currentStartDate, setCurrentStartDate] = useState("");
  const [currentEndDate, setCurrentEndDate] = useState("");

  const getSchoolPlans = () => {
    httprequest.get(SCHOOL_PLAN).then((res) => {
      setSchoolPlans(res?.data?.data);
    });
  };

  const planList = schoolPlans.map((plan) => ({
    title: plan?.title,
    start: plan?.start_date,
    end: plan?.end_date,
    description: plan?.description,
    className: "general-events"
  }));

  const handleEventClick = (args) => {
    setOpenModal(!openModal);
    const startDate = `${args?.event?.start?.getFullYear()}-${(args?.event?.start?.getMonth() + 1).toString().padStart(2, "0")}-${args?.event?.start?.getDate().toString().padStart(2, "0")}`;
    const endDate = `${args?.event?.end?.getFullYear()}-${(args?.event?.end?.getMonth() + 1).toString().padStart(2, "0")}-${args?.event?.end?.getDate().toString().padStart(2, "0")}`;
    setCurrentPlan(args?.event);
    setCurrentEndDate(endDate);
    setCurrentStartDate(startDate);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const renderPlanContent = (planInfo) => {
    return (
      <>
        <div className={classes.calendarbase}>
          <p className="no-margin-block padding-inline-10">{planInfo.event.title}</p>
          <p className="no-margin-block padding-inline-10"> {planInfo.event.extendedProps.description}</p>
        </div>
      </>
    );
  };

  useEffect(() => {
    getSchoolPlans();
  }, []);

  return (
    <>
      <div data={profile} className="breadcrumb-container">
        <PageTitle
          title="School Plans"
          subtitle="See all current school plans in your school."
        />
      </div>
      <div className={`${classes.calendarWrapper} margin-no-top`}>
        <Calendar events={planList} renderEventContent={renderPlanContent} defaultView="dayGridMonth" handleEventClick={handleEventClick} />
      </div>
      <Modal
        title={`${currentPlan?.extendedProps?.description}`}
        modalContent={
          <>
            <div><b>Date</b>: {currentStartDate} to {currentEndDate}</div>

          </>}
        isopen={openModal}
        closeModal={handleCloseModal}
      />
      {/* <div>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          weekends={false}
          headerToolbar={
            {
              start: "today prev,next",
              center: "title",
              end: "dayGridMonth,timeGridWeek,timeGridDay"
            }
          }
          events={planList}
          eventContent={renderPlanContent}
        />
      </div> */}
    </>
  );
};

export default EmployeeSchoolPlan;
