import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { TableSearch } from "../../../../components/Table/TableActions";
import Table from "../../../../components/Table/Table";
import { httprequest } from "../../../../data/api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import Layout from "../../../../components/Layout";
import PageTitle from "../../../../components/PageTitle";
import { Icon } from "@iconify/react";

const CLASS_ENDPOINT = "/api/v1/class";
const CLASS_SUBJECT_ENDPOINT = "/api/v1/class-subject";

const EditTimeTable = () => {
  const [classData, setClassData] = useState([]);
  const [value, setValue] = useState("");
  const [subjectByClass, setSubjectByClass] = useState([]);
  const [subjectResultByClass, setSearchResultByClass] = useState([]);

  // --| handle change for tab
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAllClasses = () => {
    // setIsClassFetching(true);
    httprequest
      .get(CLASS_ENDPOINT)
      .then((res) => {
        setClassData(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getSubjectsClass = () => {
    httprequest
      .get(`${CLASS_SUBJECT_ENDPOINT}/${value}`)
      .then((res) => {
        setSubjectByClass(res?.data?.data);
        setSearchResultByClass(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const subjectColumns = [
    {
      name: "Subject",
      selector: row => row?.subject_name
    },
    {
      name: "Class",
      selector: () => ""
    },
    {
      name: "Date Created",
      selector: row => row?.created_at?.substring(0, 10)
    },
    {
      name: "Date Updated",
      selector: row => row?.updated_at?.substring(0, 10)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button className="table-action-icon">
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            // onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }

  ];

  // --| Filter Subject table using name, email and class
  const handleSearchSubjectByClass = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = subjectByClass?.filter((data) => valArray?.every(
      (word) => data?.subject_name
        ?.toLowerCase()
        .includes(word.toLowerCase())
    )
    );
    setSearchResultByClass(result);
  };

  useEffect(() => {
    getAllClasses();
    getSubjectsClass();
  }, []);

  return (
    <Layout children={
      <div>
        <PageTitle
          title="Edit Timetable"
          subtitle="Edit a Class Timetable"
        />
        <div className="page-content">
          <TabContext value={value}>
            <div className="tablist-container">
              <Tabs
                indicatorColor="none"
                className="overide-tablist"
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {classData?.map((data) => {
                  return (
                    <Tab
                      key={data?.id}
                      value={data?.id}
                      label={data?.class_name}
                    />
                  );
                })}
              </Tabs>
            </div>
            {classData?.map((data) => {
              return (
                <TabPanel key={data?.id} value={data?.id}>
                  <Table
                    data={subjectResultByClass}
                    columns={subjectColumns}
                    subHeader={true}
                    pagination
                    subHeaderComponent={
                      subjectResultByClass?.length > 0 && (
                        <TableSearch
                          placeholder="Search here..."
                          searchTableFunc={handleSearchSubjectByClass}
                        />
                      )}
                  />
                </TabPanel>
              );
            })}
          </TabContext>
        </div>

      </div>
    }
    />
  );
};

export default EditTimeTable;
