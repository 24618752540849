import Layout from "../../../../components/Layout";
import { httprequest } from "../../../../data/api";
import useStyles from "../../styles";
import PageTitle from "../../../../components/PageTitle";
// import { useTruncate } from "../../../../hooks/useTruncate";
import PageLoader from "../../../../components/PageLoader";
import Modal from "../../../../components/Modal";
import { Grid } from "@mui/material";
// import NotFounditem from "../../../error/NotFound";
// import nosearch from "../../../../assets/images/no-search.svg";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import InputGroup from "../../../../components/FormComponent/InputGroup";
import Button from "../../../../components/Button";
import { ToastContainer, toast } from "react-toastify";
import { TableSearch } from "../../../../components/Table/TableActions";
import ButtonCount from "../../../../components/ButtonCount";
import Table from "../../../../components/Table/Table";
import useValidator from "../../../../hooks/useValidator";
import GradeCard from "./GradeCard";

const GRADING_SYSTEM = "/api/v1/grading-system";

const GradingSystem = () => {
  const [searchResult, setSearchResult] = useState([]);
  const [fetchingSystem, setIsFetchingSystem] = useState(false);
  const [gradeValidator, showGradeValidator] = useValidator();
  const [editGradeValidator, showEditGradeValidator] = useValidator();
  const [gradeModal, setGradeModal] = useState(false);
  const [allGradingSystem, setAllGradingSystem] = useState([]);
  const [gradeCreating, setGradeCreating] = useState((false));
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [currentSelected, setCurrentSelected] = useState({});
  const [isDeleting, setGradeDeleting] = useState(false);
  const [isediting, setGradeEditing] = useState(false);
  const [editGradeModal, setEditGradeModal] = useState(false);
  const [gradeResultForm, setGradeResultForm] = useState({
    title: "",
    grading: []
  });

  const [editGrade, setEditGrade] = useState({
    title: "",
    grading: []
  });

  // Handle Close Edit Modal
  const handleCloseEditGradeModal = () => {
    setEditGradeModal(false);
  };

  const handleEditGradeSystem = (row) => {
    setCurrentSelected(row);
    setEditGradeModal(!editGradeModal);
    setEditGrade({
      ...editGrade,
      title: row?.title,
      grading: row?.grading
    });
  };

  const [gradeArr, setGradeArr] = useState([
    { grade: "", min: 0, max: 0 }
  ]);
  const classes = useStyles();

  const handleGradeModal = () => {
    setGradeModal(!gradeModal);
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;

    // Set the updated state
    setGradeResultForm({ ...gradeResultForm, [name]: value });
  };

  // update Edit form
  const handleEDitTextChange = (e) => {
    const { name, value } = e.target;

    // Set the updated state
    setEditGrade({ ...editGrade, [name]: value });
  };

  const handleDeleteModal = (row) => {
    setCurrentSelected(row);
    setDeleteModal(!deleteModal);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  // Handle View Grade System Modal
  const handleViewGradeSystem = (row) => {
    setCurrentSelected(row);
    setViewModal(!viewModal);
  };

  // handle close view modal
  const handleCloseViewGradeSyetem = () => {
    setViewModal(false);
  };

  // Handle Get all
  const handleGetAllGradingSystem = () => {
    setIsFetchingSystem(true);
    httprequest.get(GRADING_SYSTEM).then((res) => {
      setIsFetchingSystem(false);
      setAllGradingSystem(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsFetchingSystem(false);
      toast.error(err?.response?.data?.message);
    });
  };

  const handleCloseAddResultModal = () => {
    setGradeModal(false);
    setGradeArr([{ grade: "", min: 0, max: 0 }]);
    setGradeResultForm({
      ...gradeResultForm,
      title: "",
      grading: []
    });
  };

  const handleDeleteGradeSystem = () => {
    setGradeDeleting(true);
    httprequest.delete(`${GRADING_SYSTEM}/${currentSelected?.id}`).then((res) => {
      setGradeDeleting(false);
      toast.success(res?.data?.message);
      handleGetAllGradingSystem();
      handleCloseDeleteModal();
    }).catch((err) => {
      setGradeDeleting(false);
      toast.error(err?.response?.data?.message);
    });
  };

  // handle post Grading System
  const handlePostGradingSystem = () => {
    if (gradeValidator.allValid()) {
      setGradeCreating(true);
      httprequest.post(GRADING_SYSTEM, gradeResultForm).then((res) => {
        setGradeCreating(false);
        toast.success(res?.data?.message);
        handleGetAllGradingSystem();
        handleCloseAddResultModal();
      }).catch((err) => {
        setGradeCreating(false);
        toast.error(err?.response?.data);
      });
    } else {
      showGradeValidator(true);
    }
  };

  // EDit Grading System
  const handleEditGradingSystem = () => {
    if (editGradeValidator.allValid()) {
      setGradeEditing(true);
      httprequest.patch(`${GRADING_SYSTEM}/${currentSelected?.id}`, editGrade).then((res) => {
        setGradeEditing(false);
        toast.success(res?.data?.message);
        handleGetAllGradingSystem();
        handleCloseEditGradeModal();
      }).catch((err) => {
        setGradeEditing(false);
        toast.error(err?.response?.data);
      });
    } else {
      showEditGradeValidator(true);
    }
  };

  useEffect(() => {
    handleGetAllGradingSystem();
  }, []);

  const handleGradeScoreChange = (e, index, field) => {
    const { value } = e.target;

    setGradeArr((prevGrades) => {
      const updatedGrades = [...prevGrades];
      const currentGrade = updatedGrades[index] || { grade: "", min: 0, max: 0 };

      // Convert `value` to a number for "min" and "max"
      currentGrade[field] = field === "min" || field === "max" ? Number(value) : value;
      currentGrade.validationMessage = ""; // Reset validation message

      updatedGrades[index] = currentGrade;

      let isValid = true;
      let validationMessage = "";

      // Validate only when "max" is being input
      if (field === "max") {
        const min = Number(currentGrade.min);
        const max = Number(currentGrade.max);

        if (min >= max) {
          isValid = false;
          validationMessage = `Max must be greater than Min for Grade ${currentGrade?.grade || index + 1}`;
        }

        // Check for overlaps with other grades
        updatedGrades.forEach((grade, i) => {
          if (i !== index) {
            const otherMin = Number(grade.min);
            const otherMax = Number(grade.max);

            if (
              (min >= otherMin && min <= otherMax) ||
              (max >= otherMin && max <= otherMax)
            ) {
              isValid = false;
              validationMessage = `Grade ${currentGrade?.grade || index + 1} range overlaps with Grade ${grade.grade || i + 1}`;
            }
          }
        });
      }

      // Update validation message
      currentGrade.validationMessage = validationMessage;

      if (!isValid) {
        return updatedGrades; // Update with validation message
      }

      // Update gradeResultForm if input is valid
      setGradeResultForm((prevForm) => ({
        ...prevForm,
        grading: updatedGrades
      }));

      return updatedGrades;
    });
  };

  const handleEditGradeScoreChange = (e, index, field) => {
    const { value } = e.target;

    setEditGrade((prevEditGrade) => {
      const updatedGrades = [...prevEditGrade.grading];
      const currentGrade = updatedGrades[index] || { grade: "", min: 0, max: 0 };

      // Convert `value` to a number for "min" and "max"
      currentGrade[field] = field === "min" || field === "max" ? Number(value) : value;
      currentGrade.validationMessage = "";

      updatedGrades[index] = currentGrade;

      let isValid = true;
      let validationMessage = "";

      // Validate only when "max" is being input
      if (field === "max") {
        const min = Number(currentGrade.min);
        const max = Number(currentGrade.max);

        if (min >= max) {
          isValid = false;
          validationMessage = `Max must be greater than Min for Grade ${currentGrade?.grade || index + 1}`;
        }

        // Check for overlaps with other grades
        updatedGrades.forEach((grade, i) => {
          if (i !== index) {
            const otherMin = Number(grade.min);
            const otherMax = Number(grade.max);

            if (
              (min >= otherMin && min <= otherMax) ||
              (max >= otherMin && max <= otherMax)
            ) {
              isValid = false;
              validationMessage = `Grade ${currentGrade?.grade || index + 1} range overlaps with Grade ${grade.grade || i + 1}`;
            }
          }
        });
      }

      // Update validation message
      currentGrade.validationMessage = validationMessage;

      if (!isValid) {
        return {
          ...prevEditGrade,
          grading: updatedGrades // Update with validation message
        };
      }

      // Update gradeResultForm if input is valid
      setEditGrade((prevForm) => ({
        ...prevForm,
        grading: updatedGrades
      }));

      return {
        ...prevEditGrade,
        grading: updatedGrades
      };
    });
  };

  const handleAddGrade = () => {
    setGradeArr((prevGrades) => [
      ...prevGrades,
      { grade: "", min: "", max: "" }
    ]);
  };

  const handleAddEditGrade = () => {
    setEditGrade((prevEditGrade) => ({
      ...prevEditGrade,
      grading: [
        ...prevEditGrade.grading,
        { grade: "", min: "", max: "", validationMessage: "" }
      ]
    }));
  };

  const handleDeleteGrade = (index) => {
    setGradeArr((prevGrades) => prevGrades.filter((_, i) => i !== index));
    toast.error(`Grade ${index + 1} removed successfully!`);
  };

  const handleDeleteEditGrade = (index) => {
    setEditGrade((prevState) => {
      const updatedGrading = prevState.grading.filter((_, i) => i !== index);

      return {
        ...prevState,
        grading: updatedGrading
      };
    });
  };

  const columns = [
    {
      name: "Title",
      selector: (row) => {
        return row?.title || "--/--";
      }
    },
    {
      name: "Grades",
      selector: (row) => {
        return row?.grading?.map((g) => g.grade).join(", ") || "--/--";
      },
      wrap: true
    },
    {
      name: "Min Score",
      selector: (row) => {
        return row?.grading?.[row.grading.length - 1]?.min ?? "--/--";
      },
      sortable: true
    },
    {
      name: "Max Score",
      selector: (row) => {
        return row?.grading?.[0]?.max ?? "--/--";
      },
      sortable: true
    },
    {
      name: "",
      center: "true",
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button className="table-action-icon"
            onClick={() => handleViewGradeSystem(row)}
          >
            <Icon icon="fluent:eye-16-regular" />
          </button>
          <button
            className="table-action-icon"
            onClick={() => handleEditGradeSystem(row)}
          >
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            className="table-action-icon delete-icon"
            onClick={() => handleDeleteModal(row)}
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  // --| Filter Grade table using title, Grade, Score
  const handleSearchGrade = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = allGradingSystem?.filter((data) => valArray?.every(
      (word) => data?.title?.toLowerCase().includes(word.toLowerCase()) ||
              data?.description?.toLowerCase().includes(word.toLowerCase()) ||
              data?.priority?.toLowerCase().includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Grading System"
            subtitle="Create and View Grading System in your school."
            button={
              <div className="flex gap-10">
                <Button
                  variant="primary"
                  buttonSize="fluid"
                  color="btndefault"
                  onClick={handleGradeModal}
                  buttonName="Create Grading System"
                />
              </div>
            }
          />
          <div className="page-content">
            <div className="button-count-container">
              <ButtonCount title="Grade System" count={allGradingSystem?.length} />
            </div>
            {fetchingSystem ? (
              <PageLoader />
            ) : (
              <Table
                data={searchResult}
                columns={columns}
                subHeader={true}
                subHeaderComponent={
                  <TableSearch
                    placeholder="Search by title..."
                    searchTableFunc={handleSearchGrade}
                  />
                }
                pagination
              />
            )}
          </div>
          {/* Add Grade System */}
          <Modal
            title="Add Grading System"
            subtitle={
              <div>
                Manually Create your Grading System.
              </div>
            }
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Title"
                      margin="normal"
                      required
                      placeHolder="Enter Title"
                      inputType="text"
                      inputName="title"
                      onChange={handleTextChange}
                    />
                    {gradeValidator.message("Title", gradeResultForm?.title, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {gradeArr.map((item, index) => (
                      <Grid container key={index} spacing={2}>
                        {/* Grade Input */}
                        <Grid item lg={4} md={12} sm={12} xs={12}>
                          <InputGroup
                            label={`Grade ${index + 1}`}
                            margin="normal"
                            required
                            inputName="grading"
                            placeHolder="Enter grade (e.g., A)"
                            inputType="text"
                            onChange={(e) => handleGradeScoreChange(e, index, "grade")}
                            value={item.grade}
                          />
                          {gradeValidator.message(
                            `Grade ${index + 1}`,
                            item.grade,
                            "required"
                          )}
                        </Grid>
                        {/* Min Score Input */}
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                          <InputGroup
                            label="Set Grade Min"
                            margin="normal"
                            required
                            placeHolder="Enter minimum score"
                            inputType="text"
                            inputName="min"
                            onChange={(e) => handleGradeScoreChange(e, index, "min")}
                            // value={item.min}
                          />
                          {gradeValidator.message(
                            `Grade Min for ${item.grade || `Grade ${index + 1}`}`,
                            Number(item?.min),
                            "required"
                          )}
                          {item.validationMessage && (
                            <p className={classes.validation}>
                              {item.validationMessage}
                            </p>
                          )}
                        </Grid>
                        {/* Max Score Input */}
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                          <InputGroup
                            label="Set Grade Max"
                            margin="normal"
                            required
                            placeHolder="Enter maximum score"
                            inputType="text"
                            inputName="max"
                            onChange={(e) => handleGradeScoreChange(e, index, "max")}
                            // value={item.max}
                          />
                          {gradeValidator.message(
                            `Grade Max for ${item.grade || `Grade ${index + 1}`}`,
                            Number(item.max),
                            "required"
                          )}
                          {item.validationMessage && (
                            <p className={classes.validation}>
                              {item.validationMessage}
                            </p>
                          )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          {/* Delete Button */}
                          {gradeArr.length > 1 && (
                            <p
                              className="delete-grade-input"
                              onClick={() => handleDeleteGrade(index)}
                            >
                              <Icon icon="zondicons:minus-solid" />
                                Remove this Grade
                            </p>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {gradeArr.length < 13 && (
                      <div onClick={handleAddGrade} className={`${classes.mail} flex align-items-center`}>
                        <Icon icon="octicon:feed-plus-16" width="16" height="16" />
                        <p>Add another Grade</p>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button
                    variant="primary"
                    buttonSize="full"
                    color="btndefault"
                    buttonName="Create Grading System"
                    onClick={handlePostGradingSystem}
                    isLoading={gradeCreating}
                  />
                </div>
              </>
            }
            isopen={gradeModal}
            closeModal={handleCloseAddResultModal}
          />
          {/* Edit Grade System */}
          <Modal
            title="Edit Grading System"
            subtitle={
              <div>
                Manually Edit Grading System.
              </div>
            }
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Title"
                      margin="normal"
                      required
                      placeHolder="Enter Title"
                      inputType="text"
                      inputName="title"
                      defaultValue={editGrade?.title}
                      onChange={handleEDitTextChange}
                    />
                    {editGradeValidator.message("Title", editGrade?.title, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {editGrade?.grading.map((item, index) => (
                      <Grid container key={index} spacing={2}>
                        {/* Grade Input */}
                        <Grid item lg={4} md={12} sm={12} xs={12}>
                          <InputGroup
                            label={`Grade ${index + 1}`}
                            margin="normal"
                            required
                            inputName="grading"
                            placeHolder="Enter grade (e.g., A)"
                            inputType="text"
                            defaultValue={item?.grade}
                            onChange={(e) => handleEditGradeScoreChange(e, index, "grade")}
                            value={item.grade}
                          />
                          {editGradeValidator.message(
                            `Grade ${index + 1}`,
                            item.grade,
                            "required"
                          )}
                        </Grid>
                        {/* Min Score Input */}
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                          <InputGroup
                            label="Set Grade Min"
                            margin="normal"
                            required
                            placeHolder="Enter minimum score"
                            inputType="text"
                            inputName="min"
                            defaultValue={item?.min}
                            onChange={(e) => handleEditGradeScoreChange(e, index, "min")}
                            // value={item.min}
                          />
                          {editGradeValidator.message(
                            `Grade Min for ${item.grade || `Grade ${index + 1}`}`,
                            Number(item?.min),
                            "required"
                          )}
                          {item.validationMessage && (
                            <p className={classes.validation}>
                              {item.validationMessage}
                            </p>
                          )}
                        </Grid>
                        {/* Max Score Input */}
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                          <InputGroup
                            label="Set Grade Max"
                            margin="normal"
                            required
                            placeHolder="Enter maximum score"
                            inputType="text"
                            inputName="max"
                            defaultValue={item?.max}
                            onChange={(e) => handleEditGradeScoreChange(e, index, "max")}
                            // value={item.max}
                          />
                          {editGradeValidator.message(
                            `Grade Max for ${item.grade || `Grade ${index + 1}`}`,
                            Number(item.max),
                            "required"
                          )}
                          {item.validationMessage && (
                            <p className={classes.validation}>
                              {item.validationMessage}
                            </p>
                          )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          {/* Delete Button */}
                          {editGrade?.grading?.length > 1 && (
                            <p
                              className="delete-grade-input"
                              onClick={() => handleDeleteEditGrade(index)}
                            >
                              <Icon icon="zondicons:minus-solid" />
                                Remove this Grade
                            </p>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {editGrade?.grading.length < 13 && (
                      <div onClick={handleAddEditGrade} className={`${classes.mail} flex align-items-center`}>
                        <Icon icon="octicon:feed-plus-16" width="16" height="16" />
                        <p>Add another Grade</p>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button
                    variant="primary"
                    buttonSize="full"
                    color="btndefault"
                    buttonName="Edit Grading System"
                    onClick={handleEditGradingSystem}
                    isLoading={isediting}
                  />
                </div>
              </>
            }
            isopen={editGradeModal}
            closeModal={handleCloseEditGradeModal}
          />
          {/* View Grade System */}
          <Modal
            title="View Grading System"
            subtitle={
              <div>
                View Grading System.
              </div>
            }
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.viewtitle}>
                      <p>{currentSelected?.title}</p>
                    </div>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={2}>
                      {currentSelected?.grading?.map((item, index) => {
                        const range = `${item.min} - ${item.max}`;

                        return (
                          <Grid item lg={3} md={4} sm={6} xs={6} key={index}>
                            <GradeCard grade={item.grade} range={range} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
              </>
            }
            isopen={viewModal}
            closeModal={handleCloseViewGradeSyetem}
          />
          {/* Delete Grade System Modal */}
          <Modal
            title="Delete Grading System"
            subtitle={
              <div>Are you sure you want to delete <span className="modal-name-highlight">{currentSelected?.title}</span>. Implication is that the grade system wont have available anymore.`
              </div>}
            modalContent={
              <>

              </>
            }
            modalFooter={
              <>
                <Button variant="danger" buttonName="Delete Grading System" buttonSize="full" color="btndefault" onClick={handleDeleteGradeSystem} isLoading={isDeleting} />
              </>
            }
            isopen={deleteModal}
            closeModal={handleCloseDeleteModal}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default GradingSystem;
