import Layout from "../../components/Layout";
// import EmployeeSubject from "./employee/EmployeeSubject";
import EmployerSubject from "./employer/EmployerSubject";
import StudentSubject from "./student/Student";
import { useUserState } from "../../context/UserContext";

const Profile = () => {
  const { userRole } = useUserState();

  return (
    <Layout children={
      <>
        {userRole?.role === "school_admin" ? (
          <EmployerSubject />
        ) : <StudentSubject /> }
      </>
    }
    />
  );
};

export default Profile;
