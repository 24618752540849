import { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
import { Icon } from "@iconify/react";
import { Link, useParams } from "react-router-dom";
import { httprequest } from "../../../data/api";
import { ToastContainer, toast } from "react-toastify";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import Select from "react-select";
import PageLoader from "../../../components/PageLoader";
import { Grid } from "@mui/material";

const ADMIN_CLASS_ENDPOINT = "/api/v1/class";
const SESSION_ENDPOINT = "/api/v1/school-plan/session";
const CLASS_STUDENT_ENDPOINT = "/api/v1/students";
const StudentInClass = () => {
  const [searchResult, setSearchResult] = useState([]);
  const [studentClassData, setStudentClassData] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const { id } = useParams();
  const [downloadModal, setDownloadModal] = useState(false);
  const [aSession, setASession] = useState("");
  const [sessionData, setSessionData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const handleEditSessionChange = (e) => {
    const { value } = e.target;
    setASession(value);
  };

  const getStudentClass = () => {
    setIsFetching(true);
    httprequest.get(`${CLASS_STUDENT_ENDPOINT}?class_id=${id}`).then((res) => {
      setIsFetching(false);
      setStudentClassData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsFetching(false);
      toast.error(err?.response?.data);
    });
  };

  // On Page Load, set search result data to be the initial data
  useEffect(() => {
    getStudentClass();
  }, []);

  const handleDownloadModal = () => {
    setDownloadModal(!downloadModal);
  };

  const columns = [
    {
      name: "Class Name",
      selector: row => row?.class?.name || "--/--"
    },
    {
      name: "Student Name",
      // eslint-disable-next-line prefer-template
      selector: (row) => row?.student?.frst_name + " " + row?.student?.last_name || "---------"
    },
    {
      name: "Gender",
      selector: row => row?.gender || "--/--"
    },
    {
      name: "Student Email",
      selector: row => row?.email || "--/--"
    },
    {
      name: "Guardian",
      selector: (row) => row?.guardian
        ? `${row?.guardian?.first_name || ""} ${row?.guardian?.last_name || ""}`.trim() || "---------"
        : "---------"
    },
    {
      name: "Admission Date",
      selector: row => row?.admission_date?.substring(0, 10) || "--/--"
    },
    {
      name: "",
      center: "true",
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/users/students/${row?.student_id}`} className="table-action-icon">
            <Icon icon="fluent:eye-16-regular" />
          </Link>
        </div>
      )
    }
  ];

  // --| Filter Student Class using name, email and class
  const handleStudentClass = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = studentClassData?.filter((data) => valArray?.every(
      (word) => data?.first_name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
        data?.first_name
          ?.toLowerCase()
          .includes(word.toLowerCase()) ||
                    data?.student_email
                      ?.toLowerCase()
                      .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };
  const handleRowSelection = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handlePromote = () => {
    const selectedIds = selectedRows.map((row) => row.student_id); // Extract IDs
    // eslint-disable-next-line no-console
    console.log("Promoted Students IDs:", selectedIds);
    // eslint-disable-next-line no-console
    console.log("Selected Classes for Promotion:", selectedClasses);
  };
  const getAllAdminClasses = () => {
    httprequest
      .get(ADMIN_CLASS_ENDPOINT)
      .then((res) => {
        setClassData(res?.data?.data);
      }).catch(() => {});
  };
  const getAllSession = () => {
    httprequest
      .get(SESSION_ENDPOINT)
      .then((res) => {
        setSessionData(res?.data?.data);
      })
      .catch(() => {});
  };

  const handleMultipleAccessChange = async (selectedOptions) => {
    // Extract selected class IDs and names
    const selectedValues = selectedOptions.map((option) => ({
      id: option?.id,
      name: option?.class_name
    }));

    // Save selected classes to state
    setSelectedClasses(selectedValues);
  };

  useEffect(() => {
    getAllSession();
    getAllAdminClasses();
  }, [downloadModal]);

  return (
    <>
      {isFetching ? (
        <PageLoader />
      ) : (
        <Table handleRowSelected={handleRowSelection} data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
          searchResult?.length > 0 && (
            <div className="action_div">
              <TableSearch placeholder="Search here..." searchTableFunc={handleStudentClass} />
              {selectedRows.length > 1 && (
                <Button
                  variant="primaryoutline"
                  buttonSize="fluid"
                  color="btnfontprimary"
                  buttonName="Promote Student"
                  onClick={handleDownloadModal}
                />
              )}

            </div>
          )}
        />
      )}
      <Modal
        title="Promote Selcted Student"
        subtitle=""
        modalContent={
          <>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="multiple-select">
                  <p>Class</p>
                  <Select
                    name="class_id"
                    closeMenuOnSelect={false}
                    options={classData}
                    onChange={handleMultipleAccessChange}
                    getOptionLabel={(option) => option.class_name}
                    getOptionValue={(option) => option.id}
                  />

                </div>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* {activeSession?.id && (

                    )} */}
                <SelectGroup
                  label="Select Session"
                  required
                  children={
                    <select
                      value={aSession}
                      onChange={handleEditSessionChange}
                    >
                      <option value="">---Select---</option>
                      {sessionData?.map((sesh) => {
                        return (
                          <option key={sesh?.id} value={sesh?.id}>
                            {sesh?.session}
                          </option>
                        );
                      })}
                    </select>
                  }
                />
                {/* {createExamValidator.message("Session", schoolExaminationForm?.session, "required")} */}
              </Grid>

            </Grid>
          </>
        }
        modalFooter={
          <>
            <Button
              variant="primary"
              buttonSize="full"
              color="btndefault"
              buttonName="Promote Student"
              onClick={handlePromote}
            />
          </>
        }
        isopen={downloadModal}
        closeModal={handleDownloadModal}
      />
      <ToastContainer />
    </>
  );
};

export default StudentInClass;
