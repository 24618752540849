import ReactPlayer from "react-player";
import { useState } from "react";
import Modal from "../Modal";
import Button from "../Button";

const PopModal = ({ title, videoUrl }) => {
  const [helpModal, setHelpModal] = useState(false);

  const handleCloseVideoModal = () => {
    setHelpModal(false);
  };
  const handleHelpModal = () => {
    setHelpModal(!helpModal);
  };

  return (
    <>
      <Button
        variant="primaryoutline"
        buttonSize="fluid"
        color="btnfontprimary"
        buttonName="?"
        onClick={handleHelpModal}
      />
      <Modal
        title="Need Help?"
        subtitle={`Watch the Video Below on ${title}`}
        modalContent={
          <>
            <ReactPlayer
              url={videoUrl}
              playing={true}
              controls={true}
              width="100%"
              className="responsive-player"

            />
          </>
        }
        modalFooter={
          <>
            <Button
              variant="primary"
              buttonSize="full"
              color="btndefault"
              onClick={handleCloseVideoModal}
              buttonName="Close Video"
            />
          </>}
        isopen={helpModal}
        closeModal={handleCloseVideoModal}
      />
    </>
  );
};

export default PopModal;
