import Layout from "../../components/Layout";
import TabContext from "@mui/lab/TabContext";
import PageTitle from "../../components/PageTitle";
import TabPanel from "@mui/lab/TabPanel";
import { useState, useEffect } from "react";
import Button from "../../components/Button";
import CreateEnrolment from "./components/CreateEnrolment";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";
import Table from "../../components/Table/Table";
import Badge from "../../components/Badge";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import useValidator from "../../hooks/useValidator";
import useStyles from "./styles";
import Modal from "../../components/Modal";
import { Grid, Tooltip } from "@mui/material";
import Select from "react-select";
import Popover from "@mui/material/Popover";
import PopModal from "../../components/PopupModal/PopModal";

const ENROLMENT_ENDPOINT = "/api/v1/enrolment";

const ALL_ENROLMENT_EXAM = "/api/v1/enrolment-exams";

const Index = () => {
  const classes = useStyles();
  // const [value, setValue] = useState("1");
  const [enrolmentData, setEnrolmentData] = useState([]);
  const [enrolmentExamData, setAllEnrollmenteExamData] = useState([]);
  const [isEnrolmentFetching, setIsEnrolmentFetching] = useState(false);
  const [editEnrolmentModal, setEditEnrolmentModal] = useState(false);
  const [assignValidator, showAssignValidator] = useValidator();
  const [isEditing, setIsEditing] = useState(false);
  const [isAttaching, setIsAttaching] = useState(false);
  const [currentEnrolment, setCurrentEnrolment] = useState({});
  const [addExamModal, setAddExamModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const navigate = useNavigate();
  const [schoolId, setSchoolId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopoverOpen = Boolean(anchorEl);

  const handlePopoverOpen = (event, row) => {
    setCurrentRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const [assignForm, setAssignForm] = useState({
    exam_id: []
  });

  const handleEditEnrolmentModal = (row) => {
    setEditEnrolmentModal(!editEnrolmentModal);
    setCurrentEnrolment(row);
  };

  const handleCloseEditEnrolmentModal = () => {
    setEditEnrolmentModal(false);
    setCurrentEnrolment({});
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const handleMultipleAccessChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option?.id);
    setAssignForm({ ...assignForm, exam_id: selectedValues });
  };

  // Attach an Exam to an Enrolment
  const handleAddExamModal = (row) => {
    setCurrentEnrolment(row);
    setAddExamModal(!addExamModal);
  };

  const getAllEnrolmentExam = () => {
    httprequest.get(ALL_ENROLMENT_EXAM).then((res) => {
      setAllEnrollmenteExamData(res?.data?.data.filter((published_only) => published_only?.status === "published"));
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const handleCloseConvertExam = () => {
    setAddExamModal(false);
  };

  const handleAttachEnrolmentExam = () => {
    if (assignValidator.allValid()) {
      setIsAttaching(true);
      httprequest
        .post(`${ENROLMENT_ENDPOINT}/${currentEnrolment?.id}/exams/attach`, { ...assignForm })
        .then((res) => {
          setIsAttaching(false);
          toast.success(res?.data?.message);
          handleCloseConvertExam();
        })
        .catch((err) => {
          setIsAttaching(false);
          toast.error(err?.response?.data?.message);
        });
    } else {
      showAssignValidator(true);
    }
  };

  useEffect(() => {
    getAllEnrolmentExam();
  }, []);

  const baseUrl = window.location.origin;

  useEffect(() => {
    if (enrolmentData.length > 0) {
      const activeEnrolment = enrolmentData.find((enrolment) => enrolment.open);
      if (activeEnrolment) {
        setSchoolId(activeEnrolment.school_id);
      }
    }
  }, [enrolmentData]);

  const copyToClipboard = () => {
    const enrolmentUrl = `${baseUrl}/enrol/${schoolId}`;

    navigator.clipboard.writeText(enrolmentUrl)
      .then(() => {
        toast.success("Enrolment link copied to clipboard!");
      })
      .catch(() => {
        toast.error("Failed to copy link");
      });
  };

  const columns = [
    {
      name: "Title",
      selector: (row) => row?.title || "--/--"
    },
    {
      name: "Closing Date",
      selector: (row) => row?.closing_date?.substring(0, 10) || "--/--"
    },
    {
      name: "Classes Open",
      selector: (row) => row?.classes?.map((clss) => (
        <span className="class_div" key={clss?.clss_id}>
          {clss?.class_name}
        </span>
      )) || "--/--"
    },
    {
      name: "Date Created",
      selector: (row) => row?.created_at?.substring(0, 10) || "--/--"
    },
    {
      name: "Date Updated",
      selector: (row) => row?.created_at?.substring(0, 10) || "--/--"
    },
    {
      name: "Status",
      cell: (row) => <Badge status={row?.open} />,
      center: "true"
    },
    {
      name: "Actions",
      center: "true",
      sortable: false,
      cell: (row) => (
        <div className="table-action-container">
          <button
            className="table-action-icon"
            onClick={(event) => handlePopoverOpen(event, row)}
            aria-controls={isPopoverOpen ? "action-popover" : null}
            aria-haspopup="true"
            aria-expanded={isPopoverOpen ? "true" : null}
          >
            <Icon icon="mdi:dots-horizontal" />
          </button>

          <Popover
            id="action-popover"
            open={isPopoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <div className={classes.flexitems}>
              <Tooltip title="Preview Enrolment" arrow>
                <Link
                  className="table-action-icon"
                  to={`/enrolment-preview/${currentRow?.id}`}
                  target="_blank"
                >
                  <Icon icon="majesticons:open" />
                </Link>
              </Tooltip>
              <Tooltip title="View Enrolment" arrow>
                <Link to={`/enrolments/${currentRow?.id}`}>
                  <button className="table-action-icon">
                    <Icon icon="fluent:eye-16-regular" />
                  </button>
                </Link>
              </Tooltip>
              <Tooltip title="Edit Enrolment" arrow>
                <Link to={`edit/${currentRow?.id}`} className="table-action-icon">
                  <Icon icon="lucide:edit" />
                </Link>
              </Tooltip>
              <Tooltip title="Add Exam to Enrolment" arrow>
                <button
                  onClick={() => handleAddExamModal(currentRow)}
                  className="table-action-icon"
                >
                  <Icon icon="healthicons:i-exam-multiple-choice" />
                </button>
              </Tooltip>
              {currentRow?.open === true ? (
                <Tooltip title="Close Enrolment" arrow>
                  <button
                    className="table-action-icon"
                    onClick={() => handleEditEnrolmentModal(currentRow)}
                  >
                    <Icon icon="zondicons:close-outline" width="20" height="20" />
                  </button>
                </Tooltip>
              ) : (
                <Tooltip title="Open Enrolment" arrow>
                  <button
                    className="table-action-icon"
                    onClick={() => handleEditEnrolmentModal(currentRow)}
                  >
                    <Icon icon="typcn:lock-open-outline" width="24" height="24" />
                  </button>
                </Tooltip>
              )}
            </div>
          </Popover>
        </div>
      )
    }

  ];

  const getAllEnrolments = () => {
    setIsEnrolmentFetching(true);
    httprequest
      .get(ENROLMENT_ENDPOINT)
      .then((res) => {
        setIsEnrolmentFetching(false);
        setEnrolmentData(res?.data?.data);
      })
      .catch((err) => {
        setIsEnrolmentFetching(false);
        toast.error(err?.response?.data);
      });
  };

  const handleEnrolmentStatus = () => {
    setIsEnrolmentFetching(true);
    httprequest
      .patch(`${ENROLMENT_ENDPOINT}/${currentEnrolment?.id}`, {
        open: !currentEnrolment?.open
      })
      .then(() => {
        setIsEditing(false);
        setCurrentEnrolment({});
        setEditEnrolmentModal(false);
        getAllEnrolments();
      })
      .catch((err) => {
        setIsEditing(false);
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    getAllEnrolments();
  }, []);

  const formattedEnrolmentData = enrolmentExamData?.map((exam) => ({
    ...exam,
    label: `${exam?.subject} | ${exam?.classes.map((a_class) => a_class?.name).join(", ")} | ${exam?.duration} minutes`
  }));
  const activeEnrolment = enrolmentData.some((enrolment) => enrolment.open);

  return (
    <Layout children={
      <>
        <PageTitle
          title="Enrolments"
          button={
            <div className="flex gap-10">
              <PopModal videoUrl="https://youtu.be/ap8lLoyZhmo" title="Enrolment" />
              <Button onClick={() => {
                navigate("/enrolments/applications");
              } } variant="primaryoutline" buttonSize="fluid" color="btnfontprimary" buttonName="View Applications"
              />
              <Button
                onClick={() => {
                  const activeEnrolment = enrolmentData.some((enrolment) => enrolment.open);
                  if (activeEnrolment) {
                    toast.error("Please close the active enrolment before creating a new one.");
                  } else {
                    navigate("/enrolments/create");
                  }
                }}
                variant="primary"
                buttonSize="fluid"
                color="btndefault"
                buttonName="Create Enrolment"
              />

            </div>
          }
        />
        <div className="page-content">
          <TabContext>
            <TabPanel className="tabpanel-overide">
              <Table
                data={enrolmentData}
                columns={columns}
                pagination
                isLoading={isEnrolmentFetching}
                subHeader={activeEnrolment}
                handleRowSelected={null}
                subHeaderComponent={
                  activeEnrolment && (
                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                      <Button
                        variant="primaryoutline"
                        buttonSize="fluid"
                        color="btnfontprimary"
                        onClick={copyToClipboard}
                        buttonName="Copy Enrolment Link"
                        buttonIcon={<Icon icon="solar:copy-linear" />}
                      />
                    </div>
                  )
                }
              />

            </TabPanel>
            <TabPanel value="2" className="tabpanel-overide">
              <CreateEnrolment />
            </TabPanel>
          </TabContext>

          {/* Edit Enrolment (Close/Open Enrolment) */}
          <Modal
            title="Open or Close the Enrolment Process"
            subtitle={
              <div>
                  Note that opening/closing this manages your users access to
                  the application page.
              </div>
            }
            modalContent={<></>}
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      {currentEnrolment?.open ? (
                        <Button
                          variant="danger"
                          buttonSize="full"
                          color="btndefault"
                          disabled={!currentEnrolment?.open}
                          buttonName="Close this Enrolment"
                          isLoading={isEditing}
                          onClick={handleEnrolmentStatus}
                        />
                      ) : (
                        <Button
                          variant="primary"
                          buttonSize="full"
                          color="btndefault"
                          disabled={currentEnrolment?.open}
                          buttonName="Re-Open this enrolment"
                          isLoading={isEditing}
                          onClick={handleEnrolmentStatus}
                        />
                      )}
                    </Grid>
                  </Grid>
                </div>
              </>
            }
            isopen={editEnrolmentModal}
            closeModal={handleCloseEditEnrolmentModal}
          />
        </div>
        {/* Add Exam MOdal */}
        <Modal
          title="Add Examination to an Enrolment"
          subtitle={
            <div>
            Are you sure you want to add Examination to
              <span className="modal-name-highlight">{" "}
                {currentEnrolment?.classes?.map((clss, index) => (
                  <span className="" key={clss?.class_id}>
                    {clss?.class_name}
                    {index < currentEnrolment.classes.length - 1 ? ", " : ""}
                  </span>
                )) || " these classes"}
              </span>{" "}
              Enrolment?
            </div>
          }
          modalContent={
            <>
              <div className="multiple-select .height-min-200 select_flow">
                <p>Select Enrolment Exam</p>
                <Select isMulti name="exam_id"
                  closeMenuOnSelect={true} options={formattedEnrolmentData} onChange={handleMultipleAccessChange}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.id}
                />
              </div>
              {assignValidator.message(
                "exam_id",
                assignForm?.exam_id,
                "required"
              )}
            </>}
          modalFooter={
            <>
              <Button
                variant="primary"
                buttonName="Attach Examination"
                buttonSize="full"
                color="btndefault"
                onClick={handleAttachEnrolmentExam}
                isLoading={isAttaching}
              />
            </>
          }
          isopen={addExamModal}
          closeModal={handleCloseConvertExam}
        />
        <ToastContainer />
      </>
    }
    />
  );
};

export default Index;
