import { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import WcIcon from "@mui/icons-material/Wc";
import {
  School as ClassesIcon,
  ArrowBack as ArrowBackIcon,
  Logout
} from "@mui/icons-material";
import { Icon } from "@iconify/react";
import { useTheme } from "@mui/material/styles";
import classNames from "classnames";
import useStyles from "./styles";
import SidebarLink from "./components/SidebarLink/SidebarLink";
import logo from "../../assets/images/logo.svg";

// --| using context files
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
  toggleSidebarOff,
  togglePermamence
} from "../../context/LayoutContext";
import {
  useUserDispatch,
  useUserState,
  logOut
} from "../../context/UserContext";
import { getCurrentRole, accessAllSchoolPlans, accessSubjectTeacher, accessViewAssignmentById, accessViewAllAccessCodes, accessViewAllLeaveRequests, accessViewAllKnowledgebaseInfo, accessViewClassAttendance, accessViewAllLessonNotes, accessCreateGuardian, accessViewAllEmployees, accessCreateStudent, accessAddIntegration, accessGetAllEnrolments, accessViewAllApplications, accessCreateEvent, accessApplyForLeave, accessViewAllAssignmentsInSchool, accessViewClassDetailsById } from "../../data/roles";

const Sidebar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const { userRole, profile, appLogo } = useUserState();

  // --| This is to return the role, whether admin or user
  const role = getCurrentRole(userRole);

  // global
  const { isSidebarOpened, isPermanent } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const userDispatch = useUserDispatch();

  const handleWindowWidthChange = () => {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      togglePermamence(layoutDispatch);
    } else if (!isSmallScreen && !isPermanent) {
      togglePermamence(layoutDispatch);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();

    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  useEffect(() => {
    toggleSidebarOff(layoutDispatch);
  }, []);

  // --| Re-render the page if role changes
  useEffect(() => {}, [role]);

  const [openDropdown, setOpenDropdown] = useState(null);
  const [activeLink, setActiveLink] = useState(location.pathname);

  const handleToggleDropdown = (label) => {
    setOpenDropdown(openDropdown === label ? null : label);
  };

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const adminStructure = [
    {
      id: 0,
      label: "Dashboard",
      link: "/dashboard",
      access: true,
      icon: <Icon icon="ic:round-dashboard-customize" />
    },
    {
      id: 122,
      label: "Users",
      link: "/users",
      access: true,
      icon: <Icon icon="ph:users-three-bold" />,
      children: [
        { label: "Employees", link: "/users/employees" },
        { label: "Guardian", link: "/users/guardians", icon: <WcIcon /> },
        { label: "Student", link: "/users/students", icon: <ClassesIcon /> }
      ]
    },
    {
      id: 26,
      label: "Class Activities",
      link: "/class-activities",
      access: true,
      icon: <Icon icon="charm:graduate-cap" />,
      children: [
        { label: "Classes", link: "/class-activities/classes" },
        { label: "Subjects", link: "/class-activities/subjects" },
        { label: "Assignments", link: "/assignments" },
        { label: "Lesson Notes", link: "/lesson-notes" },
        { label: "Attendance", link: "/attendance" },
        { label: "Time Table", link: "/time-table" }
      ]
    },
    {
      id: 25,
      label: "School Examination",
      link: "/school-examination",
      access: true,
      icon: <Icon icon="ic:outline-assessment" />,
      children: [{ label: "Examinations", link: "/school-examination/examination" },
        { label: "Examination TimeTable", link: "/school-examination/exam-timetable" },
        { label: "Broadsheet", link: "/school-examination/broadsheet" },
        { label: "Grading System", link: "/school-examination/grade-system" }
      ]
    },
    {
      id: 94,
      label: "School Administration",
      link: "/manage",
      access: true,
      icon: <Icon icon="iconoir:hexagon-dice" />,
      children: [
        // { label: "Knowledgebase", link: "/manage/knowledgebase" },
        { label: "Leave Management", link: "/manage/leave" },
        // { label: "Performance Evaluation", link: "/manage/goals" },
        // { label: "Inventory", link: "/manage/inventory" },
        // { label: "Procurement", link: "/manage/procurements" },
        { label: "Notice Board", link: "/manage/notice-board" }
      ]
    },
    {
      id: 2,
      label: "School Plans",
      link: "/schoolplans",
      access: true,
      icon: <Icon icon="lets-icons:calendar" />,
      children: [
        { label: "Terms", link: "/schoolterm" },
        { label: "Sessions", link: "/schoolsession" },
        { label: "Plans", link: "/schoolplans" }
      ]
    },
    {
      id: 1,
      label: "School Enrolments",
      link: "/enrolments",
      access: true,
      icon: <Icon icon="material-symbols:cast-for-education-outline-rounded" />,
      children: [
        { label: "Enrolments", link: "/enrolments" },
        { label: "Applications", link: "/enrolments/applications" },
        { label: "Enrolment Examination", link: "/enrolments/enrolment-examination" }
      ]
    },
    {
      id: 24,
      label: "Access Control",
      link: "/accesscontrol",
      access: true,
      icon: <Icon icon="ic:outline-key-off" />,
      children: [
        { label: "Access Codes", link: "/accesscontrol/codes" },
        { label: "Access Logs", link: "/accesscontrol/logs" },
        { label: "Validate Code", link: "/accesscontrol/validate" }
      ]
    },
    // { id: 18, label: "Access Control", link: "/accesscontrol", icon: <Icon icon="ic:outline-key-off" /> },
    {
      id: 19,
      label: "Library",
      link: "/library",
      access: true,
      icon: <Icon icon="ic:outline-video-library" />
    },
    {
      id: 14,
      label: "Integrations",
      link: "/integrations",
      access: true,
      icon: <Icon icon="mingcute:plugin-2-line" />
    },
    {
      id: 15,
      label: "Settings",
      link: "/settings",
      access: true,
      icon: <Icon icon="tabler:settings" />
    },
    {
      id: 16,
      label: "Event",
      link: "/events",
      access: true,
      icon: <Icon icon="lets-icons:calendar" />
    }
  ];

  const userStructure = [
    {
      id: 0,
      label: "Dashboard",
      link: "/dashboard",
      icon: <Icon icon="ic:round-dashboard-customize" />
    },
    {
      id: 2,
      label: "School Plans",
      link: "/schoolplans",
      access: accessAllSchoolPlans(userRole),
      icon: <Icon icon="lets-icons:calendar" />
    },
    {
      id: 3,
      label: "Examination",
      link: "/examinations",
      icon: <Icon icon="fa6-solid:list-check" />
    },
    // { id: 21, label: "Assignment", link: "/assignments", icon: <Icon icon="ic:round-dashboard-customize" /> },
    // { id: 6, label: "Performance Evaluation", link: "/manage/goals", icon: <Icon icon="material-symbols:readiness-score-outline" /> },
    {
      id: 7,
      label: "Classes",
      access: true,
      link: "/myclasses",
      icon: <Icon icon="charm:graduate-cap" />
    },
    {
      id: 25,
      label: "Assessments",
      link: "/assesments",
      access: accessViewAssignmentById(userRole),
      icon: <Icon icon="ic:outline-assessment" />,
      children: [{ label: "Assignments", link: "/assignments" }]
    },
    {
      id: 8,
      label: "Subjects",
      link: "/mysubjects",
      access: accessSubjectTeacher(userRole),
      icon: <Icon icon="streamline:quality-education" />
    },
    {
      id: 30,
      label: "Lesson Notes",
      link: "/lesson-notes",
      access: accessViewAllLessonNotes(userRole),
      icon: <Icon icon="healthicons:register-book" />
    },
    {
      id: 4,
      label: "Attendance",
      link: "/attendance",
      access: accessViewClassAttendance(userRole),
      icon: <Icon icon="carbon:task-location" />
    },
    { label: "Notice Board", link: "/employee/notice-board", icon: <Icon icon="mdi:bell-outline" /> },
    // { id: 9, label: "Inventory", link: "/manage/inventory", icon: <Icon icon="mdi:shield-check-outline" /> },
    // { id: 10, label: "Procurement", link: "/manage/procurements", icon: <Icon icon="uil:shopping-cart" /> },
    {
      id: 11,
      label: "Resources",
      access: accessViewAllKnowledgebaseInfo(userRole),
      link: "/manage/knowledgebase",
      icon: <Icon icon="grommet-icons:resources" />
    },
    {
      id: 14,
      label: "Welfare",
      link: "/welfare",
      access: accessApplyForLeave(userRole),
      icon: <Icon icon="material-symbols:favorite-outline" />
    },
    {
      id: 111,
      label: "Access Control",
      link: "/accesscontrol",
      access: accessViewAllAccessCodes(userRole),
      icon: <Icon icon="ic:outline-key-off" />,
      children: [
        { label: "Access Codes", link: "/accesscontrol/codes" },
        { label: "Access Logs", link: "/accesscontrol/logs" },
        { label: "Validate Code", link: "/accesscontrol/validate" }
      ]
    },
    {
      id: 16,
      label: "Event",
      link: "/events",
      access: accessCreateEvent(userRole),
      icon: <Icon icon="lets-icons:calendar" />
    },
    {
      id: 13,
      label: "Settings",
      link: "/settings",
      icon: <Icon icon="tabler:settings" />
    },
    {
      id: 94,
      label: "School Administration",
      link: "/manage",
      icon: <Icon icon="iconoir:hexagon-dice" />,
      access:
      accessViewAllKnowledgebaseInfo(userRole) ||
      accessViewAllLeaveRequests(userRole) ||
      accessViewAllEmployees(userRole) ||
      accessCreateGuardian(userRole) ||
      accessCreateStudent(userRole) ||
      accessAddIntegration(userRole) ||
      accessGetAllEnrolments(userRole) ||
      accessViewAllApplications(userRole) ||
      accessViewClassDetailsById(userRole) ||
      accessViewAllAssignmentsInSchool(userRole),
      children: [
        { label: "Employees", link: "/users/employees", access: accessViewAllEmployees(userRole) },
        { label: "Guardian", link: "/users/guardians", icon: <WcIcon />, access: accessCreateGuardian(userRole) },
        { label: "Student", link: "/users/students", icon: <ClassesIcon />, access: accessCreateStudent(userRole) },
        { label: "Knowledgebase", link: "/manage/knowledgebase", access: accessViewAllKnowledgebaseInfo(userRole) },
        { label: "Leave Management", link: "/manage/leave", access: accessViewAllLeaveRequests(userRole) },
        {
          label: "Integrations",
          link: "/integrations",
          access: accessAddIntegration(userRole),
          icon: <Icon icon="mingcute:plugin-2-line" />
        },
        { label: "Enrolments", link: "/enrolments", access: accessGetAllEnrolments(userRole) },
        { label: "Applications", link: "/enrolments/applications", access: accessViewAllApplications(userRole) },
        { label: "Classes", link: "/class-activities/classes", access: accessViewClassDetailsById(userRole) },
        { label: "Subjects", link: "/class-activities/subjects", access: accessViewAllAssignmentsInSchool(userRole) },
        { label: "Assignments", link: "/assignments", access: accessViewAllAssignmentsInSchool(userRole) }
      ]
    }
  ];

  const studentStructure = [
    {
      id: 0,
      label: "Dashboard",
      link: "/dashboard",
      icon: <Icon icon="ic:round-dashboard-customize" />
    },
    {
      id: 80,
      label: "Class Activities",
      link: "/class-activities",
      icon: <Icon icon="charm:graduate-cap" />,
      children: [
        { label: "Syllabus", link: "/class-activities/syllabus" },
        { label: "Subjects", link: "/class-activities/subjects" },
        { label: "Attendance", link: "/attendance" }
      ]
    },
    {
      id: 25,
      label: "Assessments",
      link: "/assesments",
      icon: <Icon icon="ic:outline-assessment" />,
      children: [
        { label: "Assignments", link: "/assesments/assignments" },
        { label: "Examinations", link: "/assesments/examination" },
        { label: "Grades", link: "/assesments/student/grades" }
      ]
    },
    {
      id: 2,
      label: "School Plans",
      link: "/schoolplans",
      icon: <Icon icon="lets-icons:calendar" />
    },
    { label: "Notice Board", link: "/student/notice-board", icon: <Icon icon="mdi:bell-outline" /> },
    {
      id: 19,
      label: "Library",
      link: "/library",
      icon: <Icon icon="ic:outline-video-library" />
    },
    {
      id: 15,
      label: "Settings",
      link: "/settings",
      icon: <Icon icon="tabler:settings" />
    },
    {
      id: 16,
      label: "Event",
      link: "/events",
      icon: <Icon icon="lets-icons:calendar" />
    }
  ];

  const structure =
    role === "admin"
      ? adminStructure
      : role === "teacher"
        ? userStructure
        : role === "student"
          ? studentStructure
          : [];

  const Initial = profile?.first_name?.split("")[0].toUpperCase();

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened
        })
      }}
      open={isSidebarOpened}
    >
      <div className={classes.sidebarLogo}>
        <Link to="/dashboard">
          <div className={classes.logoContent}>
            <img
              src={appLogo || logo}
              alt="App Logo"
              className={classes.logoContent}
            />
          </div>
        </Link>
      </div>
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse)
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            openDropdown={openDropdown}
            handleToggleDropdown={handleToggleDropdown}
            activeLink={activeLink}
            setActiveLink={setActiveLink}
            {...link}
          />
        ))}
      </List>
      <div className={classes.footer}>
        <Link className={classes.footerlink} to="/profile">
          <div className={classes.footercontainer}>
            <div className={classes.initials}>{Initial}</div>
            <div className={classes.footertextwrapper}>
              <p className={classes.name}>
                {`${profile?.first_name} ${profile?.last_name}`}
              </p>
              <p className={classes.email}>{profile?.email}</p>
            </div>
          </div>
        </Link>
        <div
          className={classes.logout}
          onClick={() => logOut(userDispatch, navigate)}
        >
          <Logout />
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;
