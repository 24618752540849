import { Icon } from "@iconify/react";
import useStyles from "../styles";
// import styles from "../user/student.module.css";
// import Button from "../../../components/Button";
import Table from "../../../components/Table/Table";
import { useEffect, useState } from "react";
// import Modal from "../../../components/Modal";
import { useParams } from "react-router-dom";
import ButtonCount from "../../../components/ButtonCount";
import { TableSearch } from "../../../components/Table/TableActions";
import SelectGroup from "../../../components/FormComponent/SelectGroup";

// --| Dummy Data
// import StudentReportData from "../../../dummy_data/studentReportData.json";
import { httprequest } from "../../../data/api";
import { toast } from "react-toastify";
const GRADE_RECORD = "/api/v1/grade";

const AcademicInfo = () => {
  // const navigate = useNavigate();
  const classes = useStyles();
  // const [currentRow, setCurrentRow] = useState({});
  const [searchResult, setSearchResult] = useState([]);
  const [allGrades, setAllGrades] = useState([]);
  const [studentHistory, setStudentHistory] = useState([]);
  // const [deleteModal, setDeleteModal] = useState(false);
  const [isPageFetching, setIsPageFetching] = useState(false);
  const [aSession, setASession] = useState("");
  const [term, setTerm] = useState("");
  const [sessionTerm, setSessionTerm] = useState([]);
  const { id } = useParams();
  // const handleDeleteModal = (row) => {
  //   setCurrentRow(row);
  //   setDeleteModal(!deleteModal);
  // };

  const getAllGrades = () => {
    setIsPageFetching(true);
    httprequest.get(`${GRADE_RECORD}/${id}`)
      .then((res) => {
        setIsPageFetching(false);
        setAllGrades(res?.data?.data);
        setSearchResult(res?.data?.data);
      }).catch((err) => {
        setIsPageFetching(false);
        toast.error(err?.response?.data);
      });
  };

  const getStudentHistory = () => {
    httprequest.get(`${GRADE_RECORD}/classes?student_id=${id}`)
      .then((res) => {
        setStudentHistory(res?.data?.data);
      }).catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const Active_Class = studentHistory.find((data) => data?.is_current_class === true);

  // const handleCloseDeleteModal = () => {
  //   setDeleteModal(false);
  // };
  const columns = [
    {
      name: "Subject",
      selector: row => row?.subject?.name
    },
    {
      name: "CA 1",
      // eslint-disable-next-line no-undefined
      selector: row => (row?.CA_scores?.CA1 !== undefined ? row?.CA_scores?.CA1 : "--/--")
    },
    {
      name: "CA 2",
      // eslint-disable-next-line no-undefined
      selector: row => (row?.CA_scores?.CA2 !== undefined ? row?.CA_scores?.CA2 : "--/--")
    },
    {
      name: "CA Score",
      selector: row => row?.CA_total
    },
    {
      name: "Exam Score",
      selector: row => row?.exam_score
    },
    {
      name: "Total",
      selector: row => row?.total
    },
    {
      name: "Grade",
      selector: row => row?.grade
    },
    {
      name: "Graded By",
      selector: row => row?.graded_by?.name
    }
    // {
    //   name: "",
    //   center: true,
    //   sortable: false,
    //   cell: (row) => (
    //     <div key={row?.id} className="table-action-container">
    //       <button className="table-action-icon">
    //         <Icon icon="fluent:eye-16-regular" />
    //       </button>
    //       <button
    //         onClick={() => handleDeleteModal(row)}
    //         className="table-action-icon delete-icon"
    //       >
    //         <Icon icon="mingcute:delete-2-line" />
    //       </button>
    //     </div>
    //   )
    // }
  ];

  // Handle Session Change
  const handleSessionChange = (e) => {
    const Session_ID = e.target.value;
    const Session_terms = studentHistory.find((data) => data?.session?.id === Session_ID);
    setSessionTerm(Session_terms);
  };

  useEffect(() => {
    // The data will be from the inventory get api endpint
    getAllGrades();
    getStudentHistory();
  }, []);

  useEffect(() => {
    if (Active_Class) {
      setASession(Active_Class?.session?.id);
      const Session_terms = studentHistory.find((data) => data?.session?.id === Active_Class?.session?.id);
      setSessionTerm(Session_terms);
      const termId = Session_terms?.terms?.[0]?.id;
      setTerm(termId);
    }
  }, [Active_Class]);

  // eslint-disable-next-line no-console
  console.log(term, "term");

  // --| Filter Student table using name, email and class
  const handleSearchInventory = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = allGrades?.filter((data) => valArray?.every(
      (word) => data?.subject?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                                data?.CA_total
                                  ?.toLowerCase()
                                  .includes(word.toLowerCase()) ||
                                data?.exam_score
                                  ?.toLowerCase()
                                  .includes(word.toLowerCase()) ||
                                  data?.total
                                    ?.toLowerCase()
                                    .includes(word.toLowerCase()) ||
                                    data?.grade
                                      ?.toLowerCase()
                                      .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <div className="page-content">
      <div className="action_div">
        <div className={classes.contentpadding}>
          <ButtonCount title="Subject Result" count={allGrades?.length} />
        </div>
        <div className="flex gap-10 align-items-end margin-top-5 margin-bottom-14">
          <div className="margin-bottom-10">
            {isPageFetching ? <Icon icon="svg-spinners:270-ring-with-bg" width="20" height="20" style={{ color: "#858585" }} />
              : <Icon onClick={() => getAllGrades()} icon="hugeicons:reload" width="20" height="20" style={{ color: "#858585" }} />}
          </div>
          <div>
            <SelectGroup
              children={
                <select
                  name="session"
                  onChange={handleSessionChange}
                  value={aSession}
                >
                  <option value="">---Select---</option>
                  {studentHistory?.map((sesh) => {
                    return (
                      <option key={sesh?.session?.id} value={sesh?.session?.id}>
                        {sesh?.session?.name}
                      </option>
                    );
                  })}
                </select>
              }
            />
          </div>
          <div>
            <SelectGroup
              children={
                <select value={term} name="term">
                  <option value="">---Select---</option>
                  {sessionTerm?.terms?.map((data) => {
                    return (
                      <option key={data?.id} value={data?.id}>
                        {data?.name}
                      </option>
                    );
                  })}
                </select>
              }
            />
          </div>
        </div>
      </div>
      <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
        searchResult?.length > 0 && (
          <TableSearch placeholder="Search here..." searchTableFunc={handleSearchInventory} />
        )}
      />
      {/* <div className="flex justify-items-space Blockspacing">
        <div className={classes.contentpadding}>
          <ButtonCount func={() => navigate("")} title="Student Count" />
        </div>
        <div className={`${styles.footer} flex align-items-center`}>
          <p>Class Teacher: </p>
          <p onClick={() => navigate("/employees")} className={`${styles.color}`}>Euguen Adaovore</p>
        </div>
      </div> */}
      {/* Delete Modal */}
      {/* <Modal
        title="Delete Subject"
        subtitle={
          <div>Are you sure you want to delete <span className="modal-name-highlight">{currentRow?.title}</span>. Deleting is a permanent action`
          </div>}
        modalContent={
          <>

          </>
        }
        modalFooter={
          <>
            <Button variant="danger" buttonName="Delete Inventory" buttonSize="full" color="btndefault" />
          </>
        }
        isopen={deleteModal}
        closeModal={handleCloseDeleteModal}
      /> */}
    </div>
  );
};

export default AcademicInfo;
