import DataTable from "react-data-table-component";
import "./Table.css";
import { KeyboardArrowDown } from "@mui/icons-material";
import NotFounditem from "../../pages/error/NotFound";
import nosearch from "../../assets/images/no-search.svg";
import useWindowSize from "../../hooks/useWindow";

/*
  React data tables is used in this project beacuse it allows more flexibility than Material Ui tables
  The column propos receives an array of objects
  The subHeader receives a boolean, for commponents with no subheader, the value will be false
  The subHeadeComponent prop is a node component dependent on whether the subHeader is true or false
  The action props works similarly to how the subHeader component works, this is less favoured
  -in usage as the subheder component is much easier to style than the action prop as the action prop leaves unnecessary spacing
*/

const Table = ({ data, columns, actions, subHeader, subHeaderComponent, pagination, handleRowSelected }) => {
  const width = useWindowSize();
  const desktop = width > 576;
  if (typeof handleRowSelected !== "function") {
    // eslint-disable-next-line no-console
    console.error("handleRowSelected is not a function");
  }

  return (
    <div className="datatable">
      <DataTable
        columns={columns}
        data={data}
        sortable
        onSelectedRowsChange={handleRowSelected}
        sortIcon={<KeyboardArrowDown />}
        selectableRows={desktop}
        // densed={false}
        pagination={pagination}
        paginationPerPage={10}
        responsive
        subHeaderComponent={subHeaderComponent}
        subHeader={subHeader}
        highlightOnHover={true}
        pointerOnHover={true}
        actions={actions}
        noDataComponent={
          <NotFounditem img={nosearch}
            title="No Data Found"
            subtitle="You have not added any data to the list."
          />}
        // showAAllRowsItem
      />
    </div>
  );
};

export default Table;
