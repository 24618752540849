// import useStyles from "../styles";
// import { Grid } from "@mui/material";
// import Modal from "../../../components/Modal";
import { Icon } from "@iconify/react";
import Button from "../../../components/Button";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
// import ExaminationCard from "../../../components/ExaminationCard";
import { useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { httprequest } from "../../../data/api";
import TabPanel from "@mui/lab/TabPanel";
import Tabs from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Table from "../../../components/Table/Table";
import Badge from "../../../components/Badge";
// import Table from "../../../components/Table/Table";
// import { TableSearch } from "../../../components/Table/TableActions";
import PageTitle from "../../../components/PageTitle";
import { TableSearch } from "../../../components/Table/TableActions";
import { toast } from "react-toastify";
import Modal from "../../../components/Modal";
import PopModal from "../../../components/PopupModal/PopModal";
// import { Grade, ScoreLevel } from "../../../utils/DropDown";

// --| Dummy Data
const GET_ALL_SCHOOL_EXAMINATION = "/api/v1/school-exams";
const CLASSES_ENDPOINT = "/api/v1/class";

const Examination = () => {
  // const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = useState("1");
  const [searchResult, setSearchResult] = useState([]);
  // const [grademodal, setGradeModal] = useState();
  const [enrolmentresultbyclass, setEnrolmentResultByClass] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [allExamData, setAllExamData] = useState([]);
  const [allExamDataForClass, setAllExamDataForClass] = useState([]);
  const [convertModal, setConvertModal] = useState(false);
  const [currentSelected, setCurrentSelected] = useState({});
  const [classesData, setClassData] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");
  const [isConverting, setIsConverting] = useState(false);
  const [isPageFetching, setIsPageFetching] = useState(false);
  const [isForClassFetching, setIsForClassFetching] = useState([]);

  const getAllEnrollment = () => {
    setIsPageFetching(true);
    httprequest.get(GET_ALL_SCHOOL_EXAMINATION).then((res) => {
      setIsPageFetching(false);
      setAllExamData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsPageFetching(false);
      toast.error(err?.response?.data);
    });
  };

  const getAllEnrollmentExamByClass = (classId) => {
    setIsForClassFetching(true);
    httprequest.get(`${GET_ALL_SCHOOL_EXAMINATION}?class_id=${classId}`).then((res) => {
      setIsForClassFetching(false);
      setAllExamDataForClass(res?.data?.data);
      setEnrolmentResultByClass(res?.data?.data);
    }).catch((err) => {
      setIsForClassFetching(false);
      toast.error(err?.response?.data);
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);

    // Fetch data only if a specific class tab is selected
    if (newValue !== "1") {
      getAllEnrollmentExamByClass(newValue);
    }
  };

  const getAllClasses = () => {
    httprequest.get(CLASSES_ENDPOINT).then((res) => {
      const physicalClasses = res?.data?.data?.filter((data) => data?.class_type === "physical");
      setClassData(physicalClasses);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const handleDeleteModal = (row) => {
    setCurrentSelected(row);
    setDeleteModal(!deleteModal);
  };

  const handleConvertModal = (row) => {
    setCurrentSelected(row);
    setConvertModal(!convertModal);
  };

  // handles close delte modal
  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  // handle closing of convert modal
  const handleCloseConvertExam = () => {
    setConvertModal(false);
  };

  const handleDeleteAnExam = () => {
    setIsDeleting(true);
    httprequest.delete(`${GET_ALL_SCHOOL_EXAMINATION}/${currentSelected?.id}`).then(() => {
      setIsDeleting(false);
      toast.success("Exam Deleted Succesfully");
      getAllEnrollment();
      handleCloseDeleteModal();
    }).catch((err) => {
      setIsDeleting(false);
      toast.error(err?.response?.data?.message);
    });
  };

  // Handle Convert EXAM
  const handleConvertExam = () => {
    setIsConverting(true);
    httprequest.post(`${GET_ALL_SCHOOL_EXAMINATION}/${currentSelected?.id}/convert`).then((res) => {
      setIsConverting(false);
      toast.success(res?.data?.message);
      getAllEnrollment();
      handleCloseConvertExam();
    }).catch((err) => {
      setIsConverting(false);
      toast.error(err?.response?.data?.message);
    });
  };

  useEffect(() => {
    getAllEnrollment();
    getAllClasses();
    getAllEnrollmentExamByClass();
  }, []);
  // const handleGradeModal = () => {
  //   setGradeModal(!grademodal);
  // };

  // const [searchResult, setSearchResult] = useState([]);

  const columns = [
    {
      name: "Subject",
      selector: row => row?.subject?.name || "--/--"
    },
    {
      name: "Classes",
      selector: (row) => row?.classes?.map((clss) => (
        <span className="class_div" key={clss?.id}>
          {clss?.name}
        </span>
      )) || "--/--"
    },
    {
      name: "Created By",
      selector: row => `${row?.created_by?.first_name || ""} ${row?.created_by?.last_name || ""}` || "--/--"
    },
    {
      name: "Duration (in mins)",
      selector: row => row?.duration || "--/--"
    },
    {
      name: "Date Created",
      selector: row => row?.created_at ? row.created_at.substring(0, 10) : "--/--"
    },
    {
      name: "Status",
      center: true,
      cell: (row) => <Badge status={row?.status} />
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/school-examination/examination-details/${row?.id}`}>
            <button className="table-action-icon">
              <Icon icon="fluent:eye-16-regular" />
            </button>
          </Link>
          <button
            className="table-action-icon"
            onClick={() => navigate(`/school-examination/edit-examination/${row?.id}`)}
          >
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            onClick={() => handleConvertModal(row)}
            className="table-action-icon"
          >
            <Icon icon="ion:reload-circle-sharp" width="20" height="20" />
          </button>
          <button className="table-action-icon delete-icon" onClick={() => handleDeleteModal(row)}>
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];
  const handleFilterClassChange = (e) => {
    const { value } = e.target;
    setFilterStatus(value);

    // Filter assignments based on selected status in a class
    const filteredByStatus = value === "published"
      ? enrolmentresultbyclass?.filter((data) => data?.status === "published")
      : value === "draft"
        ? enrolmentresultbyclass?.filter((data) => data?.status === "draft")
        : enrolmentresultbyclass;

    setAllExamDataForClass(filteredByStatus);
  };

  // Handle Filter for All School Examination
  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilterStatus(value);

    // Filter assignments based on selected status
    const filteredByStatus = value === "published"
      ? allExamData?.filter((data) => data?.status === "published")
      : value === "draft"
        ? allExamData?.filter((data) => data?.status === "draft")
        : allExamData;
    setSearchResult(filteredByStatus);
  };

  // --| Filter Enrolment Exam table using name, email and class
  const handleSearchAllEnrolmentExam = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");

    const filteredByStatus = filterStatus
      ? allExamData.filter((data) => data.status.toLowerCase() === filterStatus.toLowerCase())
      : allExamData;
    // --| Filter data by partial match onchange in the search input box
    const result = filteredByStatus?.filter((data) => valArray?.every(
      (word) => data?.subject?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                data?.class?.name?.toLowerCase()
                  .includes(word.toLowerCase()) ||
                data?.created_by?.name?.toLowerCase()
                  .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  const handleSearchAssignmentByClass = (e) => {
    const { value } = e.target;
    const valArray = value.split(" ");

    const filteredByStatus = filterStatus
      ? enrolmentresultbyclass.filter((data) => data.status.toLowerCase() === filterStatus.toLowerCase())
      : enrolmentresultbyclass;

    // Filter by partial match in subject, class, and creator's name
    const result = filteredByStatus?.filter((data) => valArray?.every(
      (word) => data?.subject?.name?.toLowerCase().includes(word.toLowerCase()) ||
                data?.classes?.name?.toLowerCase().includes(word.toLowerCase()) ||
                data?.created_by?.name?.toLowerCase().includes(word.toLowerCase())
    ));

    setAllExamDataForClass(result);
  };

  return (
    <>
      <PageTitle
        title="Examination"
        subtitle="View all the examination"
        button={
          <div className="flex gap-10">
            <PopModal videoUrl="https://youtu.be/4c8P3Umbytw" title="Examination" />
            <Button onClick={() => navigate("/school-examination/create-examination")} variant="primary" buttonSize="fluid" color="btndefault" buttonName="Create Examination" />
          </div>
        }
      />
      <div className="page-content">
        <TabContext value={value}>
          {searchResult?.length > 0 && (
            <div className="tablist-container padding-left-16">
              <Tabs
                indicatorColor="none"
                className="overide-tablist"
                onChange={handleChange}
                aria-label=""
              >
                <Tab label="All Examination" value="1" />
                {classesData?.map((data, i) => {
                  return (
                    <Tab key={i} value={data?.id} label={data?.class_name} />
                  );
                })}
              </Tabs>
            </div>
          )}

          <TabPanel value="1" className="tabpanel-overide">
            <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
              searchResult?.length > 0 && (
                <div className="action_div">
                  <TableSearch placeholder="Search here..." searchTableFunc={handleSearchAllEnrolmentExam} />
                  <div className="text-center gap-10 cursor-pointer">
                    {isPageFetching ? <Icon icon="svg-spinners:270-ring-with-bg" width="20" height="20" style={{ color: "#858585" }} />
                      : <Icon onClick={getAllEnrollment} icon="hugeicons:reload" width="20" height="20" style={{ color: "#858585" }} />}
                    <div>
                      <SelectGroup
                        children={
                          <select name="type" onChange={handleFilterChange}>
                            <option value="">-- Sort By--</option>
                            <option value="published">Published</option>
                            <option value="draft">Draft</option>
                            {/* <option value="withdrawn">Withdrawn</option> */}
                          </select>
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            />
          </TabPanel>
          {classesData?.map((data, i) => {
            return (
              <TabPanel className="tabpanel-overide" key={i} value={data?.id}>
                <Table
                  data={allExamDataForClass}
                  columns={columns}
                  subHeader={true}
                  pagination
                  subHeaderComponent={
                    allExamDataForClass?.length > 0 && (
                      <div className="action_div">
                        <TableSearch placeholder="Search here..." searchTableFunc={handleSearchAssignmentByClass} />
                        <div className="text-center gap-10 cursor-pointer">
                          {isForClassFetching ? <Icon icon="svg-spinners:270-ring-with-bg" width="20" height="20" style={{ color: "#858585" }} />
                            : <Icon onClick={() => getAllEnrollmentExamByClass(value)} icon="hugeicons:reload" width="20" height="20" style={{ color: "#858585" }} />}
                          <div>
                            <SelectGroup
                              children={
                                <select name="type" onChange={handleFilterClassChange}>
                                  <option value="">-- Sort By--</option>
                                  <option value="published">Published</option>
                                  <option value="draft">Draft</option>
                                  {/* <option value="withdrawn">Withdrawn</option> */}
                                </select>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )

                  }
                />
              </TabPanel>
            );
          })}
        </TabContext>
      </div>
      <Modal
        title="Delete School Examination"
        subtitle={
          <div>Are you sure you want to delete
            <span className="modal-name-highlight">{" "}
              {currentSelected?.subject?.name ?? "this subject"} for{" "}
              {currentSelected?.classes?.map((clss, index) => (
                <span className="" key={clss?.id}>
                  {clss?.name}
                  {index < currentSelected.classes.length - 1 ? ", " : ""}
                </span>
              )) || " these classes"}.
            </span>{" "}
             Implication is that this Examination will be deleted completely
          </div>}
        modalContent={
          <>

          </>
        }
        modalFooter={
          <>
            <Button variant="danger" buttonName="Delete Examination" buttonSize="full" color="btndefault" onClick={handleDeleteAnExam} isLoading={isDeleting} />
          </>
        }
        isopen={deleteModal}
        closeModal={handleCloseDeleteModal}
      />
      {/* Convert MOdal */}
      <Modal
        title="Convert Examination to Enrolment Examination"
        subtitle={
          <div>
      Are you sure you want to convert
            <span className="modal-name-highlight">{" "}
              {currentSelected?.subject?.name ?? "this subject"} for{" "}
              {currentSelected?.classes?.map((clss, index) => (
                <span className="" key={clss?.id}>
                  {clss?.name}
                  {index < currentSelected.classes.length - 1 ? ", " : ""}
                </span>
              )) || " these classes"}
            </span>{" "}
      to an Enrolment Examination?
          </div>
        }
        modalContent={<></>}
        modalFooter={
          <>
            <Button
              variant="primary"
              buttonName="Convert Examination"
              buttonSize="full"
              color="btndefault"
              onClick={handleConvertExam}
              isLoading={isConverting}
            />
          </>
        }
        isopen={convertModal}
        closeModal={handleCloseConvertExam}
      />

    </>
  );
};

export default Examination;
