import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import { TableSearch } from "../../components/Table/TableActions";
import Badge from "../../components/Badge";
import Modal from "../../components/Modal";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { httprequest } from "../../data/api";
import useValidator from "../../hooks/useValidator";
import { ToastContainer, toast } from "react-toastify";
import ButtonCount from "../../components/ButtonCount";
import InputGroup from "../../components/FormComponent/InputGroup";

const CONTRACTOR_ENDPOINT = "/api/v1/contractor";

const Contractors = () => {
  const navigate = useNavigate();
  const [searchResult, setSearchResult] = useState([]);
  const [addInfoModal, setAddInfoModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [isContracting, setIsContracting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contractorData, setContractorData] = useState([]);
  const [idToDelete, setIdToDelete] = useState(null);

  const [contractorValidator, showContractorValidator] = useValidator();
  const [contractorForm, setContractorForm] = useState({
    name: "",
    email: "",
    services: "",
    address: "",
    phone: ""
  });

  const handleAddInfoModal = () => {
    setAddInfoModal(!addInfoModal);
  };

  const handleCloseAddInfoModal = () => {
    setAddInfoModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContractorForm({ ...contractorForm, [name]: value });
  };

  const getAllContractors = () => {
    setIsLoading(true);
    httprequest
      .get(CONTRACTOR_ENDPOINT)
      .then((res) => {
        setIsLoading(false);
        setSearchResult(res?.data?.data);
        setContractorData(res?.data?.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  // handle delete contractor Modal
  const handleDeleteModal = (rowId) => {
    setCurrentRow(rowId);
    setIdToDelete(rowId);
    setDeleteModal(!deleteModal);
  };

  // handle create contractor
  const handleCreateContractor = () => {
    if (contractorValidator.allValid()) {
      setIsContracting(true);
      httprequest.post(CONTRACTOR_ENDPOINT, contractorForm).then((res) => {
        setIsContracting(false);
        toast.success(res?.data?.message);
        handleCloseAddInfoModal();
        // getAllSession();
        setContractorForm({ ...contractorForm, name: "", email: "", services: "", address: "", phone: "" });
        // handleAddSessionModal();
      }).catch((err) => {
        setIsContracting(false);
        toast.error(err?.response?.data);
      });
    } else {
      showContractorValidator(true);
    }
  };

  const deleteContractor = (contractorId) => {
    httprequest.delete(`${CONTRACTOR_ENDPOINT}/${contractorId}`)
      .then(() => {
        toast.success("Contractor deleted");
        setDeleteModal(false);
      }).catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };
  // On Page Load, set search result data to be the initial data
  useEffect(() => {
    // The data will be from the knowledgebase get api endpint
    getAllContractors();
  }, []);

  const columns = [
    {
      name: "Contractor",
      selector: row => row?.name
    },
    {
      name: "Services",
      selector: row => row?.services
    },
    {
      name: "Address",
      selector: row => row?.address
    },
    {
      name: "Phone Number",
      selector: row => row?.phone
    },
    {
      name: "Created At",
      selector: row => row?.created_at?.substring(0, 10)
    },
    {
      name: "Status",
      center: true,
      cell: (row) => (<Badge status={row?.status} />)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button
            onClick={() => {
              navigate("/manage/procurements/ProcurementMenu");
            }}
            className="table-action-icon"
            disabled
          >
            <Icon icon="fluent:eye-16-regular" />
          </button>
          <button className="table-action-icon">
            <Icon icon="jam:shield-close-f" />
          </button>
          <button
            onClick={() => handleDeleteModal(row?.id)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];
    // --| Filter Teacher table using name, email and class
  const handleSearchTeacher = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = contractorData?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                      data?.title
                        ?.toLowerCase()
                        .includes(word.toLowerCase()) ||
                      data?.label
                        ?.toLowerCase()
                        .includes(word.toLowerCase()) ||
                        data?.type
                          ?.toLowerCase()
                          .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  const { name, email, services, address, phone } = contractorForm;

  return (
    <Layout children={
      <>
        <div className="breadcrumb-container">
          <div>
            <BreadCrumb
              link_one="/manage/procurements"
              link_one_name="Procurement"
              active="Contractors"
              description="See all the list of contractors"
            />
          </div>
          <div className="flex gap-10">
            {/* <Button onClick={handleAddInfoModal} variant="primaryoutline" buttonSize="fluid" color="btnfontprimary" buttonName="Add Procurement" /> */}
            <Button onClick={handleAddInfoModal} variant="primary" buttonSize="fluid" color="btndefault" buttonName="Add Contractor" />
          </div>
        </div>
        <div className="page-content">
          <div className="button-count-container">
            <ButtonCount
              title="View Contractors"
              func={() => navigate("/manage/procurements/contractors")}
              count={contractorData?.length}
            />
          </div>
          <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
            searchResult?.length > 0 && (
              <TableSearch placeholder="Search here..." searchTableFunc={handleSearchTeacher} isLoading={isLoading} />
            )}
          />
          {/* Delete Modal */}
          <Modal
            title="Delete Contractor"
            subtitle={
              <div>Are you sure you want to remove <span className="modal-name-highlight">{currentRow?.contractor}</span>. as one of your contractors? Removing him will permanently clear all his records and contracts.`
              </div>}
            modalContent={
              <>

              </>
            }
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="dangeroutline"
                    buttonSize="full"
                    color="btndanger"
                    buttonName="Cancel"
                  />
                  <Button variant="danger" buttonName="Remove Contractor" buttonSize="full" color="btndefault" onClick={() => deleteContractor(idToDelete)} />
                </div>
              </>
            }
            isopen={deleteModal}
            closeModal={handleCloseDeleteModal}
          />

          {/* Add Contractor Modal */}
          <Modal
            title="Add a Contractor"
            subtitle={
              <div>Add a verified contractor to your organisation
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Name of Contractor"
                      margin="normal"
                      inputType="text"
                      onChange={handleChange}
                      inputName="name"
                      value={name}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Email Address"
                      margin="normal"
                      onChange={handleChange}
                      inputType="email"
                      inputName="email"
                      value={email}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Service Offering"
                      margin="normal"
                      inputType="text"
                      inputName="services"
                      onChange={handleChange}
                      value={services}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Address"
                      margin="normal"
                      inputType="text"
                      inputName="address"
                      onChange={handleChange}
                      value={address}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Phone Number"
                      margin="normal"
                      inputType="tel"
                      onChange={handleChange}
                      value={phone}
                      inputName="phone"
                    />
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <Button variant="primary" buttonName="Add Contractor" buttonSize="full" color="btndefault" onClick={handleCreateContractor} isLoading={isContracting} />
              </>
            }
            isopen={addInfoModal}
            closeModal={handleCloseAddInfoModal}
          />
        </div>
        <ToastContainer />
      </>
    }
    />
  );
};

export default Contractors;
