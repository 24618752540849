import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import useStyles from "./styles";
import { Gender } from "../../utils/DropDown";
import Button from "../../components/Button";
import InputGroup from "../../components/FormComponent/InputGroup";
import SelectGroup from "../../components/FormComponent/SelectGroup";
import Modal from "../../components/Modal";
// import Resume from "./components/Resume";
import { httprequest } from "../../data/api";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import PageLoader from "../../components/PageLoader";

const EMPLOYEES_ENDPOINT = "/api/v1/employees";
const ROLES_ENDPOINT = "api/v1/roles";

const Teacher = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [rolesData, setRolesData] = useState([]);
  // const [allFetching, setAllFetching] = useState(false);

  const [profileDetails, setProfileDetails] = useState({
    salary: 0,
    bank_details: {
      bank_name: "",
      account_number: "",
      account_name: ""
    }
  });

  const [isCreating, setIsCreating] = useState(false);

  const [offboardModal, setOffboardModal] = useState(false);

  const handleOffBoardModal = () => {
    setOffboardModal(!offboardModal);
  };

  const handleCloseOffBoard = () => {
    setOffboardModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setProfileDetails({ ...profileDetails, [name]: value });
  };

  const handleBankDetailsChange = (e) => {
    const { name, value } = e.target;
    const bank_details = { ...profileDetails.bank_details, [name]: value };
    setProfileDetails({ ...profileDetails, bank_details });
  };

  const handleEditProfile = async () => {
    setIsCreating(true);
    httprequest
      .patch(`${EMPLOYEES_ENDPOINT}/${id}`, profileDetails)
      .then((res) => {
        setIsCreating(false);
        toast.success(res?.data?.message);
        window.location.reload();
      })
      .catch((err) => {
        setIsCreating(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const getRoles = () => {
    httprequest.get(`${ROLES_ENDPOINT}`).then((res) => {
      setRolesData(res?.data?.data);
    }).catch(() => {

    });
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    // setAllFetching(true);
    httprequest.get(`${EMPLOYEES_ENDPOINT}/${id}`).then((res) => {
      // setAllFetching(false);
      setData(res?.data?.data);
      setProfileDetails({ ...profileDetails,
        bank_details: res?.data?.data?.bank_details,
        salary: res?.data?.data?.salary
      });
    }).catch((err) => {
      // setAllFetching(false);
      toast.error(err?.response?.data);
    });
  }, []);

  return (
    <Layout children={
      <>
        <BreadCrumb link_one="/users/employees" link_one_name="Employees" active={`${data?.first_name}`} description="See relevant information of employee" />

        <div className={classes.whitebg}>
          <Grid container spacing={2} mt={1}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <div className="left-container">
                <h4>Personal Information</h4>
                <h6>Update your personal details here</h6>
                <div className={classes.profilecontainer}>
                  <div className={classes.imageviewer} style={{ backgroundImage: `url(${data?.display_pic?.file_url})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                  </div>
                </div>
                {data?.is_active === false ? <Button variant="primaryoutline" color="btnfontprimary" buttonSize="full" buttonName="Re-activate employees" onClick={handleOffBoardModal} />
                  : <Button variant="primaryoutline" color="btnfontprimary" buttonSize="full" buttonName="off board employees" onClick={handleOffBoardModal} />}
              </div>

            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <div className="right-container">
                <Grid container spacing={2} className={classes.profileform}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup disabled required inputType="text" label="First Name" inputName="first_name" value={data.first_name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup required inputType="text" label="Last Name" inputName="last_name" value={data?.last_name} disabled
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup required inputType="email" label="Email Address" inputName="email" value={data?.email} disabled />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SelectGroup
                      label="Gender"
                      required
                      children={
                        <select
                          name="gender"
                          disabled
                          value={data?.gender}
                          onChange={handleChange}
                        >
                          <option value="">--- Select ---</option>
                          {Gender?.map((gend) => {
                            return (
                              <option key={gend?.key} value={gend?.key}>
                                {gend?.text}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup disabled required inputType="text" label="Phone Number" inputName="phone_number" value={data?.phone_number}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SelectGroup required label="Role" children={
                      <select onChange={handleChange} value={data.role} name="role_id" disabled>
                        {rolesData?.map((role) => {
                          return (
                            <option key={role?.role_id} value={role?.role_id}>{role?.role_name}</option>
                          );
                        })}
                      </select>
                    }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup required inputType="text" label="Reporting to" inputName="" value={`${data?.reports_to?.first_name} ${data?.reports_to?.last_name}`} disabled />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup required inputType="text" label="Bank Name" inputName="name" value={profileDetails?.bank_details?.bank_name} onChange={handleBankDetailsChange} />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup required inputType="text" label="Account Number" inputName="account_number" value={profileDetails?.bank_details?.account_number} onChange={handleBankDetailsChange} />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup required inputType="text" label="Account Name" inputName="account_name" value={profileDetails?.bank_details?.account_name} onChange={handleBankDetailsChange} />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup required inputType="number" label="Salary" inputName="salary" value={profileDetails?.salary} onChange={handleChange} />
                  </Grid>

                  <div className={classes.btnspace}>
                    <Button
                      variant={data?.is_active === false ? "secondary" : "primary"}
                      color={data?.is_active === false ? "secondaryoutline" : "btndefault"}
                      disabled={data?.is_active === false}
                      buttonSize="medium"
                      buttonName="Save Changes"
                      isLoading={isCreating}
                      onClick={handleEditProfile}
                    />
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <Modal
          title={data?.is_active === true ? "Off board employee" : "Re-activate Employee"}
          subtitle={
            data?.is_active === true
              ? (
                <div>
                  Are you sure you want to off board
                  <span className="modal-name-highlight"> {`${data?.last_name} ${data?.first_name}`}</span>?
                  If deleted, the employee will no longer have access to this platform.
                </div>
              )
              : (
                <div>
                  Are you sure you want to re-activate
                  <span className="modal-name-highlight"> {`${data?.last_name} ${data?.first_name}`}</span>?
                  If re-activated, the employee will have access to this platform.
                </div>
              )
          }
          modalContent={
            <>

            </>
          }
          modalFooter={
            <>
              {data?.is_active === true ? <Button variant="danger" buttonName="Off board Employee" buttonSize="full" color="btndefault" />
                : <Button variant="primary" buttonName="Re-activate Employee" buttonSize="full" color="btndefault" />}
            </>
          }
          isopen={offboardModal}
          closeModal={handleCloseOffBoard}
        />
        <ToastContainer />
      </>
    }
    />
  );
};

export default Teacher;
