import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import InputGroup from "../../components/FormComponent/InputGroup";
import logo from "../../assets/images/logo.svg";
import loginBG from "../../assets/images/loginimg.png";
import useValidator from "../../hooks/useValidator";
import { ToastContainer } from "react-toastify";
import Button from "../../components/Button";
import { Icon } from "@iconify/react";

// --| Context files
import { useUserDispatch, handleLogin } from "../../context/UserContext";

const Login = () => {
  const userDispatch = useUserDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [loginValidator, showValidationMessage] = useValidator();
  const navigate = useNavigate();

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
    platform: "web"
  });

  const { email, password } = loginForm;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginForm({ ...loginForm, [name]: value });
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-container">
        <div className="auth-flex">
          <div className="auth-bg-container">
            <img src={loginBG} className="auth-bg-img" alt="login BG" />
          </div>
          <div className="auth-form-container">
            <div className="auth-logo-container">
              <img src={logo} alt="logo" className="auth-logo" />
            </div>
            <div className="auth-form">
              <React.Fragment>
                <div className="auth-heading black900">Login</div>
                <div className="auth-subheading black900">
                Put in your preferred email and password to sign up now on
                Shelf21 for team management.
                </div>
                <div className="input-padding">
                  <InputGroup
                    label="Email Address"
                    inputValue={email}
                    onChange={handleChange}
                    margin="normal"
                    inputName="email"
                    // placeHolder="Email Adress"
                    inputType="email"
                  />
                  {loginValidator.message("email", email, "required")}
                </div>
                <div className="input-padding">
                  <InputGroup
                    label="Password"
                    inputValue={password}
                    onChange={handleChange}
                    inputName="password"
                    inputType={passwordType}
                  />
                  <div className="password-eye">
                    {passwordType === "password" ? (
                      <Icon icon="fluent:eye-16-regular" onClick={togglePassword} />
                    ) : (
                      <Icon icon="iconamoon:eye-off-light" onClick={togglePassword} />
                    )}
                  </div>
                  {loginValidator.message("password", password, "required")}
                </div>
                <div className="auth-form-buttons">
                  <Button buttonName="Login" variant="primary" buttonSize="full"
                    color="btndefault" isLoading={isLoading}
                    onClick={() => handleLogin(
                      userDispatch,
                      loginForm,
                      navigate,
                      setIsLoading,
                      showValidationMessage,
                      loginValidator
                    )
                    }
                  />
                  <Link to="/forgot-password"
                    className="auth-forget-button app-primary"
                  >
                  Forgot Password ?
                  </Link>
                </div>
              </React.Fragment>
              <div className="auth-divider">
                <hr />
                <span className="brown900">Or</span>
              </div>
              <div className="brown900 auth-account-button">
              Don't have an account ?
                <Link to="/auth/signup" className="app-primary">Create an account</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={10000} />
    </div>
  );
};

export default Login;
