import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  buttoncountcontainer: {
    marginTop: 15,
    marginBottom: 15
  },
  pageContent: {
    backgroundColor: "white",
    width: "100%",
    height: "100%",
    borderRadius: "10px 10px 0px 0px",
    boxSizing: "border-box",
    paddingTop: "10px",
    marginTop: 20
  },
  contentpadding: {
    paddingLeft: 10,
    paddingBottom: 10
  },
  detailstext: {
    textAlign: "left"
  },
  pagedivider: {
    marginTop: 40,
    marginBottom: 40,
    color: "#DFE2E7"
  },
  title: {
    color: "#98A2B3",
    fontSize: 14,
    marginBottom: 3
  },
  subtitle: {
    color: "#40444B",
    fontSize: 14,
    fontWeight: 500
  },
  modalFooterBtn: {
    display: "flex",
    width: "100%",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center"
  },
  calendar: {
    border: "1px solid #E4E7EC",
    padding: "16px",
    borderRadius: "16px",
    marginTop: "15px"
  },
  terminfo: {
    maxWidth: "200px",
    // marginLeft: "auto",
    // marginBottom: "10px",
    padding: "5px",
    // border: `1px solid ${theme.palette.primary.main}`,
    // borderRadius: "5px",
    "& p": {
      fontWeight: "500",
      textAlign: "left",
      marginBlock: "0px",
      fontSize: "14px",
      color: "#667185"
    }
  }
}));
