import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import useStyles from "./styles";

// --| defaultView would be either of dayGridMonth, timeGridWeek, timeGridDay
const index = ({ events, renderEventContent, defaultView, minTime, maxTime, editable, handleEventClick }) => {
  const classes = useStyles();

  // --| Contemplating on using the current time to display the scrolltime or using the now indicator

  // const getCurrentTime = () => {
  //   const now = new Date();
  //   const hours = String(now.getHours()).padStart(2, "0");
  //   const minutes = String(now.getMinutes()).padStart(2, "0");
  //   const seconds = String(now.getSeconds()).padStart(2, "0");

  //   return `${hours}:${minutes}:${seconds}`;
  // };

  return (
    <div className={classes.calendar}>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={defaultView}
        weekends={false}
        height={650}
        expandRows
        dragScroll
        editable={editable}
        headerToolbar={
          {
            start: "",
            center: "",
            end: "prev today next"
          }
        }
        nowIndicator={true}
        // scrollTime={getCurrentTime()}
        slotDuration="00:15:00"
        slotMinTime={minTime}
        slotMaxTime={maxTime}
        duration="00:30:00"
        slotLabelInterval="00:30"
        events={events}
        eventContent={renderEventContent}
        eventClick={handleEventClick}
      />
    </div>
  );
};

export default index;
