import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  assignmentcontainerform: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 10,
      paddingLeft: 1,
      paddingBottom: 10
    }
  },
  quizcontainer: {
    padding: 10,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)",
    width: "66%",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  wrapper: {
    width: "20%",
    margin: "auto",
    marginTop: 10,
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  questioncontainer: {
    padding: 10,
    borderRadius: 10,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)",
    width: "66%",
    marginTop: 20,
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  title: {
    color: "#98A2B3",
    fontSize: 14,
    marginBottom: 3
  },
  subtitle: {
    color: "#40444B",
    fontSize: 14,
    fontWeight: 500
  },
  detailstext: {
    textAlign: "left"
  },
  question: {
    borderRadius: "8px",
    border: "1px solid #E4E7EC",
    background: "#F9F9F9",
    paddingInline: "16px"
  },
  divider: {
    paddingTop: 5,
    paddingBottom: 5
  },
  inputmargin: {
    paddingTop: 10
  },
  camera: {
    color: "rgb(96, 168, 255)",
    width: "30px !important",
    fontSize: "40px !important"
  },
  profilepicker: {
    opacity: 0,
    width: "40px",
    height: "40px",
    cursor: "pointer",
    position: "absolute",
    paddingTop: "10px !important"
  },
  nocenteritem: {
    textAlign: "left"
  },
  createAssignmentContent: {
    padding: "15px 20px",
    border: "1px solid #E4E7EC",
    borderRadius: "15px",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  correctlabel: {
    marginTop: 3
  },
  correctcontainer: {
    // color: "#667185",
    fontSize: 13,
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  questionfooter: {
    display: "flex",
    justifyContent: "right",
    marginTop: "8px",
    flexDirection: "row",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  editquestionfooter: {
    display: "flex",
    marginLeft: "auto",
    marginTop: "2px",
    marginBottom: "28px",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  btnwrapper: {
    width: "50%",
    marginTop: "30px",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    gap: "16px",
    "@media (max-width: 600px)": {
      flexWrap: "wrap",
      width: "100%"
    }
  },
  pointsInput: {
    width: "180px",
    height: "30px",
    boxSizing: "border-box",
    paddingLeft: "10px",
    paddingRight: "10px",
    border: "1px solid hsl(0, 0%, 80%)",
    marginTop: "4px",
    borderRadius: "6px",
    backgroundColor: "white",
    color: "#667185",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  assignmentsection: {
    background: "#F9F9F9",
    width: "100%",
    borderRadius: "8px",
    padding: "31px",
    border: "1px solid #E4E7EC",
    marginBottom: "16px",
    "@media (max-width: 430px)": {
      padding: "14px"
    }
  },
  quelabel: {
    textAlign: "left",
    display: "flex",
    color: "#545962",
    fontSize: "14px",
    fontWeight: "700"
  },
  quesaction: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  addque: {
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer",
    color: theme.palette.primary.main,
    "& p": {
      marginBlock: "0px",
      fontWeight: "700"
    }
  },
  addoption: {
    justifyContent: "flex-start",
    marginTop: "10px",
    display: "flex",
    maxWidth: "200px",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer",
    color: theme.palette.primary.main,
    "& p": {
      marginBlock: "0px",
      fontWeight: "400",
      fontSize: "14px"
    }
  },
  // textandimgmain: {
  //   position:
  // },
  quesdisplay: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    width: "100%",
    height: "80px",
    overflow: "hidden",
    marginBottom: "24px",
    "& img": {
      height: "100%",
      width: "auto",
      objectFit: "contain",
      "@media (max-width: 430px)": {
        height: "30px"
      }
    }
  },
  optiondisplay: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    width: "100%",
    height: "40px",
    overflow: "hidden",
    "& img": {
      height: "100%",
      width: "auto",
      objectFit: "contain",
      "@media (max-width: 430px)": {
        height: "30px"
      }
    }
  },
  queimg: {
    position: "absolute",
    top: "15px",
    right: "15px",
    cursor: "pointer"
  },
  enterque: {
    color: "#101928",
    display: "flex",
    justifyContent: "left",
    marginBlock: "5px 0px",
    fontSize: "14px",
    fontWeight: "500",
    textAlign: "left"
  },
  textandimg: {
    position: "relative",
    marginTop: "2px",
    borderRadius: "6px",
    border: "1px solid hsl(0, 0%, 80%)",
    backgroundColor: "white",
    "& input": {
      width: "100%",
      height: "46px",
      boxSizing: "border-box",
      background: "none",
      border: "0px",
      paddingLeft: "10px",
      paddingRight: "35px",
      color: "#667185"
    }
  },
  preiewcont: {
    "& img": {
      marginLeft: "10px",
      maxWidth: "300px",
      height: "200px",
      marginBottom: "20px"
    },
    "@media (max-width: 500px)": {
      "& img": {
        maxWidth: "150px",
        height: "100px"
      }
    }
  },
  preiewoptioncont: {
    "& img": {
      marginLeft: "10px",
      maxWidth: "200px",
      height: "100px",
      marginBottom: "20px"
    },
    "@media (max-width: 500px)": {
      "& img": {
        maxWidth: "120px",
        height: "100px"
      }
    }
  }
}));
