import { useState, useEffect } from "react";
import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
// import SchedulerCalendar from "../../../components/Scheduler";
import Modal from "../../../components/Modal";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import useStyles from "../styles";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import { Grid } from "@mui/material";
import { ClassType, Weekdays, Subject, ClassLevel } from "../../../utils/DropDown";
import { httprequest } from "../../../data/api";
import { toast, ToastContainer } from "react-toastify";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
import PageLoader from "../../../components/PageLoader";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import ButtonCount from "../../../components/ButtonCount";
import Permission from "../../error/Permission";
// import InputGroup from "../../components/FormComponent/InputGroup";
import Layout from "../../../components/Layout";

const CLASS_ENDPOINT = "/api/v1/class-teacher/class";
const CLASS_STUDENT_ENDPOINT = "/api/v1/class/students";

const EmployeeAllClasses = () => {
  const [value, setValue] = useState("");
  // const [searchResult, setSearchResult] = useState([]);
  const [classData, setClassData] = useState([]);
  const [physicalClassData, setPhysicalClassData] = useState([]);
  const [studentClassData, setStudentClassData] = useState({});
  const [searchResult, setSearchResult] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const classes = useStyles();
  const [addtimetableModal, setAddTimeTableModal] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);

  const navigate = useNavigate();

  const handleTimeTableModal = () => {
    setAddTimeTableModal(!addtimetableModal);
  };

  const getAllStudentClass = (param) => {
    httprequest.get(`${CLASS_STUDENT_ENDPOINT}/${param}`).then((res) => {
      setStudentClassData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // --| Get all my classes as a class teacher
  const getAllClasses = () => {
    setIsPageLoading(true);
    httprequest.get(CLASS_ENDPOINT).then((res) => {
      setIsPageLoading(false);
      setPhysicalClassData(res?.data?.data?.filter((clss) => clss?.class_type === "physical"));
      setClassData(res?.data?.data);
      // --| Set the value to the first ID
      const firstID = res?.data?.data?.filter((clss) => clss?.class_type === "physical")?.[0]?.id;
      setValue(firstID);
      // --| Get First Student Data;
      getAllStudentClass(firstID);
    }).catch((err) => {
      setIsPageLoading(false);
      if (err?.response?.status === 403) {
        setPermissionDenied(true);
      }
      toast.error(err?.response?.data);
    });
  };

  if (permissionDenied) {
    return <Permission />;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    getAllStudentClass(newValue);
  };

  const studentClassColumns = [
    {
      name: "Class Name",
      cell: (row) => row?.class?.name
    },
    {
      name: "Student Name",
      // eslint-disable-next-line prefer-template
      selector: (row) => row?.student?.first_name + " " + row?.student?.last_name
    },
    {
      name: "Gender",
      selector: row => row?.gender || "-------"
    },
    {
      name: "Student Email",
      selector: row => row?.email || "-------"
    },
    {
      name: "Guardian",
      // eslint-disable-next-line prefer-template
      selector: (row) => row?.guardian?.first_name + " " + row?.guardian?.last_name
    },
    {
      name: "Admission Date",
      selector: row => row?.admission_date?.substring(0, 10)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/class-activities/classes/students/${row?.student_id}`} className="table-action-icon">
            <Icon icon="fluent:eye-16-regular" />
          </Link>
        </div>
      )
    }
  ];

  // --| Filter Student Class using name, email and class
  const handleStudentClass = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = studentClassData?.filter((data) => valArray?.every(
      (word) => data?.first_name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
          data?.first_name
            ?.toLowerCase()
            .includes(word.toLowerCase()) ||
                      data?.student_email
                        ?.toLowerCase()
                        .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  useEffect(() => {
    getAllClasses();
    // getAllEmployees();
  }, []);

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Classes"
            subtitle="View all the details of the class that you are assigned as the class teacher"
            button={
              <div className="flex gap-10">
                <Button
                  variant="primaryoutline"
                  color="btnfontprimary"
                  onClick={() => navigate("/class-activities/classes/class-calendar")}
                  buttonName="View Class Calendar"
                />
              </div>
            }
          />
          {isPageLoading ? (<PageLoader />) :
            <>
              {classData?.length ? (
                <>
                  <ButtonCount count={classData?.length} title="View all classes" />
                  <TabContext value={value}>
                    <div className="tablist-container">
                      <Tabs
                        indicatorColor="none"
                        className="overide-tablist"
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        {physicalClassData?.map((data) => {
                          return (
                            <Tab key={data?.id} value={data?.id} label={data?.class_name} />
                          );
                        })}
                      </Tabs>
                    </div>
                    {classData?.map((data) => {
                      return (
                        <TabPanel key={data?.id} value={data?.id} className="tabpanel-overide">
                          <Table data={searchResult} columns={studentClassColumns} subHeader={true} pagination subHeaderComponent={
                            searchResult?.length > 0 && (
                              <TableSearch placeholder="Search here..." searchTableFunc={handleStudentClass} />
                            )}
                          />
                        </TabPanel>
                      );
                    })}
                  </TabContext>
                </>
              ) : (
                <>
                  <ButtonCount count={classData?.length} title="View all classes" />
                  <Table data={searchResult} columns={studentClassColumns} subHeader={true} pagination subHeaderComponent={
                    searchResult?.length > 0 && (
                      <TableSearch placeholder="Search here..." searchTableFunc={handleStudentClass} />
                    )}
                  />
                </>) }
            </>
          }
          {/* Add Time Table Modal */}
          <Modal
            title="Add to Timetable"
            subtitle={
              <div>Allocate a time slot for a subject in the timetable
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SelectGroup label="Subject" children={
                      <select>
                        <option value="">--- Select ---</option>
                        {Subject?.map((label) => {
                          return (
                            <option key={label.key}>{label?.text}</option>
                          );
                        })}
                      </select>
                    }
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SelectGroup label="Class" children={
                      <select>
                        <option value="">--- Select ---</option>
                        {ClassLevel?.map((label) => {
                          return (
                            <option key={label.key}>{label?.text}</option>
                          );
                        })}
                      </select>
                    }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SelectGroup label="Day of week" children={
                      <select>
                        <option value="">--- Select ---</option>
                        {Weekdays?.map((label) => {
                          return (
                            <option key={label.key}>{label?.text}</option>
                          );
                        })}
                      </select>
                    }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SelectGroup label="Alloted Time" children={
                      <select>
                        <option value="">--- Select ---</option>
                        {ClassType?.map((label) => {
                          return (
                            <option key={label.key}>{label?.text}</option>
                          );
                        })}
                      </select>
                    }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SelectGroup label="Start Time" children={
                      <select>
                        <option value="">--- Select ---</option>
                        {ClassType?.map((label) => {
                          return (
                            <option key={label.key}>{label?.text}</option>
                          );
                        })}
                      </select>
                    }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SelectGroup label="End Time" children={
                      <select>
                        <option value="">--- Select ---</option>
                        {ClassType?.map((label) => {
                          return (
                            <option key={label.key}>{label?.text}</option>
                          );
                        })}
                      </select>
                    }
                    />
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Add to timetable" />
                </div>
              </>
            }
            isopen={addtimetableModal}
            closeModal={handleTimeTableModal}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default EmployeeAllClasses;
