import "../auth/auth.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import close_btn from "../../assets/images/close-btn.svg";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Pageloader from "../../components/PageLoader";
import logo from "../../assets/images/logo.svg";

const VERIFY_EMAIL_ENDPOINT = "/api/v1/verify-email";
const RESEND_VERIFICATION_ENDPOINT = "/api/v1/verify-email";

const AuthVerification = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(null);
  const [showResend, setShowResend] = useState(false);
  const navigate = useNavigate();

  const handleVerify = async () => {
    setIsLoading(true);
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}${VERIFY_EMAIL_ENDPOINT}/${id}`);
      setIsVerified(true);
      toast.success("Account Verified Successfully");
      setTimeout(() => navigate("/auth/login"), 1500);
    } catch (error) {
      setIsVerified(false);
      const errorMessage = error.response?.data?.message || "Verification failed. Please try again later.";

      if (errorMessage === "jwt expired" || error.response?.status === 401) {
        setShowResend(true);
        toast.warning("Your verification link has expired. Please resend the verification email.");
      } else {
        toast.error(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendVerification = async () => {
    setIsLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}${RESEND_VERIFICATION_ENDPOINT}`, { token: id });
      toast.success("Verification email sent successfully.");
      setTimeout(() => navigate("/auth/login"), 1500);
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Failed to resend verification email.";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleVerify();
  }, []);

  return (
    <div className="verfication-container">
      <img src={logo} alt="logo" />
      {isLoading ? (
        <Pageloader />
      ) : (
        <div className="overlay">
          <div className="pop-contain">
            {isVerified === true && (
              <div className="popup-content">
                <div className="verif">
                  <h4>Verification Successful</h4>
                  <img src={close_btn} alt="Close" height="32px" />
                </div>
                <p>
                  Your email has been successfully verified. Click below to visit your dashboard and start using Shelf21.
                </p>
                <Link to="/dashboard">
                  <button className="auth-create-button white bg-app-primary">
                    Go to Dashboard
                  </button>
                </Link>
              </div>
            )}

            {isVerified === false && !showResend && (
              <div className="popup-content">
                <div className="verif">
                  <h4 style={{ color: "red" }}>Verification Failed</h4>
                  <img src={close_btn} alt="Close" height="32px" />
                </div>
                <p>Your email could not be verified. Please try again later or contact support.</p>
                <Link to="/auth/login">
                  <button className="auth-create-button white bg-app-danger">
                    Go Home
                  </button>
                </Link>
              </div>
            )}

            {showResend && (
              <div className="popup-content">
                <div className="verif">
                  <h4 style={{ color: "orange" }}>Verification Link Expired</h4>
                  <img src={close_btn} alt="Close" height="32px" />
                </div>
                <p>
                  Your verification link has expired. Click the button below to resend a verification email.
                </p>
                <button
                  className="auth-create-button white bg-app-primary"
                  onClick={handleResendVerification}
                  disabled={isLoading}
                >
                  Resend Verification Email
                </button>
              </div>
            )}
          </div>
          <ToastContainer />
        </div>
      )}
    </div>
  );
};

export default AuthVerification;
