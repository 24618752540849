/* eslint-disable no-unused-vars */
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useState, Fragment, useEffect } from "react";
import Select from "react-select";
import { Grid } from "@mui/material";
import Button from "../../components/Button";
import useStyles from "./styles";
import { Icon } from "@iconify/react";
import { httprequest } from "../../data/api";
import { toast, ToastContainer } from "react-toastify";
import InputGroup from "../../components/FormComponent/InputGroup";
import SelectGroup from "../../components/FormComponent/SelectGroup";
import useValidator from "../../hooks/useValidator";
import UploadFile from "../../components/UploadFile";
import { useUpload } from "../../hooks/useUpload";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../components/Modal";

const ENROLMENT_ENDPOINT = "/api/v1/enrolment";
const CLASS_ENDPOINT = "/api/v1/class";
const BANNER_ENDPOINT = "/api/v1/banner";

const EditEnrolments = () => {
  const classes = useStyles();
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [multipleClasses, setMultipleClasses] = useState([]);
  const [enrolmentValidator, showEnrolmentValidator] = useValidator();
  const [isCreating, setIsCreating] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [previewClasses, setPreviewClasses] = useState([]);
  const [banner, setBanner] = useState("");
  const [bannerPic, setBannerPic] = useState(null);
  const [enrolmentForm, setEnrolmentForm] = useState({
    title: "",
    closing_date: new Date(),
    classes: [],
    banner: {},
    amount: 0
  });
  const [selectedFile, setSelectedFile] = useState("");
  const [payment, isPayment] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [visibleCustomFieldIndex, setVisibleCustomFieldIndex] = useState(0);
  const [customFields, setCustomFields] = useState([]);
  const [paymentIntegrationId, setPaymentIntegrationId] = useState(null);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const MAX_CUSTOM_FIELDS = 10;
  const [actionModal, setActionModal] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleCloseActionModal = () => {
    setActionModal(!actionModal);
  };
  const getEnrolmentData = async () => {
    try {
      const response = await httprequest.get(`${ENROLMENT_ENDPOINT}/${id}`);
      const enrolmentData = response.data.data;

      // Prefill the form with fetched data
      setEnrolmentForm({
        ...enrolmentForm,
        closing_date: enrolmentData.closing_date
          ? enrolmentData.closing_date.substring(0, 10)
          : "",
        classes: enrolmentData?.classes.map((cls) => cls.class_id),
        amount: enrolmentData?.amount || 0,
        banner: enrolmentData?.banner || {},
        title: enrolmentData?.title,
        require_payment: enrolmentData?.require_payment || false
      });

      isPayment(enrolmentData.require_payment);

      if (enrolmentData.payment) {
        setSelectedPaymentOption({
          label: enrolmentData?.payment?.provider,
          value: enrolmentData?.payment?.id
        });
        setPaymentIntegrationId(enrolmentData?.payment?.id);
      }

      if (enrolmentData.currency) {
        setSelectedCurrency({
          label: enrolmentData?.currency,
          value: enrolmentData?.currency
        });
      }

      // Set multiple classes if they are fetched properly
      const fetchedClasses = enrolmentData.classes.map((cls) => ({
        label: cls?.class_name,
        value: cls?.class_id
      }));
      setPreviewClasses(fetchedClasses);

      // Handle custom fields
      const customFieldsData = [];
      for (let i = 0; i < 10; i++) {
        const key = `custom_${String.fromCharCode(97 + i)}`;
        const customField = enrolmentData[key];
        if (customField && customField?.label && customField?.type) {
          customFieldsData.push({ label: customField?.label, type: customField?.type });
        }
      }
      setCustomFields(customFieldsData);
      if (customFieldsData.length > 0) {
        setIsChecked(true);
        setVisibleCustomFieldIndex(customFieldsData.length - 1);
      }

      // eslint-disable-next-line no-console
      console.log(enrolmentData, "enrolmentData");
    } catch (error) {
      toast.error("Failed to fetch enrollment data.");
    }
  };

  useEffect(() => {
    getEnrolmentData(); // Fetch enrolment data on component mount
  }, [id]);
  const handleDateChange = (event) => {
    setEnrolmentForm({ ...enrolmentForm, closing_date: event.target.value }); // Update the closing date in the form state
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    setEnrolmentForm({ ...enrolmentForm, amount: value });
  };

  const handlePaymentFieldCheck = (e) => {
    const { checked } = e.target;
    isPayment(checked);
  };

  useEffect(() => {
    setEnrolmentForm((prevForm) => ({
      ...prevForm,
      require_payment: payment ? Boolean(selectedPaymentOption) : false
    }));
  }, [payment, selectedPaymentOption]);

  const handlePaymentOptionChange = (selectedOption) => {
    setSelectedPaymentOption({
      label: selectedOption.label,
      value: selectedOption.value
    });

    const selectedOptionData = paymentOptions.find(
      (option) => option.id === selectedOption.value
    );

    if (selectedOptionData) {
      setPaymentIntegrationId(selectedOptionData.id);

      // Fetch available currencies for the selected provider
      httprequest
        .get(`/api/v1/integrations/${selectedOptionData.provider}/currencies`)
        .then((res) => {
          const currencyList = res.data.data || [];
          setCurrencyOptions(
            currencyList.map((currency) => ({
              label: currency,
              value: currency
            }))
          );
        })
        .catch((err) => {
          toast.error("Failed to fetch currencies.");
        });
    }
  };

  const handleCurrencyChange = (selectedCurrencyOption) => {
    setSelectedCurrency(selectedCurrencyOption.value);
  };

  const handleFileChange = (e) => {
    // const { name } = e.target;
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
      setBannerPic(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile("");
    setBannerPic(null);
  };
  // Get Random banner if no banner image is provided,
  // Ge Name of the school
  // Get Expiry of the enrolment

  const handleMultipleClassesChange = (event) => {
    const selectedValue = event.map((item) => item.value);

    setPreviewClasses({ ...previewClasses, event });
    setEnrolmentForm({ ...enrolmentForm, classes: selectedValue });
  };

  const handleCustomFieldCheck = (e) => {
    const { checked } = e.target;
    setIsChecked(checked);
    if (checked) {
      // Initialize the custom fields with one field
      setCustomFields([{ label: "", type: "" }]);
      setVisibleCustomFieldIndex(0); // Set the visible index to 0 for the first field
    } else {
      // Clear the custom fields when unchecked
      setCustomFields([]);
    }
  };

  const handleAddCustomField = () => {
    const newCustomFields = [...customFields];
    if (newCustomFields.length < MAX_CUSTOM_FIELDS) {
      newCustomFields.push({ label: "", type: "" });
      setCustomFields(newCustomFields);
      setVisibleCustomFieldIndex(newCustomFields.length - 1);
    } else {
      toast.error("You can only add up to 10 custom fields.");
    }
  };
  const handleRemoveCustomField = (index) => {
    const newCustomFields = [...customFields];
    newCustomFields.splice(index, 1); // Remove the selected custom field
    setCustomFields(newCustomFields);

    // Update visible field index
    if (visibleCustomFieldIndex >= index) {
      setVisibleCustomFieldIndex(Math.max(0, newCustomFields.length - 1));
    }
  };

  const handleCustomFieldChange = (index, key, value) => {
    const newCustomFields = [...customFields];
    newCustomFields[index] = { ...newCustomFields[index], [key]: value };
    setCustomFields(newCustomFields);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setEnrolmentForm({ ...enrolmentForm, [name]: value });
  };

  const getAllClasses = () => {
    httprequest
      .get(CLASS_ENDPOINT)
      .then((res) => {
        setMultipleClasses(
          res?.data?.data
            ?.filter((cls) => cls?.class_type === "physical")
            ?.map((clss) => {
              return {
                label: classes?.class_name || clss?.class_name,
                value: clss?.id
              };
            })
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getRandomBanner = () => {
    httprequest
      .get(BANNER_ENDPOINT)
      .then((res) => {
        setEnrolmentForm({
          ...enrolmentForm,
          banner: { ...enrolmentForm?.banner, file_url: res?.data?.data }
        });
        setBanner(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    if (!banner && activeStep === 1) {
      getRandomBanner();
    }
    // Fetch payment options from the API
    httprequest
      .get("/api/v1/integrations/school")
      .then((res) => {
        setPaymentOptions(res?.data?.data || []);
      })
      .catch(() => {

      });
  }, [banner, activeStep]);
  const handleNext = () => {
    let isValid = enrolmentValidator.allValid();

    // Validate custom fields
    customFields.forEach((field, index) => {
      if (!field.label || !field.type) {
        isValid = false;
        // Optionally: set specific validation errors for each field
        toast.error("Custom fields requires both label and type.");
      }
    });

    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      showEnrolmentValidator(true);
    }
  };
  const handleSave = async () => {
    const customFieldsMap = {};
    customFields.forEach((field, index) => {
      const key = `custom_${String.fromCharCode(97 + index)}`; // e.g., custom_a, custom_b, etc.
      customFieldsMap[key] = {
        label: field.label,
        type: field.type
      };
    });

    const data = {
      ...enrolmentForm,
      ...customFieldsMap,
      require_payment: payment ? Boolean(selectedPaymentOption) : false
    };

    try {
      await httprequest.patch(`${ENROLMENT_ENDPOINT}/${id}`, data);
      toast.success("Changes saved successfully!");
      setIsSaved(true); // Mark as saved after successful save
    } catch (error) {
      toast.error("Failed to save changes.");
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = ["Edit Enrollment", "Preview & Edit Enrollment Form"];

  const handleCreateEnrolment = async () => {
    if (enrolmentValidator.allValid()) {
      setIsCreating(true);
      const customFieldsMap = {};
      customFields.forEach((field, index) => {
        const key = `custom_${String.fromCharCode(97 + index)}`; // custom_a, custom_b, etc.
        customFieldsMap[key] = {
          label: field.label,
          type: field.type
        };
      });
      const data = {
        ...enrolmentForm,
        ...(payment
          ? {
            require_payment: !!payment,
            amount: payment ? enrolmentForm.amount : 0,
            payment_integration_id: paymentIntegrationId,
            currency: selectedCurrency?.value || ""
          }
          : {}),
        ...customFieldsMap
      };
      if (selectedFile) {
        // Handle file upload
        try {
          const response = await useUpload(selectedFile);
          if (response.status === 200 && response.data.data) {
            const upload_id = response.data.data?.[0]?.upload_id;
            const file_url = response.data.data?.[0]?.file_url;
            data.banner = { ...enrolmentForm?.banner, file_url, upload_id };

            const result = await httprequest.post(ENROLMENT_ENDPOINT, data);
            toast.success(result?.data?.message);
            navigate("/enrolments");
          } else {
            throw new Error("Unable to upload banner");
          }
        } catch (error) {
          toast.error(error.message);
        } finally {
          setIsCreating(false);
        }
      } else {
        try {
          const result = await httprequest.patch(`${ENROLMENT_ENDPOINT}/${id}`, data);
          toast.success(result?.data?.message);
          navigate("/enrolments");
        } catch (error) {
          toast.error(error?.response?.data?.message);
        } finally {
          setIsCreating(false);
        }
      }
    } else {
      showEnrolmentValidator(true);
    }
  };
  useEffect(() => {
    getAllClasses();
    if (!enrolmentForm?.upload_id && activeStep === 1) {
      getRandomBanner();
    }
  }, []);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/enrolments"
                link_one_name="Enrolments"
                active="Edit Enrolment"
                description="Edit Enrolment in your school"
              />
            </div>
          </div>
          <div className="page-content">
            <Stepper activeStep={activeStep} sx={{ pb: 2 }}>
              {steps.map((label) => {
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div>
              {activeStep === 0 ? (
                <div className={classes.enrolmentcontent}>
                  <div className={`${classes.imagecontent} banner_div`}>
                    <img
                      className={`${classes.enrollmentImg}`}
                      src={bannerPic || enrolmentForm.banner?.file_url}
                      alt=""
                    />

                  </div><div onClick={handleCloseActionModal} className="edit_icon"><Icon icon="lucide:edit" /> Edit</div>
                  <Grid container spacing={2} className="input-padding">
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                      <InputGroup
                        label="Title"
                        margin="normal"
                        inputType="text"
                        inputName="title"
                        defaultValue={enrolmentForm?.title}
                        value={enrolmentForm?.title}
                        onChange={handleChange}
                        required
                      />
                      {enrolmentValidator.message(
                        "title",
                        enrolmentForm?.title,
                        "required"
                      )}
                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                      <div className="multiple-select">
                        <p>
                          Classes <span className="input-required">*</span>
                        </p>
                        <Select
                          isMulti
                          name="classes"
                          required
                          closeMenuOnSelect={true}
                          options={multipleClasses}
                          value={multipleClasses.filter((cls) => enrolmentForm.classes.includes(cls.value))}
                          onChange={handleMultipleClassesChange}
                        />
                      </div>
                      {enrolmentValidator.message(
                        "classes",
                        enrolmentForm?.classes,
                        "required"
                      )}
                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                      <InputGroup
                        label="Closing Date"
                        margin="normal"
                        inputType="date"
                        inputName="closing_date"
                        defaultValue={enrolmentForm?.closing_date}
                        value={enrolmentForm.closing_date}
                        onChange={handleChange}
                        inputMin={new Date().toISOString().split("T")[0]}
                        required
                      />
                      {enrolmentValidator.message(
                        "closing date",
                        enrolmentForm?.closing_date,
                        "required"
                      )}
                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                      <>
                        <input
                          type="checkbox"
                          name="payment"
                          checked={payment}
                          onChange={handlePaymentFieldCheck}
                          id="payment"
                        />
                        <label className="checkbox-label" htmlFor="payment">
      Add Payment
                        </label>
                      </>
                    </Grid>
                    {payment && (
                      <div className="payment_div">
                        <Grid container spacing={2}>
                          <Grid item lg={8} md={8} sm={12} xs={12}>
                            <div className="multiple-select">
                              <p className="input-picker-title">Select Payment Provider</p>
                              <Select
                                name="payment_option"
                                options={paymentOptions.map((option) => ({
                                  label: option.provider,
                                  value: option.id
                                }))}
                                value={selectedPaymentOption}
                                onChange={handlePaymentOptionChange}
                              />

                            </div>
                          </Grid>

                          <Grid item lg={8} md={8} sm={12} xs={12}>
                            <InputGroup
                              label="Amount"
                              margin="normal"
                              inputType="number"
                              inputName="amount"
                              value={enrolmentForm.amount}
                              onChange={handleAmountChange}
                              required={payment}
                            />
                          </Grid>

                          <Grid item lg={8} md={8} sm={12} xs={12}>
                            <div className="multiple-select">
                              <p className="input-picker-title">Select Currency</p>
                              <Select
                                name="currency"
                                options={currencyOptions}
                                value={selectedCurrency}
                                onChange={handleCurrencyChange}
                              />
                            </div>

                          </Grid>
                        </Grid>
                      </div>
                    )}
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                      <>
                        <input
                          type="checkbox"
                          name="custom_form"
                          checked={isChecked}
                          onChange={handleCustomFieldCheck}
                          id="custom_form"
                        />
                        <label className="checkbox-label" htmlFor="custom_form">
                        Add Custom Fields
                        </label>
                      </>
                      {isChecked && (
                        <>
                          {customFields.map((field, index) => (
                            <Fragment key={index}>
                              {index <= visibleCustomFieldIndex && (
                                <Grid container spacing={2} key={index}>
                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <InputGroup
                                      label={`Custom Field (${String.fromCharCode(
                                        97 + index
                                      ).toUpperCase()}) Label`}
                                      margin="normal"
                                      inputType="text"
                                      inputName={`custom_${String.fromCharCode(97 + index)}_label`}
                                      value={field.label}
                                      onChange={(e) => handleCustomFieldChange(index, "label", e.target.value)
                                      }
                                      required
                                    />
                                  </Grid>
                                  <Grid item lg={6} md={12} sm={12} xs={12}>
                                    <div className="custom_div">
                                      <Grid item sm={12} xs={12}>
                                        <SelectGroup
                                          label={`Custom Field (${String.fromCharCode(
                                            97 + index
                                          ).toUpperCase()}) Type`}
                                          children={
                                            <select
                                              value={field.type}
                                              name={`custom_${String.fromCharCode(97 + index)}_type`}
                                              onChange={(e) => handleCustomFieldChange(index, "type", e.target.value)
                                              }
                                            >
                                              <option value="">--Select Options---</option>
                                              <option value="input_field">Input Field</option>
                                              <option value="date">Date</option>
                                              <option value="time">Time</option>
                                            </select>
                                          }
                                        />
                                      </Grid>
                                      <Button
                                        variant="danger"
                                        buttonSize="fluid"
                                        color="btndefault"
                                        onClick={() => handleRemoveCustomField(index)}
                                        buttonName="Remove"
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              )}
                            </Fragment>
                          ))}
                          {customFields.length < MAX_CUSTOM_FIELDS && (
                            <div className="custom_Button">
                              <Button
                                variant="primaryoutline"
                                buttonSize="fluid"
                                color="btnfontprimary"
                                onClick={handleAddCustomField}
                                buttonName="Add"
                              />
                            </div>
                          )}
                        </>
                      )}
                    </Grid>

                  </Grid>
                </div>
              ) : activeStep === 1 ? (
                <div className={classes.previewcontent}>
                  {/* Preview Enrollment Form */}
                  <div className={classes.ribbon}>
                    Sample Application Form that will be visible to your users
                  </div>
                  <div className={`${classes.enrolmenthero}`}>
                    <h2 className={classes.previewtitle}>
                      School Enrolment and Pre-examination.
                    </h2>
                    <h6 className={classes.previewsub}>
                      Be a part of the students that get to be enrolment in the
                      prestigious <span>Shelf21</span> academy. Registration is
                      ongoing and will last until{" "}
                      {enrolmentForm?.closing_date?.substring(0, 10)}.
                    </h6>
                    <div className={classes.enrolbtn}>
                      <Button
                        variant="primary"
                        buttonSize="fluid"
                        color="btndefault"
                        buttonName="Enroll for examination"
                        disabled
                      />
                    </div>
                  </div>
                  <div className={`${classes.imagecontent}`}>
                    <img
                      className={`${classes.enrollmentImg}`}
                      src={bannerPic || banner}
                      alt=""
                    />
                  </div>
                  <Grid item lg={11} md={11} sm={11} xs={11}>
                    <p className={`${classes.HeaderPara}`}>APPLICATION FORM</p>
                    <h5 className={classes.previewsub}>
                      Fill in the required details to complete your application
                      to Umbrella Academy.
                    </h5>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <h5 className={classes.previewsub}>
                        Upload your last session results from your previous
                        school for assessment.
                      </h5>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <h5 className={classes.previewsub}>
                        Passport Photograph
                      </h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <div className={classes.pickercontainer}>
                        <input
                          type="file"
                          className={classes.picker}
                          disabled
                        />
                        <div className={classes.columnItems}>
                          <div>
                            <Icon
                              icon="icon-park-outline:upload-two"
                              className={classes.uploadicon}
                            />
                          </div>
                          <div>
                            <h2 className={classes.uploadtitle}>
                              Drag & Drop .PDF files of your results or click to
                              upload here.
                            </h2>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <div className={classes.pickercontainer}>
                        <input
                          type="file"
                          className={classes.picker}
                          disabled
                        />
                        <div className={classes.columnItems}>
                          <div>
                            <Icon
                              icon="icon-park-outline:upload-two"
                              className={classes.uploadicon}
                            />
                          </div>
                          <div>
                            <h2 className={classes.uploadtitle}>
                              Upload your passport here
                            </h2>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <h4 className={`${classes.RightHeader}`}>
                    Application Information
                  </h4>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={6} xs={6}>
                      <InputGroup
                        inputType="text"
                        label="Full Name"
                        name=""
                        disabled
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={6}>
                      <SelectGroup
                        label="Class Applying to"
                        children={
                          <select>
                            <option value="">--- Select ---</option>
                            {previewClasses?.event?.map((cls) => {
                              return (
                                <option key={cls?.value} value={cls?.value}>
                                  {cls?.label}
                                </option>
                              );
                            })}
                          </select>
                        }
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={6}>
                      <InputGroup
                        inputType="text"
                        label="Email address"
                        name=""
                        disabled
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={6}>
                      <InputGroup
                        inputType="text"
                        label="Phone Number"
                        name=""
                        disabled
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={6}>
                      <InputGroup
                        inputType="text"
                        label="Home Address"
                        name=""
                        disabled
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={6}>
                      <InputGroup
                        inputType="text"
                        label="Full Name"
                        name=""
                        disabled
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={6}>
                      <InputGroup
                        inputType="text"
                        label="Guardian Name:"
                        name=""
                        disabled
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={6}>
                      <InputGroup
                        inputType="text"
                        label="Guardian Email address"
                        name=""
                        disabled
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={6}>
                      <InputGroup
                        inputType="text"
                        label="Guardian Phone Number"
                        name=""
                        disabled
                      />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={6}>
                      <InputGroup
                        inputType="text"
                        label="Guardian Home Address"
                        name=""
                        disabled
                      />
                    </Grid>
                    {/* Render custom fields */}
                    {customFields.map((field, index) => (
                      <Grid item lg={4} md={4} sm={6} xs={6} key={index}>
                        <InputGroup
                          inputType={field.type === "date" ? "date" : field.type}
                          label={field.label}
                          name={`custom_${String.fromCharCode(97 + index)}`}
                          value={field.value}
                          disabled
                        />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              ) : activeStep === 2 ? (
                <>
                  <div> Three</div>
                </>
              ) : null}
            </div>
            <Fragment>
              <div className={classes.enrolpaginate}>
                <Button
                  variant="dangeroutline"
                  buttonSize="fluid"
                  color="btndanger"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  buttonName="Back"
                />
                {activeStep === 0 && (
                  <Button
                    variant="primary"
                    buttonSize="fluid"
                    color="btndefault"
                    onClick={handleSave}
                    buttonName={activeStep !== steps.length - 1 && "Save Changes"}
                  />
                )}
                {activeStep === 0 && (
                  <Button
                    variant="primaryoutline"
                    buttonSize="fluid"
                    color="btnfontprimary"
                    onClick={handleNext}
                    buttonName={activeStep !== steps.length - 1 && "Preview"}
                  />
                )}
                {activeStep === 1 && (
                  <Button
                    variant="primary"
                    buttonSize="fluid"
                    color="btndefault"
                    onClick={handleCreateEnrolment}
                    isLoading={isCreating}
                    buttonName="Save Changes"
                  />
                )}
              </div>
              <Modal
                title="Upload banner"
                subtitle={<div><p>Change current school banner.</p></div>}
                modalContent={
                  <>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      {/* <p className="input-picker-title">Upload Banner</p> */}
                      <UploadFile
                        uploadTitle="Tap to Upload"
                        uploadSubtitle="PNG or JPG"
                        accept="image/jpeg,image/jpg,image/png"
                        selectedFile={selectedFile}
                        onChange={handleFileChange}
                        handleRemoveFile={handleRemoveFile}
                      />
                    </Grid>
                  </>

                }
                modalFooter={
                  <Grid container className="input-padding">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Button
                        variant="dangeroutline"
                        buttonSize="full"
                        color="btndanger"
                        buttonName="Cancel"
                        onClick={() => setActionModal(false)}
                      />
                    </Grid>
                  </Grid>
                }
                isopen={actionModal}
                closeModal={() => setActionModal(false)}
              />
            </Fragment>
            <ToastContainer />
          </div>
        </>
      }
    />
  );
};

export default EditEnrolments;
