import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import { useUserState } from "../../../context/UserContext";
import Modal from "../../../components/Modal/index";
import { Grid, Pagination } from "@mui/material";
import { Icon } from "@iconify/react";
import useStyles from "../styles";
import InputGroup from "../../../components/FormComponent/InputGroup";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NotFounditem from "../../error/NotFound";
import nosearch from "../../../assets/images/no-search.svg";
import SchoolEventCard from "./component/SchoolEventCard";
import EventBanner from "../../../assets/images/eventbanner.jpg";
import { httprequest } from "../../../data/api";
import { toast, ToastContainer } from "react-toastify";
import useValidator from "../../../hooks/useValidator";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { CardSearch } from "../../../components/CardSearch";
import { VenueType } from "../../../utils/DropDown";
import Select from "react-select";
import PageLoader from "../../../components/PageLoader";
import { useUpload, useEditUpload } from "../../../hooks/useUpload";
import ReactPlayer from "react-player";
import { useTruncate } from "../../../hooks/useTruncate";

// API ENDPOINTS
const ALL_EVENTS = "/api/v1/events";
const UPLOAD_ENDPOINT = "/api/v1/upload";
const ROLES_ENDPOINT = "/api/v1/roles";

const AdminEvents = () => {
  const navigate = useNavigate();
  const [currentRow, setCurrentRow] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editEventModal, setEditEventModal] = useState(false);
  const [eventvalidator, showEventValidator] = useValidator();
  const [editeventvalidator, showEditEventValidator] = useValidator();
  const [deleteModal, setDeleteModal] = useState(false);
  const { profile, userRole } = useUserState();
  const [value, setValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState("");
  const [editselectedFile, setEditSelectedFile] = useState("");
  // const [editselectedFileName, setEditSelectedFileName] = useState("");
  const [addEventModal, setAddEventModal] = useState(false);
  const [isEventsFetching, setIsEventsFetching] = useState(false);
  const [allevents, setAllEvents] = useState([]);
  const [multipleAccess, setMultipleAccess] = useState([]);
  const [uploadForm, setUploadForm] = useState({});
  // const [edituploadForm, setEditUploadForm] = useState({});
  const [isDeleting, setIsEventDeleting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFileRemoved, setIsFileRemoved] = useState(false);
  const [eventId, setEventID] = useState();
  const [helpModal, setHelpModal] = useState(false);

  // --| Card Pagination
  const itemsPerPage = 6;

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const [eventForm, setEventForm] = useState({
    title: "",
    venue: "",
    access: [],
    event_type: "",
    description: "",
    location: "",
    date: "",
    time: "",
    banner: {}
  });
  const [editeventForm, setEditEventForm] = useState({
    title: "",
    venue: "",
    access: [],
    event_type: "",
    description: "",
    location: "",
    date: "",
    time: "",
    banner: {}
  });

  const handleHelpModal = () => {
    setHelpModal(!helpModal);
  };

  const handleCloseVideoModal = () => {
    setHelpModal(false);
  };

  const handleEventChange = (e) => {
    const { name, value } = e.target;

    if (name === "time") {
      const formattedTime = value.slice(0, 5);

      const time = `${formattedTime}:00`;

      setEventForm({ ...eventForm, [name]: time });
    } else {
      setEventForm({ ...eventForm, [name]: value });
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setCurrentPage(1); // Reset to first page on tab change
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    if (name === "time") {
      const formattedTime = value.slice(0, 5);
      const time = `${formattedTime}:00`;
      setEditEventForm({ ...editeventForm, [name]: time });
    }
    setEditEventForm({ ...editeventForm, [name]: value });
  };

  const handleAddEventModal = () => {
    setAddEventModal(!addEventModal);
  };

  const handleCloseAddEventModal = () => {
    setAddEventModal(false);
  };

  const handleEditEventModal = (row) => {
    setEditEventModal(!editEventModal);

    // Set the edit event form with row data
    setEditEventForm({
      ...editeventForm,
      title: row?.title,
      description: row?.description,
      access: row?.access,
      event_type: row?.event_type,
      venue: row?.venue,
      location: row?.location,
      date: row?.date,
      time: row?.time,
      banner: {
        upload_id: row?.banner?.upload_id,
        file_url: row?.banner?.file_url
      }
    });

    // Set the editselected file if upload_id and file_url are available
    // if (row?.banner?.file_url) {
    //   setEditSelectedFileName({ name: row?.banner?.file_url.split("/").pop() });
    // } else {
    //   setEditSelectedFileName(null);
    // }

    setCurrentRow(row);
  };

  const navigateEventDetail = (id) => {
    navigate(`/events/${id}`);
  };

  const handleCloseEditEventModal = () => {
    setEditEventModal(false);
  };

  const handleDeleteModal = (event) => {
    setEventID(event);
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  // FOR FILE UPLOAD
  const handleFileChange = (e) => {
    // const { name } = e.target;
    if (e.target.files) setSelectedFile(e.target.files[0]);
    setUploadForm({ ...uploadForm, file: e.target.files[0].name.replaceAll(" ", "-") });
  };

  const handleEditFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setEditSelectedFile(file);
    }
  };

  const handleEditRemoveFile = async () => {
    try {
      if (currentRow?.banner?.upload_id) {
        const response = await httprequest.delete(`${UPLOAD_ENDPOINT}/${currentRow?.banner?.upload_id}`);
        if (response.status === 200) {
          setEditEventForm(prevForm => ({
            ...prevForm,
            banner: {
              upload_id: "",
              file_url: ""
            }
          }));
          setIsFileRemoved(true);
          toast.success("Banner removed successfully.");
        } else {
          toast.error(response?.data?.message);
        }
      } else {
        toast.error("No file found to remove.");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const handleRemoveFile = () => setSelectedFile("");

  const getAllRoles = () => {
    httprequest.get(ROLES_ENDPOINT).then((res) => {
      const access = res?.data?.data?.filter((res) => res?.role_type !== "school_admin");
      setMultipleAccess(access?.map((role) => {
        return {
          label: role?.role_name,
          value: role?.role_id
        };
      }));
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getAllEvents = () => {
    setIsEventsFetching(true);
    httprequest
      .get(ALL_EVENTS)
      .then((res) => {
        setIsEventsFetching(false);
        setAllEvents(res?.data?.data);
        // getCurrentItems(res?.data?.data);
        // setTotalPages(Math.ceil(res?.data?.data.length / itemsPerPage));
        // setAllSearchEvent(res?.data?.data);
      })
      .catch((err) => {
        setIsEventsFetching(false);
        toast.error(err?.response?.data);
      });
  };

  // Create Event
  const handleCreateEvent = async () => {
    if (eventvalidator.allValid()) {
      if (!selectedFile) {
        setIsCreating(false);
        toast.error("No file(s) uploaded, select a file to upload");

        return;
      }

      setIsCreating(true);
      const formData = new FormData();
      formData.append("file", selectedFile);

      try {
        const response = await httprequest.post(`${UPLOAD_ENDPOINT}`, formData);
        if (response.status === 200 && response.data.data) {
          const upload_id = response.data.data?.[0]?.upload_id;
          const file_url = response.data.data?.[0]?.file_url;

          const updatedEventForm = {
            ...eventForm,
            banner: {
              upload_id,
              file_url
            }
          };

          const res = await httprequest.post(ALL_EVENTS, updatedEventForm);
          setIsCreating(false);
          toast.success(res?.data?.message);
          getAllEvents();

          // Reset form and close modal
          setEventForm({
            ...eventForm,
            title: "",
            venue: "",
            access: "",
            event_type: "",
            description: "",
            location: "",
            date: "",
            time: "",
            banner: {}
          });
          handleCloseAddEventModal();
        } else {
          setIsCreating(false);
          handleCloseAddEventModal();
          toast.error("There is a problem uploading the file");
        }
      } catch (err) {
        setIsCreating(false);
        toast.error(err?.response?.data?.message);
      }
    } else {
      setIsCreating(false);
      showEventValidator(true);
      toast.error("Please fill in all required fields");
    }
  };

  const handleMultipleAccessChange = (event) => {
    const selectedValue = event.map((item) => item.value);
    setEventForm({ ...eventForm, access: selectedValue });
  };

  const handleEditMultipleAccessChange = (event) => {
    const selectedAccessIds = event.map((option) => option?.value);

    setEditEventForm((prevEvent) => ({
      ...prevEvent,
      access: selectedAccessIds
    }));
  };

  // const defaultAccessValue = multipleAccess.filter(option => editeventForm?.access.includes(option?.value));

  // Handle Edit
  const handleEditEvent = async () => {
    if (editeventvalidator.allValid()) {
      setEditLoading(true);
      try {
        let updatedEventForm = { ...editeventForm };

        // Handle file upload if the file has been removed and a new file to selected
        if (isFileRemoved && editselectedFile) {
          const response = await useUpload(editselectedFile);

          if (response?.status === 200 && response?.data?.data) {
            updatedEventForm = {
              ...updatedEventForm,
              banner: {
                upload_id: response?.data?.data?.[0]?.upload_id,
                file_url: response?.data?.data?.[0]?.file_url
              }
            };
          } else {
            toast.error(response?.data?.message);
            setEditLoading(false);

            return;
          }
        } else if (!isFileRemoved && editselectedFile) {
          // Handle file update when a new file is selected without removing the current file
          const response = currentRow?.banner?.upload_id
            ? await useEditUpload(editselectedFile, currentRow?.banner?.upload_id)
            : await useUpload(editselectedFile);

          if (response?.status === 200 && response?.data?.data) {
            updatedEventForm = {
              ...updatedEventForm,
              banner: {
                upload_id: response?.data?.data?.[0]?.upload_id,
                file_url: response?.data?.data?.[0]?.file_url
              }
            };
          } else {
            toast.error(response?.data?.message);
            setEditLoading(false);

            return;
          }
        } else {
          // If no file change keep the current banner values
          updatedEventForm = {
            ...updatedEventForm,
            banner: {
              upload_id: currentRow?.banner?.upload_id,
              file_url: currentRow?.banner?.file_url
            }
          };
        }

        const res = await httprequest.patch(`${ALL_EVENTS}/${currentRow?.id}`, updatedEventForm);

        if (res.status === 200) {
          setEditLoading(false);
          toast.success(res?.data?.message);
          setEditEventForm({
            title: "",
            venue: "",
            access: [],
            event_type: "",
            description: "",
            location: "",
            date: "",
            time: "",
            banner: {}
          });
          getAllEvents();
          handleCloseEditEventModal();
          setIsFileRemoved(false);
        } else {
          setEditLoading(false);
          toast.error("Failed to update the event.");
        }
      } catch (err) {
        setEditLoading(false);
        toast.error(err?.response?.data?.message);
      }
    } else {
      showEditEventValidator(true);
    }
  };

  // Delete Event
  const handleDeleteEvent = async () => {
    setIsEventDeleting(true);
    try {
      const res = await httprequest.delete(`${ALL_EVENTS}/${eventId?.id}`);
      if (res.status === 200) {
        toast.success(res?.data?.message);
        getAllEvents();
        handleCloseDeleteModal();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setIsEventDeleting(false);
    }
  };

  useEffect(() => {
    getAllEvents();
    getAllRoles();
  }, []);

  const filteredEvents = allevents.filter((e) => {
    const matchesSearch = e.title?.toLowerCase().includes(searchQuery.toLowerCase()) || e?.description?.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesTab =
      (value === "") ||
      (value === "upcoming" && e.status === "upcoming") ||
      (value === "completed" && e.status === "completed");

    return matchesSearch && matchesTab;
  });

  const totalPages = Math.ceil(filteredEvents.length / itemsPerPage);

  const paginatedEvents = filteredEvents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleEventSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const { title, venue, event_type, description, location, date, time } = eventForm;

  const RenderEvent = () => (
    <Grid container spacing={2}>
      {paginatedEvents?.map((event) => {
        const eventImage = event?.banner?.file_url || EventBanner;

        return (
          <Grid item lg={4} md={6} sm={6} xs={12} key={event.id}>
            <SchoolEventCard
              eventImage={eventImage}
              eventName={event?.title}
              eventDesc={event?.description}
              attendees={`${event?.attendees} Attendees`}
              link={event.id}
              viewattendees={`/events/${event.id}`}
              status={event.status}
              viewClick={() => navigateEventDetail(event.id)}
              editClick={() => handleEditEventModal(event)}
              deleteClick={() => handleDeleteModal(event)}
            />
          </Grid>
        );
      })}
    </Grid>
  );

  const NoEventFound = () => {
    return (
      <NotFounditem img={nosearch}
        title="No Event Found"
        subtitle="The Events that matches this query cannot be found"
      />
    );
  };
  const subtitleText = userRole?.role === "school_admin"
    ? "Schedule events and send reminders to your employees to keep them in the loop."
    : "View scheduled events and sent reminders.";

  return (
    <>
      <PageTitle
        title="Events"
        subtitle={subtitleText}
        button={
          <div className="flex gap-10" data={profile}>
            {userRole?.role === "school_admin" && (
              <Button
                variant="primaryoutline"
                buttonSize="fluid"
                color="btnfontprimary"
                buttonName="?"
                onClick={handleHelpModal}
              />
            )}
            {userRole?.role === "school_admin" && (
              <Button
                variant="primary"
                buttonSize="small"
                color="btndefault"
                onClick={handleAddEventModal}
                buttonName="Create Events"
              />
            )}
          </div>
        }
      />
      {/* Create Event Modal */}
      <div className="page-content">
        <TabContext value={value}>
          {filteredEvents?.length > 0 && (
            <div className="tablist-container padding-left-16">
              <Tabs indicatorColor="none" className="overide-tablist" onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab label="All Events" value="" />
                <Tab label="Upcoming Events" value="upcoming" />
                <Tab label="Completed Events" value="completed" />
              </Tabs>
            </div>
          )}

          <>
            {filteredEvents?.length > 0 && (
              <div className="padding-left-16">
                <CardSearch placeholder="Search your events" onChange={handleEventSearch} />
              </div>
            )}
            <TabPanel value="" className="tabpanel-overide">
              {isEventsFetching ? (
                <PageLoader />
              ) : paginatedEvents?.length < 1 ? <NoEventFound /> : <RenderEvent />}
            </TabPanel>
            <TabPanel value="upcoming" className="tabpanel-overide">
              {isEventsFetching ? (
                <PageLoader />
              ) : paginatedEvents?.length < 1 ? <NoEventFound /> : <RenderEvent />}
            </TabPanel>
            <TabPanel value="completed" className="tabpanel-overide">
              {isEventsFetching ? (
                <PageLoader />
              ) : paginatedEvents?.length < 1 ? <NoEventFound /> : <RenderEvent />}
            </TabPanel>
          </>
        </TabContext>
        <ToastContainer />
        {filteredEvents?.length > 0 && (
          <div className="pagination-container">
            <Pagination count={Math.ceil(totalPages)} page={currentPage} onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
          </div>
        )}
      </div>
      <Modal
        title="Create Event"
        subtitle={
          <div>Create an event and send invitations to your work colleagues to keep them in the loop.
          </div>}
        modalContent={
          <>
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Title"
                  required
                  margin="normal"
                  placeHolder="Name of Event"
                  inputType="text"
                  inputName="title"
                  onChange={handleEventChange}
                  value={title}
                />
                {eventvalidator.message("title", title, "required")}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Event Description"
                  margin="normal"
                  placeHolder="Event Description"
                  inputType="text"
                  inputName="description"
                  onChange={handleEventChange}
                  value={description}
                />
                {eventvalidator.message("description", description, "required")}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="multiple-select">
                  <p>Send Invite to</p>
                  <Select isMulti name="access"
                    closeMenuOnSelect={true} options={multipleAccess} onChange={handleMultipleAccessChange}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup label="Event Type" children={
                  <select onChange={handleEventChange} name="event_type" value={event_type}>
                    <option value="">---Select ---</option>
                    {VenueType?.map((data) => {
                      return (
                        <option value={data.key} key={data?.key}>{data?.text}</option>
                      );
                    })}
                  </select>}
                />
                {eventvalidator.message("event type", event_type, "required")}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Venue"
                  margin="normal"
                  inputType="text"
                  inputName="venue"
                  value={venue}
                  placeHolder="Event Venue"
                  onChange={handleEventChange}
                />
                {eventvalidator.message("venue", venue, "required")}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Location"
                  required
                  margin="normal"
                  inputType="text"
                  inputName="location"
                  value={location}
                  placeHolder="Location"
                  onChange={handleEventChange}
                />
                {eventvalidator.message("location", location, "required")}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Event Date"
                  margin="normal"
                  inputType="date"
                  inputName="date"
                  inputMin={new Date().toISOString().split("T")[0]}
                  value={date}
                  onChange={handleEventChange}
                />
                {eventvalidator.message("date", date, "required")}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Event Time"
                  margin="normal"
                  inputType="time"
                  inputName="time"
                  value={time}
                  onChange={handleEventChange}
                />
                {eventvalidator.message("time", time, "required")}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <label><strong>Upload Image</strong><span className="input-required">*</span></label>
                <div className={classes.pickercontainer}>
                  <input type="file" className={classes.picker} onChange={handleFileChange} name="file" accept="image/jpeg,image/jpe,image/png,application/pdf" />

                  <div className={classes.uploadflex}>
                    <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                    {selectedFile?.name ? (
                      <div>
                        <h2 className={classes.uploadtitle}>{useTruncate(selectedFile?.name, 10)}</h2>
                      </div>
                    ) :
                      <div>
                        <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                        <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.
                        </h6>
                      </div>}
                  </div>
                  <div className={classes.rightpicker}>
                    {selectedFile?.name ? (
                      <div className={classes.uploadbutton} onClick={handleRemoveFile}><Icon icon="zondicons:minus-solid" /> Remove File</div>
                    ) : null}
                  </div>
                </div>
                {/* {eventvalidator.message("file", selectedFile, "required")} */}
              </Grid>
            </Grid>
          </>
        }
        modalFooter={
          <>
            <div className="flex gap-10 flex-wrap-items">
              <Button variant="primaryoutline" buttonName="Cancel" buttonSize="full" color="btnfontprimary" />
              <Button variant="primary" buttonName="Create Event" buttonSize="full" color="btndefault" onClick={handleCreateEvent} isLoading={isCreating} />
            </div>
          </>
        }
        isopen={addEventModal}
        closeModal={handleCloseAddEventModal}
      />

      {/* EDIT EVENT MODal */}
      <Modal
        title="Edit Event"
        subtitle={
          <div>Edit an event and send invitations to your work colleagues to keep them in the loop.
          </div>}
        modalContent={
          <>
            <Grid container spacing={2} className="input-padding">
              {editeventForm?.banner?.file_url && (
                <div className={classes.deleteBanner} style={{ backgroundImage: `url(${editeventForm?.banner?.file_url})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                  <div className={classes.deleteButton} onClick={handleEditRemoveFile}>
                    <Icon icon="zondicons:minus-solid" /> Remove Image
                  </div>
                </div>
              )}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Title"
                  margin="normal"
                  required
                  placeHolder="Name of Event"
                  inputType="text"
                  inputName="title"
                  onChange={handleEditChange}
                  defaultValue={editeventForm?.title}
                />
                {/* {eventvalidator.message("title", title, "required")} */}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Event Description"
                  margin="normal"
                  placeHolder="Event Description"
                  inputType="text"
                  inputName="description"
                  onChange={handleEditChange}
                  defaultValue={editeventForm?.description}
                />
                {/* {eventvalidator.message("title", title, "required")} */}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="multiple-select">
                  <p>Edit Invite to</p>
                  <Select isMulti name="access"
                    closeMenuOnSelect={true} options={multipleAccess}
                    onChange={handleEditMultipleAccessChange}
                    defaultValue={editeventForm?.access?.map(item => ({ label: item?.name, value: item?.id }))}
                    getOptionLabel={(option) => option?.label}
                    getOptionValue={(option) => option?.value}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup label="Event Type" children={
                  <select onChange={handleEditChange} name="event_type" defaultValue={editeventForm?.event_type}>
                    <option value="">---Select ---</option>
                    {VenueType?.map((data) => {
                      return (
                        <option value={data.key} key={data?.key}>{data?.text}</option>
                      );
                    })}
                  </select>}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Venue"
                  margin="normal"
                  inputType="text"
                  inputName="venue"
                  defaultValue={editeventForm?.venue}
                  placeHolder="Event Venue"
                  onChange={handleEditChange}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Location"
                  margin="normal"
                  inputType="text"
                  inputName="location"
                  defaultValue={editeventForm?.location}
                  placeHolder="Location"
                  onChange={handleEditChange}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Event Date"
                  margin="normal"
                  inputType="date"
                  inputName="date"
                  inputMin={new Date().toISOString().split("T")[0]}
                  defaultValue={editeventForm?.date?.substring(0, 10)}
                  // value={editeventForm?.date}
                  onChange={handleEditChange}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Event Time"
                  margin="normal"
                  inputType="time"
                  inputName="time"
                  defaultValue={editeventForm?.time}
                  onChange={handleEditChange}
                />
              </Grid>
              {!editeventForm?.banner?.file_url ? (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <label><strong>Upload Image</strong><span className="input-required">*</span></label>
                  <div className={classes.pickercontainer}>
                    <input
                      type="file"
                      className={classes.picker}
                      onChange={handleEditFileChange}
                      name="file"
                      accept="image/jpeg,image/jpe,image/png"
                    />
                    <div className={classes.uploadflex}>
                      <div>
                        <Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} />
                      </div>

                      {editselectedFile ? (
                      // Display the new selected file name
                        <div>
                          <h2 className={classes.uploadtitle}>{editselectedFile.name}</h2>
                          <h6 className={classes.uploadsubtitle}>Selected file for upload</h6>
                        </div>
                      ) : currentRow?.banner?.file_url && !isFileRemoved ? (
                      // Display the existing file if no new file is selected and the file is not removed
                        <div>
                          <h2 className={classes.uploadtitle}>{currentRow.banner.file_url.split("/").pop()}</h2>
                          <h6 className={classes.uploadsubtitle}>SVG, PNG, JPG, GIF | 10MB max.</h6>
                        </div>
                      ) : (
                      // Default "Tap to Upload" message if no file exists
                        <div>
                          <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                          <h6 className={classes.uploadsubtitle}>SVG, PNG, JPG, GIF | 10MB max.</h6>
                        </div>
                      )}
                    </div>

                    <div className={classes.rightpicker}>
                      {(editselectedFile || currentRow?.banner?.file_url) && !isFileRemoved ? (
                        <div className={classes.uploadbutton} onClick={handleEditRemoveFile}>
                          <Icon icon="zondicons:minus-solid" /> Remove File
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </>
        }
        modalFooter={
          <>
            <div className="flex gap-10">
              <Button variant="primary" buttonName="Edit Event" buttonSize="full" color="btndefault" onClick={handleEditEvent} isLoading={editLoading} />
            </div>
          </>
        }
        isopen={editEventModal}
        closeModal={handleCloseEditEventModal}
      />
      {/* Delete Modal */}
      <Modal
        title="Delete Event"
        subtitle={
          <div>Are you sure you want to remove <span className="modal-name-highlight">{eventId?.title}</span>. from one of your Events? Removing it will permanently clear this Event.`
          </div>}
        modalContent={
          <>

          </>
        }
        modalFooter={
          <>
            <div className="flex gap-10 flex-wrap-items">
              <Button
                variant="dangeroutline"
                buttonSize="full"
                color="btndanger"
                buttonName="Cancel"
                onClick={handleCloseDeleteModal}
              />
              <Button variant="danger" buttonName="Remove Event" buttonSize="full" color="btndefault" onClick={handleDeleteEvent} isLoading={isDeleting} />
            </div>
          </>
        }
        isopen={deleteModal}
        closeModal={handleCloseDeleteModal}
      />
      {/* Need Help Modal */}
      <Modal
        title="Need Help?"
        subtitle="Watch the Video Below on Events"
        modalContent={
          <>
            <ReactPlayer
              url="https://youtu.be/7ve_B4duM2g"
              playing={true}
              controls={true}
              width="100%"
            />
          </>
        }
        modalFooter={
          <>
            <Button
              variant="primary"
              buttonSize="full"
              color="btndefault"
              onClick={handleCloseVideoModal}
              buttonName="Close Video"
            />
          </>}
        isopen={helpModal}
        closeModal={handleCloseVideoModal}
      />
    </>
  );
};

export default AdminEvents;
