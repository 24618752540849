import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import useValidator from "../../hooks/useValidator";
import InputGroup from "../../components/FormComponent/InputGroup";

// logo
import logo from "../../assets/images/logo.svg";
import loginBG from "../../assets/images/forget.png";

const FORGOT_PWD_ENDPOINT = "/api/v1/request-token";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [forgotValidator, showValidationMessage] = useValidator();
  const navigate = useNavigate();
  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (forgotValidator.allValid()) {
      setIsLoading(true);
      axios.post(`${process.env.REACT_APP_API_URL}${FORGOT_PWD_ENDPOINT}`, { email }).then(() => {
        setIsLoading(false);
        toast.success("Reset Password Sent, Please check your mail");
        setTimeout(() => navigate("/auth/login"), 1000);
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.message);
      });
    } else {
      showValidationMessage(true);
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-container">
        <div className="auth-flex">
          <div className="auth-bg-container">
            <img src={loginBG} className="auth-bg-img" alt="login BG" />
          </div>
          <div className="auth-form-container">
            <div className="auth-logo-container">
              <img src={logo} alt="logo" className="auth-logo" />
            </div>
            <div className="auth-form">
              <React.Fragment>
                <div className="auth-heading black900">Forgot Password</div>
                <div className="auth-subheading black900">
                Put in your preferred email and password to sign up now on
                Shelf21 for team management.
                </div>
                <div className="input-padding">
                  <InputGroup
                    label="Email Address"
                    value={email}
                    name="email"
                    onChange={handleChange}
                    margin="normal"
                    type="email"
                  />
                  {forgotValidator.message("email", email, "required")}
                </div>
                <div className="auth-form-buttons">
                  <Button variant="primary" color="btndefault" buttonSize="full"
                    buttonName="Continue" isLoading={isLoading} onClick={handleForgotPassword}
                  />
                </div>
                <ToastContainer />
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
