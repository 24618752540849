/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import { TableSearch } from "../../components/Table/TableActions";
import Badge from "../../components/Badge";
import Modal from "../../components/Modal";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { httprequest } from "../../data/api";
import useValidator from "../../hooks/useValidator";
import { ToastContainer, toast } from "react-toastify";
import ButtonCount from "../../components/ButtonCount";
import InputGroup from "../../components/FormComponent/InputGroup";

const CONTRACTOR_ENDPOINT = "/api/v1/contractor";
const PROCUREMENT_ENDPOINT = "/api/v1/procurement";

const Procurement = () => {
  const navigate = useNavigate();
  const [searchResult, setSearchResult] = useState([]);
  const [addInfoModal, setAddInfoModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [isContracting, setIsContracting] = useState(false);
  const [contractorData, setContractorData] = useState([]);
  const [procurementData, setProcurementData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [contractorValidator, showContractorValidator] = useValidator();
  const [contractorForm, setContractorForm] = useState({
    name: "",
    email: "",
    services: "",
    address: "",
    phone: ""
  });

  const handleAddInfoModal = () => {
    setAddInfoModal(!addInfoModal);
  };

  const handleCloseAddInfoModal = () => {
    setAddInfoModal(false);
  };

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleCloseDeleteModal = () => {
    setCurrentRow({});
    setDeleteModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContractorForm({ ...contractorForm, [name]: value });
  };

  const getAllContractors = () => {
    httprequest
      .get(CONTRACTOR_ENDPOINT)
      .then((res) => {
        setContractorData(res?.data?.data);
      })
      .catch(() => {});
  };

  const handleCreateContractor = () => {
    if (contractorValidator.allValid()) {
      setIsContracting(true);
      httprequest.post(CONTRACTOR_ENDPOINT, contractorForm).then((res) => {
        setIsContracting(false);
        toast.success(res?.data?.message);
        handleCloseAddInfoModal();
        // getAllSession();
        setContractorForm({ ...contractorForm, name: "", email: "", services: "", address: "", phone: "" });
        // handleAddSessionModal();
      }).catch((err) => {
        setIsContracting(false);
        toast.error(err?.response?.data);
      });
    } else {
      showContractorValidator(true);
    }
  };

  const getAllProcurements = () => {
    setIsLoading(true);
    httprequest.get(PROCUREMENT_ENDPOINT).then((res) => {
      setIsLoading(false);
      setProcurementData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsLoading(false);
    });
  };

  const handleDeleteProcurement = () => {
    setIsDeleteLoading(true);
    httprequest.delete(`${PROCUREMENT_ENDPOINT}/${currentRow?.id}`).then((res) => {
      setIsDeleteLoading(false);
      toast.success(res?.data?.message);
      handleCloseDeleteModal();
      getAllProcurements();
    }).catch((err) => {
      setIsDeleteLoading(false);
      toast.error(err?.response?.data);
    });
  };

  // On Page Load, set search result data to be the initial data
  useEffect(() => {
    getAllContractors();
    getAllProcurements();
  }, []);

  const columns = [
    {
      name: "Order Id",
      selector: row => row?.order_id
    },
    {
      name: "Title",
      selector: row => row?.order_title
    },
    {
      name: "Contractor Name",
      selector: row => row?.contractor
    },
    {
      name: "Amount",
      selector: row => row?.currency + " " + row?.amount
    },
    {
      name: "Supply Date",
      selector: row => row?.supply_date
    },
    {
      name: "Status",
      center: true,
      cell: (row) => (<Badge status={row?.status} />)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button
            onClick={() => {
              navigate("/manage/procurements/ProcurementMenu");
            }}
            className="table-action-icon"
          >
            <Icon icon="fluent:eye-16-regular" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];
    // --| Filter Procurement table using name, email and class
  const handleSearchProcurement = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = procurementData?.filter((data) => valArray?.every(
      (word) => data?.contractor
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                      data?.order_title
                        ?.toLowerCase()
                        .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  const { name, email, services, address, phone } = contractorForm;

  return (
    <Layout children={
      <>
        <PageTitle
          title="Procurement"
          subtitle="See all the list of procurements made"
          button={
            <div className="flex gap-10">
              <Button onClick={() => {
                navigate("/manage/procurements/new");
              }} variant="primaryoutline" buttonSize="fluid" color="btnfontprimary" buttonName="Add Purchase Order"
              />
              <Button onClick={handleAddInfoModal} variant="primary" buttonSize="fluid" color="btndefault" buttonName="Add Contractor" />
            </div>
          }
        />
        <div className="page-content">
          <div className="button-count-container">
            <ButtonCount
              title="View Contractors"
              func={() => navigate("/manage/procurements/contractors")}
              count={contractorData?.length}
            />
          </div>
          <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
            searchResult?.length > 0 && (
              <TableSearch placeholder="Search here..." searchTableFunc={handleSearchProcurement} isLoading={isLoading} />
            )}
          />
          {/* Delete Modal */}
          <Modal
            title="Delete Procurement"
            subtitle={
              <div>Are you sure you want to remove <span className="modal-name-highlight">{currentRow?.order_title}</span>. from procurements? Removing this will permanently remove the record`
              </div>}
            modalContent={
              <>

              </>
            }
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="dangeroutline"
                    buttonSize="full"
                    color="btndanger"
                    buttonName="Cancel"
                  />
                  <Button variant="danger" buttonName="Delete Procurement" buttonSize="full" color="btndefault" onClick={handleDeleteProcurement} isLoading={isDeleteLoading} />
                </div>
              </>
            }
            isopen={deleteModal}
            closeModal={handleCloseDeleteModal}
          />

          {/* Add Contractor Modal */}
          <Modal
            title="Add a Contractor"
            subtitle={
              <div>Add a verified contractor to your organisation
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Name of Contractor"
                      margin="normal"
                      inputType="text"
                      onChange={handleChange}
                      inputName="name"
                      value={name}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Email Address"
                      margin="normal"
                      onChange={handleChange}
                      inputType="email"
                      inputName="email"
                      value={email}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Service OfferING"
                      margin="normal"
                      inputType="text"
                      inputName="services"
                      onChange={handleChange}
                      value={services}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Address"
                      margin="normal"
                      inputType="text"
                      inputName="address"
                      onChange={handleChange}
                      value={address}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Phone Number"
                      margin="normal"
                      inputType="tel"
                      onChange={handleChange}
                      value={phone}
                      inputName="phone"
                    />
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <Button variant="primary" buttonName="Add Contractor" buttonSize="full" color="btndefault" onClick={handleCreateContractor} isLoading={isContracting} />
              </>
            }
            isopen={addInfoModal}
            closeModal={handleCloseAddInfoModal}
          />
        </div>
        <ToastContainer />
      </>
    }
    />
  );
};

export default Procurement;
