// import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import Tab from "@mui/material/Tab";
import { useEffect, useState } from "react";
import TabPanel from "@mui/lab/TabPanel";
import Tabs from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import { httprequest } from "../../../data/api";
import { toast, ToastContainer } from "react-toastify";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
import { useNavigate, Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import Badge from "../../../components/Badge";

// GET ALL ASSIGNMENT BY CLASS
const GET_ALL_ASSIGNMENT = "/api/v1/assignment/teacher";
const ASSIGNMENT_BY_CLASS_ENDPOINT = "/api/v1/assignment/teacher";
const CLASSES_ENDPOINT = "/api/v1/teacher/classes";

const EmployeeAssignment = () => {
  const [value, setValue] = useState("1");
  const [assignmentsdata, setAssignmentsData] = useState([]);
  const [assignmentbyclass, setAssignmentByClass] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [classesData, setClassData] = useState([]);
  const [assignmentresultbyclass, setAssignmentResultByClass] = useState([]);
  const [isPageFetching, setIsPageFetching] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");
  const [currentRow, setCurrentRow] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [isDeleting, setisDeleting] = useState(false);
  const navigate = useNavigate();

  const getAllAssignmentByClass = () => {
    httprequest.get(ASSIGNMENT_BY_CLASS_ENDPOINT).then((res) => {
      setAssignmentsData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const getAllClasses = () => {
    httprequest.get(CLASSES_ENDPOINT).then((res) => {
      const physicalClasses = res?.data?.data?.filter((data) => data?.class_type === "physical");
      setClassData(physicalClasses);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilterStatus(value);

    // Filter assignments based on selected status
    const filteredByStatus = value === "published"
      ? assignmentsdata?.filter((data) => data?.status === "published")
      : value === "draft"
        ? assignmentsdata?.filter((data) => data?.status === "draft")
        : value === "withdrawn"
          ? assignmentsdata?.filter((data) => data?.status === "withdrawn")
          : value === "scheduled"
            ? assignmentsdata?.filter((data) => data?.status === "scheduled")
            : assignmentsdata;
    setSearchResult(filteredByStatus);
  };

  const handleFilterClassChange = (e) => {
    const { value } = e.target;
    setFilterStatus(value);

    // Filter assignments based on selected status in a class
    const filteredByStatus = value === "published"
      ? assignmentresultbyclass?.filter((data) => data?.status === "published")
      : value === "draft"
        ? assignmentresultbyclass?.filter((data) => data?.status === "draft")
        : value === "withdrawn"
          ? assignmentresultbyclass?.filter((data) => data?.status === "withdrawn")
          : value === "scheduled"
            ? assignmentresultbyclass?.filter((data) => data?.status === "scheduled")
            : assignmentresultbyclass;

    setAssignmentByClass(filteredByStatus);
  };

  // --| THIS IS TO GET AN ASSIGNMENT BY CLASS
  const getAssignmentByClass = () => {
    setIsPageFetching(true);
    httprequest.get(`${ASSIGNMENT_BY_CLASS_ENDPOINT}/${value}`).then((res) => {
      setIsPageFetching(false);
      setAssignmentByClass(res?.data?.data);
      setAssignmentResultByClass(res?.data?.data);
    }).catch((err) => {
      setIsPageFetching(false);
      toast.error(err?.response?.data);
    });
  };

  const getAllAssignment = () => {
    setIsPageFetching(true);
    httprequest.get(GET_ALL_ASSIGNMENT).then((res) => {
      setIsPageFetching(false);
      setAssignmentsData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsPageFetching(false);
      toast.error(err?.response?.data);
    });
  };

  // Delete Assignment
  const handleDeleteNotice = () => {
    setisDeleting(true);
    httprequest
      .delete(`${GET_ALL_ASSIGNMENT}/${currentRow?.id}`)
      .then((res) => {
        setisDeleting(false);
        toast.success(res?.data?.message);
        handleCloseDeleteModal();
        getAllAssignment();
      })
      .catch((err) => {
        setisDeleting(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const columns = [
    {
      name: "Subject",
      selector: row => row?.subject?.name
    },
    {
      name: "Class",
      selector: row => row?.class?.name
    },
    {
      name: "Created By",
      selector: row => row?.created_by?.name
    },
    {
      name: "Date Created",
      selector: row => row?.created_at?.substring(0, 10)
    },
    {
      name: "Submission Date",
      selector: row => row?.submission_date?.substring(0, 10)
    },
    {
      name: "Status",
      center: true,
      cell: (row) => <Badge status={row.status} />
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/assignments/${row?.id}`}>
            <button className="table-action-icon">
              <Icon icon="fluent:eye-16-regular" />
            </button>
          </Link>
          <button className="table-action-icon"
            onClick={() => navigate(`/assignment/edit-assignments/${row?.id}`)}
          >
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }

  ];

  useEffect(() => {
    getAllAssignmentByClass();
    getAllAssignment();
    getAllClasses();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // --| Filter Assignment table using name, email and class
  const handleSearchAssignmentByClass = (e) => {
    const { value } = e.target;
    const valArray = value.split(" ");

    const filteredByStatus = filterStatus
      ? assignmentresultbyclass.filter((data) => data.status.toLowerCase() === filterStatus.toLowerCase())
      : assignmentresultbyclass;

    // Filter by partial match in subject, class, and creator's name
    const result = filteredByStatus?.filter((data) => valArray?.every(
      (word) => data?.subject?.name?.toLowerCase().includes(word.toLowerCase()) ||
                data?.class?.name?.toLowerCase().includes(word.toLowerCase()) ||
                data?.created_by?.name?.toLowerCase().includes(word.toLowerCase())
    ));

    setAssignmentByClass(result);
  };

  // --| Filter Assignment table using name, email and class
  const handleSearchAssignment = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    const filteredByStatus = filterStatus
      ? assignmentsdata.filter((data) => data.publish_date.toLowerCase() === filterStatus.toLowerCase())
      : assignmentsdata;
    // --| Filter data by partial match onchange in the search input box
    const result = filteredByStatus?.filter((data) => valArray?.every(
      (word) => data?.subject?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
            data?.class?.name?.toLowerCase()
              .includes(word.toLowerCase()) ||
            data?.created_by?.name?.toLowerCase()
              .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  useEffect(() => {
    if (value !== "1") {
      getAssignmentByClass();
    }
  }, [value]);

  return (
    <>
      <PageTitle
        title="See All Assignments"
        subtitle="See all my assignment"
        button={
          <div className="flex gap-10">
            <div className="flex gap-10">
              <Button onClick={() => {
                navigate("/assignment/create-assignment");
              }} variant="primaryoutline" buttonSize="fluid" color="btnfontprimary" buttonName="Create Assignment"
              />
            </div>
          </div>
        }
      />
      <div className="page-content">
        <TabContext value={value}>
          <div className="tablist-container">
            {searchResult.length > 0 &&
                     <Tabs
                       indicatorColor="none"
                       className="overide-tablist"
                       onChange={handleChange}
                       aria-label=""
                     >
                       <Tab label="All Assignments" value="1" />
                       {classesData?.map((data, i) => {
                         return (
                           <Tab key={i} value={data?.id} label={data?.class_name} />
                         );
                       })}
                     </Tabs>}
          </div>
          <TabPanel value="1" className="tabpanel-overide">
            <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
              searchResult.length > 0 && (
                <div className="action_div">
                  <TableSearch placeholder="Search here..." searchTableFunc={handleSearchAssignment} />
                  <div className="text-center gap-10 cursor-pointer">
                    {isPageFetching ? <Icon icon="svg-spinners:270-ring-with-bg" width="20" height="20" style={{ color: "#858585" }} />
                      : <Icon onClick={getAllAssignment} icon="hugeicons:reload" width="20" height="20" style={{ color: "#858585" }} />}
                    <div>
                      <SelectGroup
                        children={
                          <select name="type" onChange={handleFilterChange}>
                            <option value="">-- Sort By--</option>
                            <option value="published">Published</option>
                            <option value="draft">Draft</option>
                            <option value="withdrawn">Withdrawn</option>
                            <option value="scheduled">Scheduled</option>
                          </select>
                        }
                      />
                    </div>
                  </div>
                </div>)
            }
            />
          </TabPanel>
          {classesData?.map((data, i) => {
            return (
              <TabPanel className="tabpanel-overide" key={i} value={data?.id}>
                <Table
                  data={assignmentbyclass}
                  columns={columns}
                  subHeader={true}
                  pagination
                  subHeaderComponent={
                    assignmentbyclass?.length > 0 && (
                      <div className="action_div">
                        <TableSearch placeholder="Search here..." searchTableFunc={handleSearchAssignmentByClass} />
                        <div className="text-center gap-10 cursor-pointer">
                          {isPageFetching ? <Icon icon="svg-spinners:270-ring-with-bg" width="20" height="20" style={{ color: "#858585" }} />
                            : <Icon onClick={getAssignmentByClass} icon="hugeicons:reload" width="20" height="20" style={{ color: "#858585" }} />}
                          <div>
                            <SelectGroup
                              children={
                                <select name="type" onChange={handleFilterClassChange}>
                                  <option value="">-- Sort By--</option>
                                  <option value="published">Published</option>
                                  <option value="draft">Draft</option>
                                  <option value="withdrawn">Withdrawn</option>
                                  <option value="scheduled">Scheduled</option>
                                </select>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )
                  }
                />
              </TabPanel>
            );
          })}

        </TabContext>
      </div>
      {/* Delete Modal */}
      <Modal
        title="Delete Assignment"
        subtitle={
          <div>
            Are you sure you want to remove{" "}
            <span className="modal-name-highlight">
              {currentRow?.title}
            </span>
            from one of your Assignment? Removing it will permanently
            clear this Assignment.
          </div>
        }
        modalContent={<></>}
        modalFooter={
          <>
            <div className="flex gap-10">
              <Button
                variant="dangeroutline"
                buttonSize="full"
                color="btndanger"
                buttonName="Cancel"
                onClick={handleCloseDeleteModal}
              />
              <Button
                variant="danger"
                buttonName="Delete Assignment"
                buttonSize="full"
                color="btndefault"
                onClick={handleDeleteNotice}
                isLoading={isDeleting}
              />
            </div>
          </>
        }
        isopen={deleteModal}
        closeModal={handleCloseDeleteModal}
      />
      <ToastContainer />
    </>
  );
};

export default EmployeeAssignment;
