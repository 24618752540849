import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Grid
} from "@mui/material";
import { Icon } from "@iconify/react";
import useStyles from "../styles";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { httprequest } from "../../../data/api";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
// import { CardSearch } from "../../../components/CardSearch";
import NotFounditem from "../../error/NotFound";
import { useParams } from "react-router-dom";
import PageLoader from "../../../components/PageLoader";
import useValidator from "../../../hooks/useValidator";
import nosearch from "../../../assets/images/no-search.svg";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import InputGroup from "../../../components/FormComponent/InputGroup";

const TERM_ENDPOINT = "/api/v1/school-plan/term";
const GET_SESSIONS = "/api/v1/school-plan/session";
const GET_CLASS_GRADES = "/api/v1/broad-sheet/";
const CLASSES_ENDPOINT = "/api/v1/class";
const GET_SUBJECT_GRADES = "/api/v1/grade/";
const CLASS_SUBJECT = "/api/v1/class-subject/";
const GET_All_STUDENTS = "/api/v1/class/students/";
const GRADING_SYSTEM = "/api/v1/grading-system";

const ClassResults = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [value, setValue] = useState("");
  const [classesData, setClassData] = useState([]);
  const [activeSession, setActiveSession] = useState([]);
  const [selectedSessionTerms, setSelectedSessionTerms] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedSession, setSelectedSession] = useState("");
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [calculatedGrade, setCalculatedGrade] = useState(null);
  const [testArr, setTestArr] = useState([""]);
  const [allSessions, setAllSessions] = useState([]);
  const [aselectedTerm, setASelectedTerm] = useState("");
  const [resulttModal, setResultModal] = useState(false);
  const [classGrades, setClassGrades] = useState([]);
  const [allFetching, setAllFetching] = useState(false);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [allClassSubject, setAllClassSubject] = useState([]);
  const [resultCreating, setResultCreating] = useState((false));
  const [allStudent, setAllStudents] = useState([]);
  const [allGradingSystem, setAllGradingSystem] = useState([]);
  const [studentResultValidator, showStudentResultValidator] = useValidator();
  const [classResultForm, setClassResultForm] = useState({
    subject_id: "",
    class_id: id,
    student_id: "",
    CA_scores: { CA1: "" },
    term_id: "",
    grade: "",
    total: "",
    exam_score: "",
    CA_total: "",
    grading_system_id: ""
  });

  const handleAddSubjectModal = () => {
    setResultModal(!resulttModal);
  };

  // Get All Terms
  const getAllTerms = (Session_ID) => {
    httprequest
      .get(TERM_ENDPOINT)
      .then((res) => {
        setSelectedSessionTerms(res?.data?.data.filter((selected_term) => selected_term?.session_id === Session_ID));
      });
    // .catch(() => {});
  };

  // Handle close Result Modal
  const handleCloseAddResultModal = () => {
    setResultModal(!resulttModal);
    setTestArr([""]);
    setClassResultForm({ ...classResultForm, CA_scores: { CA1: "" }, CA_total: "", grade: "", grading_system_id: "", total: "", exam_score: "", student_id: "" });
  };

  // Handle Get all Grading System
  const handleGetAllGradingSystem = () => {
    httprequest.get(GRADING_SYSTEM).then((res) => {
      setAllGradingSystem(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data?.message);
    });
  };

  // Handle Select Grade system
  const handleGradeChange = (e) => {
    const selectedId = e.target.value;
    const selectedGradingSystem = allGradingSystem.find(
      (gradeSystem) => gradeSystem?.id === selectedId
    );

    setSelectedGrade(selectedGradingSystem);

    if (selectedGradingSystem && classResultForm?.total !== null) {
      const gradeDetails = selectedGradingSystem.grading.find(
        (grade) => classResultForm.total >= grade.min && classResultForm?.total <= grade.max
      );

      if (gradeDetails) {
        setCalculatedGrade(gradeDetails?.grade);
        // Update the grade in the subjectForm state
        setClassResultForm((prevForm) => ({
          ...prevForm,
          grade: gradeDetails?.grade,
          grading_system_id: selectedId
        }));
      } else {
        toast.error("Total does not fall within any grade range.");
        // Optionally clear the grade if no range matches
        setClassResultForm((prevForm) => ({
          ...prevForm,
          grade: "",
          grading_system_id: ""
        }));
      }
    }
  };

  // CA SCores Input
  const handleTestScoreChange = (e, index) => {
    const updatedScores = [...testArr];
    updatedScores[index] = e.target.value;

    // This Map array to object format for CA_scores
    const CA_scores = updatedScores.reduce((acc, score, idx) => {
      acc[`CA${idx + 1}`] = Number(score) || 0;

      return acc;
    }, {});

    // This Calculate the total and average for CA scores
    const totalScores = updatedScores.reduce((sum, score) => sum + (Number(score) || 0), 0);
    const numberOfCAs = updatedScores.length;
    const CA_total = numberOfCAs > 0 ? totalScores / numberOfCAs : 0;

    // Calculate the overall total score (CA_total + exam_score)
    const examScore = parseFloat(classResultForm?.exam_score) || 0;
    const totalScore = CA_total + examScore;

    // Update state
    setTestArr(updatedScores);
    setClassResultForm({
      ...classResultForm,
      CA_scores,
      CA_total: CA_total.toFixed(2),
      total: totalScore.toFixed(2)
    });
  };

  // Handle Add score Input
  const AddAdditionalTestScore = () => {
    const newSubject = "";
    setTestArr([...testArr, newSubject]);
  };

  // Handle Get all classes
  const getAllClasses = () => {
    // setIsPageFetching(true);
    httprequest
      .get(CLASSES_ENDPOINT)
      .then((res) => {
        const physicalClasses = res?.data?.data?.filter(
          (data) => data?.class_type === "physical"
        );
        // setIsPageFetching(false);
        setClassData(physicalClasses);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // Handle Get All Session
  const getAllSessions = async () => {
    try {
      const res = await httprequest.get(GET_SESSIONS);
      setAllSessions(res?.data?.data);
      setActiveSession(res?.data?.data?.find((active_session) => active_session?.current_session === true));
    } catch (err) {
      toast.error(err?.response?.message);
    }
  };

  const NoResultFound = () => {
    return (
      <NotFounditem
        img={nosearch}
        title="No Result Found"
        subtitle="The Name that matches this query cannot be found"
      />
    );
  };
    // Find Active Term in an Current Session
  const active_term_session = activeSession?.terms?.find((found_active_term) => found_active_term?.is_current === true);

  // Handle Session Change
  const handleSessionChange = (e) => {
    const Session_ID = e.target.value;
    // setASelectedSession(e.target.value);
    getAllTerms(Session_ID);
  };

  // handle Get All Class Result
  const handleGetAllClassResult = async (classID, termId) => {
    setAllFetching(true);
    try {
      const res = await httprequest.get(`${GET_CLASS_GRADES}${classID}?term_id=${termId}`);
      setAllFetching(false);
      setClassGrades(res?.data?.data);
      setFilteredStudents(res?.data?.data);
    } catch (err) {
      setAllFetching(false);
      toast.error(err?.response?.message);
    }
  };

  // Create Add Student Result
  const handlePostStudentResult = () => {
    if (studentResultValidator.allValid()) {
      setResultCreating(true);
      httprequest.post(GET_SUBJECT_GRADES, { ...classResultForm, term_id: aselectedTerm }).then((res) => {
        setResultCreating(false);
        toast.success(res?.data?.message);
        handleGetAllClassResult(id, aselectedTerm);
        handleCloseAddResultModal();
      }).catch((err) => {
        setResultCreating(false);
        toast.error(err?.response?.data);
      });
    } else {
      showStudentResultValidator(true);
    }
  };

  // Handle Delete Test Score
  const handleDeleteTestScore = (index) => {
    // Filter out the selected test score
    const newTestArr = testArr.filter((_item, ind) => ind !== index);

    // Refill the CA_scores object
    const updatedCA_scores = newTestArr.reduce((acc, score, idx) => {
      acc[`CA${idx + 1}`] = score || 0;

      return acc;
    }, {});

    // Calculate the total and average score
    const totalScore = newTestArr.reduce((sum, score) => sum + Number(score || 0), 0);
    const averageScore = newTestArr.length > 0 ? totalScore / newTestArr.length : 0;

    // Update state
    setTestArr(newTestArr);
    setClassResultForm({
      ...classResultForm,
      CA_scores: updatedCA_scores,
      CA_total: averageScore.toFixed(2)
    });
  };

  // Handles the change event when a new term is selected
  const handleTermChange = (event) => {
    const selectedTermId = event.target.value;

    setASelectedTerm(selectedTermId);
    handleGetAllClassResult(id, selectedTermId);
  };

  // Get All Subject for a Class
  const getAllSubjectForClass = async () => {
    try {
      const res = await httprequest.get(`${CLASS_SUBJECT}${id}`);
      setAllClassSubject(res?.data?.data);
    } catch (err) {
      toast.error(err?.response?.data);
    }
  };

  // Handle All Input Change
  const handleTextChange = (e) => {
    const { name, value } = e.target;

    // Update the form state
    const updatedForm = { ...classResultForm, [name]: value };

    // CA_total and exam_score to calculate the total score
    const caTotal = parseFloat(updatedForm.CA_total) || 0;
    const examScore = parseFloat(updatedForm.exam_score) || 0;

    // Update the total score
    updatedForm.total = caTotal + examScore;

    if (updatedForm?.total > 100) {
      toast.error("Total score cannot exceed 100!");
    }

    // Calculate the grade based on the updated total score
    let grade = "";
    if (selectedGrade?.grading) {
      const gradeDetails = selectedGrade.grading.find(
        (grade) => updatedForm.total >= grade.min && updatedForm.total <= grade.max
      );
      grade = gradeDetails ? gradeDetails.grade : "";

      // Update the calculated grade
      setCalculatedGrade(grade);
    }

    // Update the grade in the form
    updatedForm.grade = grade;

    // Set the updated form state
    setClassResultForm(updatedForm);
  };

  // Handle get student in a class
  const handleGetAllStudents = () => {
    httprequest.get(`${GET_All_STUDENTS}${id}`).then((res) => {
      setAllStudents(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // On mount renders all classes
  useEffect(() => {
    getAllClasses();
    getAllSessions();
    getAllTerms();
    handleGetAllStudents();
    getAllSubjectForClass();
    handleGetAllGradingSystem();
  }, []);

  useEffect(() => {
    if (active_term_session?.id) {
      setASelectedTerm(active_term_session.id);
    }
  }, [active_term_session]);

  useEffect(() => {
    if (activeSession?.id) {
      setSelectedSession(activeSession?.id);
      getAllTerms(activeSession?.id);
    }
  }, [activeSession]);

  const handleClassChange = (event, newValue) => {
    setValue(newValue);
    getAllSubjectForClass(value);
  };

  useEffect(() => {
    if (id?.length > 0 && active_term_session?.id) {
      const params = {
        term_id: active_term_session.id
      };

      handleGetAllClassResult(id, params?.term_id);
    }
  }, [id, active_term_session]);

  const students = Array.from(
    new Map(
      (classGrades?.sheet || [])
        .flatMap((entry) => entry?.students.map((student) => [
          student.id,
          { id: student?.id, name: `${student?.last_name} ${student?.first_name}` }
        ])
        )
    ).values()
  );

  const subjects = classGrades?.sheet?.map((entry) => entry.subject) || [];
  // Extract unique subjects
  // const subjects = [
  //   ...new Set(
  //     (classGrades?.sheet || []).flatMap(subjectData => subjectData?.subject?.name || [])
  //   )
  // ];

  return (
    <>
      <div className={classes.headinfo}>
        {/* <div className={`${classes.searchcont} margin-bottom-10`}>
          <CardSearch
            placeholder="Search Student Result"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div> */}
        <div>
          <div className={classes.resultbtn}>
            <Button
              onClick={handleAddSubjectModal}
              variant="primary"
              buttonSize="fluid"
              color="btndefault"
              buttonName="Add Student Result"
            />
          </div>
          <div className="flex gap-10 align-items-end margin-top-5">
            <div>
              {activeSession?.id && (
                <SelectGroup
                  children={
                    <select
                      defaultValue={activeSession?.id}
                      onChange={handleSessionChange}
                      name="session"
                    >
                      <option value="">---Select---</option>
                      {allSessions?.map((sesh) => {
                        return (
                          <option key={sesh?.id} value={sesh?.id}>
                            {sesh?.session}
                          </option>
                        );
                      })}
                    </select>
                  }
                />
              )}
            </div>
            <div>
              {active_term_session?.id && (
                <SelectGroup
                  children={
                    <select onChange={handleTermChange} name="term" value={aselectedTerm}>
                      <option value="">---Select---</option>
                      {selectedSessionTerms?.map((data) => {
                        return (
                          <option key={data?.id} value={data?.id}>
                            {data?.term}
                          </option>
                        );
                      })}
                    </select>
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {allFetching ? (
        <PageLoader />
      ) : classGrades?.length === 0 ? (
        <NoResultFound />
      ) : (
        <TableContainer component={Paper} style={{ overflowX: "auto" }} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={`${classes.headerCell} ${classes.firstColumn}`} rowSpan={2}>Student Name</TableCell>
                {subjects.map((subject) => (
                  <>
                    <TableCell className={classes.headerCell} colSpan={6} align="center">
                      {subject?.name}
                    </TableCell>
                  </>
                ))}
              </TableRow>
              <TableRow>
                {subjects.map((_, subjectIndex) => (
                  <>
                    <TableCell className={classes.subHeaderCell} align="center">CA1</TableCell>
                    <TableCell className={classes.subHeaderCell} align="center">CA2</TableCell>
                    <TableCell className={classes.subHeaderCell} align="center">Total CA</TableCell>
                    <TableCell className={classes.subHeaderCell} align="center">Exam</TableCell>
                    <TableCell className={classes.subHeaderCell} align="center">Total</TableCell>
                    <TableCell className={`${classes.subHeaderCell} ${
                      subjectIndex === subjects.length - 1 ? "" : classes.separator
                    }`} align="center"
                    >Grade
                    </TableCell>
                  </>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((student, index) => (
                <TableRow key={student.id} className={index % 2 === 0 ? "" : classes.bodyRowOdd}>
                  <TableCell className={`${classes.secondRowCol}`}>{student?.name}</TableCell>
                  {subjects.map((subject, subjectIndex) => {
                    const studentScores = filteredStudents?.sheet
                      .find((entry) => entry.subject.id === subject.id)
                      ?.students.find((s) => s.id === student.id);

                    return studentScores ? (
                      <>
                        <TableCell className={classes.bodyCell} align="center">{studentScores?.scores?.CA_scores?.CA1 || "-"}</TableCell>
                        <TableCell className={classes.bodyCell} align="center">{studentScores?.scores?.CA_scores?.CA2 || "-"}</TableCell>
                        <TableCell className={classes.bodyCell} align="center">{studentScores?.scores?.CA_total || "-"}</TableCell>
                        <TableCell className={classes.bodyCell} align="center">{studentScores?.scores?.exam_score || "-"}</TableCell>
                        <TableCell className={classes.bodyCell} align="center">{studentScores?.scores?.total || "-"}</TableCell>
                        <TableCell className={`${classes.bodyCell} ${
                          subjectIndex === subjects.length - 1 ? "" : classes.separator
                        }`} align="center"
                        >{studentScores.scores.grade || "-"}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell className={classes.bodyCell} align="center">-</TableCell>
                        <TableCell className={classes.bodyCell} align="center">-</TableCell>
                        <TableCell className={classes.bodyCell} align="center">-</TableCell>
                        <TableCell className={classes.bodyCell} align="center">-</TableCell>
                        <TableCell className={classes.bodyCell} align="center">-</TableCell>
                        <TableCell className={`${classes.bodyCell} ${
                          subjectIndex === subjects.length - 1 ? "" : classes.separator
                        }`} align="center"
                        >-
                        </TableCell>
                      </>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* Add Results */}
          <Modal
            title="Add Result"
            subtitle={
              <div>
                Manually add your student’s result.
              </div>
            }
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup
                      label="Class"
                      required
                      children={
                        <select disabled value={id} name="class_id" onChange={handleClassChange}>
                          <option value="">--- Select ---</option>
                          {classesData?.map((class_id) => {
                            return (
                              <option key={class_id.id} value={class_id?.id}>
                                {class_id?.class_name}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                    {studentResultValidator.message("Class", classResultForm?.class_id, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup
                      label="Subject"
                      required
                      children={
                        <select name="subject_id" onChange={handleTextChange}>
                          <option value="">--- Select ---</option>
                          {allClassSubject?.map((subject) => {
                            return (
                              <option key={subject.subject_id} value={subject?.subject_id}>
                                {subject?.subject_name}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                    {studentResultValidator.message("Subject", classResultForm?.subject_id, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SelectGroup
                      label="Students"
                      required
                      children={
                        <select name="student_id" onChange={handleTextChange}>
                          <option value="">--- Select ---</option>
                          {allStudent?.map((student) => {
                            return (
                              <option key={student.student_id} value={student?.student_id}>
                                {`${student?.student?.last_name} ${student?.student.first_name}`}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                    {studentResultValidator.message("Student", classResultForm?.student_id, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={2}>
                      {testArr?.map((item, index) => (
                        <Grid key={index} item lg={6} md={6} sm={12} xs={12}>
                          <InputGroup
                            label={`CA ${index + 1}`}
                            margin="normal"
                            required
                            placeHolder="Enter score"
                            inputType="text"
                            onChange={(e) => handleTestScoreChange(e, index)}
                            value={item}
                          />
                          {studentResultValidator.message(
                            `CA ${index + 1}`,
                            classResultForm?.CA_scores?.[`CA${index + 1}`],
                            "required"
                          )}
                          {testArr?.length > 1 && (
                            <p
                              className="delete-input"
                              onClick={() => handleDeleteTestScore(index)}
                            >
                              <Icon icon="zondicons:minus-solid" />
                              Remove this CA
                            </p>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {testArr?.length < 2 && (
                      <div
                        className={`${classes.mail} flex align-items-center`}
                        onClick={AddAdditionalTestScore}
                      >
                        <Icon icon="octicon:feed-plus-16" width="16" height="16" />
                        <p>Add another CA</p>
                      </div>
                    )}
                  </Grid>
                  <Grid item lg={4} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Total CA Score"
                      margin="normal"
                      inputType="text"
                      required
                      inputName="CA_total"
                      value={classResultForm?.CA_total}
                      disabled
                    />
                    {studentResultValidator.message("CA Total", classResultForm?.CA_total, "required")}
                  </Grid>
                  <Grid item lg={4} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Exam Score"
                      margin="normal"
                      inputType="text"
                      required
                      inputName="exam_score"
                      onChange={handleTextChange}
                    />
                    {studentResultValidator.message("Exam Score", classResultForm?.exam_score, "required")}
                  </Grid>
                  <Grid item lg={4} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Total Score"
                      margin="normal"
                      inputType="text"
                      required
                      inputName="total"
                      value={classResultForm?.total || 0}
                      disabled
                    />
                    {studentResultValidator.message("Total Score", classResultForm?.total, "required")}
                  </Grid>
                  <Grid item lg={9} md={12} sm={12} xs={12}>
                    <SelectGroup
                      label="Grade System"
                      required
                      children={
                        <select name="grading_system_id" onChange={handleGradeChange}>
                          <option value="">--- Select ---</option>
                          {allGradingSystem?.map((grade_system) => {
                            return (
                              <option key={grade_system?.id} value={grade_system?.id}>
                                {grade_system?.title}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  </Grid>
                  <Grid item lg={3} md={12} sm={12} xs={12}>
                    <SelectGroup
                      label="Grade"
                      required
                      children={
                        <select disabled value={calculatedGrade || ""} name="grade" onChange={handleTextChange}>
                          <option value="">--- Select ---</option>
                          {selectedGrade?.grading?.map((grade_type) => {
                            return (
                              <option key={grade_type?.grade} value={grade_type?.grade}>
                                {grade_type?.grade}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                    {studentResultValidator.message("Grade", classResultForm?.grade, "required")}
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button
                    variant="primary"
                    buttonSize="full"
                    color="btndefault"
                    buttonName="Add Result"
                    onClick={handlePostStudentResult}
                    isLoading={resultCreating}
                  />
                </div>
              </>
            }
            isopen={resulttModal}
            closeModal={handleCloseAddResultModal}
          />
        </TableContainer>
      )}
    </>

  );
};

export default ClassResults;
