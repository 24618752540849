import Logo from "../../assets/images/logo.svg";
// import styles from "./Enrollment.module.css";
import Button from "../../components/Button";
import Child from "../../assets/images/child.svg";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../components/Modal";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import InputGroup from "../../components/FormComponent/InputGroup";
import useStyles from "./styles";
import { toast, ToastContainer } from "react-toastify";
import PageLoader from "../../components/PageLoader";
import { httprequest } from "../../data/api";
import SelectGroup from "../../components/FormComponent/SelectGroup";
import useValidator from "../../hooks/useValidator";
import { useUpload } from "../../hooks/useUpload";
import { useTruncate } from "../../hooks/useTruncate";

const SCHOOL_ENROLMENT = "/api/v1/student-enrolment";
const SCHOOL_APPLICATION = "/api/v1/enrolment/apply";
const MAKE_PAYMENT = "/api/v1/enrolment/apply";

const StudentEnrolment = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();

  const [isEnrolmentFetching, setIsEnrolmentFetching] = useState(false);
  const [applicationValidator, showApplicationValidator] = useValidator();
  const [isCreating, setIsCreating] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [enrolmentData, setEnrolmentData] = useState({});
  const [nonActiveEnrolment, setNonActiveEnrolment] = useState("");
  const [applicationmodal, setApplicationModal] = useState(false);
  const [displayPic, setDisplayPic] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [applicationForm, setApplicationForm] = useState({
    school_id: id,
    first_name: "",
    last_name: "",
    class_id: "",
    email: "",
    phone: "",
    address: "",
    guardian_info: {
      first_name: "",
      last_name: "",
      address: "",
      email: "",
      phone: ""
    }
  });
  const [uploadedFile, setUploadedFile] = useState({});
  const [customFields, setCustomFields] = useState([]);
  const [uploads, setUploads] = useState([]);

  // Function to handle input change in custom fields
  const handleFieldChange = (index, newValue) => {
    setCustomFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index].value = newValue; // Update the value at the correct index

      return updatedFields;
    });
  };

  // Effect to set initial custom fields based on enrolmentData
  useEffect(() => {
    if (enrolmentData) {
      const filteredCustomFields = Object.keys(enrolmentData)
        .filter(key => key.startsWith("custom") && enrolmentData[key] && enrolmentData[key].type)
        .map(key => ({
          key,
          label: enrolmentData[key]?.label || "",
          type: enrolmentData[key]?.type || "",
          value: enrolmentData[key]?.value || "" // Ensure value is initialized
        }));

      setCustomFields(filteredCustomFields);
    }
  }, [enrolmentData]);

  const handleApplChange = (e) => {
    const { name, value } = e.target;
    setApplicationForm({ ...applicationForm, [name]: value });
  };

  const guardianObjChange = (e) => {
    const { name, value } = e.target;
    setApplicationForm(prevState => ({
      ...prevState,
      guardian_info: {
        ...prevState.guardian_info,
        [name]: value
      }
    }));
  };

  const handleProfileChange = (event) => {
    setDisplayPic(event.target.files[0]);
    const binaryData = [];
    binaryData.push(event.target.files[0]);

    // window.URL.createObjectURL(new Blob(binaryData))
    setProfilePic(URL.createObjectURL(new Blob(binaryData)));
    // setProfilePic(URL.createObjectURL(event.target.files[0]));
  };

  const handleApplicationModal = () => {
    setApplicationModal(!applicationmodal);
  };

  const getOpenEnrolment = () => {
    setIsEnrolmentFetching(true);
    httprequest
      .get(`${SCHOOL_ENROLMENT}/${id}`)
      .then((res) => {
        const enrolmentData = res?.data?.data; // Accessing the response data
        setEnrolmentData(enrolmentData);
        setIsEnrolmentFetching(false);
        setEnrolmentData(res?.data?.data);
        setApplicationForm({ ...applicationForm, enrolment_id: res?.data?.data?.id });
      })
      .catch((err) => {
        setIsEnrolmentFetching(false);
        if (err?.response?.status === "404") {
          setNonActiveEnrolment("not-open");
        }
        toast.error(err?.response?.data);
      });
  };

  const payid = enrolmentData?.payment?.id;
  const schoolid = enrolmentData?.school_id;
  const enrolmentid = enrolmentData?.enrolment_id;

  const handleCreateApplication = async () => {
    if (applicationValidator.allValid()) {
      setIsCreating(true);
      try {
        // 2. Handle Multiple File Uploads
        const uploadedFiles = [];
        for (let i = 0; i < uploads.length; i++) {
          const upload = uploads[i];
          const uploadResponse = await useUpload(upload.file);
          if (uploadResponse?.status === 200 && uploadResponse.data.data) {
            const uploadedFileData = uploadResponse.data?.data?.[0];
            uploadedFiles.push({
              upload_id: uploadedFileData.upload_id,
              file_url: uploadedFileData.file_url,
              title: upload.title
            });
          } else {
            toast.error("Unable to upload ");
            setIsCreating(false);

            return;
          }
        }

        if (uploadedFile && Object.keys(uploadedFile).length > 0) {
          uploads.push(uploadedFiles);
        }

        const customFieldsObject = customFields.reduce((obj, field) => {
          obj[field.key] = {
            label: field.label,
            value: field.value,
            type: field.type
          };

          return obj;
        }, {});
        let display_pic = {};
        if (displayPic) {
          const displayResponse = await useUpload(displayPic);
          if (displayResponse?.status === 200 && displayResponse.data.data) {
            display_pic = displayResponse.data?.data?.[0];
          } else {
            toast.error("Unable to upload profile picture");
            setIsCreating(false);
            // eslint-disable-next-line
            return;
          }
        }

        if (enrolmentData?.require_payment) {
          const paymentUrl = MAKE_PAYMENT;
          const paymentData = {
            school_integration_id: payid,
            enrolment_id: enrolmentid,
            school_id: schoolid,
            ...applicationForm,
            uploads,
            display_pic,
            custom_fields: customFieldsObject
          };
          const paymentResponse = await httprequest.post(paymentUrl, paymentData);
          if (paymentResponse?.status === 200 && paymentResponse?.data?.data?.redirect_url) {
            window.location.href = paymentResponse.data.data.redirect_url;

            return;
          } else {
            toast.error("Payment failed or no redirect URL provided");
            setIsCreating(false);

            return;
          }
        }
        // const payload = {
        //   school_integration_id: payid,
        //   enrolment_id: enrolmentid,
        //   school_id: schoolid,
        //   ...applicationForm,
        //   uploads,
        //   display_pic,
        //   custom_fields: customFieldsObject
        // };

        // Log the entire payload before sending the request
        // console.log("Final Payload being sent to the API:", payload);

        // Submit form data including uploads and display picture
        await httprequest
          .post(SCHOOL_APPLICATION, { ...applicationForm,
            uploads,
            display_pic,
            custom_fields: customFieldsObject
          })
          .then((res) => {
            setIsCreating(false);
            toast.success(res?.data?.message);
            setApplicationForm({
              school_id: id,
              first_name: "",
              last_name: "",
              class_id: "",
              email: "",
              phone: "",
              address: "",
              guardian_info: {
                first_name: "",
                last_name: "",
                address: "",
                email: "",
                phone: ""
              }
            });
            setCustomFields([]);
            setDisplayPic("");
            setProfilePic(null);
            setUploadedFile({});
            setTimeout(() => {
              navigate("/auth/applicant");
            }, 1000);
          })
          .catch((err) => {
            setIsCreating(false);
            toast.error(err?.response?.data?.message);
          });
      } catch (err) {
        toast.error(err?.response?.data?.message);
      } finally {
        setIsCreating(false);
      }
    } else {
      showApplicationValidator(true);
    }
  };

  useEffect(() => {
    getOpenEnrolment();
  }, []);

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const updatedUploads = [...uploads];
      updatedUploads[index].file = file;
      setUploads(updatedUploads);
    }
  };
  const handleAddFileSection = () => {
    setUploads([...uploads, { file: null, title: "" }]);
  };

  const handleRemoveFileSection = (index) => {
    setUploads(uploads.filter((_, idx) => idx !== index));
  };

  const handleUploadTitleChange = (event, index) => {
    const { value } = event.target;
    const updatedUploads = [...uploads];
    updatedUploads[index].title = value;
    setUploads(updatedUploads);
  };

  return !isEnrolmentFetching ? (
    <>
      <div className={classes.maincontainer}>
        <div className={classes.container}>
          {nonActiveEnrolment === "not-open" ? (
            <div className={classes.hero}>
              <h2 className={classes.heading}>
                School Enrolment and Pre-examination.
              </h2>
              <h6 className={`${classes.subheading} text-danger`}>
                Sorry, Enrolments is not open for this time, Please contact the
                admin of the school for more information
              </h6>
              <div className={"flex gap-10 justify-content-center"}>
                <Button
                  onClick={() => navigate("/enrollment/upload-file")}
                  variant="primary"
                  buttonSize="fluid"
                  color="btndefault"
                  buttonName="Enroll for examination"
                />
                <Button
                  variant="primaryoutline"
                  buttonSize="fluid"
                  color="btnfontprimary"
                  buttonName="School Brochure"
                />
              </div>
            </div>
          ) : (
            <>
              <div className={classes.navbar}>
                <div className="flex">
                  <img
                    src={enrolmentData?.branding?.brand_logo?.file_url || Logo}
                    alt=""
                    className={classes.logo}
                  />
                </div>
                {/* <ul className="flex gap-24">
            <li>Home</li>
            <li>Services</li>
            <li>About Us</li>
            <li>Contact</li>
          </ul> */}
                <div className="flex">
                  <Button
                    variant="primary"
                    buttonSize="fluid"
                    color="btndefault"
                    buttonName="Enroll For Examination"
                  />
                </div>
              </div>
              <div className={classes.hero}>
                <h2 className={classes.heading}>
                  School Enrolment and Pre-examination.
                </h2>
                <h6 className={classes.subheading}>
                  Be a part of the students that get to be enrolment in the
                  prestigious
                  <span className={classes.herospan}>
                    {" "}
                    {enrolmentData?.branding?.description}{" "}
                  </span>{" "}
                  . Registration is ongoing and will last until{" "}
                  {enrolmentData?.closing_date?.substring(0, 10)}
                </h6>
                <div className={"flex gap-10 justify-content-center"}>
                  <Button
                    onClick={() => navigate("/enrollment/upload-file")}
                    variant="primary"
                    buttonSize="fluid"
                    color="btndefault"
                    buttonName="Enroll for examination"
                  />
                  <Button
                    variant="primaryoutline"
                    buttonSize="fluid"
                    color="btnfontprimary"
                    buttonName="School Brochure"
                  />
                </div>
              </div>
              <div className={classes.imagecontent}>
                <img
                  className={classes.bannerimg}
                  src={enrolmentData?.banner?.file_url || Child}
                  alt=""
                />
              </div>
              <div className="page-content margin-top">
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <p
                      className={`${classes.sectionheader} text-center margin-top`}
                    >
                      SCHOOL APPLICATION FORM
                    </p>
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <div className={classes.piclabel}>
                      Applicant Display Picture{" "}
                      <span className="input-required">*</span>
                    </div>
                    <div style={{ textAlign: "left" }}>
                      {applicationValidator.message(
                        "display_pic",
                        displayPic,
                        "required"
                      )}
                    </div>
                    <div className={classes.profilecontainer}>
                      <div className={classes.imageviewer}>
                        <img src={profilePic} alt="" />
                        <div className={classes.pickercontainer}>
                          <input
                            type="file"
                            name="profile_picture"
                            accept=".jpg, .jpeg, .png"
                            className={classes.profilepicker}
                            onChange={handleProfileChange}
                          />
                          <CameraAltIcon className={classes.camera} />
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <h4 className={classes.subsection}>Personal Information</h4>
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <InputGroup
                      inputType="text"
                      label="First Name"
                      inputName="first_name"
                      onChange={handleApplChange}
                      required
                    />
                    {applicationValidator.message(
                      "first_name",
                      applicationForm.first_name,
                      "required"
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <InputGroup
                      inputType="text"
                      label="Last Name"
                      inputName="last_name"
                      onChange={handleApplChange}
                      required
                    />
                    {applicationValidator.message(
                      "last_name",
                      applicationForm.last_name,
                      "required"
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <SelectGroup
                      label="Class Applying To"
                      required
                      children={
                        <select name="class_id" onChange={handleApplChange}>
                          <option value="">--- Select ---</option>
                          {enrolmentData?.classes?.map((clss) => {
                            return (
                              <option
                                key={clss?.class_id}
                                value={clss?.class_id}
                              >
                                {clss?.class_name}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                    {applicationValidator.message(
                      "Class",
                      applicationForm.class_id,
                      "required"
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <InputGroup
                      inputType="email"
                      label="Email address"
                      inputName="email"
                      onChange={handleApplChange}
                      required
                    />
                    {applicationValidator.message(
                      "Email Address",
                      applicationForm.email,
                      "required"
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <InputGroup
                      inputType="tel"
                      label="Phone Number"
                      inputName="phone"
                      onChange={handleApplChange}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <InputGroup
                      inputType="address"
                      label="Home Address"
                      inputName="address"
                      onChange={handleApplChange}
                      required
                    />
                    {applicationValidator.message(
                      "Home Address",
                      applicationForm.address,
                      "required"
                    )}
                  </Grid>
                </Grid>
                <h4 className={`${classes.subsection} margin-top`}>
                  Guardian Information
                </h4>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <InputGroup
                      inputType="text"
                      label="First Name"
                      inputName="first_name"
                      onChange={guardianObjChange}
                    />
                    {applicationValidator.message(
                      "Guardian First Name",
                      applicationForm?.guardian_info?.first_name,
                      "required"
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <InputGroup
                      inputType="text"
                      label="Last Name"
                      inputName="last_name"
                      onChange={guardianObjChange}
                    />
                    {applicationValidator.message(
                      "Guardian Last Name",
                      applicationForm?.guardian_info?.last_name,
                      "required"
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <InputGroup
                      inputType="address"
                      label="Address"
                      inputName="address"
                      onChange={guardianObjChange}
                    />
                    {applicationValidator.message(
                      "Guardian Address",
                      applicationForm?.guardian_info?.address,
                      "required"
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <InputGroup
                      inputType="text"
                      label="Email"
                      inputName="email"
                      onChange={guardianObjChange}
                    />
                    {applicationValidator.message(
                      "Guardian Email",
                      applicationForm?.guardian_info?.email,
                      "required"
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <InputGroup
                      inputType="text"
                      label="Phone"
                      inputName="phone"
                      onChange={guardianObjChange}
                    />
                  </Grid>

                  {customFields.map((field, index) => (
                    <Grid key={field.key} item lg={4} md={4} sm={6} xs={12}>
                      <InputGroup
                        label={field.label}
                        inputType={field.type}
                        name={field.label}
                        onChange={(e) => handleFieldChange(index, e.target.value)}
                        value={field.value}
                      />
                    </Grid>

                  ))}
                </Grid>
                <Grid container>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div>
                      <div className="text-align-left flex">
                        <Icon
                          icon="material-symbols:upload"
                          width="20"
                          height="20"
                          style={{ color: "#111111" }}
                        />
                        <div>
                          <h4>Uploads</h4>
                        </div>
                      </div>

                      {uploads.map((upload, index) => (
                        <div key={index}>
                          {upload.upload_id ? (
                            <div className={classes.pickercontain}>
                              <div className={classes.uploadflexs}>
                                <h2 className={classes.uploadtitles}>{upload.title}</h2>
                              </div>
                            </div>
                          ) : (
                            <div className={classes.newUpload}>
                              <InputGroup
                                label={"Enter Title"}
                                margin="normal"
                                inputType="text"
                                inputName={`upload_title_${index}`}
                                value={upload.title}
                                onChange={(e) => handleUploadTitleChange(e, index)}
                              />

                              {/* File Input */}
                              <div className={classes.pickercontain}>
                                <input
                                  type="file"
                                  className={classes.pickers}
                                  onChange={(e) => handleFileChange(e, index)}
                                  name={`file_${index}`}
                                  accept="image/jpeg,image/jpe,image/png,application/pdf"
                                />
                                <div className={classes.uploadflexs}>
                                  <div>
                                    <Icon
                                      icon="icon-park-outline:upload-two"
                                      className={classes.uploadicons}
                                    />
                                  </div>
                                  {upload?.file?.name ? (
                                    <div>
                                      <h2 className={classes.uploadtitles}>{useTruncate(upload?.file?.name, 15)}</h2>
                                    </div>
                                  ) : (
                                    <div>
                                      <h2 className={classes.uploadtitles}>Tap to Upload</h2>
                                      <h6 className={classes.uploadsubtitles}>
                        PDF, PNG, JPG | 10MB max.
                                      </h6>
                                    </div>
                                  )}
                                </div>
                                <div className={classes.rightpicker}>
                                  {upload.file?.name && (
                                    <div
                                      className={classes.uploadbuttons}
                                      onClick={() => handleRemoveFileSection(index)}
                                    >
                                      <Icon icon="zondicons:minus-solid" /> Remove File
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="margin-top-16">
                                <Button
                                  variant="dangeroutline"
                                  buttonSize="small"
                                  color="btndanger"
                                  buttonName="Remove"
                                  onClick={() => handleRemoveFileSection(index)}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      <div className="margin-top-16">
                        <Button
                          variant="primaryoutline"
                          buttonSize="small"
                          color="btnfontprimary"
                          buttonName="Add Upload"
                          onClick={handleAddFileSection}
                        />
                      </div>

                    </div>
                  </Grid>
                </Grid>

                <div className={classes.btncontainer}>
                  <Button
                    onClick={handleCreateApplication}
                    variant="primary"
                    buttonSize="full"
                    color="btndefault"
                    buttonName="Apply"
                    isLoading={isCreating}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        title="Application Successful"
        modalContent={
          <>
            <h6 className={classes.modaltitle}>
              You have successfully submited your application form, should you
              be eligible for enrolment, a mail will be sent to you.
            </h6>
          </>
        }
        modalFooter={
          <>
            <div className={classes.modalFooterBtn}>
              <Button
                variant="primary"
                buttonSize="full"
                color="btndefault"
                buttonName="Continue"
              />
            </div>
          </>
        }
        isopen={applicationmodal}
        closeModal={handleApplicationModal}
      />
      <ToastContainer />
    </>
  ) : (
    <PageLoader />
  );
};

export default StudentEnrolment;
