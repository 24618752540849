import useStyles from "../styles";
import Layout from "../../../components/Layout";
import BreadCrumb from "../../../components/BreadCrumb";
import { httprequest } from "../../../data/api";
import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Calendar from "../../../components/Calendar";
import Modal from "../../../components/Modal";
import { Grid } from "@mui/material";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import InputGroup from "../../../components/FormComponent/InputGroup";
import NotFounditem from "../../error/NotFound";
import nosearch from "../../../assets/images/no-search.svg";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PageLoader from "../../../components/PageLoader";
import useWindowSize from "../../../hooks/useWindow";
import useValidator from "../../../hooks/useValidator";

import Button from "../../../components/Button";

const SCHEDULE_EXAM = "/api/v1/exam-schedule";
const SCHEDULE_EXAM_DETAILS = "/api/v1/exam-schedule/details";
const ALL_EXAM = "/api/v1/school-exams";
const CLASSES_ENDPOINT = "/api/v1/class";
const CLASS_SUBJECT = "/api/v1/class-subject/";
const TEACHERS_ENDPOINT = "/api/v1/teachers";

const ExamCalendar = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [value, setValue] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [aSchoolSchedule, setASchoolSchedule] = useState([]);
  const [isPageFetching, setIsPageFetching] = useState(false);
  const [classesData, setClassData] = useState([]);
  const [currentEvent, setCurrentEvent] = useState({});
  const [scheduleExamModal, setSchduleExamModal] = useState(false);
  const [allExams, setAllExams] = useState([]);
  const [allClassSubject, setAllClassSubject] = useState([]);
  const [selectedExamDuration, setSelectedExamDuration] = useState("0");
  const [editSelectedExamDuration, setEditSelectedExamDuration] = useState("0");
  const [examScheduleValidator, showExamScheduleValidator] = useValidator();
  const [editExamScheduleValidator, showEditExamScheduleValidator] = useValidator();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [deleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [teacherData, setTeacherData] = useState([]);
  const [selectedClassSubjects, setSelectedClassSubjects] = useState([]);
  const [sheduleExamCalendarForm, setScheduleExamCalendarForm] = useState({
    exam_schedule_id: id,
    exam_date: "",
    end_time: "",
    duration: ""
  });
  const location = useLocation();

  // Extract "type" from the query string
  const queryParams = new URLSearchParams(location.search);
  const detail_type = { type: queryParams.get("type") };

  const [editSheduleExamCalendarForm, setEditScheduleExamCalendarForm] = useState({
    exam_schedule_id: id,
    exam_date: "",
    end_time: "",
    exam_duration: "",
    supervisor: ""
  });

  const width = useWindowSize();
  const mobile = width < 576;

  const renderView = () => {
    if (mobile) {
      return "timeGridDay";
    } else {
      return "timeGridWeek";
    }
  };

  const handleCloseCreateSchdule = () => {
    setSchduleExamModal(false);
    setScheduleExamCalendarForm({
      exam_schedule_id: id,
      exam_date: "",
      end_time: "",
      duration: "",
      supervisor: ""
    });
  };

  const getAllClasses = () => {
    httprequest
      .get(CLASSES_ENDPOINT)
      .then((res) => {
        const physicalClasses = res?.data?.data?.filter(
          (data) => data?.class_type === "physical"
        );
        setClassData(physicalClasses);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // --| THIS IS TO GET AN ASSIGNMENT BY CLASS
  const getScheduleByClass = (class_id) => {
    setIsPageFetching(true);
    httprequest
      .get(`${SCHEDULE_EXAM}/${id}?class_id=${class_id}`)
      .then((res) => {
        setIsPageFetching(false);

        // Filter schedules by `schedule_type`
        const filteredSchedules = res?.data?.data.filter(
          (schedule) => schedule?.schedule_type === detail_type?.type
        );

        setASchoolSchedule(filteredSchedules);
        // setSearchResult(res?.data?.data);
      })
      .catch((err) => {
        setIsPageFetching(false);
        toast.error(err?.response?.data);
      });
  };

  const getAllTeachers = () => {
    httprequest
      .get(TEACHERS_ENDPOINT)
      .then((res) => {
        const formattedData = res?.data?.data.map((teacher) => ({
          user_id: teacher?.user_id,
          teacher_name: `${teacher.first_name} ${teacher.last_name}`
        }));
        setTeacherData(formattedData);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const handleCloseEditModal = () => {
    setEditScheduleExamCalendarForm({
      exam_schedule_id: id,
      exam_date: "",
      end_time: "",
      exam_duration: ""
    }
    );
    setEditSelectedExamDuration("");
    setOpenEditModal(false);
  };

  // handle Create Exam Schedule
  const handleCreateExamScheduleDetails = () => {
    if (examScheduleValidator.allValid()) {
      setIsCreating(true);
      httprequest
        .post(SCHEDULE_EXAM_DETAILS, sheduleExamCalendarForm)
        .then((res) => {
          setIsCreating(false);
          toast.success(res?.data?.message);
          getScheduleByClass(value);
          handleCloseCreateSchdule();
        })
        .catch((err) => {
          setIsCreating(false);
          toast.error(err?.response?.data);
        });
    } else {
      showExamScheduleValidator(true);
    }
  };

  // Get All Exams
  const getAllExams = () => {
    httprequest
      .get(ALL_EXAM)
      .then((res) => {
        setAllExams(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const handleScheduleExamModal = () => {
    setSchduleExamModal(!scheduleExamModal);
  };

  const events = aSchoolSchedule?.map((schedule) => {
    const startDateTime = `${schedule?.exam_date.split("T")[0]}T${schedule?.start_time}`;
    const endDateTime = `${schedule?.exam_date.split("T")[0]}T${schedule?.end_time}`;

    return {
      title: `${" "}${schedule?.subject?.name} | ${schedule?.exam_duration} mins`,
      start: startDateTime,
      end: endDateTime,
      exam_duration: schedule?.exam_duration,
      exam_id: schedule?.exam_id,
      details_id: schedule?.id,
      subject: schedule?.subject,
      classes: schedule?.classes,
      exam_date: schedule?.exam_date,
      schedule_type: schedule?.schedule_type,
      start_time: schedule?.start_time,
      supervisor: schedule?.supervisor,
      end_time: schedule?.end_time
    };
  });

  // Hanle all input or select change
  const handleTitleChange = (e) => {
    const { name, value } = e.target;

    if (name === "exam_id") {
      const selectedExam = allExams.find((exam) => exam?.id === value);

      const startTime = sheduleExamCalendarForm?.start_time;
      if (startTime && selectedExam?.duration) {
        const startTimeDate = new Date(`1970-01-01T${startTime}Z`);
        const endTimeDate = new Date(startTimeDate.getTime() + selectedExam.duration * 60000);
        const formattedEndTime = endTimeDate.toISOString().split("T")[1].slice(0, 8);

        setScheduleExamCalendarForm({
          ...sheduleExamCalendarForm,
          [name]: value,
          duration: selectedExam?.duration || "",
          end_time: formattedEndTime
        });
      } else {
        setScheduleExamCalendarForm({
          ...sheduleExamCalendarForm,
          [name]: value,
          duration: selectedExam?.duration || ""
        });
      }

      // Update selected exam duration if needed elsewhere
      setSelectedExamDuration(selectedExam);
    } else if (name === "start_time") {
      // Calculate end time based on start time and exam duration
      const durationInMinutes = selectedExamDuration?.duration || 0;

      // Format start_time to include seconds
      const startTime = `${value}:00`;

      if (durationInMinutes) {
        // Calculate end time by adding duration to start time
        const startTimeDate = new Date(`1970-01-01T${startTime}Z`);
        const endTimeDate = new Date(startTimeDate.getTime() + durationInMinutes * 60000);
        const formattedEndTime = endTimeDate.toISOString().split("T")[1].slice(0, 8);

        setScheduleExamCalendarForm({
          ...sheduleExamCalendarForm,
          [name]: startTime,
          end_time: formattedEndTime
        });
      } else {
        setScheduleExamCalendarForm({
          ...sheduleExamCalendarForm,
          [name]: startTime
        });
      }
    } else if (name === "end_time") {
      // Thid is to Append :00 to end_time
      const endTime = `${value}:00`;
      const startTime = sheduleExamCalendarForm?.start_time;

      if (startTime) {
        // Calculate duration in minutes when Type is Manual
        const startTimeDate = new Date(`1970-01-01T${startTime}Z`);
        const endTimeDate = new Date(`1970-01-01T${endTime}Z`);

        const durationInMinutes = Math.round(
          (endTimeDate - startTimeDate) / 60000
        );

        setScheduleExamCalendarForm({
          ...sheduleExamCalendarForm,
          [name]: endTime,
          duration: durationInMinutes
        });
      } else {
        setScheduleExamCalendarForm({
          ...sheduleExamCalendarForm,
          [name]: endTime
        });
      }
    } else {
      // For other input changes, update form as usual
      setScheduleExamCalendarForm({
        ...sheduleExamCalendarForm,
        [name]: value
      });
    }
  };

  const handleEditTitleChange = (e) => {
    const { name, value } = e.target;

    if (name === "exam_id") {
      const editSelectedExam = allExams.find((exam) => exam?.id === value);
      const startTime = editSheduleExamCalendarForm?.start_time || "15:00:00";
      const durationInMinutes = editSelectedExam?.duration;

      // Update the end time based on exam duration
      const startTimeDate = new Date(`1970-01-01T${startTime}Z`);
      const endTimeDate = new Date(startTimeDate.getTime() + durationInMinutes * 60000);
      const formattedEndTime = endTimeDate.toISOString().split("T")[1].slice(0, 8);

      setEditScheduleExamCalendarForm({
        ...editSheduleExamCalendarForm,
        [name]: value,
        exam_duration: durationInMinutes,
        end_time: formattedEndTime
      });

      setEditSelectedExamDuration(editSelectedExam);
    } else if (name === "start_time") {
      const startTime = `${value}:00`;
      const endTime = editSheduleExamCalendarForm?.end_time ;

      if (detail_type?.type === "automated") {
        // Calculate new end_time using the exam duration from editSelectedExamDuration
        const durationInMinutes = editSelectedExamDuration?.duration || 0;
        if (durationInMinutes) {
          const startTimeDate = new Date(`1970-01-01T${startTime}Z`);
          const endTimeDate = new Date(startTimeDate.getTime() + durationInMinutes * 60000);
          const formattedEndTime = endTimeDate.toISOString().split("T")[1].slice(0, 8);

          setEditScheduleExamCalendarForm({
            ...editSheduleExamCalendarForm,
            [name]: startTime,
            end_time: formattedEndTime
          });

          return;
        }
      }

      if (endTime) {
        // Calculate the duration in minutes using the existing end_time
        const startTimeDate = new Date(`1970-01-01T${startTime}Z`);
        const endTimeDate = new Date(`1970-01-01T${endTime}Z`);
        const durationInMinutes = Math.round((endTimeDate - startTimeDate) / 60000);

        setEditScheduleExamCalendarForm({
          ...editSheduleExamCalendarForm,
          [name]: startTime,
          exam_duration: durationInMinutes
        });
      } else {
        setEditScheduleExamCalendarForm({
          ...editSheduleExamCalendarForm,
          [name]: startTime
        });
      }
    } else if (name === "end_time") {
      const endTime = `${value}:00`;
      const startTime = editSheduleExamCalendarForm?.start_time;

      if (startTime) {
        // Calculate the duration in minutes using the existing start_time
        const startTimeDate = new Date(`1970-01-01T${startTime}Z`);
        const endTimeDate = new Date(`1970-01-01T${endTime}Z`);
        const durationInMinutes = Math.round((endTimeDate - startTimeDate) / 60000);

        setEditScheduleExamCalendarForm({
          ...editSheduleExamCalendarForm,
          [name]: endTime,
          exam_duration: durationInMinutes
        });
      } else {
        setEditScheduleExamCalendarForm({
          ...editSheduleExamCalendarForm,
          [name]: endTime
        });
      }
    } else {
      setEditScheduleExamCalendarForm({
        ...editSheduleExamCalendarForm,
        [name]: value
      });
    }
  };

  const getAllSubjectForClass = async (class_value) => {
    try {
      const res = await httprequest.get(`${CLASS_SUBJECT}${class_value}`);
      setAllClassSubject(res?.data?.data);
    } catch (err) {
      toast.error(err?.response?.data);
    }
  };

  const handleEventClick = (args) => {
    getAllSubjectForClass(args?.event?.extendedProps?.classes?.map((cls) => cls?.id));
    setOpenEditModal(!openEditModal);
    setCurrentEvent(args?.event);
    setEditScheduleExamCalendarForm({
      class_id: args?.event?.extendedProps?.classes?.map((cls) => cls?.id) || [],
      exam_schedule_id: id,
      exam_id: args?.event?.extendedProps?.exam_id,
      exam_date: args?.event?.extendedProps?.exam_date,
      subject_id: args?.event?.extendedProps?.subject?.id,
      end_time: args?.event?.extendedProps?.end_time,
      exam_duration: args?.event?.extendedProps?.exam_duration,
      start_time: args?.event?.extendedProps?.start_time,
      supervisor: args?.event?.extendedProps?.supervisor?.id,
      schedule_type: args?.event?.extendedProps?.schedule_type
    });
  };

  const handleClassChange = (e) => {
    const { name, value } = e.target;
    setScheduleExamCalendarForm({ ...sheduleExamCalendarForm, [name]: [value] });
    setSelectedClassSubjects(value);
    getAllSubjectForClass(value);
  };

  // Handle Edit Class Change
  const handleEDitClassChange = (e) => {
    const { name, value } = e.target;
    setEditScheduleExamCalendarForm({ ...editSheduleExamCalendarForm, [name]: [value] });
    setSelectedClassSubjects(value);
    getAllSubjectForClass(value);
  };

  useEffect(() => {
    if (selectedClassSubjects) {
      getAllSubjectForClass(selectedClassSubjects);
    }
  }, [selectedClassSubjects]);

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  };

  // Edit Schedule Details
  const handleEditDetails = () => {
    if (editExamScheduleValidator.allValid()) {
      setIsEditing(true);

      // Remove exam_id if detail_type is "manual"
      const payload = { ...editSheduleExamCalendarForm };
      if (payload?.schedule_type === "manual") {
        delete payload?.exam_id;
      }

      httprequest
        .patch(`${SCHEDULE_EXAM_DETAILS}/${currentEvent?.extendedProps?.details_id}`, payload)
        .then((res) => {
          setIsEditing(false);
          toast.success(res?.data?.message);
          handleCloseEditModal();
          getScheduleByClass(value);
        })
        .catch((err) => {
          setIsEditing(false);
          toast.error(err.response?.data?.message);
        });
    } else {
      showEditExamScheduleValidator(true);
    }
  };

  // Delete a Schedule Detials
  const handleDeleteDetails = () => {
    setIsDeleting(true);
    httprequest.delete(`${SCHEDULE_EXAM_DETAILS}/${currentEvent?.extendedProps?.details_id}`).then((res) => {
      setIsDeleting(false);
      handleCloseEditModal();
      toast.success(res?.data?.message);
      getScheduleByClass(value);
    }).catch((err) => {
      setIsDeleting(false);
      toast.error(err.response?.data?.message);
    });
  };

  useEffect(() => {
    if (classesData?.length > 0 && !value) {
      const class_value = classesData[0]?.id;
      setValue(class_value);
      getScheduleByClass(class_value);
      setScheduleExamCalendarForm({ ...sheduleExamCalendarForm, class_id: [class_value] });
      getAllSubjectForClass(class_value);
    }
  }, [classesData, sheduleExamCalendarForm?.class_id]);

  // Handle CLass CHange
  const handleChange = (event, newValue) => {
    setValue(newValue);

    // Update class_id to always be an array with one item
    setScheduleExamCalendarForm({ ...sheduleExamCalendarForm, class_id: [newValue] });

    // Fetch the data based on the selected class
    getScheduleByClass(newValue);
    getAllSubjectForClass(newValue);
  };

  useEffect(() => {
    getAllClasses();
    getAllExams();
    getAllTeachers();
  }, []);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one={"/school-examination/exam-timetable"}
                link_one_name="School Examination Timetable"
                active="View Examination Timetable"
                description="View and Create Exam Timetable for all Classes in your School"
              />
            </div>
            <div className="flex gap-10">
              <Button
                variant="primaryoutline"
                buttonSize="fluid"
                color="btnfontprimary"
                onClick={handleScheduleExamModal}
                buttonName="Add Exam to calender "
              />
            </div>
          </div>
          <div className="page-content">
            <TabContext value={value}>
              <div className="tablist-container">
                <Tabs
                  indicatorColor="none"
                  className="overide-tablist"
                  onChange={handleChange}
                  aria-label=""
                >
                  {classesData?.map((data, i) => {
                    return (
                      <Tab key={i} value={data?.id} label={data?.class_name} />
                    );
                  })}
                </Tabs>
              </div>
              {classesData?.map((data, i) => {
                return (
                  <TabPanel
                    className="tabpanel-overide"
                    key={i}
                    value={data?.id}
                  >
                    {isPageFetching ? (
                      <PageLoader />
                    ) : aSchoolSchedule.length > 0 ? (
                      <Calendar
                        events={events}
                        renderEventContent={renderEventContent}
                        defaultView={renderView()}
                        handleEventClick={handleEventClick}
                      />
                    ) : (
                      <div className={classes.notfound}>
                        <NotFounditem
                          img={nosearch}
                          title="No Exam Period Found"
                          subtitle="No Schedule has been added to the list."
                        />
                      </div>
                    )}
                  </TabPanel>
                );
              })}

            </TabContext>
            <div className={classes.calendar}>
              {/* <Calendar events={events} renderEventContent={renderEventContent} defaultView="dayGridMonth" handleEventClick={handleEventClick} /> */}
            </div>
          </div>
          {/* Schedule Examination */}
          <Modal
            title="Schedule An Examination"
            subtitle={<div>Create an Examination Timetable</div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  {detail_type?.type === "manual" && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <SelectGroup
                        label="Class"
                        required
                        children={
                          <select
                            name="class_id"
                            onChange={handleClassChange}
                          >
                            <option value="">--- Select ---</option>
                            {classesData?.map((label) => {
                              return (
                                <option key={label.key} value={label?.id}>
                                  {label?.class_name}
                                </option>
                              );
                            })}
                          </select>
                        }
                      />
                      {examScheduleValidator.message("Class", sheduleExamCalendarForm?.class_id?.[0], "required")}
                    </Grid>
                  )}
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <SelectGroup
                        label="Select Supervisor"
                        required
                        children={
                          <select
                            name="supervisor"
                            onChange={handleTitleChange}
                          >
                            <option value="">--- Select ---</option>

                            {teacherData?.map((teacherdata) => {
                              return (
                                <option key={teacherdata?.user_id} value={teacherdata?.user_id}>
                                  {teacherdata?.teacher_name}
                                </option>
                              );
                            })}
                          </select>
                        }
                      />
                      {examScheduleValidator.message("Supervisor", sheduleExamCalendarForm?.supervisor, "required")}
                    </Grid>
                  </Grid>
                  {detail_type?.type === "automated" && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <SelectGroup
                        label="Select Exam"
                        required
                        children={
                          <select
                            name="exam_id"
                            onChange={handleTitleChange}
                          >
                            <option value="">--- Select ---</option>

                            {allExams?.map((exam) => {
                              const classNames = exam?.classes.map((cls) => cls.name).join(" - ");

                              return (
                                <option key={exam?.id} value={exam?.id}>
                                  {classNames} | {exam.subject?.name}
                                </option>
                              );
                            })}
                          </select>
                        }
                      />
                      {examScheduleValidator.message("Exam", sheduleExamCalendarForm?.exam_id, "required")}
                    </Grid>
                  )}
                  {detail_type?.type === "manual" && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <SelectGroup
                        label="Select Subject"
                        required
                        children={
                          <select
                            name="subject_id"
                            onChange={handleTitleChange}
                          >
                            <option value="">--- Select ---</option>

                            {allClassSubject?.map((subject) => {
                              return (
                                <option key={subject?.subject_id} value={subject?.subject_id}>
                                  {subject?.subject_name}
                                </option>
                              );
                            })}
                          </select>
                        }
                      />
                      {examScheduleValidator.message("Exam Date", sheduleExamCalendarForm?.subject_id, "required")}
                    </Grid>
                  )}
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Exam Date"
                      margin="normal"
                      inputType="date"
                      required
                      inputMin={new Date().toISOString().split("T")[0]}
                      inputName="exam_date"
                      onChange={handleTitleChange}
                    />
                    {examScheduleValidator.message("Exam Date", sheduleExamCalendarForm?.exam_date, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      label="Start Time"
                      margin="normal"
                      inputType="time"
                      required
                      inputName="start_time"
                      onChange={handleTitleChange}
                    />
                    {examScheduleValidator.message("Exam Start Time", sheduleExamCalendarForm?.start_time, "required")}
                  </Grid>
                  {detail_type?.type === "manual" && ((
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <InputGroup
                        label="End Time"
                        margin="normal"
                        inputType="time"
                        required
                        inputName="end_time"
                        onChange={handleTitleChange}
                      />
                      {examScheduleValidator.message("Exam End Time", sheduleExamCalendarForm?.end_time, "required")}
                    </Grid>
                  ))}
                  {detail_type?.type === "automated" && ((
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <InputGroup
                        label="End Time"
                        margin="normal"
                        inputType="time"
                        required
                        disabled
                        value={sheduleExamCalendarForm?.end_time || ""}
                        inputMin={new Date().toISOString().split("T")[0]}
                        inputName="end_time"
                        onChange={handleTitleChange}
                      />
                      {examScheduleValidator.message("Exam End Time", sheduleExamCalendarForm?.end_time, "required")}
                    </Grid>
                  ))}
                  {detail_type?.type === "manual" && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <InputGroup
                        label="Exam Duration"
                        margin="normal"
                        inputType="text"
                        required
                        disabled
                        value={`${sheduleExamCalendarForm?.duration || 0} Mins`}
                        inputName="duration"
                        onChange={handleTitleChange}
                      />
                      {examScheduleValidator.message("Exam Duration", sheduleExamCalendarForm?.duration, "required")}
                    </Grid>
                  )}
                  {detail_type?.type === "automated" && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <InputGroup
                        label="Exam Duration"
                        margin="normal"
                        inputType="text"
                        required
                        inputName="duration"
                        disabled
                        value={`${selectedExamDuration?.duration || 0} Mins`}
                        onChange={handleTitleChange}
                      />
                      {examScheduleValidator.message("Exam Duration", sheduleExamCalendarForm?.duration, "required")}
                    </Grid>
                  )}
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div>
                  {/* <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Add Another Period" /> */}
                  <Button
                    variant="primary"
                    buttonSize="full"
                    color="btndefault"
                    isLoading={isCreating}
                    onClick={handleCreateExamScheduleDetails}
                    buttonName="Create Exam Schedule"
                  />
                </div>
              </>
            }
            isopen={scheduleExamModal}
            closeModal={handleCloseCreateSchdule}
          />
          {/* Edit Exam Time Table */}
          <Modal
            title="Edit Scheduled Examination Details"
            subtitle={`Time Table Type: ${currentEvent?.extendedProps?.schedule_type}`}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  {currentEvent?.extendedProps?.schedule_type === "manual" && (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <SelectGroup
                          label="Class"
                          required
                          children={
                            <select
                              name="class_id"
                              defaultValue={currentEvent?.extendedProps?.classes?.map(item => item?.id)}
                              onChange={handleEDitClassChange}
                            >
                              <option value="">--- Select ---</option>
                              {classesData?.map((label) => {
                                return (
                                  <option key={label.key} value={label?.id}>
                                    {label?.class_name}
                                  </option>
                                );
                              })}
                            </select>
                          }
                        />
                        {editExamScheduleValidator.message("Class", editSheduleExamCalendarForm?.class_id?.[0], "required")}
                      </Grid>
                      {editSheduleExamCalendarForm?.subject_id && (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <SelectGroup
                            label="Select Subject"
                            required
                            children={
                              <select
                                defaultValue={currentEvent?.extendedProps?.subject?.id}
                                name="subject_id"
                                onChange={handleEditTitleChange}
                              >
                                <option value="">--- Select ---</option>

                                {allClassSubject?.map((subject) => {
                                  return (
                                    <option key={subject?.subject_id} value={subject?.subject_id}>
                                      {subject?.subject_name}
                                    </option>
                                  );
                                })}
                              </select>
                            }
                          />
                          {editExamScheduleValidator.message("Subject", editSheduleExamCalendarForm?.subject_id, "required")}
                        </Grid>
                      )}

                    </>
                  )}
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SelectGroup
                      label="Select Supervisor"
                      required
                      children={
                        <select
                          name="supervisor"
                          defaultValue={currentEvent?.extendedProps?.supervisor?.id}
                          onChange={handleEditTitleChange}
                        >
                          <option value="">--- Select ---</option>

                          {teacherData?.map((teacherdata) => {
                            return (
                              <option key={teacherdata?.user_id} value={teacherdata?.user_id}>
                                {teacherdata?.teacher_name}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                    {editExamScheduleValidator.message("Supervisor", editSheduleExamCalendarForm?.supervisor, "required")}
                  </Grid>
                  {currentEvent?.extendedProps?.schedule_type === "automated" && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <SelectGroup
                        label="Select Exam"
                        required
                        children={
                          <select
                            defaultValue={currentEvent?.extendedProps?.exam_id}
                            name="exam_id"
                            onChange={handleEditTitleChange}
                          >
                            <option value="">--- Select ---</option>

                            {allExams?.map((exam) => {
                              const classNames = exam?.classes.map((cls) => cls.name).join(" - ");

                              return (
                                <option key={exam?.id} value={exam?.id}>
                                  {classNames} | {exam.subject?.name}
                                </option>
                              );
                            })}
                          </select>
                        }
                      />
                      {editExamScheduleValidator.message("Exam", editSheduleExamCalendarForm?.exam_id, "required")}
                    </Grid>
                  )}
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Exam Date"
                      margin="normal"
                      inputType="date"
                      required
                      defaultValue={currentEvent?.extendedProps?.exam_date.substring(0, 10)}
                      inputMin={new Date().toISOString().split("T")[0]}
                      inputName="exam_date"
                      onChange={handleEditTitleChange}
                    />
                    {editExamScheduleValidator.message("End Date", editSheduleExamCalendarForm?.exam_date, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      label="Start Time"
                      margin="normal"
                      inputType="time"
                      required
                      inputName="start_time"
                      defaultValue={currentEvent?.extendedProps?.start_time}
                      onChange={handleEditTitleChange}
                    />
                    {editExamScheduleValidator.message("Start Time", editSheduleExamCalendarForm?.start_time, "required")}
                  </Grid>
                  {currentEvent?.extendedProps?.schedule_type === "manual" && ((
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <InputGroup
                        label="End Time"
                        margin="normal"
                        inputType="time"
                        required
                        inputName="end_time"
                        defaultValue={currentEvent?.extendedProps?.end_time}
                        onChange={handleEditTitleChange}
                      />
                      {editExamScheduleValidator.message("Exam End Time", editSheduleExamCalendarForm?.end_time, "required")}
                    </Grid>
                  ))}
                  {currentEvent?.extendedProps?.schedule_type === "automated" && (
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <InputGroup
                        label="End Time"
                        margin="normal"
                        inputType="time"
                        required
                        disabled
                        value={editSheduleExamCalendarForm?.end_time || currentEvent?.extendedProps?.end_time}
                        inputName="end_time"
                        onChange={handleEditTitleChange}
                      />
                      {editExamScheduleValidator.message("Exam End Time", editSheduleExamCalendarForm?.end_time, "required")}
                    </Grid>
                  )}
                  {currentEvent?.extendedProps?.schedule_type === "automated" && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <InputGroup
                        label="Exam Duration"
                        margin="normal"
                        inputType="text"
                        required
                        inputName="exam_duration"
                        disabled
                        value={`${editSelectedExamDuration?.duration || currentEvent?.extendedProps?.exam_duration} Mins`}
                        onChange={handleEditTitleChange}
                      />
                      {editExamScheduleValidator.message("Duration", editSheduleExamCalendarForm?.exam_duration, "required")}
                    </Grid>
                  )}
                  {currentEvent?.extendedProps?.schedule_type === "manual" && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <InputGroup
                        label="Exam Duration"
                        margin="normal"
                        inputType="text"
                        required
                        inputName="exam_duration"
                        disabled
                        value={`${editSheduleExamCalendarForm?.exam_duration || currentEvent?.extendedProps?.exam_duration} Mins`}
                        onChange={handleEditTitleChange}
                      />
                      {editExamScheduleValidator.message("Duration", editSheduleExamCalendarForm?.exam_duration, "required")}
                    </Grid>
                  )}
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="danger" buttonSize="full" color="btndefault" buttonName="Delete Details" onClick={handleDeleteDetails} isLoading={deleting} />
                  <Button
                    variant="primary"
                    buttonSize="full"
                    color="btndefault"
                    isLoading={isEditing}
                    onClick={handleEditDetails}
                    buttonName="Edit Details"
                  />
                </div>
              </>
            }
            isopen={openEditModal}
            closeModal={handleCloseEditModal}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default ExamCalendar;
