import { useState, useEffect } from "react";
import Button from "../../components/Button";
import Table from "../../components/Table/Table";
import { TableSearch } from "../../components/Table/TableActions";
import Modal from "../../components/Modal";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import Badge from "../../components/Badge";
import { httprequest } from "../../data/api";
import { toast } from "react-toastify";

const EMPLOYEE_ENDPOINT = "/api/v1/employees/";

const AllEmployees = ({ data }) => {
  const [searchResult, setSearchResult] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [isreactivating, setIsReactivating] = useState(false);
  const [AllEmployeesData, setAllEmployeesData] = useState([]);

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDeleteEmployee = () => {
    setIsDeleting(true);
    httprequest.delete(`${EMPLOYEE_ENDPOINT}${currentRow?.employee_id}`).then((res) => {
      setIsDeleting(false);
      toast.success(res?.data?.message);
      window.location.reload();
    }).catch((err) => {
      setIsDeleting(false);
      toast.error(err?.response?.data);
    });
  };

  const handleReactivateEmployee = () => {
    setIsReactivating(true);

    // Create a new object with null values replaced by empty strings
    const updatedRow = Object.keys(currentRow).reduce((acc, key) => {
      acc[key] = currentRow[key] === null ? "" : currentRow[key];

      return acc;
    }, {});

    // this set is_active to true update to suit the enpoint requirement
    updatedRow.is_active = true;
    updatedRow.role = currentRow?.role?.id || "";
    updatedRow.reports_to = currentRow?.reports_to?.id || "";
    delete updatedRow.code;
    delete updatedRow.employee_id;
    delete updatedRow.user_id;
    delete updatedRow.school_id;
    delete updatedRow.first_name;
    delete updatedRow.last_name;
    delete updatedRow.termination_date;
    delete updatedRow.email;
    delete updatedRow.gender;
    delete updatedRow.resume;
    delete updatedRow.phone_number;

    httprequest.patch(`${EMPLOYEE_ENDPOINT}${currentRow?.employee_id}`, updatedRow)
      .then((res) => {
        setIsReactivating(false);
        toast.success(res?.data?.message);
        window.location.reload();
      })
      .catch((err) => {
        setIsReactivating(false);
        toast.error(err?.data?.message);
      });
  };

  const columns = [
    // {
    //   name: "Code",
    //   selector: row => row?.code
    // },
    {
      name: "Name",
      // eslint-disable-next-line prefer-template
      selector: (row) => row?.first_name + " " + row?.last_name
    },
    {
      name: "Email Address",
      selector: row => row?.email
    },
    {
      name: "Role",
      selector: row => row?.role?.name
    },
    {
      name: "Status",
      cell: (row) => (<Badge status={row?.is_active} />)
    },
    {
      name: "",
      sortable: false,
      cell: (row) => (
        <div key={row.employee_id} className="table-action-container" style={{ display: "flex", justifyContent: "center" }}>
          <Link to={`/users/employees/${row?.employee_id}`} className="table-action-icon">
            <Icon icon="fluent:eye-16-regular" />
          </Link>
          <Link to={`/users/employees/${row?.employee_id}`} className="table-action-icon">
            <Icon icon="heroicons:pencil-square-solid" />
          </Link>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon"
            key={row.employee_id}
          >
            {row?.is_active === true ? <div className="delete-icon"><Icon icon="mingcute:delete-2-line" /></div>
              : <div className="approve-icon"><Icon icon="mdi:checkbox-marked-circle-outline" /></div>}
          </button>
        </div>
      )
    }
  ];

  // --| Filter Teacher table using name, email and class
  const handleSearchTeacher = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = AllEmployeesData?.filter((data) => valArray?.every(
      (word) => data?.first_name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                    data?.last_name
                      ?.toLowerCase()
                      .includes(word.toLowerCase()) ||
                    data?.email
                      ?.toLowerCase()
                      .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  useEffect(() => {
    setAllEmployeesData(data);
    setSearchResult(data);
  }, [data]);

  return (
    <>
      <Table data={searchResult}
        columns={columns} keyField="employee_id" subHeader={true} pagination subHeaderComponent={
          data?.length > 0 && (
            <TableSearch placeholder="Search here..." searchTableFunc={handleSearchTeacher} />
          )}
      />
      <Modal
        title={currentRow?.is_active === true ? "Off board employee" : "Re-activate Employee"}
        subtitle={
          currentRow?.is_active === true
            ? (
              <div>
                Are you sure you want to off board
                <span className="modal-name-highlight"> {`${currentRow?.last_name} ${currentRow?.first_name}`}</span>?
                If deleted, the employee will no longer have access to this platform.
              </div>
            )
            : (
              <div>
                Are you sure you want to re-activate
                <span className="modal-name-highlight"> {`${currentRow?.last_name} ${currentRow?.first_name}`}</span>?
                If re-activated, the employee will have access to this platform.
              </div>
            )
        }
        modalContent={
          <>

          </>
        }
        modalFooter={
          <>
            {currentRow?.is_active === true ? <Button variant="danger" onClick={handleDeleteEmployee} isLoading={isDeleting} buttonName="Off board Employee" buttonSize="full" color="btndefault" />
              : <Button variant="primary" isLoading={isreactivating} onClick={handleReactivateEmployee} buttonName="Re-activate Employee" buttonSize="full" color="btndefault" />}
          </>
        }
        isopen={deleteModal}
        closeModal={handleCloseDeleteModal}
      />
    </>
  );
};

export default AllEmployees;
