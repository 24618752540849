import { TableContainer } from "@mui/material";
import { useState, useEffect } from "react";
import BreadCrumb from "../../../components/BreadCrumb";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/system";
// import Present from "../../../assets/images/present.svg";
// import Absent from "../../../assets/images/absent.svg";
import useStyles from "../styles";
import { Icon } from "@iconify/react/dist/iconify.js";
import { ToastContainer, toast } from "react-toastify";
import { httprequest } from "../../../data/api";
import PageLoader from "../../../components/PageLoader";
import nosearch from "../../../assets/images/no-search.svg";
import NotFounditem from "../../error/NotFound";
import Button from "../../../components/Button";

const CLASS_ENDPOINT = "/api/v1/class-teacher/class";
const CLASS_ATTENDANCE = "/api/v1/attendance";

const UserAttendance = () => {
  const classes = useStyles();
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri"];
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [isClassFetching, setIsClassFetching] = useState(false);
  const [classData, setClassData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [value, setValue] = useState("");
  // const [checked, setChecked] = useState(attendanceData?.present || false);
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [updateAttendance, setUpdateAttendance] = useState({
    class_id: "",
    student_id: [],
    attendance_id: ""
  });

  // Handles the checkbox change event when a student’s attendance is toggled true/false
  const handleCheckboxChange = (studentId, dayId, classId, present) => {
    // This update the attendance data in state
    setAttendanceData((prevAttendanceData) => prevAttendanceData.map((week) => ({
      ...week,
      // This Map through each day's record in the current week
      record: week?.record.map((day) => {
        // Check if the current day matches the dayId
        if (day?.id === dayId) {
          // Update the attendance array for the specific day checking if attendance is true then set it to false and vice versa
          // (last conditional statement att) returns the original record if attendance does not match
          const updatedAttendance = day?.attendance.map((att) => att?.id === studentId ? { ...att, present: present !== true } : att
          );

          // This returns the updated day record with the modified attendance array
          return {
            ...day,
            attendance: updatedAttendance
          };
        }

        // If the current day does not match, return the original day record
        return day;
      })
    }))
    );

    setUpdateAttendance((prevUpdateAttendance) => ({
      ...prevUpdateAttendance,
      class_id: classId,
      attendance_id: dayId,
      student_id: [
        // This update the list of student IDs, ensuring the current student is available
        ...prevUpdateAttendance.student_id.filter((student) => student?.id !== studentId), // Remove the existing student if it exists
        { id: studentId, present: present !== true } // This adds the updated student entry with the toggled attendance status
      ]
    }));

    // Show the update button, indicating that changes have been made and need to be saved even tho it is changed to it's original state (true/false)
    setShowUpdateButton(true);
  };

  // update Attendance
  const handleUpdateAttendance = () => {
    httprequest.post(CLASS_ATTENDANCE, updateAttendance)
      .then((res) => {
        toast.success(res?.data?.message);
        setShowUpdateButton(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // get All Classes for Attendance Marking
  const getAllClasses = () => {
    setIsClassFetching(true);
    httprequest.get(CLASS_ENDPOINT).then((res) => {
      setIsClassFetching(false);

      setClassData(res?.data?.data);
    }).catch((err) => {
      setIsClassFetching(false);
      toast.error(err?.response?.data);
    });
  };

  // get All Classes Attendance
  const getClassAttendance = (id) => {
    httprequest.get(`${CLASS_ATTENDANCE}/${id}`).then((res) => {
      setAttendanceData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // Handles the change event when a new tab is selected
  const handleChange = (event, newValue) => {
    setValue(newValue);
    getClassAttendance(newValue);
  };

  // on mount render the class of the class tab selected
  useEffect(() => {
    if (classData?.length > 0) {
      setValue(classData[0]?.id);
      getClassAttendance(classData[0]?.id);
    }
  }, [classData]);

  // On mount renders all classes
  useEffect(() => {
    getAllClasses();
  }, []);

  // Extract each student names from the data
  const schStudentNames = attendanceData && attendanceData?.length > 0 ? [
    ...new Set(
      attendanceData.flatMap((week) => week?.records?.flatMap((day) => day?.attendance?.map((stu_name) => `${stu_name?.first_name} ${stu_name?.last_name}`) || []
      ) || []
      )
    )
  ] : [];

  // Checks the number of weeks
  const numberOfWeeks = attendanceData?.length;

  // eslint-disable-next-line no-console
  console.log(schStudentNames, "schStudentNames");

  // eslint-disable-next-line no-console
  console.log(attendanceData, "attendanceData");

  return (
    <>
      <div className="breadcrumb-container">
        <div>
          <BreadCrumb
            link_one="/class-activities/classes"
            link_one_name="My Classes"
            link_two="/users/students"
            link_two_name="My Students"
            active="Attendance"
            description="See attendance for my class as a class Teacher"
          />
        </div>
      </div>
      <div className={`${classes.attcontent} page-content-mh-83`}>
        <TabContext value={value}>
          <div className="flex align-items-center justify-content-between flex-wrap-items-600 margin-top-10-v600">
            <div className="tablist-container">
              <Tabs
                indicatorColor="none"
                className="overide-tablist"
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {/* renders The Tabs For Each Of The Classes in a School e.g: JSS3, JSS2, JSS1 etc */}
                {classData?.map((data) => {
                  return (
                    <Tab key={data?.id} value={data?.id} label={data?.class_name} />
                  );
                })}
              </Tabs>
            </div>
            {/* conditionally Renders The Update Attendance Button When Any Student Attendance is instantly Checked */}
            {showUpdateButton && (
              <div className="align-self-end">
                <Button variant="primary" buttonSize="fluid" color="btndefault" buttonName="Update Attendance" onClick={handleUpdateAttendance} />
              </div>
            )}
          </div>
          {/* Renders Attendance For Each Of The Classes In a School */}
          {classData?.map((data) => {
            return (
              <TabPanel sx={{ border: "1px solid #98A2B3", borderRadius: "8px", marginTop: "16px", "@media (max-width: 500px)": { padding: "2px !important" } }} key={data?.id} value={data?.id}>
                {isClassFetching ? (
                  <PageLoader />
                ) : schStudentNames?.length === 0 || attendanceData?.length === 0 ? (
                  <div className={classes.notfound}>
                    <NotFounditem img={nosearch} title="No Attendance Found" subtitle="No attendance has been added to the list." />
                  </div>
                ) : (
                  <>
                    <Box sx={{ overflowX: "auto" }}>
                      <TableContainer component={Paper} sx={{ display: "table", width: "auto" }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            {/* First row of the table head */}
                            <TableRow>
                              {/* First cell in the first row, which is the "Attendance" header */}
                              <TableCell sx={{ minWidth: 200, borderBottom: "1px solid #F5F6F7", position: "sticky", left: 0, backgroundColor: "white", zIndex: 3, "@media (max-width: 576px)": { visibility: "hidden", minWidth: 130, backgroundColor: "none" } }}>
                      Attendance
                              </TableCell>
                              {/* Generates table cells for each week */}
                              {Array.from({ length: numberOfWeeks }).map((_, weekIndex) => (
                                <TableCell align="center" sx={{ borderBottom: "none", "&:not(:last-child)": { borderRight: "0.5px solid #6C737F" } }} colSpan={daysOfWeek.length} key={`week-${weekIndex}`}>
                        Week {weekIndex + 1}
                                </TableCell>
                              ))}
                            </TableRow>
                            {/* Second row of the table head */}
                            <TableRow>
                              <TableCell sx={{ position: "sticky", left: 0, borderBottom: "1px solid #F5F6F7", backgroundColor: "white", zIndex: 3 }}>
                      Student
                              </TableCell>
                              {/* Generates table cells for each day of the week for each week */}
                              {Array.from({ length: numberOfWeeks }).map((_, weekIndex) => daysOfWeek.map((day, dayIndex) => (
                                <TableCell align="center" sx={{ borderBottom: "none", "&:not(:last-child)": { borderRight: "0.5px solid #6C737F" } }} key={`day-${weekIndex}-${day}-${dayIndex}`}>
                                  {day}
                                </TableCell>
                              )))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* Iterates and renders the list of student names under the student row */}
                            {schStudentNames.map((studentName) => (
                              <TableRow
                                key={studentName}
                                sx={{
                                  "&:nth-of-type(even)": { backgroundColor: "#F9F9FB" }
                                }}
                              >
                                {/* First cell containing the student name, styled to be sticky for proper scrolling */}
                                <TableCell component="th" scope="row" sx={{ position: "sticky", left: 0, backgroundColor: "white", zIndex: 2 }}>
                                  {studentName}
                                </TableCell>
                                {/* Iterate over attendance data for each week and day */}
                                {attendanceData.flatMap((week, weekIndex) => daysOfWeek.map((day) => {
                                  // Find the attendance record for the specific day(e.g Monday, Tuesday, Thursday etc) converts to lowercase to know which is true from daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri"]
                                  const dayRecord = week?.records.find((record) => record?.day.toLowerCase().startsWith(day.toLowerCase()));

                                  // If no record exists for the day, render a disabled checkbox
                                  if (!dayRecord) {
                                    return (
                                      <TableCell align="center" sx={{ borderBottom: "0px", "&:not(:last-child)": { borderRight: "0.5px solid #6C737F" } }} key={`empty-${weekIndex}-${day}`}>
                                        <Checkbox
                                          {...label}
                                          defaultChecked
                                          disabled
                                          sx={{
                                            "&.Mui-checked": {
                                              color: "#98A2B3"
                                            }
                                          }}
                                        />
                                      </TableCell>
                                    );
                                  }
                                  // Find the attendance record for a specific student
                                  const attendance = dayRecord?.attendance.find((att) => `${att?.first_name} ${att?.last_name}` === studentName);

                                  // Renders checkbox for the student's attendance, styled based on presence
                                  return (
                                    <TableCell align="center" sx={{ borderBottom: "0px", "&:not(:last-child)": { borderRight: "0.5px solid #6C737F" } }} key={attendance ? `att-${day}-${weekIndex}-${attendance?.id}-${attendance?.name}` : `empty-${weekIndex}-${day}`}>
                                      <Checkbox
                                        {...label}
                                        checked={attendance?.present === true || attendance?.present === false}
                                        onChange={() => handleCheckboxChange(attendance?.id, dayRecord?.id, classData[0]?.id, attendance?.present)}
                                        sx={{
                                          color: "inherit",
                                          "&.Mui-checked": {
                                            color: attendance?.present === true ? "#3FAC64" : attendance?.present === false ? "#D42620" : "inherit"
                                          }
                                        }}
                                      />
                                    </TableCell>
                                  );
                                }))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </>
                )}
              </TabPanel>
            );
          })}

        </TabContext>

        {/* If Student Attendance is available Legend Key is displayed else null */}
        {schStudentNames?.length > 0 ? (
          <div>
            <div className={`${classes.legends} .margin-top-10-v600 flex justify-content-between align-items-center flex-wrap-items-600`}>
              <div className={`${classes.attcounter}`}>
                {/* <p className="margin-bottom-10">Attendance Counter</p>
                <div className={`${classes.attlegend} flex gap-10 align-items-center`}>
                  <h5>Week1 <span><Icon icon="iconamoon:arrow-down-2-thin" width="10px" height="10px" style={{ color: "#0065FF" }} /></span></h5>
                  <h6>100/500</h6>
                </div> */}
              </div>
              <div className={`${classes.systemkey}`}>
                <p className="margin-bottom-10">System Key</p>
                <div className={`${classes.attlegend} flex gap-10 align-items-center flex-wrap-items-v430`}>
                  <div className="flex gap-5 align-items-center">
                    <h6>Unselected</h6>
                    <Icon icon="ci:checkbox-unchecked" width="15" height="15" style={{ color: "#98A2B3" }} />
                  </div>
                  <div className="flex gap-5 align-items-center">
                    <h6>No Record</h6>
                    <Icon icon="bxs:checkbox-checked" width="17" height="17" style={{ color: "#98A2B3" }} />
                  </div>
                  <div className="flex gap-5 align-items-center">
                    <h6>Present</h6>
                    <Icon icon="bxs:checkbox-checked" width="17" height="17" style={{ color: "#3FAC64" }} />
                  </div>
                  <div className="flex gap-5 align-items-center">
                    <h6>Absent</h6>
                    <Icon icon="bxs:checkbox-checked" width="17" height="17" style={{ color: "#D42620" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          null
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default UserAttendance;
