import Layout from "../../components/Layout";
import Examination from "../examination/employer/Examination";
import { useUserState } from "../../context/UserContext";
import Permission from "../error/Permission";

const ForExamination = () => {
  const { userRole } = useUserState();

  return (
    <Layout children={
      <>
        {userRole?.role === "school_admin" ? (
          <Examination />
        ) : <Permission />}
      </>
    }
    />
  );
};

export default ForExamination;
