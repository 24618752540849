import { useEffect, useState } from "react";
// import TabContext from "@mui/lab/TabContext";
import { Grid } from "@mui/material";
// import Tab from "@mui/material/Tab";
// import Tabs from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
import { toast, ToastContainer } from "react-toastify";
import PageTitle from "../../../components/PageTitle";
import Modal from "../../../components/Modal";
import useStyles from "../styles";
import Button from "../../../components/Button";
import NoExamFound from "../../../assets/images/noexam.svg";
import ApplicantResultCard from "./ApplicantResultCard";
import { axiosrequest } from "../../../data/api";
import PageLoader from "../../../components/PageLoader";

const APPLICANT_RESULT = "/api/v1/applicant/result";

const ApplicantResult = () => {
  const classes = useStyles();
  // const [value, setValue] = useState("");
  const [noResultModal, setNoResultModal] = useState(false);
  const [isResultFetching, setIsresultFetching] = useState(false);
  const [applicantResult, setApplicantResult] = useState([]);
  // const handleTabChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const handleNoResultModal = () => {
    setNoResultModal(!noResultModal);
  };

  const handleGetApplicantResult = () => {
    setIsresultFetching(true);
    axiosrequest.get(APPLICANT_RESULT).then((res) => {
      setIsresultFetching(false);
      setApplicantResult(res?.data?.data);
    }).catch((err) => {
      setIsresultFetching(false);
      toast.error(err?.response?.data?.message);
    });
  };

  useEffect(() => {
    handleGetApplicantResult();
  }, []);

  return (
    <div className={`${classes.border} page-content`}>
      <PageTitle
        title="Your Exam Results"
        subtitle="These are all the examinations you can take in this enrolment"
        button={
          <div className="flex gap-10">
            <Button onClick={handleNoResultModal} variant="primary" buttonSize="fluid" color="btndefault" buttonName="Check for Result" />
          </div>
        }
      />
      <div className="page-content height-full">
        {isResultFetching ? (
          <PageLoader />
        ) : (
          applicantResult?.length === 0 ? (
            <div>
              <div className={classes.noexamimg}>
                <img src={NoExamFound} alt="Not Found" />
              </div>
              <div>
                <p className={classes.nocodeheader}>No Result Found</p>
                <p className={classes.nocodesentence}>The school admin has not uploaded any result for you to see.</p>
              </div>
            </div>
          ) : (
            <Grid container spacing={2}>
              {applicantResult.map((item, index) => {
                return (
                  <Grid item lg={4} md={4} sm={12} xs={12} key={index}>
                    <ApplicantResultCard resulttitle={item?.subject} result={`${((item?.total_score / item?.exam_attainable_score) * 100).toFixed(1)}%`} />
                  </Grid>
                );
              })}
              {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                <ApplicantResultCard resulttitle="Mathematics" result="60%" />
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <ApplicantResultCard resulttitle="Quantum Physics" result="90%" />
              </Grid> */}
            </Grid>
          )
        )}
        <ToastContainer />
      </div>
      {/* No Result Found MOdal */}
      <Modal
        title="Your Results"
        subtitle=""
        modalContent={
          <>
            <div>
              <div className={classes.noexamimg}>
                <img src={NoExamFound} alt="Not Found" />
              </div>
              <div>
                <p className={classes.nocodeheader}>No Result Found</p>
                <p className={classes.nocodesentence}>The school admin has not uploaded any result for you to see.</p>
              </div>
            </div>
          </>
        }
        modalFooter={
          <>
          </>
        }
        isopen={noResultModal}
        closeModal={handleNoResultModal}
      />
    </div>
  );
};

export default ApplicantResult;
