import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import useStyles from "./styles";
import { Grid } from "@mui/material";
import Button from "../../components/Button";
import CardCount from "../../components/CardCount";
import { Group as StudentIcon, MenuBook as Book, Folder, Class as ClassIcon, AddCircle } from "@mui/icons-material";
import PageTitle from "../../components/PageTitle";
import InputGroup from "../../components/FormComponent/InputGroup";
import Modal from "../../components/Modal/index";
import WidgetTitle from "./components/WidgetTitle/WidgetTitle";
import { useNavigate } from "react-router-dom";
// import Table from "../../components/Table/Table";
import useWindowSize from "../../hooks/useWindow";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { toast } from "react-toastify";
import { httprequest } from "../../data/api";
import NoticeBoardCard from "./components/NoticeBoardCard/NoticeBoardCard";
import AdminLeaveCard from "./components/AdminLeaveCard/AdminLeaveCard";
import DetailsText from "../leavemgt/components/DetailsText";
import ClassPeriod from "./components/ClassPeriodCard";
import { useUserState } from "../../context/UserContext";
import empty from "../../assets/images/emptyperiod.png";
import NoData from "./components/NotFoundCard/NoData";
import NoNotice from "../../../src/assets/images/nofeatureicons/nonotice.svg";
import { accessGetEventRSVP, accessTeacherClasses, accessViewAllLeaveOptions } from "../../data/roles";

const LEAVE_MGT_ENDPOINT = "/api/v1/leave";

const EmployeeDashboard = () => {
  const { userRole } = useUserState();
  const classes = useStyles();
  const [modalisopen, setModalIsOpen] = useState(false);
  const [actionType, setActionType] = useState("");
  const [isApproving, setIsApproving] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [currentLeave, setCurrentLeave] = useState({});
  const [overview, setOverview] = useState([]);
  const [currentNotice, setCurrentNotice] = useState({});
  const [viewNoticeModal, setViewNoticeModal] = useState(false);
  // const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  const width = useWindowSize();
  const mobile = width < 650;

  const handleCloseApproveModal = () => {
    setApproveModal(false);
  };
  const getOverview = () => {
    httprequest
      .get("/api/v1/overview/employee")
      .then((res) => {
        setOverview(res?.data.data || []);
      })
      .catch(() => {
      });
  };

  const handleCloseViewNoticeModal = () => {
    // setCurrentNotice(row);
    setViewNoticeModal(!viewNoticeModal);
  };

  const handleViewNoticeModal = (notice) => {
    setCurrentNotice(notice);
    setViewNoticeModal(!viewNoticeModal);
  };

  useEffect(() => {
    getOverview();
  }, []);

  const handleApproveLeave = (leave_id) => {
    setIsApproving(true);
    httprequest
      .patch(`${LEAVE_MGT_ENDPOINT}/status/${leave_id}`, { leave_status: "approved" })
      .then((res) => {
        setIsApproving(false);
        handleCloseApproveModal();
        // handleGetOverview();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        setIsApproving(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const handleDeclineLeave = (leave_id) => {
    setIsDeclining(true);
    httprequest
      .patch(`${LEAVE_MGT_ENDPOINT}/status/${leave_id}`, { leave_status: "declined" })
      .then(() => {
        setIsDeclining(false);
        handleCloseApproveModal();
        // handleGetOverview();
        toast.success("Leave status has been declined");
      })
      .catch((err) => {
        setIsDeclining(false);
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Dashboard"
            subtitle="Keep up with everything going on"
            button={
              <Button
                variant="primary"
                buttonSize="small"
                color="btndefault"
                onClick={() => {
                  setModalIsOpen(true);
                }}
                buttonName="Schedule Class"
              />
            }
          />
          <Grid container spacing={4}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="My Students"
                count={overview?.students?.total || 0}
                icon={<StudentIcon />}
                leftSubText="Active :"
                rightSubText="Inactive :"
                leftSubValue={overview?.students?.active}
                rightSubValue={overview?.students?.inactive}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="My Subjects"
                count={overview?.subjects?.total || 0}
                icon={<Book />}
                leftSubText="Active :"
                rightSubText="Inactive :"
                leftSubValue={overview?.subjects?.active || 0}
                rightSubValue={overview?.subjects?.inactive || 0}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="My Classes"
                count={overview?.classes?.total || 0}
                icon={<ClassIcon />}
                leftSubText="Active :"
                rightSubText="Inactive :"
                leftSubValue={overview?.classes?.active || 0}
                rightSubValue={overview?.classes?.inactive || 0}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="My Lesson Notes"
                count={overview?.lesson_notes?.total || 0}
                icon={<Folder />}
                leftSubText="Active :"
                rightSubText="Inactive :"
                leftSubValue={overview?.lesson_notes?.active || 0}
                rightSubValue={overview?.lesson_notes?.inactive || 0}
              />
            </Grid>
          </Grid>
          {/* <div className="cover-page">
            <WidgetTitle
              title="My TimeTable Schedule"
              subtitle=""
              // link={<Link className={classes.widgetlink} to="/teachers"><CalendarToday /> See full schedule</Link>}
            />

            <TabContext value={value}>
              <div className="tablist-container">
                <Tabs
                  indicatorColor="none"
                  className="overide-tablist"
                  onChange={handleChange}
                  aria-label="class tabs"
                >
                  {allClassesData?.map((data) => {
                    return (
                      <Tab
                        key={data?.class?.id}
                        value={data?.class?.id}
                        label={data?.class?.name}
                      />
                    );
                  })}
                </Tabs>
              </div>
              {allClassesData?.map((data) => {
                return (
                  <TabPanel key={data?.class?.id} value={data?.class?.id}>
                    <div className={classes.calendar}>
                      <Calendar events={events} renderEventContent={renderEventContent} defaultView={renderView()} />
                    </div>
                  </TabPanel>
                );
              })}
            </TabContext>
          </div> */}
          {accessTeacherClasses(userRole) &&
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <WidgetTitle
                  title="Today's Classes"
                  subtitle="View your schedule for the day"
                />
                <div className={classes.period}>
                  <div className={classes.static}>
                    <h3 style={{ fontSize: "14px" }} onClick={() => navigate("/class-activities/classes/class-calendar")}>View all</h3>
                    <hr />
                  </div>
                  <div className={classes.periodclasses}>
                    <div className={classes.preriodflex}>
                      {overview?.period && overview.period.length > 0 ? (
                        overview.period.map((period, index) => (
                          <div key={index} className={classes.perioditem}>
                            <ClassPeriod
                              start_time={period?.start_time}
                              end_time={period?.end_time}
                              period_subject={period?.subject}
                              period_class={period?.class}
                            />
                          </div>
                        ))
                      ) : (
                        <div className="no_upload" style={{ width: "100%" }}>
                          <img src={empty} />
                          <span>No periods available</span>
                        </div>
                      )}

                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          }
          {accessGetEventRSVP(userRole) &&
          <>
            <WidgetTitle
              title="Upcoming Events"
              subtitle="See all the Upcoming events"
            />
            <div className={classes.events}>
              <h3 style={{ fontSize: "14px" }} onClick={() => navigate("/events")}>View all</h3>
              <hr />
              <div className={classes.chrono}>
                {overview?.events && overview.events.length > 0 ? (
                  <Stepper
                    activeStep={1}
                    alternativeLabel={!mobile}
                    orientation={mobile ? "vertical" : "horizontal"}
                  >
                    {[...overview.events].reverse().map((label) => (
                      <Step key={label.id}>
                        <StepLabel>
                          <div>
                            <span>{label?.title}</span>
                            <br />
                            <span>{new Date(label?.date).toLocaleDateString()}</span>
                            <br />
                            <span>{label?.description}</span>
                          </div>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                ) : (
                  <div className={classes.notfound}>
                    <NoData
                      img={NoNotice}
                      title="Empty Period"
                      subtitle="No periods available"
                    />
                  </div>
                )}
              </div>

            </div>
          </>
          }

          <Grid container spacing={2}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <WidgetTitle
                title="Notice Board"
                subtitle="View important information for your school"
              />
              <div className={classes.notice}>
                <div className={classes.static}>
                  <h3 style={{ fontSize: "14px" }} onClick={() => navigate("/employee/notice-board")}>View all</h3>
                  <hr />
                </div>
                <div className={classes.pendingleaves}>
                  {overview?.notice_board?.length > 0 ? (
                    overview.notice_board.map((notice) => (
                      <div key={notice.id}>
                        <NoticeBoardCard
                          viewClick={() => handleViewNoticeModal(notice)}
                          title={notice.title}
                          date={notice.created_at}
                          teacher={notice.added_by?.name}
                          priority={notice.priority}
                        />
                      </div>
                    ))
                  ) : (
                    <div className={classes.notfound}>
                      <NoData
                        img={NoNotice}
                        title="Empty Notice Board"
                        subtitle="No Information added"
                      />
                    </div>
                  )}

                </div>
              </div>
            </Grid>
            {accessViewAllLeaveOptions(userRole) &&
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <WidgetTitle
                title="Student Leave Requests"
                subtitle="View leave request of student in your class"
              />
              <div className={classes.leave}>
                <div className={classes.static}>
                  <h3 style={{ fontSize: "14px" }} onClick={() => navigate("/manage/leave")}>View all</h3>
                  <hr />
                </div>
                <div className={classes.pendingleaves}>
                  {overview?.student_leaves?.length > 0 ? (
                    overview?.student_leaves?.map((leaves) => (
                      <AdminLeaveCard
                        key={leaves?.id}
                        to={`/manage/leave/${leaves?.id}`}
                        t_name={leaves?.leave_requester?.name}
                        t_role={leaves?.role?.name}
                        start_date={leaves?.start_date}
                        end_date={leaves?.end_date}
                        approveClick={() => {
                          setCurrentLeave(leaves);
                          setActionType("approve");
                          setApproveModal(true);
                        }}
                        declineClick={() => {
                          setCurrentLeave(leaves);
                          setActionType("decline");
                          setApproveModal(true);
                        }}
                      />
                    ))
                  ) : (
                    <div className={classes.notfound}>
                      <NoData
                        img={NoNotice}
                        title="Empty Leave Request"
                        subtitle="No Leave Request added"
                      />
                    </div>
                  )}

                </div>
              </div>
            </Grid>
            }
          </Grid>

          {/* <WidgetTitle
            title="Students"
            subtitle="See all the students in your class"
            link={<Link className={classes.widgetlink} to="/teachers">View all students</Link>}
          />
          <Table data={studentData} columns={studentColumns} /> */}

          <Modal
            title="Add a class"
            subtitle="You can add a new class and assign a teacher to the class."
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Class Name"
                      margin="normal"
                      placeHolder="Enter Class Name"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Class Teacher"
                      margin="normal"
                      placeHolder="Select Class Teacher"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      label="Subject"
                      margin="normal"
                      placeHolder="Subjects"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      label="Subject"
                      margin="normal"
                      placeHolder="Subjects"
                      inputType="text"
                    />
                  </Grid>
                </Grid>
                <div className={`${classes.extrasubject}`}>
                  <p className={classes.subject_add}>Add another Subject</p>
                  <AddCircle className={classes.addicon} />
                </div>
              </>
            }
            modalFooter={
              <>
                <Button variant="primary" buttonName="Add Class" buttonSize="full" color="btndefault" />
              </>
            }
            isopen={modalisopen}
            closeModal={() => {
              setModalIsOpen(false);
            }}
          />

          {/* Approve Leave */}
          <Modal
            title="Handle Leave Request"
            subtitle={`Approve Leave of ${currentLeave?.leave_requester?.name}`}
            modalContent={
              <>
                <div className="margin-top-30">
                  <Grid container spacing={1}>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <DetailsText title="Role" subtitle={currentLeave?.role?.name} />
                      {/* <h3>Role: {currentLeave?.role?.name}</h3> */}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <DetailsText title="Start Date:" subtitle={currentLeave?.start_date} />
                      {/* <h3>Start Date: {currentLeave?.start_date}</h3> */}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <DetailsText title="End Date:" subtitle={currentLeave?.end_date} />
                      {/* <h3>End Date: {currentLeave?.end_date}</h3> */}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <DetailsText title="Number of Days" subtitle={currentLeave?.number_of_days} />
                      {/* <h3>Number of Days: {currentLeave?.number_of_days}</h3> */}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <DetailsText title="Leave Type:" subtitle={currentLeave?.leave_type?.name} />
                      {/* <h3>Leave Type: {currentLeave?.leave_type?.name}</h3> */}
                    </Grid>

                  </Grid>
                </div>
              </>
            }
            modalFooter={
              <>
                {actionType === "approve" ? (
                  <Button
                    variant="success"
                    buttonSize="full"
                    color="btndefault"
                    buttonName="Approve Leave"
                    onClick={() => handleApproveLeave(currentLeave?.id)}
                    isLoading={isApproving}
                  />
                ) : (
                  <Button
                    variant="danger"
                    buttonSize="full"
                    color="btndefault"
                    buttonName="Decline Leave"
                    onClick={() => handleDeclineLeave(currentLeave?.id)}
                    isLoading={isDeclining}
                  />
                )}
              </>
            }
            isopen={approveModal}
            closeModal={() => setApproveModal(false)}
          />
          <Modal
            title="View a Notice"
            subtitle={
              <div>
                View a notice set from your school
              </div>
            }
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {currentNotice?.image?.file_url && (
                      <div
                        className={classes.viewBanner}
                        style={{
                          backgroundImage: `url(${currentNotice?.image?.file_url})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat"
                        }}
                      >
                      </div>
                    )}
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Title"
                      required
                      disabled="disabled"
                      margin="normal"
                      placeHolder="Add Title of Document"
                      inputType="text"
                      inputName="title"
                      // onChange={handleEditChange}
                      defaultValue={currentNotice?.title}
                    />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="End Date"
                      margin="normal"
                      disabled="disabled"
                      // onChange={handleEditChange}
                      inputType="text"
                      required
                      value={currentNotice?.end_date}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Description"
                      margin="normal"
                      required
                      disabled="disabled"
                      placeHolder="Notice Description"
                      inputType="text"
                      inputName="description"
                      // onChange={handleEditChange}
                      defaultValue={currentNotice?.description}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Priority"
                      margin="normal"
                      required
                      disabled="disabled"
                      placeHolder="Notice priority"
                      inputType="text"
                      inputName="priority"
                      // onChange={handleEditChange}
                      defaultValue={currentNotice?.priority}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Status"
                      margin="normal"
                      required
                      disabled="disabled"
                      placeHolder="Notice status"
                      inputType="text"
                      inputName="Status"
                      // onChange={handleEditChange}
                      defaultValue={currentNotice?.status}
                    />
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="primaryoutline"
                    buttonName="Cancel"
                    buttonSize="full"
                    color="btnfontprimary"
                    onClick={handleCloseViewNoticeModal}
                  />
                </div>
              </>
            }
            isopen={viewNoticeModal}
            closeModal={handleCloseViewNoticeModal}
          />
        </>
      }
    />
  );
};

export default EmployeeDashboard;
