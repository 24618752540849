import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  profilecontainer: {
    backgroundColor: "#F5F6F7",
    width: "100%",
    height: 258,
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 22
  },
  pickercontainer: {
    position: "absolute",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "25px",
    height: "25px",
    bottom: 0,
    right: 0,
    color: "white",
    borderRadius: "50%"
  },
  camera: {
    color: "white",
    width: "14px!important"
  },
  profilepicker: {
    opacity: 0,
    width: 25,
    height: 25,
    cursor: "pointer",
    position: "absolute"
  },
  imageviewer: {
    width: "150px",
    height: "150px",
    backgroundColor: "white",
    borderRadius: "50%",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  profileform: {
    // width: "70%!important",
    [theme.breakpoints.up("lg")]: {
      width: "100%!important"
    }
  },
  btnspace: {
    marginTop: 112,
    marginLeft: -30,
    "@media (max-width: 600px)": {
      marginTop: 50,
      marginLeft: 0,
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      width: "100%"
    }
  },
  downloadbutton: {
    marginTop: 20,
    textAlign: "center"
  },
  whitebg: {
    background: "white",
    borderRadius: "10px"
  }
}));
