import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import { useState, useEffect } from "react";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import { Icon } from "@iconify/react";
import Tabs from "@mui/lab/TabList";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TabContext from "@mui/lab/TabContext";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
import { httprequest } from "../../../data/api";
import { useUserState } from "../../../context/UserContext";
import { accessCreateLessonNote, accessViewLessonNoteDetails } from "../../../data/roles";

const CLASS_ENDPOINT = "/api/v1/teacher/classes";
const LESSON_NOTE = "/api/v1/lesson-notes";

const AdminLessonNote = () => {
  const { userRole } = useUserState();
  const navigate = useNavigate();
  const { profile } = useUserState();
  const [value, setValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [classLevels, setClassLevels] = useState([]);
  const [allLessonNotes, setAllLessonNotes] = useState([]);

  // Table Tab change
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // http get all class levels requests
  const getClassLevels = () => {
    httprequest
      .get(CLASS_ENDPOINT)
      .then((res) => {
        setClassLevels(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  //   http get all lessons requests
  const getAllLessonNotes = () => {
    httprequest
      .get(`${LESSON_NOTE}?class_id=${value}`
      )
      .then((res) => {
        setAllLessonNotes(res?.data?.data);
        setSearchResult(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const navigateViewLesson = (row) => {
    navigate(`/lesson-notes/${row?.id}`);
  };

  const columns = [
    {
      name: "Class",
      selector: (row) => row?.class?.name
    },
    {
      name: "Subject",
      selector: (row) => row?.subject?.name
    },
    {
      name: "Note Count",
      selector: (row) => row?.outline_count
    },
    {
      name: "Created at",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Last Updated",
      selector: (row) => row?.updated_at?.substring(0, 10)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          {accessViewLessonNoteDetails(userRole) &&
            <button
              className="table-action-icon"
              onClick={() => navigateViewLesson(row)}
            >
              <Icon icon="fluent:eye-16-regular" />
            </button>
          }
        </div>
      )
    }
  ];

  useEffect(() => {
    getClassLevels();
  }, []);

  useEffect(() => {
    getAllLessonNotes();
  }, [value]);

  // --| Filter Shelf21 table using name, email and class
  const handleLessonNoteSearch = (e) => {
    const { value } = e.target;
    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = allLessonNotes?.filter((data) => valArray?.every(
      (word) => data?.class?.name?.toLowerCase().includes(word.toLowerCase()) ||
      data?.subject?.name?.toLowerCase().includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  // const { subject_id, class_id } = lessonNoteForm;

  return (
    <div>
      <PageTitle
        title="Lesson Note"
        subtitle="See all your Lesson Note"
        button={
          <div className="flex gap-10">
            {accessCreateLessonNote(userRole) &&
              <Button
                variant="primaryoutline"
                buttonSize="fluid"
                color="btnfontprimary"
                onClick={() => navigate("/lesson-notes/create-lesson-note")}
                buttonName="Create Lesson Outline"
              />
            }
          </div>
        }
      />
      <div className="page-content">
        <TabContext value={value}>
          {searchResult?.length > 0 && (
            <div className="tablist-container">
              <Tabs
                indicatorColor="none"
                className="overide-tablist"
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                <Tab label="All Lesson note" value="" data={profile} />
                {classLevels?.map((data) => {
                  return (
                    <Tab
                      key={data?.class?.id}
                      value={data?.class?.id}
                      label={data?.class?.name}
                    />
                  );
                })}
              </Tabs>
            </div>
          )}
          <>
            <TabPanel value="" className="tabpanel-overide">
              <Table
                data={searchResult}
                columns={columns}
                subHeader={true}
                pagination
                subHeaderComponent={
                  searchResult?.length > 0 && (
                    <TableSearch
                      placeholder="Search here..."
                      searchTableFunc={handleLessonNoteSearch}
                    />
                  )}
              />
            </TabPanel>
            {classLevels?.map((data) => {
              return (
                <TabPanel key={data?.class?.id} value={data?.class?.id}>
                  <Table
                    data={allLessonNotes}
                    columns={columns}
                    subHeader={true}
                    pagination
                    subHeaderComponent={
                      allLessonNotes.length > 0 && (
                        <TableSearch placeholder="Search here..." />
                      )

                    }
                  />
                </TabPanel>
              );
            })}
          </>
        </TabContext>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AdminLessonNote;
