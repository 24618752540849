import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import { Grid, Pagination } from "@mui/material";
import Modal from "../../../components/Modal";
import { ToastContainer, toast } from "react-toastify";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import useStyles from "../styles";
import PageLoader from "../../../components/PageLoader";
import NotFounditem from "../../error/NotFound";
import nosearch from "../../../assets/images/no-search.svg";
// import EventBanner from "../../../assets/images/eventbanner.jpg";
import InputGroup from "../../../components/FormComponent/InputGroup";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import LibraryItemCard from "../component/LibraryItemCard";
import { CardSearch } from "../../../components/CardSearch";
import { httprequest } from "../../../data/api";
import useValidator from "../../../hooks/useValidator";
import ButtonCount from "../../../components/ButtonCount";
import { TableSearch } from "../../../components/Table/TableActions";
import Table from "../../../components/Table/Table";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import ReactPlayer from "react-player";
import { useUpload, useEditUpload } from "../../../hooks/useUpload";
// import { Link } from "react-router-dom";
// import useUpload from "../../../hooks/useUpload";
// import { LibraryItemType } from "../../../utils/DropDown";

// All Library item
const ALL_LIBRARY_ITEMS = "/api/v1/library";
// To Upload Item
const UPLOAD_ENDPOINT = "/api/v1/upload";

const AdminLibrary = () => {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [forBookSearch, setForBookSearch] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [isLibraryItemFetching, setIsLibraryItemFetching] = useState(false);
  const [allLibraryItem, setAllLibraryitem] = useState([]);
  const [currentRow, setCurrentRow] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [showBookOption, setShowBookOption] = useState(false);
  const [showVideoOption, setShowVideoOption] = useState(false);
  const [libraryItemvalidator, showLibraryItemValidator] = useValidator();
  const [editlibraryItemvalidator, showEditlibraryItemvalidator] =
    useValidator();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLibraryItemDeleting, setIsLibraryItemDeleting] = useState(false);
  const [addLibraryItemModal, setAddLibraryItemModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [currentItems, setCurrentItems] = useState([]);
  const [editLibraryItemModal, setEditLibraryItemModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [uploadForm, setUploadForm] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [isPdfModalOpen, setPdfModalOpen] = useState(false);
  const [editselectedFile, setEditSelectedFile] = useState("");
  const [isFileRemoved, setIsFileRemoved] = useState(false);
  const [helpModal, setHelpModal] = useState(false);

  // --| Card Pagination
  const itemsPerPage = 6;

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const [libraryForm, setLibraryForm] = useState({
    title: "",
    type: "",
    link: "",
    file: {},
    description: ""
  });

  const [editlibraryForm, setEditLibraryForm] = useState({
    title: "",
    type: "",
    file: {},
    link: "",
    description: ""
  });

  const handleHelpModal = () => {
    setHelpModal(!helpModal);
  };

  const handleCloseVideoModal = () => {
    setHelpModal(false);
  };

  // Handle button click to show/hide PDF modal
  const handleViewPdfClick = (row) => {
    setCurrentRow(row);
    setPdfModalOpen(true);
  };

  const closePdfModal = () => {
    setEditLibraryForm({ title: "", type: "", description: "", link: "", file: { upload_id: "", file_url: "" } });
    setPdfModalOpen(false);
  };

  // FOR FILE UPLOAD
  const handleFileChange = (e) => {
    // const { name } = e.target;
    if (e.target.files) setSelectedFile(e.target.files[0]);
    setUploadForm({
      ...uploadForm,
      file: e.target.files[0].name.replaceAll(" ", "-")
    });
  };

  const handleEditFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setEditSelectedFile(file);
    }
  };

  const handleEditRemoveFile = async () => {
    try {
      if (currentRow?.file?.upload_id) {
        const response = await httprequest.delete(
          `${UPLOAD_ENDPOINT}/${currentRow?.file?.upload_id}`
        );
        if (response.status === 200) {
          setLibraryForm((prevForm) => ({
            ...prevForm,
            file: {
              upload_id: "",
              file_url: ""
            }
          }));
          setIsFileRemoved(true);
          toast.success("File removed successfully.");
        } else {
          toast.error(response?.data?.message);
        }
      } else {
        toast.error("No file found to remove.");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const handleRemoveFile = () => setSelectedFile("");

  const handleLibraryChange = (e) => {
    const { name, value } = e.target;
    setLibraryForm({ ...libraryForm, [name]: value });
  };

  const handleEditLibraryChange = (e) => {
    const { name, value } = e.target;
    setEditLibraryForm({ ...editlibraryForm, [name]: value });
  };

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setLibraryForm({ ...libraryForm, type: value });
    setShowBookOption(e.target.value === "book");
    setShowVideoOption(e.target.value === "video");
  };

  const handleEditSelectChange = (e) => {
    const { value } = e.target;
    setEditLibraryForm({ ...editlibraryForm, type: value });
    setShowBookOption(e.target.value === "book");
    setShowVideoOption(e.target.value === "video");
  };

  const handleCloseEditLibraryModal = () => {
    setCurrentRow({});
    setEditLibraryItemModal(!editLibraryItemModal);
    setEditLibraryForm({
      ...editlibraryForm,
      title: "",
      type: "",
      file: {},
      link: "",
      description: ""
    });
  };

  const handleEditLibraryitemModal = (row) => {
    setCurrentRow(row);
    setEditLibraryForm({
      ...editlibraryForm,
      title: row?.title,
      description: row?.description,
      file: row?.file ? {
        upload_id: row.file?.upload_id,
        file_url: row?.file?.file_url
      } : {},
      type: row?.type,
      link: row?.link
    });
    setEditLibraryItemModal(!editLibraryItemModal);
  };

  const handleCloseEditLibraryItemModal = () => {
    setEditLibraryForm({ title: "", type: "", description: "", link: "", file: { upload_id: "", file_url: "" } });
    setEditLibraryItemModal(false);
  };

  const handleAddLibraryItemModal = () => {
    setAddLibraryItemModal(!addLibraryItemModal);
  };

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleCloseAddLibraryItemModal = () => {
    setLibraryForm({ title: "", type: "", description: "", file: {}, link: "" });
    setAddLibraryItemModal(false);
    setShowBookOption(false);
    setShowVideoOption(false);
  };

  // get all Library Item in the School
  const getAllLibraryItems = () => {
    setIsLibraryItemFetching(true);
    httprequest
      .get(ALL_LIBRARY_ITEMS)
      .then((res) => {
        setIsLibraryItemFetching(false);
        setAllLibraryitem(res?.data?.data);
        setSearchResult(res?.data?.data);
        setCurrentItems(res?.data?.data);
        setForBookSearch(
          res?.data?.data?.filter((allbooks) => allbooks?.type === "book")
        );
      })
      .catch((err) => {
        setIsLibraryItemFetching(false);
        toast.error(err?.response?.data);
      });
  };

  // -- Create Library item
  const handleCreateLibraryItem = async () => {
    if (libraryItemvalidator.allValid()) {
      setIsCreating(true);
      try {
        let updatedLibraryForm = { ...libraryForm };

        if (libraryForm?.type === "book") {
          const formData = new FormData();
          formData?.append("file", selectedFile);
          const response = await httprequest.post(
            `${UPLOAD_ENDPOINT}`,
            formData
          );
          if (response?.status === 200 && response?.data?.data) {
            const upload_id = response?.data?.data?.[0]?.upload_id;
            const file_url = response?.data?.data?.[0]?.file_url;

            updatedLibraryForm = {
              ...updatedLibraryForm,
              file: { upload_id, file_url }
            };
          } else {
            toast.error(response?.data?.message);
            setIsCreating(false);
          }
        }

        const res = await httprequest.post(
          ALL_LIBRARY_ITEMS,
          updatedLibraryForm
        );
        if (res.status === 201) {
          setIsCreating(false);
          toast.success(res?.data?.message);
          setLibraryForm({ title: "", type: "", description: "", file: {}, link: "" });
          handleCloseAddLibraryItemModal();
          getAllLibraryItems();
        } else {
          setIsCreating(false);
          toast.error("Failed to Create Library Item");
        }
      } catch (err) {
        toast.error(err?.response?.data?.message);
        setIsCreating(false);
      }
    } else {
      showLibraryItemValidator(true);
    }
  };

  // Handle Edit Library Item
  const handleEditLibrary = async () => {
    if (!editlibraryItemvalidator.allValid()) {
      showEditlibraryItemvalidator(true);

      return;
    }

    setIsEditLoading(true);

    try {
      const updatedLibraryForm = {
        title: editlibraryForm?.title,
        type: editlibraryForm?.type,
        description: editlibraryForm?.description,
        link: editlibraryForm?.link,
        file: editlibraryForm?.file ? {
          upload_id: editlibraryForm?.file?.upload_id,
          file_url: editlibraryForm?.file?.file_url
        } : {}
      };

      let response;

      // handle file related updates based on the type
      if (editlibraryForm?.type === "book") {
        if (isFileRemoved && editselectedFile) {
          response = await useUpload(editselectedFile);
        } else if (!isFileRemoved && editselectedFile) {
          response = currentRow?.file?.upload_id
            ? await useEditUpload(editselectedFile, currentRow?.file?.upload_id)
            : await useUpload(editselectedFile);
        }

        if (response && (response?.status !== 200 || !response?.data?.data)) {
          toast.error(response?.data?.message);
          setIsEditLoading(false);

          return;
        }

        if (response) {
          // Update form with new file information
          updatedLibraryForm.file = {
            upload_id: response?.data?.data?.[0]?.upload_id,
            file_url: response?.data?.data?.[0]?.file_url
          };
        }
      } else if (editlibraryForm?.type === "video") {
        updatedLibraryForm.file = {
          file_url: editlibraryForm?.link
        };
      } else {
        updatedLibraryForm.file = {
          upload_id: currentRow?.file?.upload_id,
          file_url: currentRow?.file?.file_url
        };
      }

      // send the updated form data for patch request
      const res = await httprequest.patch(`${ALL_LIBRARY_ITEMS}/${currentRow?.id}`, updatedLibraryForm);

      if (res.status === 200) {
        toast.success(res?.data?.message);
        getAllLibraryItems();
        setEditLibraryForm({ title: "", type: "", description: "", link: "", file: { upload_id: "", file_url: "" } });
        handleCloseEditLibraryModal();
        setIsFileRemoved(false);
      } else {
        toast.error("Failed to update the library item.");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setIsEditLoading(false);
    }
  };

  // Delete Event
  const handleDeleteEvent = () => {
    setIsLibraryItemDeleting(true);
    httprequest
      .delete(`${ALL_LIBRARY_ITEMS}/${currentRow?.id}`)
      .then((res) => {
        setIsLibraryItemDeleting(false);
        toast.success(res?.data?.message);
        handleCloseDeleteModal();
        getAllLibraryItems();
      })
      .catch((err) => {
        setIsLibraryItemDeleting(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const allVideos = allLibraryItem?.filter(
    (library) => library?.type === "video"
  );

  const filteredLibrary = allVideos.filter((e) => {
    const matchesSearch =
      e.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      e?.description?.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesTab = value === "video";

    return matchesSearch && matchesTab;
  });

  const totalPages = Math.ceil(filteredLibrary.length / itemsPerPage);

  const paginatedLibrary = filteredLibrary.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleVideoSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const NoLibraryFound = () => {
    return (
      <NotFounditem
        img={nosearch}
        title="No Video Found"
        subtitle="The Library that matches this query cannot be found"
      />
    );
  };

  useEffect(() => {
    setForBookSearch(allLibraryItem);
    setSearchResult(allLibraryItem);
    getAllLibraryItems();
  }, []);

  const columns = [
    {
      name: "Title",
      selector: (row) => row?.title
    },
    {
      name: "Description",
      selector: (row) => row?.description
    },
    {
      name: "Type",
      selector: (row) => row?.type
    },
    {
      name: "Created at",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Updated at",
      selector: (row) => row?.updated_at?.substring(0, 10)
    },
    {
      name: "",
      center: "true",
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          {/* <Link to={`/library/${row?.id}`} className="table-action-icon">
            <Icon icon="fluent:eye-16-regular" />
          </Link> */}
          <button
            className="table-action-icon"
            onClick={() => handleViewPdfClick(row)}
          >
            <Icon icon="fluent:eye-16-regular" />
          </button>
          <button
            className="table-action-icon"
            onClick={() => handleEditLibraryitemModal(row)}
          >
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            className="table-action-icon delete-icon"
            onClick={() => handleDeleteModal(row)}
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  // --| Filter all cards using name and description
  const handleLibraryItemSearch = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = allLibraryItem?.filter((data) => valArray?.every(
      (word) => data?.title?.toLowerCase().includes(word.toLowerCase()) ||
          data?.description?.toLowerCase().includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
    setForBookSearch(result);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setCurrentPage(1);
    if (newValue === "book") {
      setCurrentItems(
        allLibraryItem?.filter((library) => library?.type === "book")
      );
    } else {
      setCurrentItems(allLibraryItem);
    }
  };

  const RenderLibraryItem = () => (
    <Grid container spacing={2}>
      {paginatedLibrary?.map((item) => {
        // const itemImage = item?.file?.file_img || EventBanner;

        return (
          <Grid item lg={4} md={6} sm={6} xs={12} key={item.id}>
            <LibraryItemCard
              // libImage={itemImage}
              libTitle={item.title}
              libDesc={item.description}
              libLink={item.link}
              status={item.type}
              // viewClick={() => navigateEventDetail(event.id)}
              editClick={() => handleEditLibraryitemModal(item)}
              deleteClick={() => handleDeleteModal(item)}
            />
          </Grid>
        );
      })}
    </Grid>
  );

  // Object Destructuring from Library Form
  const { title, description, type } = libraryForm;

  return (
    <div>
      <PageTitle
        title="Library"
        subtitle="Enrich Your Learning: Add Essential Resources to Your Library"
        button={
          <div className="flex gap-10">
            <Button
              variant="primaryoutline"
              buttonSize="fluid"
              color="btnfontprimary"
              buttonName="?"
              onClick={handleHelpModal}
            />
            <Button
              variant="primary"
              buttonSize="fluid"
              color="btndefault"
              onClick={handleAddLibraryItemModal}
              buttonName="Add to your Library"
            />
          </div>
        }
      />
      <div className="page-content">
        <TabContext value={value}>
          {searchResult?.length > 0 && (
            <div className="tablist-container padding-left-16">
              <Tabs
                indicatorColor="none"
                className="overide-tablist"
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                <Tab label="All Library Items" value="" />
                <Tab label="Books" value="book" />
                <Tab label="Videos" value="video" />
              </Tabs>
            </div>
          )}
          <>
            {/* <CardSearch placeholder="Search here ..." onChange={handleLibrarySearch} /> */}
            <TabPanel value="" className="tabpanel-overide">
              {isLibraryItemFetching ? (
                <PageLoader />
              ) : (
                <>
                  <div className="button-count-container">
                    <ButtonCount
                      title="All Library Items"
                      count={allLibraryItem?.length}
                    />
                  </div>
                  <Table
                    data={searchResult}
                    columns={columns}
                    subHeader={true}
                    pagination
                    subHeaderComponent={
                      searchResult?.length > 0 && (
                        <TableSearch
                          placeholder="Search here..."
                          searchTableFunc={handleLibraryItemSearch}
                        />
                      )}
                  />
                </>
              )}
            </TabPanel>
            <TabPanel value="book" className="tabpanel-overide">
              {isLibraryItemFetching ? (
                <PageLoader />
              ) : (
                <>
                  <div className="button-count-container">
                    <ButtonCount
                      title="All Books"
                      count={forBookSearch?.length}
                    />
                  </div>
                  <Table
                    data={forBookSearch}
                    columns={columns}
                    subHeader={true}
                    pagination
                    subHeaderComponent={
                      forBookSearch?.length > 0 && (
                        <TableSearch
                          placeholder="Search here..."
                          searchTableFunc={handleLibraryItemSearch}
                        />
                      )

                    }
                  />
                </>
              )}
            </TabPanel>
            <TabPanel value="video" className="tabpanel-overide">
              {isLibraryItemFetching ? (
                <PageLoader />
              ) : (
                <>
                  <div className="button-count-container">
                    <ButtonCount
                      title="All Library Items"
                      count={paginatedLibrary?.length}
                    />
                  </div>
                  <div className="margin-bottom-10">
                    <CardSearch
                      placeholder="Search your Video"
                      onChange={handleVideoSearch}
                    />
                  </div>
                  {paginatedLibrary?.length > 1 ? (
                    <RenderLibraryItem />
                  ) : (
                    <NoLibraryFound />
                  )}

                  <div className="pagination-container">
                    <Pagination
                      count={Math.ceil(totalPages)}
                      page={currentPage}
                      onChange={handlePageChange}
                      variant="outlined"
                      shape="rounded"
                      color="primary"
                    />
                  </div>
                </>
              )}
            </TabPanel>
          </>
        </TabContext>
        <ToastContainer />
      </div>
      {/* Add Resources To your Library */}
      <Modal
        title="Add Resource to your library"
        subtitle={<div>Add Resource to your library.</div>}
        modalContent={
          <>
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Title"
                  required
                  margin="normal"
                  placeHolder="Enter Title"
                  inputType="text"
                  inputName="title"
                  onChange={handleLibraryChange}
                />
                {libraryItemvalidator.message("title", title, "required")}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup
                  required
                  label="Type"
                  children={
                    <select name="type" onChange={handleSelectChange}>
                      <option value="select">--- Select ---</option>
                      <option value="book">Book</option>
                      <option value="video">Video</option>
                    </select>
                  }
                />
                {libraryItemvalidator.message("type", type, "required")}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputGroup
                  label="Description"
                  required
                  margin="normal"
                  placeHolder="Enter Description"
                  inputType="text"
                  inputName="description"
                  onChange={handleLibraryChange}
                />
                {libraryItemvalidator.message("title", description, "required")}
              </Grid>
              {showVideoOption && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Link"
                      required
                      margin="normal"
                      placeHolder="Enter Video link"
                      inputType="text"
                      inputName="link"
                      onChange={handleLibraryChange}
                    />
                    {libraryItemvalidator.message(
                      "link",
                      libraryForm?.link,
                      "required"
                    )}
                  </Grid>
                </>
              )}
              {showBookOption && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <label>
                      <strong>Upload Image</strong>
                      <span className="input-required">*</span>
                    </label>
                    <div className={classes.pickercontainer}>
                      <input
                        type="file"
                        className={classes.picker}
                        onChange={handleFileChange}
                        name="file"
                        accept="image/jpeg,image/jpe,image/png,application/pdf"
                      />

                      <div className={classes.uploadflex}>
                        <div>
                          <Icon
                            icon="icon-park-outline:upload-two"
                            className={classes.uploadicon}
                          />
                        </div>
                        {selectedFile?.name ? (
                          <div>
                            <h2 className={classes.uploadtitle}>
                              {selectedFile?.name}
                            </h2>
                          </div>
                        ) : (
                          <div>
                            <h2 className={classes.uploadtitle}>
                              Tap to Upload
                            </h2>
                            <h6 className={classes.uploadsubtitle}>
                              PDF,PNG,JPG | 10MB max.
                            </h6>
                          </div>
                        )}
                      </div>
                      <div className={classes.rightpicker}>
                        {selectedFile?.name ? (
                          <div
                            className={classes.uploadbutton}
                            onClick={handleRemoveFile}
                          >
                            <Icon icon="zondicons:minus-solid" /> Remove File
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {libraryItemvalidator.message(
                      "file",
                      libraryForm?.file,
                      "required"
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </>
        }
        modalFooter={
          <>
            <div className="flex gap-10">
              <Button
                variant="primaryoutline"
                buttonName="Cancel"
                buttonSize="full"
                color="btnfontprimary"
                onClick={handleCloseAddLibraryItemModal}
              />
              <Button
                variant="primary"
                buttonName="Add to Library"
                buttonSize="full"
                color="btndefault"
                isLoading={isCreating}
                onClick={handleCreateLibraryItem}
              />
            </div>
          </>
        }
        isopen={addLibraryItemModal}
        closeModal={handleCloseAddLibraryItemModal}
      />
      {/* Edit Library Item */}
      <Modal
        title="Edit a resource in your library"
        subtitle={<div>Edit a resource in your library.</div>}
        modalContent={
          <>
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup
                  label="Title"
                  margin="normal"
                  required
                  placeHolder="Enter Title"
                  inputType="text"
                  inputName="title"
                  onChange={handleEditLibraryChange}
                  defaultValue={editlibraryForm?.title}
                />
                {editlibraryItemvalidator.message(
                  "title",
                  editlibraryForm?.title,
                  "required"
                )}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup
                  required
                  label="Type"
                  children={
                    <select
                      name="type"
                      disabled={editlibraryForm?.type === "video"}
                      onChange={handleEditSelectChange}
                      defaultValue={editlibraryForm?.type}
                    >
                      <option value="select">--- Select ---</option>
                      <option value="book">Book</option>
                      <option value="video">Video</option>
                    </select>
                  }
                />
                {editlibraryItemvalidator.message(
                  "type",
                  editlibraryForm?.type,
                  "required"
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputGroup
                  label="Description"
                  margin="normal"
                  required
                  placeHolder="Enter Description"
                  inputType="text"
                  inputName="description"
                  onChange={handleEditLibraryChange}
                  defaultValue={editlibraryForm?.description}
                />
                {editlibraryItemvalidator.message(
                  "description",
                  editlibraryForm?.description,
                  "required"
                )}
              </Grid>
              {editlibraryForm?.type === "video" && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Link"
                      required
                      margin="normal"
                      placeHolder="Enter Video link"
                      inputType="text"
                      inputName="link"
                      onChange={handleEditLibraryChange}
                      defaultValue={editlibraryForm?.link}
                    />
                    {/* {editlibraryItemvalidator.message("link", libraryForm?.link, "required")} */}
                  </Grid>
                </>
              )}
              {editlibraryForm?.type === "book" && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <label>
                      <strong>Upload Document</strong>
                      <span className="input-required">*</span>
                    </label>
                    <div className={classes.pickercontainer}>
                      <input
                        type="file"
                        className={classes.picker}
                        onChange={handleEditFileChange}
                        name="file"
                        accept="image/jpeg,image/jpg,image/png,.pdf"
                      />
                      <div className={classes.uploadflex}>
                        <div>
                          <Icon
                            icon="icon-park-outline:upload-two"
                            className={classes.uploadicon}
                          />
                        </div>

                        {editselectedFile ? (
                          // Display the new selected file name
                          <div>
                            <h2 className={classes.uploadtitle}>
                              {editselectedFile.name}
                            </h2>
                            <h6 className={classes.uploadsubtitle}>
                              Selected file for upload
                            </h6>
                          </div>
                        ) : currentRow?.file?.file_url && !isFileRemoved ? (
                          // Display the existing file if no new file is selected and the file is not removed
                          <div>
                            <h2 className={classes.uploadtitle}>
                              {currentRow?.file?.file_url.split("/").pop()}
                            </h2>
                            <h6 className={classes.uploadsubtitle}>
                              PDF,PNG,JPG | 10MB max.
                            </h6>
                          </div>
                        ) : (
                          // Default "Tap to Upload" message if no file exists
                          <div>
                            <h2 className={classes.uploadtitle}>
                              Tap to Upload
                            </h2>
                            <h6 className={classes.uploadsubtitle}>
                              PDF,PNG,JPG | 10MB max.
                            </h6>
                          </div>
                        )}
                      </div>

                      <div className={classes.rightpicker}>
                        {(editselectedFile || currentRow?.file?.file_url) &&
                        !isFileRemoved ? (
                            <div
                              className={classes.uploadbutton}
                              onClick={handleEditRemoveFile}
                            >
                              <Icon icon="zondicons:minus-solid" /> Remove File
                            </div>
                          ) : null}
                      </div>
                    </div>
                    {/* {editlibraryItemvalidator.message("file", libraryForm?.file, "required")} */}
                  </Grid>
                </>
              )}
            </Grid>
          </>
        }
        modalFooter={
          <>
            <div className="flex gap-10">
              <Button
                variant="primaryoutline"
                buttonName="Cancel"
                buttonSize="full"
                color="btnfontprimary"
                onClick={handleCloseEditLibraryItemModal}
              />
              <Button
                variant="primary"
                buttonName="Edit Library Item"
                buttonSize="full"
                color="btndefault"
                isLoading={isEditLoading}
                onClick={handleEditLibrary}
              />
            </div>
          </>
        }
        isopen={editLibraryItemModal}
        closeModal={handleCloseEditLibraryModal}
      />
      <Modal
        title={currentRow?.title}
        subtitle={currentRow?.description}
        modalContent={
          <>
            {currentRow?.type === "book" && (
              <>
                <div className={classes.fordownloadcont}>
                  <div className={`${classes.fordownload}`}>
                    <a
                      download
                      href={currentRow?.file?.file_url || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {currentRow?.file?.file_url ? `Download ${currentRow?.title}` : "upload a document" }
                    </a>
                  </div>
                </div>

                {currentRow?.file?.file_url && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                      <Viewer fileUrl={currentRow?.file?.file_url} />
                    </Worker>
                  </Grid>
                )}
              </>
            )}

            {currentRow?.type === "video" && currentRow?.link && (
              <div className={`${classes.imageheader}`}>
                <ReactPlayer
                  width="100%"
                  height="300px"
                  url={currentRow?.link}
                  style={{ borderRadius: "12px 12px 0px 0px" }}
                />
              </div>
            )}
          </>
        }
        // modalFooter={
        //   <Button
        //     variant="primary"
        //     buttonSize="full"
        //     color="btndefault"
        //     buttonName="Close"
        //     onClick={closePdfModal}
        //   />
        // }
        isopen={isPdfModalOpen}
        closeModal={closePdfModal}
      />
      {/* Delete Modal */}
      <Modal
        title="Delete Library Item"
        subtitle={
          <div>
            Are you sure you want to remove{" "}
            <span className="modal-name-highlight">{currentRow?.title}</span>.
            from one of your Library Item? Removing it will permanently clear
            this Item.`
          </div>
        }
        modalContent={<></>}
        modalFooter={
          <>
            <div className="flex gap-10">
              <Button
                variant="dangeroutline"
                buttonSize="full"
                color="btndanger"
                buttonName="Cancel"
                onClick={handleCloseDeleteModal}
              />
              <Button
                variant="danger"
                buttonName="Remove Library Item"
                buttonSize="full"
                color="btndefault"
                onClick={handleDeleteEvent}
                isLoading={isLibraryItemDeleting}
              />
            </div>
          </>
        }
        isopen={deleteModal}
        closeModal={handleCloseDeleteModal}
      />
      {/* Help Modal */}
      <Modal
        title="Need Help?"
        subtitle="Watch the Video Below on Library"
        modalContent={
          <>
            <ReactPlayer
              url="https://youtu.be/IQGYWW2cktM"
              playing={true}
              controls={true}
              width="100%"
            />
          </>
        }
        modalFooter={
          <>
            <Button
              variant="primary"
              buttonSize="full"
              color="btndefault"
              onClick={handleCloseVideoModal}
              buttonName="Close Video"
            />
          </>}
        isopen={helpModal}
        closeModal={handleCloseVideoModal}
      />
    </div>
  );
};

export default AdminLibrary;
