/* eslint-disable no-console */
import Layout from "../../../components/Layout";
import BreadCrumb from "../../../components/BreadCrumb";
import Button from "../../../components/Button";
import { useEffect, useState } from "react";
import useStyles from "../styles";
import useValidator from "../../../hooks/useValidator";
import { ToastContainer, toast } from "react-toastify";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import { Grid, Menu, MenuItem, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import InputGroup from "../../../components/FormComponent/InputGroup";
import { httprequest } from "../../../data/api";
import { useParams, useNavigate } from "react-router-dom";
import WidgetTitle from "../../dashboard/components/WidgetTitle/WidgetTitle";
import { Icon } from "@iconify/react/dist/iconify.js";
import TextAreaGroup from "../../../components/FormComponent/TextAreaGroup";
import PageLoader from "../../../components/PageLoader";

const ADMIN_CLASS_ENDPOINT = "/api/v1/class";
const CLASS_SUBJECT_ENDPOINT = "/api/v1/class-subject/";
const ASSIGNMENT_ENDPOINT = "/api/v1/assignment";
const WITHDRAW_ASSIGNMENT = "/api/v1/assignment/withdraw/";
const PUBLISH_ASSIGNMENT = "/api/v1/assignment/publish/";
const UPLOAD_ENDPOINT = "/api/v1/upload";
const TERM_ENDPOINT = "/api/v1/school-plan/term";
const SESSION_ENDPOINT = "/api/v1/school-plan/session";

const EditAssignment = () => {
  const [classData, setClassData] = useState([]);
  const { id } = useParams();
  const styles = useStyles();
  const navigate = useNavigate();
  // const [subjectData, setSubjectData] = useState([]);
  // const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isWithdrawing, setIsWithDrawing] = useState(false);
  const [isDraftSaving, setIsDraftSaving] = useState(false);
  const [isPublishingSaving, setIsPublishingSaving] = useState(false);
  const [allClassSubject, setAllClassSubject] = useState([]);
  const [currentAnchor, setCurrentAnchor] = useState("");
  const [disabledInputs, setDisabledInputs] = useState({});
  const [allTerm, setAllTerms] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [defaultSession, setDefaultSession] = useState({});
  const [aselectedTerm, setASelectedTerm] = useState("");
  // const [totalPoints, setTotalPoints] = useState(0);
  // const [questionImages, setQuestionImages] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [totalGrade, setTotalGrade] = useState(0);
  const [editassignmentValidator, showEditAssignmentValidator] = useValidator();

  const handleClick = (event, queInd) => {
    setAnchorEl(event.currentTarget);
    setCurrentAnchor(queInd);
  };
  const handleAdditionalQueClose = () => {
    setAnchorEl(null);
  };

  const isSmallScreen = useMediaQuery("(max-width: 700px)");

  const [editAssignmentForm, setEditAssignmentForm] = useState({
    subject_id: "",
    title: "",
    class_id: "",
    term_id: "",
    status: "",
    submission_date: "",
    publish_date: "",
    description: "",
    total_grade: "",
    instruction: "",
    questions: [
      {
        question_index: 1

      }
    ]
  });

  // This creates a new question
  const handleAdditionalQuestion = () => {
    const newQue = {
      question: "",
      question_index: editAssignmentForm?.questions?.length + 1,
      type: "",
      answer: [],
      file: {},
      required: false,
      grade_point: "",
      options: []
    };

    setEditAssignmentForm((prevForm) => ({
      ...prevForm,
      questions: [...(prevForm.questions || []), newQue]
    }));
  };

  // handle Get all Terms
  const getAllTerms = (Session_id) => {
    httprequest
      .get(TERM_ENDPOINT)
      .then((res) => {
        setAllTerms(res?.data?.data.filter((session_term) => session_term?.session_id === Session_id));
        // setActiveTerm(
        //   res?.data?.data.filter(
        //     (active_term) => active_term?.is_current === true
        //   )
        // );
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // --| Remove additional choice added as an option in a question
  const removeAdditionalChoice = (index, queInd) => {
    const newQueArr = [...editAssignmentForm.questions];
    const newChoiceArr = newQueArr[queInd]["options"]?.filter(
      (_item, ind) => ind !== index
    );
    newQueArr[queInd]["options"] = newChoiceArr;
    setEditAssignmentForm({ ...editAssignmentForm, questions: newQueArr });
  };

  const handleMultipleChoiceChange = (e, index, queInd) => {
    const { value } = e.target;

    const options = editAssignmentForm.questions[queInd]["options"];
    const newOptionArr = [...options];

    newOptionArr[index] = value;
    const newQueArr = [...editAssignmentForm.questions];
    newQueArr[queInd]["options"] = newOptionArr;
    setEditAssignmentForm({ ...editAssignmentForm, questions: newQueArr });
  };

  const removeQueDescription = (index) => {
    const newQuestionArr = [...editAssignmentForm.questions];
    delete newQuestionArr[index]["description"];
    setEditAssignmentForm({ ...editAssignmentForm, questions: newQuestionArr });
  };

  // Edit Correct Option Check of a Specific Question
  const handleCorrectChoiceChange = (e, optionIndex, queInd) => {
    const newQuestionArr = [...editAssignmentForm.questions];

    // Check the type for the specific question based on (queInd)
    if (newQuestionArr[queInd]?.type === "multiple_choice") {
      // Update the state with the new questions array
      newQuestionArr[queInd]["answer"] = [optionIndex];
    } else if (newQuestionArr[queInd]?.type === "checkbox") {
      // For checkbox ( one or multiple correct answers allowed)
      const answers = newQuestionArr[queInd]["answer"] || [];

      if (e.target.checked) {
        // Add the option index if checked
        answers.push(optionIndex);
      } else {
        // Remove the option index if unchecked
        const answerIndex = answers.indexOf(optionIndex);
        if (answerIndex > -1) {
          answers.splice(answerIndex, 1);
        }
      }

      // Update answer in the question array
      newQuestionArr[queInd]["answer"] = answers;
    }

    // Set the updated questions array
    setEditAssignmentForm({ ...editAssignmentForm, questions: newQuestionArr });
  };

  // Handle Session Change
  const handleEditSessionChange = (e) => {
    const { value } = e.target;
    getAllTerms(value);
  };

  // This adds an additional option to a specific question
  const handleAdditionalOptions = (queInd) => {
    const updatedQuestions = [...editAssignmentForm.questions];

    updatedQuestions[queInd].options = [...updatedQuestions[queInd].options, [" "]];

    setEditAssignmentForm({
      ...editAssignmentForm,
      questions: updatedQuestions
    });
  };

  // This Updates the value a specific question based on user input.
  const handleQueTypeChange = (e, index) => {
    const { name, value } = e.target;
    const updatedQuestions = [...editAssignmentForm.questions];
    updatedQuestions[index][name] = value;
    if (updatedQuestions[index].options.length === 0) {
      handleAdditionalOptions(index);
    }

    setEditAssignmentForm({
      ...editAssignmentForm,
      questions: updatedQuestions
    });
  };

  const toggleQueDescriptionTrue = () => {
    const updatedQuestions = [...editAssignmentForm.questions];
    updatedQuestions[currentAnchor]["description"] = "";
    setEditAssignmentForm({
      ...editAssignmentForm,
      questions: updatedQuestions
    });
  };

  const handleQueChange = (e, queInd) => {
    const { name, value } = e.target;
    const updatedQuestions = [...editAssignmentForm.questions];
    updatedQuestions[queInd][name] = value;

    setEditAssignmentForm({
      ...editAssignmentForm,
      questions: updatedQuestions
    });
  };

  // calculate the total grade
  const calculateTotalGrade = () => {
    const total = editAssignmentForm?.questions.reduce((sum, question) => {
      return sum + (parseFloat(question.grade_point) || 0);
    }, 0);

    setEditAssignmentForm({
      ...editAssignmentForm,
      total_grade: total
    });
    setTotalGrade(total);
  };

  // Handle Input of Question Point of a Specific Question
  const handleQuePointChange = (e, queInd) => {
    const { name, value } = e.target;
    const updatedQuestions = [...editAssignmentForm.questions];
    updatedQuestions[queInd][name] = value;

    setEditAssignmentForm({
      ...editAssignmentForm,
      questions: updatedQuestions
    });

    calculateTotalGrade();
  };

  useEffect(() => {
    calculateTotalGrade();
  }, [editAssignmentForm?.questions]);

  // Handle Question Description of a Specific Question
  const handleQueDescChange = (e, queInd) => {
    const { name, value } = e.target;
    const updatedQuestions = [...editAssignmentForm.questions];
    updatedQuestions[queInd][name] = value;

    setEditAssignmentForm({
      ...editAssignmentForm,
      questions: updatedQuestions
    });
  };

  // get All Sessions
  useEffect(() => {
    const getAllSession = async () => {
      try {
        const response = await httprequest.get(SESSION_ENDPOINT);
        const sessionData = response?.data?.data;
        setSessionData(sessionData);

        // Filter for sessions where terms is not null and match `editAssignmentForm.term_id`
        const matchedSessions = sessionData?.find((default_session) => {
          // Check if terms exist and filter based on the term_id match
          const hasMatchingTerm = default_session.terms?.some(term => term.id === editAssignmentForm?.term_id);

          return hasMatchingTerm;
        });
        setDefaultSession(matchedSessions);
      } catch (error) {
        console.error("Error fetching session data:", error);
      }
    };

    getAllSession();
  }, [editAssignmentForm?.term_id]);

  // Get An Assignment By ID
  const getAssignmentById = () => {
    setIsLoading(true);
    httprequest.get(`${ASSIGNMENT_ENDPOINT}/${id}`).then((res) => {
      // setAssignmentData(res?.data?.data);
      const data = res?.data?.data;
      setEditAssignmentForm({
        ...editAssignmentForm, subject_id: data?.subject?.id,
        title: data?.title,
        class_id: data?.class?.id,
        description: data?.description,
        total_grade: data?.total_grade,
        instruction: data?.instruction,
        submission_date: data?.submission_date,
        publish_date: data?.publish_date,
        term_id: data?.term?.id,
        status: data?.status,
        questions: data?.questions
      });
      // setEditdableData(res?.data?.data);
      setIsLoading(false);
    }).catch((err) => {
      toast.error(err?.response?.data);
      setIsLoading(false);
    });
  };

  // Handle Edit an Assignemnt
  const handleEditAssignment = () => {
    if (editassignmentValidator.allValid()) {
      setIsEditing(true);
      const formDataWithoutStatus = { ...editAssignmentForm };
      delete formDataWithoutStatus?.status;

      httprequest.patch(`${ASSIGNMENT_ENDPOINT}/${id}`, formDataWithoutStatus).then((res) => {
        setIsEditing(false);
        setTimeout(() => {
          navigate("/assignments");
        }, 2000);
        toast.success(res?.data?.message);
        // getAssignmentById();
      }).catch((err) => {
        setIsEditing(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      showEditAssignmentValidator(true);
    }
  };

  // tO WITHDRAW assignemnt
  const handleWithdrawAssignment = () => {
    setIsWithDrawing(true);
    httprequest.patch(`${WITHDRAW_ASSIGNMENT}${id}`, editAssignmentForm).then((res) => {
      setIsWithDrawing(false);
      toast.success(res?.data?.message);
      getAssignmentById();
    }).catch((err) => {
      setIsWithDrawing(false);
      toast.error(err?.response?.data?.message);
    });
  };

  // tO PUBLISH assignemnt
  const handlePublishAssignment = () => {
    setIsPublishingSaving(true);
    httprequest.patch(`${PUBLISH_ASSIGNMENT}${id}`, editAssignmentForm).then((res) => {
      setIsPublishingSaving(false);
      toast.success(res?.data?.message);
      getAssignmentById();
    }).catch((err) => {
      setIsPublishingSaving(false);
      toast.error(err?.response?.data?.message);
    });
  };

  const handleDraftAssignment = () => {
    setIsDraftSaving(true);
    httprequest.patch(`${ASSIGNMENT_ENDPOINT}${id}`, { ...editAssignmentForm, status: "draft" }).then((res) => {
      setIsDraftSaving(false);
      toast.success(res?.data?.message);
      getAssignmentById();
    }).catch((err) => {
      setIsDraftSaving(false);
      toast.error(err?.response?.data?.message);
    });
  };

  // --| Remove Additional Question that has been added
  const handleDeleteQuestion = (question_ind) => {
    const allquestions = editAssignmentForm?.questions;
    const questionToDelete = allquestions?.find((item) => item?.question_index === question_ind);

    const newQuestions = allquestions?.filter((item) => item?.question_index !== questionToDelete?.question_index);
    setEditAssignmentForm((prev) => ({
      ...prev,
      questions: newQuestions
    }));
  };

  const handleIconClick = (queInd) => {
    document.getElementById(`imageInput-${queInd}`).click();
  };

  const handleIconOptionClick = (i, queInd) => {
    document.getElementById(`imageInput-${i}${queInd}`).click();
  };

  // Handle image upload and update the specific question
  // const handleQuestionImageChange = (event, queInd) => {
  //   if (event?.target?.files?.length > 0) {
  //     const file = event.target.files[0];

  //     // Create a mock uploaded image details object (e.g., using a temporary URL or placeholder values)
  //     const uploadedImageDetails = {
  //       upload_id: "",
  //       file_url: URL.createObjectURL(file) // Temporary URL for preview
  //     };

  //     // Create a copy of the current question with the updated image details
  //     const updatedQuestion = {
  //       ...editAssignmentForm.questions[queInd],
  //       file: uploadedImageDetails
  //     };

  //     // Update the state without API calls
  //     setEditAssignmentForm((prevForm) => ({
  //       ...prevForm,
  //       questions: prevForm.questions.map((question, index) => index === queInd ? updatedQuestion : question
  //       )
  //     }));

  //     toast.success(`Image added to question ${queInd + 1}`);
  //   }
  // };

  const handleQuestionImageChange = async (event, queInd) => {
    if (event?.target?.files?.length > 0) {
      const file = event.target.files[0];

      // Prepare the image for upload
      const formData = new FormData();
      formData.append("file", file);

      try {
        // Upload the image
        const response = await httprequest.post(UPLOAD_ENDPOINT, formData);

        if (response?.status === 200 && response?.data?.data?.[0]?.file_url) {
          const uploadedImageDetails = {
            upload_id: response?.data?.data?.[0]?.upload_id,
            file_url: response?.data?.data?.[0]?.file_url
          };

          // Create a copy of the current question
          const updatedQuestion = {
            ...editAssignmentForm?.questions[queInd],
            file: uploadedImageDetails
          };

          // // Send only the updated question to the backend
          // await httprequest.patch(`${ASSIGNMENT_ENDPOINT}/${id}`, {
          //   ...editAssignmentForm,
          //   questions: [updatedQuestion] // This update the modified question
          // });

          // Update local state after successful upload
          setEditAssignmentForm((prevForm) => ({
            ...prevForm,
            questions: prevForm.questions.map((question, index) => index === queInd ? updatedQuestion : question
            )
          }));

          toast.success(`Image added to question ${queInd + 1}`);
        } else {
          toast.error(response?.data);
        }
      } catch (error) {
        toast.error(error?.response?.message);
      }
    }
  };

  // Remove a Question Image
  // const removeQueImage = async (queInd) => {
  //   const upload_id = editAssignmentForm.questions[queInd]?.file?.upload_id;

  //   if (!upload_id) {
  //     toast.error("No upload ID found for this question");

  //     return;
  //   }

  //   try {
  //     // This makes a DELETE request to remove the image from the database
  //     const response = await httprequest.delete(`${UPLOAD_ENDPOINT}/${upload_id}`);

  //     if (response?.status === 200) {
  //       // After Image is successfully removed, update editAssignmentForm to remove the image for the specific question
  //       setEditAssignmentForm((prevForm) => ({
  //         ...prevForm,
  //         questions: prevForm.questions.map((question, index) => index === queInd
  //           ? {
  //             ...question,
  //             file: { upload_id: "", file_url: "" }
  //           }
  //           : question
  //         )
  //       }));

  //       // Optionally, remove the image preview from the state
  //       toast.success(`Image removed from question ${queInd + 1}`);
  //     } else {
  //       toast.error(response?.data);
  //     }
  //   } catch (error) {
  //     toast.error(error?.response?.message);
  //   }
  // };

  const removeQueImage = (queInd) => {
    // Update editAssignmentForm to remove the image for the specific question without making an API call
    setEditAssignmentForm((prevForm) => ({
      ...prevForm,
      questions: prevForm.questions.map((question, index) => index === queInd
        ? {
          ...question,
          file: {}
        }
        : question
      )
    }));
    // Temporarily remove the image preview from the state
    toast.success(`Image removed from question ${queInd + 1}`);
  };

  // Handle Upload Image to an Option
  const handleOptionImageChange = async (event, optionIndex, questionIndex) => {
    if (event?.target?.files?.length > 0) {
      const file = event.target.files[0];

      // Disable the input while the file is being uploaded
      setDisabledInputs((prevDisabled) => ({
        ...prevDisabled,
        [questionIndex]: {
          ...prevDisabled[questionIndex],
          [optionIndex]: true
        }
      }));

      // Prepare to send the image to the upload endpoint
      const formData = new FormData();
      formData.append("file", file);

      try {
        // Upload the image
        const response = await httprequest.post(UPLOAD_ENDPOINT, formData);

        if (response?.status === 200 && response?.data?.data?.[0]?.file_url) {
          const uploadedImageDetails = {
            upload_id: response.data.data[0].upload_id,
            file_url: response.data.data[0].file_url
          };

          // Update only the specific option in the question at questionIndex
          const updatedOptions = editAssignmentForm.questions[questionIndex].options.map(
            (option, oIndex) => oIndex === optionIndex ? uploadedImageDetails : option
          );

          const updatedQuestion = {
            ...editAssignmentForm.questions[questionIndex],
            options: updatedOptions
          };

          // Update the editAssignmentForm state with the modified question
          setEditAssignmentForm((prevForm) => ({
            ...prevForm,
            questions: prevForm.questions.map((question, index) => index === questionIndex ? updatedQuestion : question
            )
          }));

          // API call to update only the specific option of the particular question
          await httprequest.patch(`${ASSIGNMENT_ENDPOINT}/${id}`, {
            ...editAssignmentForm,
            questions: [updatedQuestion]
          });

          toast.success(`Image added to option ${optionIndex + 1} in question ${questionIndex + 1}`);
        } else {
          toast.error(response?.data);
        }
      } catch (error) {
        toast.error(error?.response?.message);
      }
    }
  };

  // Delete an Image of an Option
  // const removeOptionImage = async (optionIndex, questionIndex) => {
  //   const upload_id = editAssignmentForm.questions[questionIndex]?.options?.[optionIndex]?.upload_id;

  //   if (!upload_id) {
  //     toast.error("No upload ID found for this option");

  //     return;
  //   }

  //   try {
  //     // Send DELETE request to remove the image
  //     const response = await httprequest.delete(`${UPLOAD_ENDPOINT}/${upload_id}`);

  //     if (response?.status === 200) {
  //       // Remove the image details from the specific option
  //       const updatedOptions = editAssignmentForm.questions[questionIndex].options.map(
  //         (option, oIndex) => oIndex === optionIndex ? { ...option, upload_id: "", file_url: "" } : option
  //       );

  //       // Create the updated question object with the modified option
  //       const updatedQuestion = {
  //         ...editAssignmentForm.questions[questionIndex],
  //         options: updatedOptions
  //       };

  //       await httprequest.patch(`${ASSIGNMENT_ENDPOINT}/${id}`, {
  //         ...editAssignmentForm,
  //         questions: [updatedQuestion]
  //       });

  //       // Update local state after the image is removed
  //       setEditAssignmentForm((prevForm) => ({
  //         ...prevForm,
  //         questions: prevForm.questions.map((question, index) => index === questionIndex ? updatedQuestion : question
  //         )
  //       }));

  //       toast.success(`Image removed from option ${optionIndex + 1} in question ${questionIndex + 1}`);
  //     } else {
  //       toast.error(response?.data);
  //     }
  //   } catch (error) {
  //     toast.error(error?.response?.message);
  //   }
  // };

  const removeOptionImage = (optionIndex, questionIndex) => {
    // Remove the image details from the specific option
    const updatedOptions = editAssignmentForm.questions[questionIndex].options.map(
      (option, oIndex) => oIndex === optionIndex ? { ...option, upload_id: "", file_url: "" } : option
    );
    // Create the updated question object with the modified option
    const updatedQuestion = {
      ...editAssignmentForm.questions[questionIndex],
      options: updatedOptions
    };
    // Update local state after the image is removed
    setEditAssignmentForm((prevForm) => ({
      ...prevForm,
      questions: prevForm.questions.map((question, index) => index === questionIndex ? updatedQuestion : question
      )
    }));
    toast.success(`Image removed from option ${optionIndex + 1} in question ${questionIndex + 1}`);
  };

  const getAllSubjectForClass = async (class_value) => {
    try {
      const res = await httprequest.get(`${CLASS_SUBJECT_ENDPOINT}${class_value}`);
      setAllClassSubject(res?.data?.data);
    } catch (err) {
      toast.error(err?.response?.data);
    }
  };

  // Handle Class Change
  const handleClassChange = (e) => {
    const { name, value } = e.target;
    setEditAssignmentForm({ ...editAssignmentForm, [name]: value });
    getAllSubjectForClass(value);
  };

  // --| Get all my Subject as a Admin
  // const getAllTeacherSubjects = () => {
  //   httprequest
  //     .get(`${CLASS_SUBJECT_ENDPOINT}${editAssignmentForm?.class_id}`)
  //     .then((res) => {
  //       setSubjectData(res?.data?.data);
  //     })
  //     .catch(() => {});
  // };

  // --| Get all classes as a school admin
  const getAllAdminClasses = () => {
    httprequest
      .get(ADMIN_CLASS_ENDPOINT)
      .then((res) => {
        setClassData(res?.data?.data);
      })
      .catch(() => {});
  };

  // This handles the Assignment Description input
  const handleEditAssignmentChange = (e) => {
    const { name, value } = e.target;

    setEditAssignmentForm({ ...editAssignmentForm, [name]: value });
  };

  const handleTermChange = (event) => {
    const selectedTermId = event.target.value;
    setASelectedTerm(selectedTermId);
  };

  useEffect(() => {
    getAllAdminClasses();
    // getAllTeacherSubjects();
    getAssignmentById();
    // getAllSession();
  }, []);

  useEffect(() => {
    if (editAssignmentForm?.class_id) {
      getAllSubjectForClass(editAssignmentForm?.class_id);
    }
  }, [editAssignmentForm?.class_id]);

  useEffect(() => {
    if (editAssignmentForm?.term_id) {
      setASelectedTerm(editAssignmentForm?.term_id);
    }
  }, [editAssignmentForm?.term_id]);

  useEffect(() => {
    if (defaultSession?.id) {
      getAllTerms(defaultSession?.id);
    }
  }, [defaultSession?.id]);

  const options = [
    // {
    //   value: "paragraph",
    //   text: "Paragraph",
    //   icon: <Icon icon="carbon:text-short-paragraph" width="25" height="25" />
    // },
    {
      value: "multiple_choice",
      text: "Multiple Choice",
      icon: <Icon icon="mdi:radiobox-marked" width="25" height="25" />
    },
    {
      value: "checkbox",
      text: "Checkbox",
      icon: <Icon icon="ci:checkbox-check" width="25" height="25" />
    }
    // {
    //   value: "dropdown",
    //   text: "Dropdown",
    //   icon: <Icon icon="tabler:select" width="25" height="25" />
    // },
    // {
    //   value: "file_upload",
    //   text: "File Upload",
    //   icon: <Icon icon="basil:cloud-upload-outline" width="25" height="25" />
    // },
    // {
    //   value: "date",
    //   text: "Date",
    //   icon: (
    //     <Icon
    //       icon="material-symbols-light:date-range-sharp"
    //       width="25"
    //       height="25"
    //     />
    //   )
    // },
    // {
    //   value: "time",
    //   text: "Time",
    //   icon: <Icon icon="mingcute:time-line" width="25" height="25" />
    // }
  ];

  // const { questions } = editAssignmentForm;

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/assignments"
                link_one_name="Assignments"
                active="Edit Assignment"
                description="Edit an assignment"
              />
            </div>
            <div className="flex gap-10">
              <Button
                variant="primaryoutline"
                buttonSize="fluid"
                color="btnfontprimary"
                onClick={
                  editAssignmentForm?.status === "published"
                    ? handleWithdrawAssignment
                    : (editAssignmentForm?.status === "withdrawn" || editAssignmentForm?.status === "draft")
                      ? handlePublishAssignment
                      : handleDraftAssignment
                }
                buttonName={
                  editAssignmentForm?.status === "published"
                    ? "Withdraw Assignment"
                    : (editAssignmentForm?.status === "withdrawn" || editAssignmentForm?.status === "draft")
                      ? "Publish Assignment"
                      : "Save as Draft"
                }
                isLoading={editAssignmentForm?.status === "published" ? isWithdrawing : (editAssignmentForm?.status === "withdrawn" || editAssignmentForm?.status === "draft") ? isPublishingSaving : isDraftSaving}
              />
              <Button
                variant="primary"
                buttonSize="small"
                color="btndefault"
                buttonName="Edit Assignment"
                isLoading={isEditing}
                onClick={handleEditAssignment}
              />
            </div>
          </div>
          <div className="page-content">
            {isLoading ? (
              <PageLoader />
            ) :
              <>
                <WidgetTitle
                  title="Class Information"
                />
                <div className={`${styles.assignmentsection} margin-bottom-14`}>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                      {editAssignmentForm?.class_id && (
                        <SelectGroup
                          label="Select Class"
                          required
                          children={
                            <select
                              name="class_id"
                              value={editAssignmentForm?.class_id}
                              onChange={handleClassChange}
                            >
                              <option value="">--- Select ---</option>
                              {classData?.map((clss) => {
                                return (
                                  <option key={clss?.id} value={clss?.id}>
                                    {clss?.class_name}
                                  </option>
                                );
                              })}
                            </select>
                          }
                        />
                      )}
                      {editassignmentValidator.message(
                        "Class Id",
                        editAssignmentForm?.class_id,
                        "required"
                      )}
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                      <SelectGroup
                        label="Select Subject"
                        required
                        children={
                          <select
                            name="subject_id"
                            value={editAssignmentForm?.subject_id}
                            onChange={handleEditAssignmentChange}
                          >
                            <option value="">--- Select ---</option>

                            {allClassSubject?.map((sub) => {
                              return (
                                <option key={sub?.id} value={sub?.subject_id}>
                                  {sub?.subject_name}
                                </option>
                              );
                            })}
                          </select>
                        }
                      />
                      {editassignmentValidator.message(
                        "Subject Id",
                        editAssignmentForm?.subject_id,
                        "required"
                      )}
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                      <InputGroup
                        label="Submission Date"
                        margin="normal"
                        inputType="date"
                        inputName="submission_date"
                        defaultValue={editAssignmentForm?.submission_date?.substring(0, 10)}
                        inputMin={new Date().toISOString().split("T")[0]}
                        onChange={handleEditAssignmentChange}
                        required
                      />
                      {editassignmentValidator.message(
                        "Submission Date",
                        editAssignmentForm?.submission_date,
                        "required"
                      )}
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                      {defaultSession?.id && (
                        <SelectGroup
                          label="Select Session"
                          required
                          children={
                            <select
                              onChange={handleEditSessionChange}
                              defaultValue={defaultSession?.id}
                            >
                              <option value="">---Select---</option>
                              {sessionData?.map((sesh) => {
                                return (
                                  <option key={sesh?.id} value={sesh?.id}>
                                    {sesh?.session}
                                  </option>
                                );
                              })}
                            </select>
                          }
                        />
                      )}
                      {/* {editassignmentValidator.message("Session", editAssignmentForm?.session, "required")} */}
                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                      {aselectedTerm && (
                        <SelectGroup
                          label="Select Term"
                          required
                          children={
                            <select
                              name="term_id"
                              onChange={handleTermChange}
                              value={aselectedTerm}
                            >
                              <option value="">--- Select ---</option>

                              {allTerm?.map((term) => {
                                return (
                                  <option key={term?.id} value={term?.id}>
                                    {term?.term}
                                  </option>
                                );
                              })}
                            </select>
                          }
                        />
                      )}
                      {editassignmentValidator.message(
                        "Term",
                        editAssignmentForm?.term_id,
                        "required"
                      )}
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                      <Tooltip title="If selected, assignment will be created; otherwise, saved as a draft">
                        <label className={styles.enterque}>Published Date<span className="input-required"> *</span></label>
                      </Tooltip>
                      <InputGroup
                        margin="normal"
                        inputType="date"
                        inputName="publish_date"
                        defaultValue={editAssignmentForm?.publish_date.substring(0, 10)}
                        inputMin={new Date().toISOString().split("T")[0]}
                        onChange={handleEditAssignmentChange}
                      />
                      {editassignmentValidator.message(
                        "Publish Date",
                        editAssignmentForm?.publish_date,
                        "required"
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div className={`${styles.assignmentsection} margin-bottom-14`}>
                  <Grid container spacing={2}>
                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <InputGroup
                        label="Assignment Title"
                        margin="normal"
                        inputType="text"
                        defaultValue={editAssignmentForm?.title}
                        inputName="title"
                        onChange={handleEditAssignmentChange}
                        required
                      />
                      {editassignmentValidator.message(
                        "title",
                        editAssignmentForm?.title,
                        "required"
                      )}
                    </Grid>
                    <Grid item lg={2} md={12} sm={12} xs={12}>
                      <InputGroup
                        label="Total Grade"
                        margin="normal"
                        inputType="text"
                        inputName="total_grade"
                        value={totalGrade}
                        disabled
                        // onChange={handleAssignmentChange}
                        required
                      />
                      {editassignmentValidator.message(
                        "total_grade",
                        editAssignmentForm?.total_grade,
                        "required"
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className={styles.inputmargin}>
                        <TextAreaGroup
                          label="Assignment Instruction"
                          inputName="instruction"
                          margin="normal"
                          defaultValue={editAssignmentForm?.instruction}
                          placeholder=""
                          onChange={handleEditAssignmentChange}
                        />
                      </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className={styles.inputmargin}>
                        <TextAreaGroup
                          label="Description"
                          inputName="description"
                          defaultValue={editAssignmentForm?.description}
                          margin="normal"
                          placeholder=""
                          onChange={handleEditAssignmentChange}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={styles.assignmentsection}>
                  <Grid container spacing={2}>
                    {editAssignmentForm?.questions?.map((question, queInd) => (
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12} key={queInd}>
                          <div className={styles.quesaction}>
                            <label className={styles.enterque}> Question {queInd + 1}<span className="input-required"> *</span></label>
                            <div>
                              {editAssignmentForm?.questions?.length > 1 ? (
                                <>
                                  <Icon
                                    icon="material-symbols:delete-outline"
                                    width="20"
                                    height="20"
                                    className="delete-icon"
                                    onClick={() => handleDeleteQuestion(question?.question_index, queInd)}
                                  />
                                </>
                              ) : null}
                            </div>
                          </div>
                          <div className={styles.textandimgmain}>
                            <div className={styles.textandimg}>
                              <input
                                type="text"
                                placeholder="Type Question"
                                name="question"
                                value={question?.question}
                                onChange={(e) => {
                                  handleQueChange(e, queInd);
                                }}
                              />
                              {editassignmentValidator.message(
                                `Question ${queInd + 1}`,
                                editAssignmentForm?.questions[queInd]?.question,
                                "required"
                              )}
                              <div onClick={() => handleIconClick(queInd)} className={styles.queimg}>
                                <Icon icon="basil:image-outline" width="20" height="20" style={{ color: "#858585" }} />
                                <input
                                  type="file"
                                  accept="image/*"
                                  id={`imageInput-${queInd}`}
                                  multiple
                                  onChange={(e) => handleQuestionImageChange(e, queInd)}
                                  style={{ display: "none" }}
                                />
                              </div>
                              {/* Image preview */}
                              <div className={styles.preiewcont}>
                                {editAssignmentForm?.questions[queInd]?.file?.file_url && (
                                  <>
                                    <img
                                      src={editAssignmentForm?.questions[queInd]?.file?.file_url}
                                      alt={`Selected for question ${queInd + 1}`}
                                    />
                                    <Icon
                                      icon="material-symbols:delete-outline"
                                      width="20"
                                      height="20"
                                      className="delete-icon"
                                      onClick={() => removeQueImage(queInd)}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </Grid>
                        {/* For Question Description */}
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          {"description" in question ? (
                            <div className="margin-top full-width">
                              <div className=" flex gap-10 justify-items-space">
                                <label className={styles.enterque}>Question Description</label>
                                <div>
                                  <div
                                    className="delete-icon"
                                    onClick={() => removeQueDescription(queInd)}
                                  >
                                    <Icon
                                      icon="ooui:close"
                                      width="20"
                                      height="20"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={styles.textandimgmain}>
                                <div className={styles.textandimg}>
                                  <input
                                    type="text"
                                    placeHolder="Enter Description"
                                    name="description"
                                    value={question?.description}
                                    onChange={(e) => handleQueDescChange(e, queInd)}
                                  />
                                  <></>
                                  <div onClick={handleIconClick} className={styles.queimg}>
                                    <Icon icon="basil:image-outline" width="20" height="20" style={{ color: "#858585" }} />
                                    <input
                                      type="file"
                                      accept="image/*"
                                      id="imageInput"
                                      multiple
                                      disabled
                                      onChange={handleOptionImageChange}
                                      style={{ display: "none" }}
                                    />
                                  </div>
                                  {/* Image preview */}
                                  {/* <div className={styles.preiewoptioncont}>
                                    {optionimages.map((optionimage, index) => (
                                      <img
                                        key={index}
                                        src={optionimage}
                                        alt={`Selected ${index}`}
                                      />
                                    ))}
                                  </div> */}
                                </div>
                              </div>
                              {/* <div className="width-full">
                                <InputGroup
                                  label="Question Description"
                                  margin="normal"
                                  placeHolder="Enter Description"
                                  inputType="text"
                                  value={question?.description}
                                  inputName="description"
                                  onChange={(e) => handleQueDescChange(e, queInd)}
                                />
                              </div> */}
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputGroup
                            className={styles.pointsInput}
                            label="Question Point"
                            inputType="number"
                            inputMin={1}
                            required
                            inputName="grade_point"
                            placeHolder="Enter Question Points"
                            value={question?.grade_point}
                            onChange={(e) => {
                              handleQuePointChange(e, queInd);
                            }}
                          />
                          {editassignmentValidator.message(
                            `Question ${queInd + 1} Point`,
                            editAssignmentForm?.questions[queInd]?.grade_point,
                            "required"
                          )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <SelectGroup
                            label="Add Answer Type"
                            required
                            children={
                              <select
                                name="type"
                                value={question?.type}
                                onChange={(e) => handleQueTypeChange(e, queInd)}
                              >
                                <option value="">--- Select ---</option>
                                {options?.map((label) => {
                                  return (
                                    <option
                                      key={label?.key}
                                      value={label?.value}
                                    >
                                      <Icon
                                        className={`${styles.add_question_color}`}
                                        icon="flat-color-icons:plus"
                                        width="24"
                                        height="24"
                                      />
                                      {label?.text}
                                    </option>
                                  );
                                })}
                              </select>
                            }
                          />
                          {editassignmentValidator.message(
                            `Question ${queInd + 1} Type`,
                            editAssignmentForm?.questions[queInd]?.type,
                            "required"
                          )}
                        </Grid>
                        {/* Checkbox */}
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          {question?.type === "checkbox" ? (
                            <>
                              <Grid container spacing={2} mt={1}>
                                {question?.options?.map((checkbox, i) => {
                                  return (
                                    <Grid
                                      item
                                      lg={6}
                                      md={6}
                                      sm={12}
                                      xs={12}
                                      key={i + 1}
                                    >
                                      <div>
                                        <div className=" flex gap-10 justify-items-space">
                                          <label className={styles.enterque}>Option {i + 1}<span className="input-required"> *</span></label>
                                          {/* if checkbox option is more thatn 1, show close*/}
                                          {question?.options?.length > 1 ? (
                                            <Icon
                                              onClick={() => {
                                                removeAdditionalChoice(i, queInd);
                                              }}
                                              className="delete-icon"
                                              icon="ooui:close"
                                              width="20"
                                              height="20"
                                            />
                                          ) : null}
                                        </div>
                                        <div className={styles.textandimgmain}>
                                          <div className={styles.textandimg}>
                                            <input
                                              type="text"
                                              placeholder="Enter option"
                                              name="question"
                                              value={checkbox?.file_url ? "" : checkbox}
                                              disabled={checkbox?.file_url || disabledInputs[queInd]?.[i]}
                                              onChange={(e) => handleMultipleChoiceChange(e, i, queInd)}
                                            />
                                            {/* {que?.type === "paragraph" ? (
                                              <div className={styles.inputmargin}>
                                                <TextAreaGroup
                                                  margin="normal"
                                                  placeholder=""
                                                // disabled
                                                />
                                              </div>
                                            ) : null} */}
                                            <></>
                                            <div onClick={() => handleIconOptionClick(i, queInd)} className={styles.queimg}>
                                              <Icon icon="basil:image-outline" width="20" height="20" style={{ color: "#858585" }} />
                                              <input
                                                type="file"
                                                accept=".jpg, .jpeg, .png"
                                                id={`imageInput-${i}${queInd}`}
                                                // multiple
                                                onChange={(e) => handleOptionImageChange(e, i, queInd)}
                                                style={{ display: "none" }}
                                              />
                                            </div>
                                            {/* Image preview */}
                                            <div className={styles.preiewoptioncont}>
                                              {checkbox?.file_url && (
                                                <>
                                                  <img
                                                    src={checkbox.file_url}
                                                    alt={`Option ${i + 1}`}
                                                  />
                                                  <Icon
                                                    icon="material-symbols:delete-outline"
                                                    width="20"
                                                    height="20"
                                                    className="delete-icon"
                                                    onClick={() => removeOptionImage(i, queInd)}
                                                  />
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        {editassignmentValidator.message(
                                          `Option ${i + 1}`,
                                          editAssignmentForm?.questions[queInd]?.options[i],
                                          "required"
                                        )}
                                        <div className="text-align-left">
                                          <div
                                            className="flex gap-10 align-items-center justify-items-space margin-top-5 "
                                          >
                                            <div className={styles.correctcontainer}>
                                              <input
                                                type="checkbox"
                                                name="answer"
                                                value={i}
                                                id={`correct-${queInd}-${i}`}
                                                defaultChecked={editAssignmentForm?.questions[queInd]?.answer?.includes(i)}
                                                onChange={(e) => handleCorrectChoiceChange(e, i, queInd)}
                                              />
                                              <label
                                                className={styles.correctlabel}
                                                htmlFor={`correct-${queInd}-${i}`}
                                              >
                                              is correct
                                              </label>
                                            </div>
                                          </div>
                                          {editassignmentValidator.message(
                                            `question ${queInd + 1} answer`,
                                            editAssignmentForm?.questions[queInd]["answer"].length > 0 ? editAssignmentForm?.questions[queInd] : null,
                                            "required"
                                          )}
                                        </div>
                                      </div>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                              <div
                                className={styles.addoption}
                                onClick={() => handleAdditionalOptions(queInd)}
                              >
                                <Icon
                                  icon="octicon:feed-plus-16"
                                  width="14"
                                  height="14"
                                />
                                <p>Add another checkbox</p>
                              </div>
                            </>
                          ) :
                            <>
                              {/* Type === multiple choice */}
                              <Grid container spacing={2} mt={1}>
                                {question?.options?.map((radio, i) => {
                                  return (
                                    <Grid
                                      item
                                      lg={6}
                                      md={6}
                                      sm={12}
                                      xs={12}
                                      key={i + 1}
                                    >
                                      <div>
                                        <div className=" flex gap-10 justify-items-space">
                                          <label className={styles.enterque}>Option {i + 1} <span className="input-required"> * </span></label>
                                          {/* if checkbox option is more thatn 1, show close*/}
                                          {question?.options?.length > 1 ? (
                                            <Icon
                                              onClick={() => {
                                                removeAdditionalChoice(i, queInd);
                                              }}
                                              className="delete-icon"
                                              icon="ooui:close"
                                              width="20"
                                              height="20"
                                            />
                                          ) : null}
                                        </div>
                                        <div className={styles.textandimgmain}>
                                          <div className={styles.textandimg}>
                                            <input
                                              type="text"
                                              placeholder="Enter option"
                                              name="question"
                                              disabled={radio?.file_url || disabledInputs[queInd]?.[i]}
                                              value={radio?.file_url ? "" : radio}
                                              onChange={(e) => handleMultipleChoiceChange(e, i, queInd)}
                                            />
                                            <></>
                                            <div onClick={() => handleIconOptionClick(i, queInd)} className={styles.queimg}>
                                              <Icon icon="basil:image-outline" width="20" height="20" style={{ color: "#858585" }} />
                                              <input
                                                type="file"
                                                accept="image/*"
                                                id={`imageInput-${i}${queInd}`}
                                                // multiple
                                                onChange={(e) => handleOptionImageChange(e, i, queInd)}
                                                style={{ display: "none" }}
                                              />
                                            </div>
                                            {/* Image preview */}
                                            <div className={styles.preiewoptioncont}>
                                              {radio?.file_url && (
                                                <>
                                                  <img
                                                    src={radio.file_url}
                                                    alt={`Option ${i + 1}`}
                                                  />
                                                  <Icon
                                                    icon="material-symbols:delete-outline"
                                                    width="20"
                                                    height="20"
                                                    className="delete-icon"
                                                    onClick={() => removeOptionImage(i, queInd)}
                                                  />
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        {editassignmentValidator.message(
                                          `Option ${i + 1}`,
                                          editAssignmentForm?.questions[queInd]?.options[i],
                                          "required"
                                        )}
                                        <div className="text-align-left">
                                          <div
                                            className="flex gap-10 align-items-center justify-items-space margin-top-5 "
                                          >
                                            <div className={styles.correctcontainer}>
                                              <input
                                                type="radio"
                                                name={`answer-${queInd}`}
                                                value={i}
                                                id={`correct-${queInd}-${i}`}
                                                defaultChecked={editAssignmentForm?.questions[queInd]?.answer?.includes(i)}
                                                onChange={(e) => handleCorrectChoiceChange(e, i, queInd)}
                                              />
                                              <label
                                                className={styles.correctlabel}
                                                htmlFor={`correct-${queInd}-${i}`}
                                              >
                                          is correct
                                              </label>
                                            </div>
                                          </div>
                                          {editassignmentValidator.message(
                                            `question ${queInd + 1} answer`,
                                            editAssignmentForm?.questions[queInd]["answer"].length > 0 ? editAssignmentForm?.questions[queInd] : null,
                                            "required"
                                          )}
                                        </div>
                                      </div>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                              <div
                                className={styles.addoption}
                                onClick={() => handleAdditionalOptions(queInd)}
                              >
                                <Icon
                                  icon="octicon:feed-plus-16"
                                  width="14"
                                  height="14"
                                />
                                <p>Add another option</p>
                              </div>
                            </>}
                        </Grid>
                        <div className={styles.editquestionfooter}>
                          <div className="flex gap-10 align-items-center justifyRight">
                            {/* Conditionally render the IconButton only if there is no description */}
                            {!question?.description && (
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? "long-menu" : null}
                                aria-expanded={open ? "true" : null}
                                aria-haspopup="true"
                                onClick={(e) => handleClick(e, queInd)}
                              >
                                <Icon
                                  icon="radix-icons:dots-vertical"
                                  width="20"
                                  height="20"
                                />
                                <label className={styles.enterque}>More</label>
                              </IconButton>
                            )}
                          </div>
                        </div>
                        <Menu
                          id="basic-menu"
                          MenuListProps={{
                            "aria-labelledby": "basic-button"
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleAdditionalQueClose}
                        >
                          <MenuItem
                            onClick={() => {
                              toggleQueDescriptionTrue();
                              handleAdditionalQueClose();
                            }}
                          >
                          Add Description on Question
                          </MenuItem>
                        </Menu>
                      </>
                    ))}
                  </Grid>
                  <div
                    className={styles.addque}
                  >
                    <Icon icon="octicon:feed-plus-16" width="16" height="16" />
                    <p onClick={handleAdditionalQuestion}>Add question</p>
                  </div>
                  <div className={styles.btnwrapper}>
                    <Button
                      variant="primaryoutline"
                      buttonSize={isSmallScreen ? "full" : "fluid"}
                      color="btnfontprimary"
                      onClick={
                        editAssignmentForm?.status === "published"
                          ? handleWithdrawAssignment
                          : (editAssignmentForm?.status === "withdrawn" || editAssignmentForm?.status === "draft")
                            ? handlePublishAssignment
                            : handleDraftAssignment
                      }
                      buttonName={
                        editAssignmentForm?.status === "published"
                          ? "Withdraw Assignment"
                          : (editAssignmentForm?.status === "withdrawn" || editAssignmentForm?.status === "draft")
                            ? "Publish Assignment"
                            : "Save as Draft"
                      }
                      isLoading={editAssignmentForm?.status === "published" ? isWithdrawing : (editAssignmentForm?.status === "withdrawn" || editAssignmentForm?.status === "draft") ? isPublishingSaving : isDraftSaving}
                    />
                    <Button
                      variant="primary"
                      buttonSize={isSmallScreen ? "full" : "fluid"}
                      color="btndefault"
                      buttonName="Edit Assignment"
                      isLoading={isEditing}
                      onClick={handleEditAssignment}
                    />
                  </div>
                </div>
              </>
            }
          </div>
          <ToastContainer />
        </>
      }
    />
  );
};

export default EditAssignment;
