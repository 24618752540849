import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  // --| actve styling theme in use
  subjectdivide: {
    display: "flex",
    gap: "1.5rem",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
      gap: "0.2rem"
    }
  },

  subject_divide_items: {
    width: "100%"
  },

  extrasubject: {
    display: "flex",
    alignItems: "center",
    gap: "0.2rem",
    padding: "1rem 0rem 1rem 0rem"
  },

  subject_add: {
    fontWeight: "500",
    fontSize: "14px",
    color: theme.palette.primary.main,
    marginBottom: "0px"
  },

  addicon: {
    color: theme.palette.primary.main
  },

  quoteContainer: {
    maxWidth: "70%",
    minWidth: 329,
    display: "flex",
    flexDirection: "column"
  },

  quoteText: {
    fontWeight: 400,
    fontSize: 16,
    marginTop: 5,
    marginBottom: 0,
    maxWidth: "70%"
  },

  quoteAuthor: {
    fontWeight: 400,
    fontSize: 16,
    marginTop: 5,
    marginBottom: 0,
    alignSelf: "end",
    color: theme.palette.primary.main
  },

  widgetlink: {
    fontWeight: "bold",
    fontSize: 16,
    letterSpacing: "-0.5px",
    textDecoration: "none",
    color: theme.palette.primary.main,
    display: "flex",
    gap: "5px"
  },

  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1)
  },
  progressSection: {
    marginBottom: theme.spacing(1)
  },
  progressTitle: {
    marginBottom: theme.spacing(2)
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: "rgb(236, 236, 236)"
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1)
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between"
  },
  tableWidget: {
    overflowX: "auto"
  },
  progressBarPrimary: {
    backgroundColor: theme.palette.primary.main
  },
  progressBarWarning: {
    backgroundColor: theme.palette.warning.main
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2)
  },
  legendElementText: {
    marginLeft: theme.spacing(1)
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%"
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2)
  },
  serverOverviewElementChartWrapper: {
    width: "100%"
  },
  mainChartBody: {
    overflowX: "auto"
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap"
    }
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    }
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3)
  },
  mainChartSelectRoot: {
    borderColor: `${theme.palette.text.hint}80 !important`
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25
  },
  mainChartLegentElement: {
    fontSize: "18px !important",
    marginLeft: theme.spacing(1)
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: "#fff"
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: "#fff"
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff"
  },
  calendar: {
    border: "1px solid #E4E7EC",
    padding: "16px",
    borderRadius: "16px"
  },
  quickcont: {
    background: "white",
    borderRadius: "10px",
    border: "1px solid #E4E7EC"
  },
  quickcardinfo: {
    padding: "25px 36px",
    background: "white",
    borderRadius: "10px",
    border: "1px solid #E4E7EC",
    marginBottom: "24px",
    "@media (max-width: 550px)": {
      padding: "25px 14px"
    }
  },
  attendcardinfo: {
    padding: "25px 36px",
    background: "white",
    borderRadius: "10px",
    border: "1px solid #E4E7EC",
    marginBottom: "24px",
    height: "297px",
    paddingTop: "2rem",
    "@media (max-width: 600px)": {
      padding: "25px 14px",
      paddingLeft: "2rem",
      height: "auto"
    }
  },
  events: {
    paddingBlock: "12px",
    borderRadius: "10px, 10px, 0px, 0px",
    border: "1px solid #E4E7EC",
    background: "#FFFFFF",
    overflowX: "scroll",
    "& hr": {
      marginBlock: "0px",
      border: "1px solid #E4E7EC"
    },
    "& h3": {
      marginBlock: "10px",
      textAlign: "right",
      paddingRight: "16px",
      cursor: "pointer"
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#343434",
      marginBlock: 0
    },
    "& h4": {
      fontWeight: "700",
      fontSize: "20px",
      color: "#0065FF",
      marginBlock: 0
    }
  },
  notfound: {
    maxWidth: "300px",
    marginTop: "18px",
    margin: "auto"
  },
  notice: {
    paddingBlock: "12px",
    borderRadius: "10px",
    background: "#FFFFFF",
    height: "245px",
    // overflowY: "scroll",
    "& hr": {
      marginBlock: "0px",
      border: "1px solid #E4E7EC"
    },
    "& h3": {
      marginBlock: "5px",
      textAlign: "right",
      paddingRight: "16px"
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#343434",
      marginBlock: 0
    },
    "& h4": {
      // fontWeight: "700",
      // fontSize: "20px",
      // color: "#0065FF",
      marginBlock: 0
    }
  },
  period: {
    paddingBlock: "12px",
    borderRadius: "10px",
    border: "1px solid #E4E7EC",
    background: "#FFFFFF",
    height: "fitContent",
    // overflowY: "scroll",
    "& hr": {
      marginBlock: "0px",
      border: "1px solid #E4E7EC"
    },
    "& h3": {
      marginBlock: "5px",
      textAlign: "right",
      paddingRight: "16px"
    }
  },
  leavedetails: {
    background: "#FFFFFF",
    // paddingBlock: "9px",
    height: "190px",
    borderRadius: "0px 0px 10px 10px",
    border: "1px solid #E4E7EC",
    "& h3": {
      marginBlock: "0px",
      textAlign: "right",
      paddingRight: "16px",
      cursor: "pointer"
    },
    "& h4": {
      fontWeight: "700",
      fontSize: "20px",
      color: "#0065FF",
      marginBlock: 0
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#343434",
      marginBlock: 0
    }
  },
  leave: {
    position: "relative",
    paddingBlock: "12px",
    borderRadius: "10px 10px 0px 0px",
    border: "1px solid #E4E7EC",
    background: "#FFFFFF",
    // height: "245px",
    overflowY: "scroll",
    "& hr": {
      marginBlock: "0px",
      border: "1px solid #E4E7EC"
    },
    "& h3": {
      marginBlock: "0px",
      textAlign: "right",
      paddingRight: "16px",
      cursor: "pointer"
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#343434",
      marginBlock: 0
    },
    "& h4": {
      fontWeight: "700",
      fontSize: "20px",
      color: "#0065FF",
      marginBlock: 0
    }
  },
  noticeitem: {
    marginInline: "10px",
    marginTop: "10px"
  },
  pendingleaves: {
    position: "relative",
    height: "180px",
    // paddingBottom: "10px",
    overflowY: "scroll"
  },
  static: {
    position: "sticky",
    cursor: "pointer",
    zIndex: "10"
  },
  timedot: {
    color: theme.palette.primary.main
  },
  chrono: {
    marginBlock: "50px",
    "@media (max-width: 650px)": {
      maxWidth: "300px",
      height: "300px",
      overflowY: "scroll",
      margin: "auto",
      paddingInline: "16px"
    }
  },
  periodcont: {
    padding: "8px 16px",
    background: "#E3EFFC",
    borderRadius: "5px",
    "& h4": {
      textAlign: "left",
      color: "#667185",
      fontSize: "12px",
      fontWeight: "400",
      marginTop: "5px"
    }
  },
  timecont: {
    padding: "5px",
    paddingInline: "9px 3px",
    borderRadius: "5px",
    width: "80%",
    "& p": {
      fontSize: "12px",
      fontWeight: "500",
      color: "white",
      marginBlock: "5px"
    }
  },
  periodoverflow: {
    wrap: "nowrap",
    overflowX: "auto"
  },
  periodclasses: {
    margin: "16px 23px",
    overflowX: "auto"
  },
  perioditem: {
    minWidth: "220px"
  },
  preriodflex: {
    display: "flex",
    gap: "16px"
  },
  viewBanner: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "30px",
    height: "300px",
    width: "100%",
    borderRadius: "20px"
  },
  erroritems: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "400px",
    margin: "auto",
    "& p": {
      color: "#6C737F",
      fontSize: "14px",
      fontWeight: "400",
      marginTop: "0px"
    },
    "& h4": {
      color: "black",
      fontSize: "18px",
      marginBlock: "0px"
    }
  },
  errorimg: {
    width: "100px",
    height: "100px"
  },
  errorspan: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }
}));
