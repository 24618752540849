import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import InputGroup from "../../components/FormComponent/InputGroup";
import logo from "../../assets/images/logo.svg";
import loginBG from "../../assets/images/signupBG.png";
import { httprequest } from "../../data/api";
import Button from "../../components/Button";
import useValidator from "../../hooks/useValidator";
import SelectGroup from "../../components/FormComponent/SelectGroup";
import { ToastContainer, toast } from "react-toastify";
import { Countries } from "../../utils/DropDown";
import { Icon } from "@iconify/react";
// --| Context files
const SIGNUP_ENDPOINT = "/api/v1/signup";
// const COUNTRIES_API = "https://countriesnow.space/api/v0.1/countries/iso";

const Signup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const customValidator = {
    password: {
      message:
        "Password should be at least 8 characters, contain a lowercase, uppercase and a number",
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(
          val,
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\w\W]{8,}$/
        );
      },
      required: true
    }
  };
  const [signupValidator, showValidationMessage] = useValidator({}, customValidator);
  const navigate = useNavigate();
  const [signUpForm, setSignupForm] = useState({
    first_name: "",
    last_name: "",
    password: "",
    email: "",
    school_name: "",
    country: ""
  });

  const { first_name, last_name, password, email, school_name, country } = signUpForm;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignupForm({ ...signUpForm, [name]: value });
  };

  const handleSignup = (e) => {
    e.preventDefault();
    if (signupValidator.allValid()) {
      setIsLoading(true);
      httprequest.post(SIGNUP_ENDPOINT, signUpForm).then(() => {
        setIsLoading(false);
        toast.success("Account Created Successfully");
        setTimeout(() => navigate("/auth/login"), 1000);
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      showValidationMessage(true);
    }
  };
  const [viewSecretKeys, setViewSecretKeys] = useState({
    secret_key: false
  });

  const toggleSecretKey = (key) => {
    setViewSecretKeys((prevState) => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  // useEffect(() => {
  //   const fetchCountries = async () => {
  //     try {
  //       const response = await axios.get(COUNTRIES_API);
  //       const countryList = response.data.data.map((item) => ({
  //         value: item.Iso2,
  //         label: item.name
  //       }));
  //       setCountries(countryList);
  //     } catch (error) {
  //       toast.error("Failed to fetch countries. Please try again.");
  //     }
  //   };
  //   fetchCountries();
  // }, []);

  return (
    <div className="auth-wrapper">
      <div className="auth-container">
        <div className="auth-flex">
          <div className="auth-bg-container">
            <img src={loginBG} className="auth-bg-img" alt="login BG" />
          </div>
          <div className="auth-form-container">
            <div className="auth-logo-container">
              <img src={logo} alt="logo" className="auth-logo" />
            </div>
            <div className="auth-form">
              <React.Fragment>
                <div className="auth-heading black900">Signup</div>
                <div className="auth-subheading black900">
                Put in your preferred email and password to sign up now on
                Shelf21 for team management.
                </div>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      label="First Name"
                      inputValue={first_name}
                      onChange={handleChange}
                      margin="normal"
                      inputName="first_name"
                      inputType="text"
                    />
                    {signupValidator.message("first_name", first_name, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      label="Last Name"
                      inputValue={last_name}
                      onChange={handleChange}
                      margin="normal"
                      inputName="last_name"
                      inputType="text"
                    />
                    {signupValidator.message("last_name", last_name, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Email Address"
                      inputValue={school_name}
                      onChange={handleChange}
                      margin="normal"
                      inputName="email"
                      inputType="email"
                    />
                    {signupValidator.message("email", email, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SelectGroup label="Country" children={
                      <select onChange={handleChange} name="country" defaultValue={country}>
                        <option>---Select Country---</option>
                        {Countries.map((country, i) => {
                          return (
                            <option key={i} value={country.Iso2}>{country.name}</option>
                          );
                        })}
                      </select>
                    }
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="School Name"
                      inputValue={school_name}
                      onChange={handleChange}
                      margin="normal"
                      inputName="school_name"
                      inputType="text"
                    />
                    {signupValidator.message("school_name", school_name, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Password"
                      inputValue={password}
                      onChange={handleChange}
                      inputName="password"
                      inputType={viewSecretKeys.secret_key ? "text" : "password"}
                    />
                    <div className="password-eye-pass" onClick={() => toggleSecretKey("secret_key")}>
                      {viewSecretKeys.secret_key ? (
                        <Icon icon="fluent:eye-16-regular" color="black" />
                      ) : (
                        <Icon icon="iconamoon:eye-off-light" color=" black" />
                      )}
                    </div>
                    <div style={{ marginTop: "-17px" }}>
                      {signupValidator.message("password", password, "required|password")}
                    </div>
                  </Grid>

                  {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      label="School Address"
                      inputValue={school_address}
                      onChange={handleChange}
                      margin="normal"
                      inputName="school_address"
                      inputType="text"
                    />
                    {signupValidator.message("school_address", school_address, "required")}
                  </Grid> */}
                </Grid>
                <div className="auth-form-buttons">
                  <Button buttonName="Create an Account" variant="primary" buttonSize="full" onClick={handleSignup} isLoading={isLoading} color="btndefault" />
                </div>
              </React.Fragment>
              <div className="auth-divider">
                <hr />
                <span className="brown900">Or</span>
              </div>
              <div className="brown900 auth-account-button">
              Already have an account ?
                <Link to="/auth/login" className="app-primary">Login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Signup;
