import Badge from "../Badge";
import styles from "./AccessControl.module.css";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";

const AccessComponent = (props) => {
  const copyToClipboard = () => {
    if (!navigator.clipboard) {
      toast.error("Clipboard API not available");

      return;
    }

    navigator.clipboard.writeText(props.accessCode)
      .then(() => {
        toast.success(`${props.accessCode} copied to clipboard!`);
      })
      .catch(() => {
        toast.error("Failed to copy access code.");
      });
  };

  return (
    <div className={`${styles.maincontainer}`}>
      <div className="flex gap-5 align-items-start">
        <Icon icon="f7:link-circle-fill" width="30" height="30" color="black" />
        <div>
          <h5 className={`${styles.forh5}`}>{props.name}</h5>
          <h6>{props.usecase}
            <span
              style={{ cursor: "pointer", marginLeft: "8px" }}
              onClick={copyToClipboard}
              title="Copy Access Code"
            >
              <Icon icon="ph:copy-light" />
            </span>
          </h6>
        </div>
      </div>
      <div className="flex justify-items-space">
        <p>Email:</p>
        <p>{props.mailaddress}</p>
      </div>
      <div className="flex justify-items-space">
        <p>Phone No:</p>
        <p>{props.phonenumber}</p>
      </div>
      <div className="flex justify-items-space">
        <p>Access Control:</p>
        <p>{props.accesscontrol}</p>
      </div>
      <div className="flex justify-items-space">
        <p>Status</p>
        <Badge status={props.codestatus} />
      </div>
      <div className="flex gap-5 align-items-center">
        <p>Revoke Code</p>
        <Icon icon="mdi:link-lock" cursor="pointer" color="red" onClick={props.onRevoke} />
      </div>
    </div>
  );
};

export default AccessComponent;
