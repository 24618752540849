import Layout from "../../../components/Layout";
import BreadCrumb from "../../../components/BreadCrumb";
import { httprequest } from "../../../data/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Calendar from "../../../components/Calendar";
import { useUserState } from "../../../context/UserContext";
import Modal from "../../../components/Modal";
import useStyles from "../styles";

const LEAVE_MGT_ENDPOINT = "/api/v1/leave";

const LeaveCalendar = () => {
  const { profile } = useUserState();
  const classes = useStyles();

  // const { id } = useParams();
  const [myLeave, setMyLeave] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [currentStartDate, setCurrentStartDate] = useState("");
  const [currentEndDate, setCurrentEndDate] = useState("");

  const getMyLeave = () => {
    httprequest.get(`${LEAVE_MGT_ENDPOINT}?leave_status=approved`).then((res) => {
      setMyLeave(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data?.message);
    });
  };

  const events = myLeave.map((leave) => ({
    title: leave?.type,
    start: leave?.start_date,
    end: leave?.end_date,
    last_name: leave?.last_name,
    first_name: leave?.first_name,
    status: leave?.leave_status,
    leave_id: leave?.leave_id,
    className: leave?.employee_id === profile?.employee_id ? "approved-events" : "general-events"
  }));

  const handleEventClick = (args) => {
    setOpenModal(!openModal);
    const startDate = `${args?.event?.start.getFullYear()}-${(args?.event?.start.getMonth() + 1).toString().padStart(2, "0")}-${args?.event?.start.getDate().toString().padStart(2, "0")}`;
    const endDate = `${args?.event?.end.getFullYear()}-${(args?.event?.end.getMonth() + 1).toString().padStart(2, "0")}-${args?.event?.end.getDate().toString().padStart(2, "0")}`;
    setCurrentEvent(args?.event);
    setCurrentEndDate(endDate);
    setCurrentStartDate(startDate);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b className="padding-inline-10">{`${eventInfo.event.extendedProps.last_name} ${eventInfo.event.extendedProps.first_name}`}</b>
        {/* <p className="no-margin-block padding-inline-10">{eventInfo.event.title}</p> */}
        {/* <p className="no-margin-block padding-inline-10">Status: {eventInfo.event.extendedProps.status}</p> */}
      </>
    );
  };

  useEffect(() => {
    getMyLeave();
  }, []);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one={"/welfare"}
                link_one_name="Welfare"
                active="Team Leave Calendar"
                description="See the calender details of everyone in your team"
              />
            </div>
          </div>
          <div className={classes.calendarWrapper}>
            <Calendar events={events} renderEventContent={renderEventContent} defaultView="dayGridMonth" handleEventClick={handleEventClick} />
          </div>
          <Modal
            title={`${currentEvent?.extendedProps?.first_name} ${currentEvent?.extendedProps?.last_name}`}
            modalContent={
              <>
                <p><b>Range</b>: {currentStartDate} to {currentEndDate}</p>
                <p><b>Status</b>: {currentEvent?.extendedProps?.status}</p>

              </>}
            isopen={openModal}
            closeModal={handleCloseModal}
          />
        </>
      }
    />
  );
};

export default LeaveCalendar;
