import ExamBook from "../../../assets/images/book.svg";
import useStyles from "../styles";
import PropTypes from "prop-types";
import Timer from "../../../assets/images/timer.svg";
import { Link } from "react-router-dom";

const ApplicantExaminationCard = ({ examtitle, examdesc, duration, examlink }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.cardcont} text-align-left`}>
      <img src={ExamBook} alt="book" />
      <h3>{examtitle}</h3>
      <p>{examdesc}</p>
      <div className={classes.itembottom}>
        <div>
          <Link className={classes.examlink} to={examlink}>Start Exam</Link>
        </div>
        <div className="flex gap-10 justifyRight">
          <img src={Timer} alt="" />
          <p>{duration}</p>
        </div>
      </div>
    </div>
  );
};
ApplicantExaminationCard.PropTypes = {
  examlink: PropTypes.string,
  examtitle: PropTypes.string,
  examdesc: PropTypes.string,
  duration: PropTypes.string
};

export default ApplicantExaminationCard;
