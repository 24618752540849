/* eslint-disable no-console */
import PropTypes from "prop-types";
import useStyles from "../../styles";

const QuestionText = ({ item, onAnswerClick }) => {
  const classes = useStyles();

  const handleOptionChange = (event) => {
    const { value } = event.target;

    onAnswerClick(value);
  };

  return (
    <div id={`question_${item.question_index}`}>
      <div className={`${classes.questiontext} margin-top-12`}>
        <div className="flex gap-10">
          <h4>
            {item?.question_index}.
          </h4>
          <h4>{item?.question}</h4>
        </div>
        <div className={`${classes.queimg} margin-top-12`}>
          {item?.file?.file_url && (
            <img src={item?.file?.file_url} alt="question image" />
          )}
        </div>
        {item?.type === "checkbox" ? <p className="fontweight-500 fontsize-14 no-margin-block">{item.description}</p> : null}
        <div className="margin-top-12">
          <div>
            {item?.options.map((option, index) => (
              <div className="flex gap-5" key={index}>
                {console.log(item)}
                {item?.type === "checkbox" ? (
                  <input type="checkbox" name={`question_${item.question_index}`}
                    value={index + 1}
                    onChange={handleOptionChange}
                    // checked={item.answer && item.answer.includes(index + 1)}
                  />
                ) : item?.type === "multiple_choice" ? (
                  <input type="radio" name={`question_${item.question_index}`}
                    value={index + 1}
                    onChange={handleOptionChange}
                    checked={item.answer[0]}
                  />
                ) : null}
                {typeof option === "string" ? (
                  <label>{option}</label>
                ) : (
                  <div className={`${classes.imgans}`}>
                    <label htmlFor="for Answer"><img src={option.file_url} alt="answer" /></label>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        {/* <div className="flex gap-5 justify-item-right">
          <p className="no-margin-block fontsize-14">Grade Point:</p>
          <p className="no-margin-block fontsize-14">{item.grade_point}</p>
        </div> */}
      </div>
    </div>
  );
};
QuestionText.PropTypes = {
  question: PropTypes.string,
  question_index: PropTypes.string,
  grade_point: PropTypes.string,
  option: PropTypes.string,
  file_url: PropTypes.shape
};

export default QuestionText;
