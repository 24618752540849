import PageTitle from "../../components/PageTitle";
import { httprequest } from "../../data/api";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import TabContext from "@mui/lab/TabContext";
import { useNavigate } from "react-router-dom";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { CardSearch } from "../../components/CardSearch";
import PageLoader from "../../components/PageLoader";
import RenderNotifications from "./component/RenderNotifications";
import { Grid, Pagination } from "@mui/material";
import ButtonCount from "../../components/ButtonCount";
import ActivityIcon from "../../assets/images/avtiveicon.svg";
import ActivityBlue from "../../assets/images/blue.svg";
import Layout from "../../components/Layout";

// All Notification Endpoint
const ALL_NOTIFICATION = "/api/v1/notifications";

const AllNotification = () => {
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const [allNotification, setAllNotification] = useState([]);
  const [isNotificationFetching, setIsNotificationFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const [totalPages, setTotalPages] = useState();

  // --| Card Pagination
  const itemsPerPage = 6;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const getCurrentItems = (notify) => {
    const allnewNotification = notify.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(allnewNotification);
    setTotalPages(Math.ceil(notify.length / itemsPerPage));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAllNotifications = () => {
    setIsNotificationFetching(true);
    httprequest
      .get(ALL_NOTIFICATION)
      .then((res) => {
        setIsNotificationFetching(false);
        setAllNotification(res?.data?.data);
        getCurrentItems(res?.data?.data);
        setTotalPages(Math.ceil(res?.data?.data.length / itemsPerPage));
      })
      .catch((err) => {
        setIsNotificationFetching(false);
        toast.error(err?.response?.data);
      });
  };

  //   Count Number of Unread Notification
  const unreadCount = allNotification?.filter(notificationcount => notificationcount.read === false).length;

  const navigateNotification = (id) => {
    navigate(`/notification/${id}`);
  };

  useEffect(() => {
    getAllNotifications();
  }, []);

  // --| Filter all cards using name and description
  const handleNotificationSearch = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = currentItems?.filter((data) => valArray?.every(
      (word) => data?.title
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                data?.description
                  ?.toLowerCase()
                  .includes(word.toLowerCase())
    )
    );
    getCurrentItems(result);
  };

  const RenderNotificationItems = () => (
    <Grid container spacing={2}>
      {allNotification
        .sort((a, b) => (a.read === b.read ? 0 : a.read ? 1 : -1))
        .map((notification) => {
          const Activity =
            notification?.read === true ? (
              <img src={ActivityBlue} alt="" />
            ) : (
              <img src={ActivityIcon} alt="" />
            );

          return (
            <Grid item lg={4} md={6} sm={6} xs={12} key={notification.id}>
              <RenderNotifications
                title={notification.title}
                created_at={notification.created_at}
                message={notification.message}
                readImg={Activity}
                viewMore={() => navigateNotification(notification.id)}
              />
            </Grid>
          );
        })}
    </Grid>
  );

  return (
    <Layout children={
      <div>
        <PageTitle
          title="All Notifications"
          subtitle="See all your Notifications"
        />
        <div className="page-content">
          <TabContext value={value}>
            <div className="tablist-container">
              <Tabs
                indicatorColor="none"
                className="overide-tablist"
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                <Tab label="All Notifications" value="" />
              </Tabs>
            </div>
            <>
              <TabPanel value="" className="tabpanel-overide">
                {isNotificationFetching ? (
                  <PageLoader />
                ) : (
                  <>
                    <div className="button-count-container">
                      <ButtonCount title="Unread Notifications" count={unreadCount} />
                    </div>
                    <div className="margin-bottom-10">
                      <CardSearch placeholder="Search your Notification" onChange={handleNotificationSearch} />
                    </div>
                    <RenderNotificationItems />
                  </>
                )}
              </TabPanel>
            </>
          </TabContext>
          <ToastContainer />
          <div className="pagination-container">
            <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
          </div>
        </div>
      </div>
    }
    />
  );
};

export default AllNotification;
