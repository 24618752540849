import useStyles from "../../styles";

const GradeCard = ({ grade, range }) => {
  const classes = useStyles();

  return (
    <div className={classes.gradecont}>
      <h1>{grade}</h1>
      <p>{range}</p>
    </div>
  );
};

export default GradeCard;
