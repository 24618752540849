import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import { useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { httprequest } from "../../../data/api";
import Table from "../../../components/Table/Table";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react/dist/iconify.js";
import Badge from "../../../components/Badge";
// import useStyles from "../styles";
import { TableSearch } from "../../../components/Table/TableActions";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import Modal from "../../../components/Modal";
import PopModal from "../../../components/PopupModal/PopModal";

// const CLASSES_ENDPOINT = "/api/v1/class";
const ALL_ENROLMENT_EXAM = "/api/v1/enrolment-exams";

const AdminEnrolmentExam = () => {
  const navigate = useNavigate();
  // const classes = useStyles();
  const [searchResult, setSearchResult] = useState([]);
  // const [classesData, setClassData] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");
  const [isPageFetching, setIsPageFetching] = useState(false);
  const [allEnrolmentData, setAllEnrollmentData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentSelected, setCurrentSelected] = useState({});

  const handleDeleteModal = (row) => {
    setCurrentSelected(row);
    setDeleteModal(!deleteModal);
  };

  // handles close delte modal
  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const getAllEnrolmentExam = () => {
    setIsPageFetching(true);
    httprequest.get(ALL_ENROLMENT_EXAM).then((res) => {
      setIsPageFetching(false);
      setAllEnrollmentData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsPageFetching(false);
      toast.error(err?.response?.data);
    });
  };

  const handleDeleteAnExam = () => {
    setIsDeleting(true);
    httprequest.delete(`${ALL_ENROLMENT_EXAM}/${currentSelected?.id}`).then(() => {
      setIsDeleting(false);
      toast.success("Exam Deleted Succesfully");
      getAllEnrolmentExam();
      handleCloseDeleteModal();
    }).catch((err) => {
      setIsDeleting(false);
      toast.error(err?.response?.data?.message);
    });
  };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilterStatus(value);

    // Filter assignments based on selected status
    const filteredByStatus = value === "published"
      ? allEnrolmentData?.filter((data) => data?.status === "published")
      : value === "draft"
        ? allEnrolmentData?.filter((data) => data?.status === "draft")
        : value === "withdrawn"
          ? allEnrolmentData?.filter((data) => data?.status === "withdrawn")
          : allEnrolmentData;
    setSearchResult(filteredByStatus);
  };

  // --| Filter Assignment table using name, email and class
  const handleSearchEnrolmentExam = (e) => {
    const { value } = e.target;
    const valArray = value.split(" ");

    const filteredByStatus = filterStatus
      ? allEnrolmentData.filter((data) => data.status.toLowerCase() === filterStatus.toLowerCase())
      : allEnrolmentData;

    // Filter by partial match in subject, class, and creator's name
    const result = filteredByStatus?.filter((data) => valArray?.every(
      (word) => data?.subject?.name?.toLowerCase().includes(word.toLowerCase()) ||
                  data?.class?.name?.toLowerCase().includes(word.toLowerCase()) ||
                  data?.created_by?.name?.toLowerCase().includes(word.toLowerCase())
    ));

    setSearchResult(result);
  };

  useEffect(() => {
    getAllEnrolmentExam();
  }, []);

  const columns = [
    {
      name: "Subject",
      selector: row => row?.subject || ""
    },
    {
      name: "Classes",
      selector: (row) => row?.classes?.map((clss) => (
        <span className="class_div" key={clss?.id}>
          {clss?.name}
        </span>
      ))
    },
    {
      name: "Duration (in mins)",
      selector: row => row?.duration || ""
    },
    {
      name: "Title",
      selector: row => row?.title || ""
    },
    {
      name: "Date Created",
      selector: row => row?.created_at ? row.created_at.substring(0, 10) : ""
    },
    {
      name: "Status",
      center: "true",
      cell: (row) => <Badge status={row.status} />
    },
    {
      name: "",
      center: "true",
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/enrolments/enrolment-examination-details/${row?.id}`}>
            <button className="table-action-icon">
              <Icon icon="fluent:eye-16-regular" />
            </button>
          </Link>
          <button
            className="table-action-icon"
            onClick={() => navigate(`/enrolments/edit-enrolment-examination/${row?.id}`)}
          >
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button className="table-action-icon delete-icon" onClick={() => handleDeleteModal(row)}>
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  return (
    <>
      <PageTitle
        title="Enrolment Examination"
        subtitle="see all the enrollexamination papers"
        button={
          <div className="flex gap-10">
            <PopModal title="Enrolment Examination" videoUrl="https://youtu.be/SEwkQ530-80" />
            <Button onClick={() => navigate("/enrolments/create-enrolment-examination")} variant="primary" buttonSize="fluid" color="btndefault" buttonName="Create Enrolment Examination" />
          </div>
        }
      />
      <div className="page-content">
        <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
          searchResult?.length > 0 && (
            <div className="action_div">
              <TableSearch placeholder="Search here..." searchTableFunc={handleSearchEnrolmentExam} />
              <div className="text-center gap-10 cursor-pointer">
                {isPageFetching ? <Icon icon="svg-spinners:270-ring-with-bg" width="20" height="20" style={{ color: "#858585" }} />
                  : <Icon onClick={getAllEnrolmentExam} icon="hugeicons:reload" width="20" height="20" style={{ color: "#858585" }} />}
                <div>
                  <SelectGroup
                    children={
                      <select name="type" onChange={handleFilterChange}>
                        <option value="">-- Sort By--</option>
                        <option value="published">Published</option>
                        <option value="draft">Draft</option>
                        <option value="withdrawn">Withdrawn</option>
                      </select>
                    }
                  />
                </div>
              </div>
            </div>
          )}
        />
        <Modal
          title="Delete Enrolment Examination"
          subtitle={
            <div>Are you sure you want to delete
              <span className="modal-name-highlight">{" "}
                {currentSelected?.subject ?? "this subject"} for{" "}
                {currentSelected?.classes?.map((clss, index) => (
                  <span className="" key={clss?.id}>
                    {clss?.name}
                    {index < currentSelected.classes.length - 1 ? ", " : ""}
                  </span>
                )) || " these classes"}.
              </span>{" "}
             Implication is that this Examination will be deleted completely
            </div>}
          modalContent={
            <>

            </>
          }
          modalFooter={
            <>
              <Button variant="danger" buttonName="Delete Examination" buttonSize="full" color="btndefault" onClick={handleDeleteAnExam} isLoading={isDeleting} />
            </>
          }
          isopen={deleteModal}
          closeModal={handleCloseDeleteModal}
        />
      </div>
    </>
  );
};

export default AdminEnrolmentExam;
