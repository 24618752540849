import useStyles from "./PersonalStyle";
import { Grid } from "@mui/material";
import styles from "../user/student.module.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Button from "../../../components/Button";
import InputGroup from "../../../components/FormComponent/InputGroup";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import { Gender } from "../../../utils/DropDown";
import { httprequest } from "../../../data/api";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";

const CLASSES_ENDPOINT = "/api/v1/class";
const STUDENTS_ENDPOINT = "/api/v1/students";

// --| This Personal Info page is needed on the student info in the user directory
const StudentProfile = ({ data, allGuardian }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [classData, setClassData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [personalInfoForm, setPersonalInfoForm] = useState({
    // first_name: data?.first_name,
    // last_name: data?.last_name,
    // admission_date: data?.admission_date,
    // class_admitted: data?.class_id,
    email: data?.email,
    contact: data?.contact,
    guardian: [],
    upload_id: data?.upload_id,
    gender: data?.gender,
    age: data?.age
    // address: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfoForm({ ...personalInfoForm, [name]: value });
  };

  const guardianOptions = allGuardian?.map((parent) => ({
    value: parent?.guardian_id,
    label: `${parent?.last_name} ${parent?.first_name}`,
    first_name: parent?.first_name, // Include first name
    last_name: parent?.last_name     // Include last name
  }));

  const handleGuardianChange = (selectedOptions) => {
    // Check if selectedOptions is an array
    const guardianData = Array.isArray(selectedOptions)
      ? selectedOptions.map(option => ({
        first_name: option.first_name,
        last_name: option.last_name,
        guardian_id: option.value
      }))
      : []; // Fallback to an empty array if not an array

    setPersonalInfoForm((prevState) => ({
      ...prevState,
      guardian: guardianData // Update the guardian field with the array of objects
    }));
  };

  const handleUpdateInfo = () => {
    setIsLoading(true);
    httprequest.patch(`${STUDENTS_ENDPOINT}/${id}`, personalInfoForm).then((res) => {
      setIsLoading(false);
      toast.success(res?.data?.message);
      window.location.reload();
    }).catch((err) => {
      setIsLoading(false);
      toast.error(err?.response?.data?.message);
    });
  };

  const getAllClasses = () => {
    httprequest.get(CLASSES_ENDPOINT).then((res) => {
      setClassData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  useEffect(() => {
    // The data will be from the Student get api endpint
    setPersonalInfoForm({ ...personalInfoForm,
      // first_name: data?.first_name,
      // last_name: data?.last_name,
      email: data?.email,
      contact: data?.contact,
      guardian: [],
      upload_id: data?.upload_id,
      gender: data?.gender,
      age: data?.age });
    getAllClasses();
  }, [data]);

  const handleActivateStudent = () => {
    setIsDeleting(true);
    httprequest.patch(`${STUDENTS_ENDPOINT}/${data?.student_id}/reactivate`).then((res) => {
      setIsDeleting(false);
      toast.success(res?.data?.message);
    }).catch((err) => {
      setIsDeleting(false);
      toast.error(err?.response?.data?.message);
    });
  };

  const { contact, email, age, gender } = personalInfoForm;

  return (
    <Grid container spacing={4}>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <div className={`${styles.border} left-container`}>
          <h4>Personal Information</h4>
          <h6>Personal Information of Student</h6>
          <div className={classes.profilecontainer}>
            <div className={classes.imageviewer}>
              <div className={classes.pickercontainer}>
                <input type="file" accept="image/*" name="profile_picture" className={classes.profilepicker} onChange={handleChange} />
                <CameraAltIcon className={classes.camera} />
              </div>
            </div>
          </div>
          {data?.is_active === false ? <Button variant="primaryoutline" color="btnfontprimary" isLoading={isDeleting} buttonSize="full" onClick={handleActivateStudent} buttonName="Re-activate Student" />
            : <Button variant="primaryoutline" color="btnfontprimary" buttonSize="full" buttonName="Update Personal Information" onClick={handleUpdateInfo} isLoading={isLoading} />}
        </div>
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12}>
        <div className={`${styles.border} right-container`}>
          <Grid container spacing={2} className={classes.profileform}>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup required inputType="text" label="First name" disabled inputName="first_name" value={data?.first_name}
                onChange={handleChange}
                defaultValue={data?.first_name}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup required inputType="text" label="Last name" disabled inputName="last_name" value={data?.last_name}
                onChange={handleChange}
                defaultValue={data?.last_name}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup required inputType="email" disabled label="Email address" inputName="email" value={email}
                onChange={handleChange}
                defaultValue={data?.email}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup required inputType="text" label="Age" inputName="age" value={age} onChange={handleChange}
                defaultValue={data?.age}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SelectGroup required label="Gender" children={
                <select value={gender} name="gender" onChange={handleChange}>
                  <option value="">---Select  ---</option>
                  {Gender?.map((gend) => {
                    return (
                      <option key={gend?.key} value={gend?.key}>{gend?.text}</option>
                    );
                  })}
                </select>
              }
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup required inputType="tel" label="Contact" inputName="contact" value={contact} onChange={handleChange}
                defaultValue={data?.contact}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <InputGroup required inputType="date" label="Admission Date" inputName="admission_date" value={data?.admission_date} onChange={handleChange}
                defaultValue={data?.admission_date}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <SelectGroup required label="Class Admitted to" children={
                <select disabled value={data?.class_id} name="class_admitted" onChange={handleChange} defaultValue={data?.id}>
                  <option value="">---Select Class ---</option>
                  {classData?.map((data) => {
                    return (
                      <option key={data?.id} value={data?.id}>{data?.class_name}</option>
                    );
                  })}
                </select>
              }
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <div className="multiple-select">
                <p>Parent/Guardian <span className="input-required">*</span></p>
                <Select
                  name="guardian"
                  options={guardianOptions}
                  onChange={handleGuardianChange}
                  value={guardianOptions.filter(option => personalInfoForm.guardian.some(guardian => guardian.guardian_id === option.value))}
                  isClearable
                  isSearchable
                  isMulti
                />
              </div>
              {/* <SelectGroup
                label="Parent/Guardian"
                children={
                  <Select
                    options={guardianOptions}
                    onChange={(selectedOption) => handleChange({
                      target: {
                        name: "guardian",
                        value: selectedOption ? selectedOption.value : ""
                      }
                    })}
                    defaultValue={guardianOptions.find(option => option?.value === data?.guardian?.guardian_id)}
                    // placeholder="--- Select Guardian ---"
                    isClearable
                    isSearchable
                    isMulti
                  />
                }
              /> */}
            </Grid>
          </Grid>
          <ToastContainer />
        </div>
      </Grid>
    </Grid>
  );
};

export default StudentProfile;
