import useStyles from "../styles";
import Layout from "../../../components/Layout";
import BreadCrumb from "../../../components/BreadCrumb";
import { httprequest } from "../../../data/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Calendar from "../../../components/Calendar";
import Modal from "../../../components/Modal";

const LEAVE_MGT_ENDPOINT = "/api/v1/leave";

const AdminCalendar = () => {
  const classes = useStyles();
  const [myLeave, setMyLeave] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [currentStartDate, setCurrentStartDate] = useState("");
  const [currentEndDate, setCurrentEndDate] = useState("");

  const getMyLeave = () => {
    httprequest.get(`${LEAVE_MGT_ENDPOINT}`).then((res) => {
      setMyLeave(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data?.message);
    });
  };

  const events = myLeave.map((leave) => ({
    title: leave.type,
    start: leave.start_date,
    end: leave.end_date,
    last_name: leave?.last_name,
    first_name: leave?.first_name,
    role_name: leave?.role_name,
    status: leave?.leave_status,
    comment: leave?.comment,
    className: leave?.leave_status === "approved" ? "approved-events" : leave?.leave_status === "pending" ? "pending-events" : leave?.leave_status === "declined" ? "declined-events" : "general-events"
  }));

  const handleEventClick = (args) => {
    setOpenModal(!openModal);
    const startDate = `${args?.event?.start.getFullYear()}-${(args?.event?.start.getMonth() + 1).toString().padStart(2, "0")}-${args?.event?.start.getDate().toString().padStart(2, "0")}`;
    const endDate = `${args?.event?.end.getFullYear()}-${(args?.event?.end.getMonth() + 1).toString().padStart(2, "0")}-${args?.event?.end.getDate().toString().padStart(2, "0")}`;
    setCurrentEvent(args?.event);
    setCurrentEndDate(endDate);
    setCurrentStartDate(startDate);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b className="padding-inline-10">{`${eventInfo.event.extendedProps.last_name} ${eventInfo.event.extendedProps.first_name}`}</b>
        <p className="no-margin-block padding-inline-10">{eventInfo.event.title}</p>
      </>
    );
  };

  useEffect(() => {
    getMyLeave();
  }, []);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one={"/manage/leave"}
                link_one_name="Leave Management"
                active="Team Leave Calendar"
                description="See the leave calendar details of your school"
              />
            </div>
          </div>
          <div className={classes.calendar}>
            <Calendar events={events} renderEventContent={renderEventContent} defaultView="dayGridMonth" handleEventClick={handleEventClick} />
          </div>
          <Modal
            title={`${currentEvent?.extendedProps?.first_name} ${currentEvent?.extendedProps?.last_name}`}
            modalContent={
              <>
                <p><b>Leave Type</b>: {currentEvent?.title}</p>
                <p><b>Range</b>: {currentStartDate} to {currentEndDate}</p>
                <p><b>Status</b>: {currentEvent?.extendedProps?.status}</p>
                <p><b>Comments</b>: {currentEvent?.extendedProps?.comment}</p>

              </>}
            isopen={openModal}
            closeModal={handleCloseModal}
          />
        </>
      }
    />
  );
};

export default AdminCalendar;
