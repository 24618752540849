/* eslint-disable prefer-template */
import Layout from "../../../components/Layout";
// import PageTitle from "../../components/PageTitle/PageTitle";
import BreadCrumb from "../../../components/BreadCrumb";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
import { useEffect, useState } from "react";
import useStyles from "../styles";
import { Grid } from "@mui/material";
import Badge from "../../../components/Badge";
import { httprequest } from "../../../data/api";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import EventBanner from "../../../assets/images/eventbanner.jpg";
import WidgetTitle from "../../dashboard/components/WidgetTitle/WidgetTitle";

const EVENT_ENDPOINT = "/api/v1/events";

const EventDetails = () => {
  const classes = useStyles();
  const [eventData, setEventInfoData] = useState([]);
  const [rsvpData, setRsvpData] = useState([]);
  const [rsvpFilter, setRsvpFilter] = useState([]);
  const { id } = useParams();

  const getEventDetails = () => {
    httprequest.get(`${EVENT_ENDPOINT}/${id}`).then((res) => {
      setEventInfoData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getEventRsvp = () => {
    httprequest.get(`${EVENT_ENDPOINT}/rsvp/${id}`).then((res) => {
      setRsvpData(res?.data?.data);
      setRsvpFilter(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  useEffect(() => {
    getEventDetails();
    getEventRsvp();
  }, []);

  const columns = [
    {
      name: "Name",
      selector: row => row?.full_name
    },
    {
      name: "Email Address",
      selector: row => row?.email
    },
    {
      name: "Date",
      selector: row => row?.rsvp_date?.substring(0, 10)
    }
  ];

  // --| Filter Rsvp table using name and email
  const handleSearchRsvp = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = rsvpData?.filter((data) => valArray?.every(
      (word) => data?.full_name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                      data?.last_name
                        ?.toLowerCase()
                        .includes(word.toLowerCase()) ||
                      data?.email
                        ?.toLowerCase()
                        .includes(word.toLowerCase())
    )
    );
    setRsvpFilter(result);
  };

  return (
    <Layout children={
      <>
        <div className="breadcrumb-container">
          <div>
            <BreadCrumb
              link_one="/events"
              link_one_name="Events"
              active={eventData?.title}
              description={"See event details and those that Rsvp'd"}
            />
          </div>
        </div>
        <div className="page-content">
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className={classes.eventdetailsheader} style={{ backgroundImage: `url(${eventData?.banner?.file_url || EventBanner})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
              </div>
            </Grid>
            <Grid item lg={8} md={12} sm={12} xs={12}>

              <div className="key-pair-container">
                <div className="key-pair-content">
                  <p className="key-pair-name">Event Title</p>
                  <h3 className="key-pair-value">{eventData?.title}</h3>
                </div>
                <div className="key-pair-content">
                  <p className="key-pair-name">Event Description</p>
                  <h3 className="key-pair-value">{eventData?.description}</h3>
                </div>
                <div className="key-pair-content">
                  <p className="key-pair-name">Venue</p>
                  <h3 className="key-pair-value">{eventData?.venue}</h3>
                </div>
                <div className="key-pair-content">
                  <p className="key-pair-name">Location</p>
                  <h3 className="key-pair-value">{eventData?.location}</h3>
                </div>
                <div className="key-pair-content">
                  <p className="key-pair-name">Date</p>
                  <h3 className="key-pair-value">{eventData?.date?.substring(0, 10)}</h3>
                </div>
                <div className="key-pair-content">
                  <p className="key-pair-name">Time</p>
                  <h3 className="key-pair-value">{eventData?.time}</h3>
                </div>
                <div className="key-pair-content">
                  <p className="key-pair-name">Event Type</p>
                  <h3 className="key-pair-value">{eventData?.event_type}</h3>
                </div>
                <div className="key-pair-content">
                  <p className="key-pair-name">Access to Event</p>
                  <div className={classes.acessflex}>
                    {eventData?.access?.map((all, i) => {
                      return (
                        <h3 className="key-pair-value" key={i}>{all?.name}</h3>
                      );
                    })}
                  </div>
                </div>
                <div className="key-pair-content">
                  <p className="key-pair-name">Status</p>
                  <h3 className="key-pair-value"><Badge status={eventData?.status} /></h3>
                </div>
              </div>
            </Grid>
            <Grid item lg={10} md={12} sm={12} xs={12}>
              <WidgetTitle
                title="Attendees"
                subtitle="See everyone that RSVP'd to this event"
                link={""}
              />
              <Table
                data={rsvpFilter}
                columns={columns}
                subHeader={true}
                pagination
                subHeaderComponent={
                  rsvpFilter.length > 0 && (
                    <TableSearch
                      placeholder="Search here..."
                      searchTableFunc={handleSearchRsvp}
                    />
                  )
                }
              />
            </Grid>
          </Grid>
        </div>
        <ToastContainer />
      </>
    }
    />
  );
};

export default EventDetails;
