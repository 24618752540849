import { useUserState } from "../../context/UserContext";
import Layout from "../../components/Layout/index";
import EmployerAssignment from "./admin/Assignment";
import EmployeeAssignment from "./user/EmployeeAssignment";

const Profile = () => {
  const { userRole } = useUserState();

  return (
    <Layout children={
      <>
        {userRole?.role === "school_admin" ? (
          <EmployerAssignment />) : <EmployeeAssignment />
        }
      </>
    }
    />
  );
};

export default Profile;
