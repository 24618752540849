import { useEffect, useState } from "react";
import BreadCrumb from "../../../../components/BreadCrumb";
import Layout from "../../../../components/Layout";
import { useParams } from "react-router-dom";
import { httprequest } from "../../../../data/api";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import SyllabusCard from "./SyllabusCard";
import useStyles from "../../styles";

const GETASYLLABUS = "/api/v1/syllabus/";

const SyllabusDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [getSyllabus, setGetSyllabus] = useState([]);

  const getSyllabusById = () => {
    httprequest.get(`${GETASYLLABUS}${id}`).then((res) => {
      setGetSyllabus(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.message);
    });
  };

  useEffect(() => {
    getSyllabusById();
  }, []);

  return (
    <Layout children={
      <>
        <div className="breadcrumb-container">
          <div>
            <BreadCrumb
              link_one="/class-activities/syllabus"
              link_one_name="All syllabus"
              active="View a Syllabus"
              description="See the details of this syllabus"
            />
          </div>
        </div>
        <div className={`${classes.detailscont} page-content`}>
          <h2 className="text-align-left">All Syllabus</h2>
          <Grid container spacing={2}>
            {getSyllabus?.weeks?.map((item) => (
              <Grid item lg={4} md={6} sm={6} xs={12} key={item.week_id}>
                <SyllabusCard
                  week={item.week}
                  topic={item.topic}
                  sub_topics={item.sub_topics}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </>
    }
    />
  );
};

export default SyllabusDetails;
