import Layout from "../../../components/Layout";
import useStyles from "../styles";
import PageTitle from "../../../components/PageTitle";
import BreadCrumb from "../../../components/BreadCrumb";
import { useState, useEffect } from "react";
// import { Icon } from "@iconify/react";
import { Grid } from "@mui/material";
// import Badge from "../../../../components/Badge";
import { httprequest } from "../../../data/api";
import { useParams, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const SUBJECT_ENDPOINT = "/api/v1/subjects";

const SubjectDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [subjectData, setSubjectData] = useState([]);

  const getSubjectById = () => {
    httprequest.get(`${SUBJECT_ENDPOINT}/${id}`).then((res) => {
      setSubjectData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.message);
    });
  };

  useEffect(() => {
    getSubjectById();
  }, []);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/class-activities/subjects"
                link_one_name="All Subjects"
                active={`${subjectData?.name}`}
                description="See the details of this subject"
              />
            </div>
          </div>
          <div className="page-content">
            <PageTitle className={classes.inline}
              title={`${subjectData?.name}`}
              subtitle={`Created By:${subjectData?.created_by?.name || "----"}   |   Class: ${subjectData?.class?.name || "----"} `}
            />
            <div className={classes.keycontents}>
              <Grid container spacing={1}>
                <Grid item lg={5} md={5} sm={12} xs={12}>
                  <div className="key-pair-container">
                    <div className="key-pair-content">
                      <p className="key-pair-name">Subject Name</p>
                      <h3 className="key-pair-value">{subjectData?.name}</h3>
                    </div>
                    <div className="key-pair-content">
                      <p className="key-pair-name">Term</p>
                      <h3 className="key-pair-value">{subjectData?.term?.name || "-------"}</h3>
                    </div>
                    <div className="key-pair-content">
                      <p className="key-pair-name">Created By</p>
                      <h3 className="key-pair-value">{subjectData?.created_by?.name || "-------"}</h3>
                    </div>
                    <div className="key-pair-content">
                      <p className="key-pair-name">Created At</p>
                      <h3 className="key-pair-value">{subjectData?.created_at?.substring(0, 10) || "-------"}</h3>
                    </div>
                    <div className="key-pair-content">
                      <p className="key-pair-name">Updated At</p>
                      <h3 className="key-pair-value">{subjectData?.updated_at?.substring(0, 10) || "-------"}</h3>
                    </div>
                    <div className="key-pair-content">
                      <p className="key-pair-name">Class Notes</p>
                      <Link>Class Materials</Link>
                    </div>
                    <div className="key-pair-content">
                      <p className="key-pair-name">Assignments</p>
                      <Link>Class Assignments</Link>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <ToastContainer />
          </div>
        </>
      }
    />
  );
};

export default SubjectDetails;
