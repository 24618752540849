import styles from "./NotFound.module.css";

const NotFounditem = (props) => {
  return (
    <>
      <div className="page-content">
        <div className={`${styles.erroritems}`}>
          <img src={props.img} alt="Not Found Image" className={styles.errorimg} />
          <div className={`${styles.errorspan}`}>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.subtitle}>{props.subtitle}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFounditem;
