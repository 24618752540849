import Layout from "../../components/Layout";
import { useUserState } from "../../context/UserContext";
import AdminLibrary from "./admin/AdminLibrary";
import StudentLibrary from "./student/StudentLibrary";
const index = () => {
  const { userRole } = useUserState();

  return (
    <Layout children={
      <>
        {userRole?.role === "school_admin" ? (
          <AdminLibrary />
        ) : <StudentLibrary />}
      </>
    }
    />
  );
};

export default index;
