import Layout from "../../components/Layout";
import Table from "../../components/Table/Table";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { TableSearch } from "../../components/Table/TableActions";
import BreadCrumb from "../../components/BreadCrumb";
import { Icon } from "@iconify/react";
import InputGroup from "../../components/FormComponent/InputGroup";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";
import useValidator from "../../hooks/useValidator";
import { useUserState } from "../../context/UserContext";
import { getCurrentRole } from "../../data/roles";
import Permission from "../error/Permission";

const GOAL_CATEGORY_ENDPOINT = "/api/v1/goalcategory";

const GoalCategory = () => {
  const { userRole } = useUserState();
  const currentRole = getCurrentRole(userRole);

  const IS_ADMIN = currentRole === "admin";
  const [goalCategoryModal, setGoalCategoryModal] = useState(false);
  const [editGoalCategoryModal, setEditlCategoryModal] = useState(false);
  const [deleteGoalCategoryModal, setDeleteGoalCategoryModal] = useState(false);
  const [currentDelete, setCurrentDelete] = useState("");
  const [currentRow, setCurrentRow] = useState({});
  const [isCategoryFetching, setIsCategoryFetching] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [goalCategoryValidator, showGoalCategoryValidator] = useValidator();
  const [editOptionsValidator, showEditOptionsValidator] = useValidator();
  const [goalCategory, setGoalCategory] = useState({
    category: "",
    percentage: ""
  });

  const [editGoalCategory, setEditGoalCategory] = useState({
    category: "",
    percentage: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGoalCategory({ ...goalCategory, [name]: value });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditGoalCategory({ ...editGoalCategory, [name]: value });
  };

  const handleDeleteModal = (row) => {
    setDeleteGoalCategoryModal(true);
    setCurrentDelete(row);
  };

  const handleCloseDeleteModal = () => {
    setDeleteGoalCategoryModal(false);
    setCurrentDelete({});
  };

  const handleGoalCategoryModal = () => {
    setGoalCategoryModal(!goalCategoryModal);
  };

  const handleEditGoalModal = (row) => {
    setCurrentRow(row);
    setEditGoalCategory({ ...editGoalCategory, category: row?.category, percentage: row?.percentage });
    setEditlCategoryModal(true);
  };

  const handleCloseEditOptionsModal = () => {
    setCurrentRow({});
    setEditGoalCategory({ ...editGoalCategory, category: "", percentage: "" });
    setEditlCategoryModal(false);
  };

  const getAllGoalCategory = () => {
    setIsCategoryFetching(true);
    httprequest.get(GOAL_CATEGORY_ENDPOINT).then((res) => {
      setIsCategoryFetching(false);

      setCategoryData(res?.data?.data?.goalCategories);
      setSearchResult(res?.data?.data?.goalCategories);
    }).catch((err) => {
      setIsCategoryFetching(false);
      toast.error(err?.response?.data);
    });
  };

  const handleCreateGoalCategory = () => {
    if (goalCategoryValidator.allValid()) {
      setIsLoading(true);
      httprequest.post(GOAL_CATEGORY_ENDPOINT, goalCategory).then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
        getAllGoalCategory();
        setGoalCategory({ ...goalCategory, category: "", percentage: "" });
        handleGoalCategoryModal();
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data);
      });
    } else {
      showGoalCategoryValidator(true);
    }
  };

  const handleDeleteGoalCategory = () => {
    setIsDeleteLoading(true);
    httprequest.delete(`${GOAL_CATEGORY_ENDPOINT}/${currentDelete?.id}`).then(() => {
      setIsDeleteLoading(false);
      toast.success("Goal Category Deleted Successfully");
      getAllGoalCategory();
      handleCloseDeleteModal();
    }).catch((err) => {
      setIsDeleteLoading(false);
      toast.error(err?.response?.data);
    });
  };

  const handleEditGoalCategory = () => {
    if (editOptionsValidator.allValid()) {
      setIsEditLoading(true);
      httprequest.patch(`${GOAL_CATEGORY_ENDPOINT}/${currentRow?.id}`, editGoalCategory).then(() => {
        setIsEditLoading(false);
        toast.success("Goal Category Edited");
        getAllGoalCategory();
        setEditGoalCategory({ ...editGoalCategory, category: "", percentage: "" });
        handleCloseEditOptionsModal();
      }).catch((err) => {
        setIsEditLoading(false);
        toast.error(err?.response?.data?.message);
        handleCloseEditOptionsModal();
      });
    } else {
      showEditOptionsValidator(true);
    }
  };

  // --| Filter Goal Category table using type, number of days, who can access
  const handleSearchGoalCategory = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = categoryData?.filter((data) => valArray?.every(
      (word) => data?.category
        ?.toLowerCase()
        .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  useEffect(() => {
    getAllGoalCategory();
  }, []);

  const columns = [
    {
      name: "Category Name",
      selector: (row) => row?.category
    },
    {
      name: "Percentage",
      selector: (row) => row?.percentage
    },
    {
      name: "Date Created",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Date Updated",
      selector: (row) => row?.updated_at?.substring(0, 10)
    },
    {
      name: " ",
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button className="table-action-icon" onClick={() => {
            handleEditGoalModal(row);
          }}
          >
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  const { category, percentage } = goalCategory;

  return (
    <>
      {IS_ADMIN ? (
        <Layout
          children={
            <>
              <div className="breadcrumb-container">
                <div>
                  <BreadCrumb
                    link_one="/manage/goals"
                    link_one_name="Performance Evaluation"
                    active="Goal Categories"
                    description="See the goal categories of your goal for better classification."
                  />
                </div>
                <Button
                  variant="primary"
                  buttonSize="fluid"
                  color="btndefault"
                  onClick={handleGoalCategoryModal}
                  buttonName="Add Goal Category"
                />
              </div>

              <Table
                data={searchResult}
                columns={columns}
                subHeader={true}
                isLoading={isCategoryFetching}
                subHeaderComponent={
                  searchResult?.length > 0 && (<TableSearch placeholder="Search here..." searchTableFunc={handleSearchGoalCategory} />
                  )}
                pagination
              />
              <Modal
                title="Add New Goal Category"
                subtitle="Create a category for your goals to classify them"
                modalContent={
                  <>
                    <Grid container spacing={2} className="input-padding">
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputGroup inputName="category" type="text" label="Category Name" value={category} onChange={handleChange} />
                        {goalCategoryValidator.message("Category", category, "required")}
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputGroup inputName="percentage" type="number" label="Percentage" value={percentage} onChange={handleChange} />
                        {goalCategoryValidator.message("Percentage", percentage, "required")}
                      </Grid>
                    </Grid>
                  </>
                }
                modalFooter={
                  <>
                    <Button
                      type="submit"
                      onClick={handleCreateGoalCategory}
                      variant="primary"
                      buttonName="Create Goal Category"
                      buttonSize="full"
                      color="btndefault"
                      isLoading={isLoading}
                    />
                  </>
                }
                isopen={goalCategoryModal}
                closeModal={handleGoalCategoryModal}
              />
              <Modal
                title="Delete Current Goal Category"
                subtitle={`Are you sure you want to delete this  ${currentDelete?.category} goal category?. If you delete it, it becomes unavaiable for the whole school.`}
                modalContent={
                  <>
                    <div>
                    </div>
                  </>
                }
                modalFooter={
                  <>
                    <Button
                      type="submit"
                      variant="danger"
                      buttonName="Delete Goal Category"
                      buttonSize="full"
                      color="btndefault"
                      onClick={handleDeleteGoalCategory}
                      isLoading={isDeleteLoading}
                    />
                  </>
                }
                isopen={deleteGoalCategoryModal}
                closeModal={handleCloseDeleteModal}
              />

              {/* Modal to edit goal category */}
              <Modal
                title="Edit Goal Category"
                subtitle="Edit your current goal category"
                modalContent={
                  <>
                    <Grid container spacing={2} className="input-padding">
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputGroup inputName="category" type="text" label="Category Name" defaultValue={editGoalCategory?.category} onChange={handleEditChange} />
                        {editOptionsValidator.message("Category Name", editGoalCategory?.category, "required")}
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputGroup inputName="percentage" type="number" label="Percentage" defaultValue={editGoalCategory?.percentage} onChange={handleEditChange} />
                        {editOptionsValidator.message("Percentage", editGoalCategory?.percentage, "required")}
                      </Grid>
                    </Grid>
                  </>
                }
                modalFooter={
                  <>
                    <Button
                      type="submit"
                      onClick={handleEditGoalCategory}
                      variant="primary"
                      buttonName="Edit Goal Category"
                      buttonSize="full"
                      color="btndefault"
                      isLoading={isEditLoading}
                    />
                  </>
                }
                isopen={editGoalCategoryModal}
                closeModal={handleCloseEditOptionsModal}
              />
              <ToastContainer />
            </>
          }
        />
      ) : <Permission />}
    </>

  );
};

export default GoalCategory;
