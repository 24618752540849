/* eslint-disable nonblock-statement-body-position */
import { useState, useEffect } from "react";
import PageTitle from "../../../../components/PageTitle";
import { Grid, useTheme, useMediaQuery } from "@mui/material";
import useStyles from "../../styles";
// import { Numbers } from "../../../../utils/DropDown";
import QuestionText from "../component/QuestionText";
import PageLoader from "../../../../components/PageLoader";
import ProgressBar from "@ramonak/react-progress-bar";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useParams, useNavigate } from "react-router-dom";
import { axiosrequest } from "../../../../data/api";
import { toast } from "react-toastify";
import Button from "../../../../components/Button";

const APPLICANT_EXAM = "/api/v1/applicant/exams";
const APPLICANT_CACHE = "/api/v1/applicant/exams";

const Examination = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [applicantExam, setApplicantExam] = useState({});
  const [percentage, setPercentage] = useState(100);
  const [timer, setTimer] = useState("00:00:00");
  const classes = useStyles();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [activeQuestion, setActiveQuestion] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectedAnswer, setSelectedAnswer] = useState({
    answers: []
  });
  const [intervalId, setIntervalId] = useState(null);

  // const countdownWarningTime = 20;
  const totalTime = applicantExam?.duration * 60;

  // const Ref = useRef(null);

  // get Applicant Exam
  const getApplicantExam = () => {
    setIsLoading(true);
    axiosrequest.get(`${APPLICANT_EXAM}/${id}`).then((res) => {
      setIsLoading(false);
      setApplicantExam(res?.data?.data);
    }).catch((err) => {
      setIsLoading(false);
      toast.error(err?.response?.data);
    });
  };

  const getTimeRemaining = (endTime) => {
    const total = Date.parse(endTime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);

    return { total, hours, minutes, seconds };
  };

  const handleNavigate = () => {
    navigate("/student-application");
  };

  // for Submit Exam
  const handleFinalSubmit = async () => {
    try {
      const res = await axiosrequest.patch(`${APPLICANT_EXAM}/${id}`, applicantExam);
      toast.success(res?.data?.message);
      // Navigate after successful response
      handleNavigate();
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  // // Handle Soft Submit
  // const handleSoftSubmit = () => {
  //   axiosrequest
  //     .patch(`${APPLICANT_EXAM}/${id}/cache`, applicantExam)
  //     .then(() => {
  //     })
  //     .catch((err) => {
  //       toast.error(err?.response?.data?.message);
  //     });
  // };

  const startTimer = async (endTime) => {
    const { total, hours, minutes, seconds } = getTimeRemaining(endTime);

    if (total > 0) {
      setTimer(
        `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}:${seconds > 9 ? seconds : `0${seconds}`}`
      );

      const elapsedTime = totalTime - total / 1000;
      const percentRemaining = Math.round((elapsedTime / totalTime) * 100);
      setPercentage(percentRemaining);
    } else {
      try {
        const res = await axiosrequest.patch(`${APPLICANT_EXAM}/${id}`, applicantExam);
        toast.success(res?.data?.message);
        handleNavigate();
      } catch (err) {
        toast.error(err?.response?.data?.message);
      }

      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
  };

  const clearTimer = (endTime) => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    const newIntervalId = setInterval(() => startTimer(endTime), 1000);
    setIntervalId(newIntervalId);
  };

  const getDeadTime = () => {
    const deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + totalTime);

    return deadline;
  };

  // post each answer that's clicked
  const handleAnswerClick = (questionIndex, answer, type) => {
    // Update only the selected question's answer
    const updatedQuestions = applicantExam.questions.map((question) => {
      if (question.question_index === questionIndex) {
        const updatedQuestion = { ...question };

        if (type === "checkbox") {
          // Initialize the answer array if not present
          if (!updatedQuestion.answer) {
            updatedQuestion.answer = [];
          }

          const answerIndex = updatedQuestion.answer.indexOf(answer);
          if (answerIndex !== -1) {
            // Uncheck the answer
            updatedQuestion.answer.splice(answerIndex, 1);
          } else {
            // Check the answer
            updatedQuestion.answer.push(answer);
          }
        } else if (type === "multiple_choice") {
          // Replace the current answer for single-choice questions
          updatedQuestion.answer = [answer];
        }

        return updatedQuestion;
      }

      return question;
    });

    // Create the updated exam object
    const updatedExam = {
      ...applicantExam,
      questions: updatedQuestions
    };

    const updatedSelectedAnswer = updatedQuestions.map((question) => ({
      question_index: question.question_index,
      answer: question.answer
    }));

    // Send the updated
    axiosrequest
      .patch(`${APPLICANT_CACHE}/${id}/cache`, updatedExam)
      .then(() => {
        // Update the state locally to reflect changes without re-fetching
        setApplicantExam(updatedExam);
        setSelectedAnswer({ answers: updatedSelectedAnswer });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  // const handleStartExam = () => {
  //   clearTimer(getDeadTime());

  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   };
  // };

  useEffect(() => {
    getApplicantExam();
  }, []);

  useEffect(() => {
    if (applicantExam?.duration) {
      const deadline = getDeadTime();
      clearTimer(deadline);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [applicantExam?.duration]);

  // Navigate to clicked question
  const handleNavigateToQuestion = (index) => {
    setActiveQuestion(index);
    const idElement = document.getElementById(`question_${index}`);
    if (idElement) {
      idElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isQuestionAnswered = (questionIndex) => {
    return selectedAnswer?.answers.some(
      (a) => a?.question_index === questionIndex && a?.answer?.length > 0
    );
  };

  // Submits Exam if Student Reloads the page in attempt to restart
  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     handleFinalSubmit();
  //   };

  //   // Add the event listener
  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Clean up the event listener on unmount
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  return (
    <div className={`${classes.mainpage} page-content`}>
      <PageTitle
        title={applicantExam?.title}
        subtitle={applicantExam?.instruction}
      />
      <div className="margin-top-30">
        <Grid container spacing={2} sx={{
          flexWrap: isSmallScreen ? "wrap-reverse" : "nowrap"
        }}
        >
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <div className={`${classes.progressbar} padding-inline-10`}>
              <ProgressBar completed={100 - percentage} baseBgColor="#F0F2F5" bgColor="#28AE00" labelSize="12px" height="14px" />
              <div className={classes.timeleft}>
                <h2>Time Remaining: {timer}</h2>
              </div>
            </div>
            {/* Fetch temporary draft */}
            <div className="container-y-overflow padding-inline-10">
              <div>
                {isLoading ? (
                  <PageLoader />
                ) : (
                  <>
                    {applicantExam && applicantExam?.questions && applicantExam?.questions.map((question, index) => (
                      <QuestionText
                        id={`question_${index + 1}`}
                        key={index + 1}
                        item={question}
                        onAnswerClick={(answer) => handleAnswerClick(question?.question_index, answer - 1, question?.type)}
                      />
                    ))}
                  </>
                )}
              </div>
              <div className={classes.submitbtn}>
                <Button onClick={handleFinalSubmit} variant="primary" buttonName="Submit Exam" buttonSize="full" color="btndefault" />
              </div>
            </div>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <div className={`${classes.rightcont}`}>
              {/* <p onClick={handleStartExam}>Start Exam</p> */}
              <div className={classes.timercont}>
                <h5>FINISH BEFORE</h5>
                <div className={classes.times}>
                  <CircularProgressbar counterClockwise background value={100 - percentage} text={timer} strokeWidth={5}
                    styles={buildStyles({
                      textColor: "#000000",
                      pathColor: "#28AE00",
                      trailColor: "#FFF",
                      textSize: "20px",
                      strokeLinecap: "butt",
                      textWeight: "400"
                    })}
                  />
                </div>
              </div>
              <div className={`${classes.questioncont}`}>
                <h5>QUESTIONS</h5>
                <Grid container spacing={1}>
                  {applicantExam && applicantExam?.questions ? (
                    applicantExam?.questions.map((queNumber, index) => (
                      <Grid item key={index} lg={4} md={4} sm={1} xs={2}>
                        <p onClick={() => handleNavigateToQuestion(index + 1)}
                          style={{
                            backgroundColor: isQuestionAnswered(queNumber?.question_index) ? "#d4edda" : (activeQuestion === index + 1 ? "#F0F2F5" : "transparent"),
                            fontWeight: activeQuestion === index + 1 ? "bold" : "normal"
                          }}
                        >{queNumber.question_index}
                        </p>
                      </Grid>
                    ))
                  ) : (
                    <p>Please Wait ...</p>
                  )}
                </Grid>
                <Button onClick={handleFinalSubmit} variant="primary" buttonName="Submit Exam" buttonSize="full" color="btndefault" />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Examination;
