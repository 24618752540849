// import { Icon } from "@iconify/react";
import useStyles from "../../styles";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper
} from "@mui/material";
// import Button from "../../../../components/Button";
import { useEffect, useState } from "react";
// import Modal from "../../../../components/Modal";
// import { useNavigate } from "react-router-dom";
// import ButtonCount from "../../../../components/ButtonCount";
import NotFounditem from "../../../error/NotFound";
import nosearch from "../../../../assets/images/no-search.svg";
import { httprequest } from "../../../../data/api";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PageLoader from "../../../../components/PageLoader";
// import StudentResults from "../../../../dummy_data/StudentResult.json";
import { ToastContainer, toast } from "react-toastify";
import Layout from "../../../../components/Layout";
import PageTitle from "../../../../components/PageTitle";
// import { CardSearch } from "../../../../components/CardSearch";
import SelectGroup from "../../../../components/FormComponent/SelectGroup";

// END POINT CALLS
const TERM_ENDPOINT = "/api/v1/school-plan/term";
const GET_SESSIONS = "/api/v1/school-plan/session";
const GET_CLASS_GRADES = "/api/v1/broad-sheet/";
const CLASSES_ENDPOINT = "/api/v1/class";

const BroadSheet = () => {
  const classes = useStyles();
  const [value, setValue] = useState("");
  // const [searchResult, setSearchResult] = useState([]);
  const [classesData, setClassData] = useState([]);
  const [activeSession, setActiveSession] = useState([]);
  const [selectedSessionTerms, setSelectedSessionTerms] = useState([]);
  // const [selectedSession, setSelectedSession] = useState("");
  const [allSessions, setAllSessions] = useState([]);
  const [aselectedTerm, setASelectedTerm] = useState("");
  // const [searchTerm, setSearchTerm] = useState("");
  const [classGrades, setClassGrades] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [isPageFetching, setIsPageFetching] = useState(false);

  const getAllClasses = () => {
    setIsPageFetching(true);
    httprequest
      .get(CLASSES_ENDPOINT)
      .then((res) => {
        const physicalClasses = res?.data?.data?.filter(
          (data) => data?.class_type === "physical"
        );
        setIsPageFetching(false);
        setClassData(physicalClasses);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getAllTerms = (Session_ID) => {
    httprequest
      .get(TERM_ENDPOINT)
      .then((res) => {
        setSelectedSessionTerms(res?.data?.data.filter((selected_term) => selected_term?.session_id === Session_ID));
      });
    // .catch(() => {});
  };

  // Handle Get All Classes
  const getAllSessions = async () => {
    try {
      const res = await httprequest.get(GET_SESSIONS);
      setAllSessions(res?.data?.data);
      setActiveSession(res?.data?.data?.find((active_session) => active_session?.current_session === true));
    } catch (err) {
      toast.error(err?.response?.message);
    }
  };

  // const NoResultFound = () => {
  //   return (
  //     <NotFounditem
  //       img={nosearch}
  //       title="No Broadsheet Found"
  //       subtitle="The Name that matches this query cannot be found"
  //     />
  //   );
  // };

  // Find Active Term in an Current Session
  const active_term_session = activeSession?.terms?.find((found_active_term) => found_active_term?.is_current === true);

  // Handle Session Change
  const handleSessionChange = (e) => {
    const Session_ID = e.target.value;
    // setASelectedSession(e.target.value);
    getAllTerms(Session_ID);
  };

  const handleGetAllClassResult = async (classID, termId) => {
    setIsPageFetching(true);
    try {
      const res = await httprequest.get(`${GET_CLASS_GRADES}${classID}?term_id=${termId}`);
      setIsPageFetching(false);
      setClassGrades(res?.data?.data);
      setFilteredStudents(res?.data?.data);
    } catch (err) {
      setIsPageFetching(false);
      toast.error(err?.response?.message);
    }
  };

  // On mount renders all classes
  useEffect(() => {
    getAllSessions();
    getAllTerms();
    getAllClasses();
  }, []);

  useEffect(() => {
    if (active_term_session?.id) {
      setASelectedTerm(active_term_session.id);
    }
  }, [active_term_session]);

  useEffect(() => {
    if (activeSession?.id) {
      // setSelectedSession(activeSession?.id);
      getAllTerms(activeSession?.id);
    }
  }, [activeSession]);

  useEffect(() => {
    if (classesData?.length > 0 && active_term_session?.id) {
      const class_value = classesData[0]?.id;
      setValue(class_value);
      const params = {
        term_id: active_term_session.id
      };

      handleGetAllClassResult(class_value, params.term_id);
    }
  }, [classesData, active_term_session]);

  // Handle Select Class
  const handleChange = (event, newValue) => {
    setValue(newValue);

    handleGetAllClassResult(newValue, aselectedTerm);
  };

  // Handles the change event when a new tab is selected
  const handleTermChange = (event) => {
    const selectedTermId = event.target.value;

    setASelectedTerm(selectedTermId);
    handleGetAllClassResult(value, selectedTermId);
  };

  // handle Search
  // const handleSearchChange = (event) => {
  //   const value = event.target.value.toLowerCase();

  //   // Check if input is empty
  //   if (!value) {
  //     setFilteredStudents(classGrades || []);

  //     return;
  //   }

  //   // Filter students based on the input value
  //   const filtered = classGrades?.sheet[0]?.students.filter((student) => student?.first_name.toLowerCase().includes(value) ||
  //     student?.last_name.toLowerCase().includes(value)
  //   );

  //   setFilteredStudents(filtered);
  // };

  const students = Array.from(
    new Map(
      (filteredStudents?.sheet || [])
        .flatMap((entry) => (entry?.students || []).map((student) => [
          student?.id,
          { id: student?.id, name: `${student?.last_name} ${student?.first_name}` }
        ])
        )
    ).values()
  );

  const subjects = classGrades?.sheet?.map((entry) => entry?.subject) || [];

  // eslint-disable-next-line no-console
  console.log(classGrades, "classGrades");

  return (
    <Layout children={
      <>
        <PageTitle
          title="Examination Broadsheet"
          subtitle="See all Student Examination Result"
          // button={
          //   <div>
          //     <Button
          //       variant="primary"
          //       buttonSize="fluid"
          //       color="btndefault"
          //       // onClick={() => handleCreateNoteModal()}
          //       buttonName="Add Result to Broadsheet"
          //     />
          //   </div>
          // }
        />
        <TabContext value={value}>
          {classesData.length > 0 && (
            <div className="tablist-container">
              <Tabs
                indicatorColor="none"
                className="overide-tablist"
                onChange={handleChange}
                aria-label=""
              >
                {classesData?.map((data, i) => {
                  return (
                    <Tab key={i} value={data?.id} label={data?.class_name} />
                  );
                })}
              </Tabs>
            </div>
          )}

          <div className="page-content margin-top-16">
            <div className={classes.headinfo}>
              {/* {classGrades?.sheet?.length > 0 && (
                <div className={`${classes.searchcont} margin-bottom-10`}>
                  <CardSearch
                    placeholder="Search Student Result"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
              )} */}
              <div className="flex gap-10 align-items-end margin-top-5">
                <div>
                  {activeSession?.id && (
                    <SelectGroup
                      children={
                        <select
                          defaultValue={activeSession?.id}
                          onChange={handleSessionChange}
                          name="session"
                        >
                          <option value="">---Select---</option>
                          {allSessions?.map((sesh) => {
                            return (
                              <option key={sesh?.id} value={sesh?.id}>
                                {sesh?.session}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  )}
                </div>
                <div>
                  {active_term_session?.id && (
                    <SelectGroup
                      children={
                        <select onChange={handleTermChange} name="term" value={aselectedTerm}>
                          <option value="">---Select---</option>
                          {selectedSessionTerms?.map((data) => {
                            return (
                              <option key={data?.id} value={data?.id}>
                                {data?.term}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            {classesData?.map((data, i) => (
              <TabPanel className="tabpanel-overide" key={i} value={data?.id}>
                {isPageFetching ? (
                  <PageLoader />
                ) : classGrades?.sheet === 0 ? (
                  <div className={classes.notfound}>
                    <NotFounditem
                      img={nosearch}
                      title="No Exam BroadSheet Found"
                      subtitle="No Broadsheet has been added to the list."
                    />
                  </div>
                ) : (
                  <TableContainer component={Paper} style={{ overflowX: "auto" }} className={classes.tableContainer}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={`${classes.headerCell} ${classes.firstColumn}`} rowSpan={2}>Student Name</TableCell>
                          {subjects.map((subject) => (
                            <>
                              <TableCell className={classes.headerCell} colSpan={6} align="center">
                                {subject?.name}
                              </TableCell>
                            </>
                          ))}
                        </TableRow>
                        <TableRow>
                          {subjects.map((_, subjectIndex) => (
                            <>
                              <TableCell className={classes.subHeaderCell} align="center">CA1</TableCell>
                              <TableCell className={classes.subHeaderCell} align="center">CA2</TableCell>
                              <TableCell className={classes.subHeaderCell} align="center">Total CA</TableCell>
                              <TableCell className={classes.subHeaderCell} align="center">Exam</TableCell>
                              <TableCell className={classes.subHeaderCell} align="center">Total</TableCell>
                              <TableCell className={`${classes.subHeaderCell} ${
                                subjectIndex === subjects.length - 1 ? "" : classes.separator
                              }`} align="center"
                              >Grade
                              </TableCell>
                            </>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {students.map((student, index) => (
                          <TableRow key={student.id} className={index % 2 === 0 ? "" : classes.bodyRowOdd}>
                            <TableCell className={`${classes.secondRowCol}`}>{student?.name}</TableCell>
                            {subjects.map((subject, subjectIndex) => {
                              const studentScores = filteredStudents?.sheet
                                ?.find((entry) => entry?.subject?.id === subject?.id)
                                ?.students?.find((s) => s?.id === student?.id);

                              return studentScores ? (
                                <>
                                  <TableCell className={classes.bodyCell} align="center">{studentScores?.scores?.CA_scores?.CA1 || "-"}</TableCell>
                                  <TableCell className={classes.bodyCell} align="center">{studentScores?.scores?.CA_scores?.CA2 || "-"}</TableCell>
                                  <TableCell className={classes.bodyCell} align="center">{studentScores?.scores?.CA_total || "-"}</TableCell>
                                  <TableCell className={classes.bodyCell} align="center">{studentScores?.scores?.exam_score || "-"}</TableCell>
                                  <TableCell className={classes.bodyCell} align="center">{studentScores?.scores?.total || "-"}</TableCell>
                                  <TableCell className={`${classes.bodyCell} ${
                                    subjectIndex === subjects.length - 1 ? "" : classes.separator
                                  }`} align="center"
                                  >{studentScores.scores.grade || "-"}
                                  </TableCell>
                                </>
                              ) : (
                                <>
                                  <TableCell className={classes.bodyCell} align="center">-</TableCell>
                                  <TableCell className={classes.bodyCell} align="center">-</TableCell>
                                  <TableCell className={classes.bodyCell} align="center">-</TableCell>
                                  <TableCell className={classes.bodyCell} align="center">-</TableCell>
                                  <TableCell className={classes.bodyCell} align="center">-</TableCell>
                                  <TableCell className={`${classes.bodyCell} ${
                                    subjectIndex === subjects.length - 1 ? "" : classes.separator
                                  }`} align="center"
                                  >-
                                  </TableCell>
                                </>
                              );
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </TabPanel>
            ))}
          </div>
        </TabContext>
        {/* <div className="flex justify-items-space Blockspacing">
          <div className={classes.contentpadding}>
            <ButtonCount func={() => navigate("")} title="Student Count" />
          </div>
          <div className={classes.employee}>
            <p>Class Teacher: </p>
            <h4 onClick={() => navigate("/employees")}>Euguen Adaovore</h4>
          </div>
        </div> */}
        <ToastContainer />
      </>
    }
    />
  );
};

export default BroadSheet;
