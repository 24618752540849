import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { httprequest } from "../../../data/api";
import Layout from "../../../components/Layout";
import Button from "../../../components/Button";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import useStyles from "../styles";
import { Grid } from "@mui/material";
import useValidator from "../../../hooks/useValidator";
import { Weekday } from "../../../utils/DropDown";
import Modal from "../../../components/Modal";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import Calendar from "../../../components/Calendar";
import InputGroup from "../../../components/FormComponent/InputGroup";
import useWindowSize from "../../../hooks/useWindow";
import BreadCrumb from "../../../components/BreadCrumb";
// import InputGroup from "../../components/FormComponent/InputGroup";

const CLASS_ENDPOINT = "/api/v1/class";
const SCHOOL_PERIOD = "/api/v1/period";
// const SUBJECTS_ENDPOINT = "/api/v1/subjects";
const CLASS_SUBJECT = "/api/v1/class-subject/";
const TERM_ENDPOINT = "/api/v1/school-plan/term";
const GET_SESSIONS = "/api/v1/school-plan/session";

const AllClasses = () => {
  const [classData, setClassData] = useState([]);
  const classes = useStyles();
  // const navigate = useNavigate();
  const [periodData, setPeriodData] = useState([]);
  const [value, setValue] = useState("");
  const class_params = { class_id: value };
  // const [subjectsData, setSubjectsData] = useState([]);
  const [termsData, setTermsData] = useState([]);
  const [activeTerm, setActiveTerm] = useState([]);
  const [selectedSessionTerms, setSelectedSessionTerms] = useState([]);
  const [activeSession, setActiveSession] = useState([]);
  const [allSessions, setAllSessions] = useState([]);
  const [timeTableValidator, showTimeTableValidator] = useValidator();
  const [addtimetableModal, setAddTimeTableModal] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const [showSubject, setShowSubject] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [deleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedClassSubjects, setSelectedClassSubjects] = useState([]);
  const [allClassSubject, setAllClassSubject] = useState([]);
  const [timeTable, setTimeTable] = useState({
    term_id: "",
    time_zone: "GMT +1",
    class_id: "",
    period: []
  });
  const [editTimeTable, setEditTimeTable] = useState({
    start_time: currentEvent?.start
      ? new Date(Date.parse(currentEvent.start)).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" })
      : "",
    end_time: currentEvent?.end
      ? new Date(Date.parse(currentEvent.end)).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" })
      : ""
  });

  // Handle change events for time
  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    setEditTimeTable((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const width = useWindowSize();
  const mobile = width < 576;

  const renderView = () => {
    if (mobile) {
      return "timeGridDay";
    } else {
      return "timeGridWeek";
    }
  };

  const handleTimeTableModal = () => {
    setAddTimeTableModal(!addtimetableModal);
  };

  const handleTitleChange = (e) => {
    const { name, value } = e.target;
    setTimeTable({ ...timeTable, [name]: value });
  };

  // handle Type Change
  const handleSelectChange = (e) => {
    const { value } = e.target;
    setTimeTable({ ...timeTable, type: value });
    setShowActivity(value === "activity");
    setShowSubject(value === "subject");
  };

  // Handle Subject Change
  const handleSubjectChange = (e) => {
    const { name, value } = e.target;
    setTimeTable({ ...timeTable, [name]: value });
  };

  // Handle Get All Classes
  const getAllClasses = () => {
    httprequest
      .get(CLASS_ENDPOINT)
      .then((res) => {
        setClassData(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // Handle Get All Classes
  const getAllSessions = () => {
    httprequest
      .get(GET_SESSIONS)
      .then((res) => {
        setAllSessions(res?.data?.data);
        setActiveSession(res?.data?.data?.find((active_session) => active_session?.current_session === true));
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };
  // Find Active Term in an Current Session
  const active_term_session = activeSession?.terms?.find((found_active_term) => found_active_term?.is_current === true);

  // handle Get all Terms
  const getAllTerms = (Session_ID) => {
    httprequest
      .get(TERM_ENDPOINT)
      .then((res) => {
        setTermsData(res?.data?.data);
        setSelectedSessionTerms(res?.data?.data.filter((selected_term) => selected_term?.session_id === Session_ID));
        setActiveTerm(
          res?.data?.data.find(
            (active_term) => active_term?.is_current === true
          )
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getAllPeriods = (params) => {
    httprequest
      .get(`${SCHOOL_PERIOD}`, { params })
      .then((res) => {
        setPeriodData(res?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // Get all Subject for a Class
  const getAllSubjectForClass = (Class_ID) => {
    httprequest
      .get(`${CLASS_SUBJECT}${Class_ID}`)
      .then((res) => {
        setAllClassSubject(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // Handle Class Change
  const handleClassChange = (e) => {
    const { name, value } = e.target;
    setTimeTable({ ...timeTable, [name]: value });
    setSelectedClassSubjects(value);
    getAllSubjectForClass(value);
  };

  // Handle Session Change
  const handleSessionChange = (e) => {
    const { name, value } = e.target;
    setTimeTable({ ...timeTable, [name]: value });
    getAllTerms(value);
  };

  // Handle Add Periods
  const handleAddAllPeriods = async () => {
    try {
      const periodInfo = [
        {
          start_time: timeTable?.start_time,
          end_time: timeTable?.end_time,
          day: timeTable?.day || "",
          ...(showSubject && { subject_id: timeTable?.subject_id || "" }),
          ...(showActivity && { title: timeTable?.title || "" }),
          type: timeTable?.type || ""
        }
      ];

      const createdPeriod = {
        term_id: timeTable.term_id,
        time_zone: timeTable.time_zone,
        class_id: timeTable.class_id,
        period: periodInfo
      };

      if (timeTableValidator.allValid()) {
        await httprequest.post(SCHOOL_PERIOD, createdPeriod);
        setAddTimeTableModal(false);
        getAllPeriods(class_params);
        toast.success("Attendance Created Successfully");
      } else {
        showTimeTableValidator(true);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  // Mapping day abbreviations to actual dates
  const startDate = new Date(activeTerm?.start_date);
  const EndDate = new Date(activeTerm?.end_date);

  const generateEvents = () => {
    const allEvents = [];
    const currentDate = new Date(startDate);

    while (currentDate <= EndDate) {
      const weekDay = currentDate
      // Get day and convert to lowercase abbreviat
        .toLocaleDateString("en-US", { weekday: "short" })
        .toLowerCase();

      periodData?.data?.days?.forEach((day) => {
        if (weekDay === day?.day) {
          day?.periods?.forEach((periodItem) => {
            const event = {
              type: periodItem?.type,
              title: periodItem?.subject?.name || periodItem?.title,
              start: `${currentDate.toISOString().split("T")[0]}T${periodItem?.start_time}`,
              end: `${currentDate.toISOString().split("T")[0]}T${periodItem?.end_time}`,
              teacher: periodItem?.teachers?.[0]?.name,
              subject_name: periodItem?.subject?.name,
              subject_id: periodItem?.subject?.id,
              day: day?.day,
              class: periodData?.data?.class?.name,
              class_id: periodData?.data?.class?.id,
              term: periodData?.data?.term?.name,
              term_id: periodData?.data?.term?.id,
              period_id: periodItem?.id,
              className: "general-events"
            };
            allEvents.push(event);
          });
        }
      });

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return allEvents;
  };

  const events = generateEvents();

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <p className="no-margin-block padding-inline-10">{eventInfo.event?.title} | {eventInfo?.event?.extendedProps?.class}</p>
        <p className="no-margin-block padding-inline-10">{eventInfo.event?.extendedProps?.teacher}</p>
      </>
    );
  };

  const handleEventClick = (args) => {
    setOpenEditModal(!openEditModal);
    // const startDate = `${args?.event?.start?.getFullYear()}-${(args?.event?.start?.getMonth() + 1).toString().padStart(2, "0")}-${args?.event?.start?.getDate().toString().padStart(2, "0")}`;
    // const endDate = `${args?.event?.end?.getFullYear()}-${(args?.event?.end?.getMonth() + 1).toString().padStart(2, "0")}-${args?.event?.end?.getDate().toString().padStart(2, "0")}`;
    setCurrentEvent(args?.event);
    // setCurrentEndDate(endDate);
    // setCurrentStartDate(startDate);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  // --| handle change for tab
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const params = {
      class_id: newValue
    };
    getAllPeriods(params);
  };

  // Delete a Period
  const handleDeletePeriod = () => {
    setIsDeleting(true);
    httprequest.delete(`${SCHOOL_PERIOD}/${currentEvent?.extendedProps?.period_id}`).then((res) => {
      setIsDeleting(false);
      handleCloseEditModal();
      toast.success(res?.data?.message);
      getAllPeriods(class_params);
    }).catch((err) => {
      setIsDeleting(false);
      toast.error(err.response?.data?.message);
    });
  };

  // Edit Period
  const handleEditPeriod = () => {
    setIsEditing(true);

    const edit_period_data = {
      start_time: editTimeTable.start_time,
      end_time: editTimeTable.end_time
    };

    httprequest.patch(`${SCHOOL_PERIOD}/${currentEvent?.extendedProps?.period_id}`, edit_period_data)
      .then((res) => {
        setIsEditing(false);
        toast.success(res?.data?.message);
        handleCloseEditModal();
        getAllPeriods(class_params);
      })
      .catch((err) => {
        setIsEditing(false);
        toast.error(err.response?.data?.message);
      });
  };

  useEffect(() => {
    if (classData?.length > 0 && !value) {
      setValue(classData[0]?.id);
      const Class_ID = classData[0]?.id;
      getAllSubjectForClass(Class_ID);
      const params = {
        class_id: classData[0]?.id
      };
      getAllPeriods(params);
    }
  }, [classData]);

  useEffect(() => {
    if (activeSession?.terms?.length > 0) {
      setSelectedSessionTerms(activeSession?.terms);
    }
  }, [activeSession]);

  useEffect(() => {
    getAllClasses();
    getAllTerms();
    getAllSessions();
  }, []);

  useEffect(() => {
    if (selectedClassSubjects) {
      getAllSubjectForClass(selectedClassSubjects);
    }
  }, [selectedClassSubjects]);

  const {
    class_id,
    term_id,
    type,
    day,
    title,
    start_time,
    end_time,
    subject_id
  } = timeTable;

  return (
    <Layout children={
      <>
        <div className="breadcrumb-container">
          <div>
            <BreadCrumb link_one="/class-activities/classes" link_one_name="Classes" active="Classes Calendar" description="See all the calendar in your school" />
          </div>
          <div className="flex gap-10">
            <Button
              variant="primaryoutline"
              buttonSize="small"
              color="btnfontprimary"
              onClick={handleTimeTableModal}
              buttonName="Add to Timetable"
            />
          </div>
        </div>
        <div className="page-content">
          <TabContext value={value}>
            {classData.length > 0 && (
              <div className="tablist-container padding-left-24">
                <Tabs
                  indicatorColor="none"
                  className="overide-tablist"
                  onChange={handleChange}
                  aria-label="class tabs"
                >
                  {classData?.map((data) => {
                    return (
                      <Tab
                        key={data?.id}
                        value={data?.id}
                        label={data?.class_name}
                      />
                    );
                  })}
                </Tabs>
              </div>
            )}
            {classData?.map((data) => {
              return (
                <TabPanel key={data?.id} value={data?.id}>
                  <div className={classes.terminfo}>
                    <p className="text-align-left">Current Term: {activeTerm?.term}</p>
                    <div className="flex gap-10 justify-content-between">
                      <p>Starts On: {activeTerm?.start_date?.substring(0, 10)}</p>
                      <p>Ends on: {activeTerm?.end_date?.substring(0, 10)}</p>
                    </div>
                  </div>
                  <div className={classes.calender}>
                    <Calendar events={events} renderEventContent={renderEventContent} defaultView={renderView()} handleEventClick={handleEventClick} />
                  </div>
                </TabPanel>
              );
            })}
          </TabContext>
        </div>
        <Modal
          title="Add to Timetable"
          subtitle={
            <div>Allocate a time slot for a subject in the timetable</div>
          }
          modalContent={
            <>
              <Grid container spacing={2} className="input-padding">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <SelectGroup
                    label="Class"
                    children={
                      <select
                        name="class_id"
                        value={class_id}
                        onChange={handleClassChange}
                      >
                        <option value="">--- Select ---</option>
                        {classData?.map((label) => {
                          return (
                            <option key={label.key} value={label?.id}>
                              {label?.class_name}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {timeTableValidator.message("class_id", class_id, "required")}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <SelectGroup
                    label="Session"
                    children={
                      <select
                        defaultValue={activeSession?.id}
                        name="session_id"
                        // value={term_id}
                        onChange={handleSessionChange}
                      >
                        <option value="">--- Select ---</option>
                        {allSessions?.map((label) => {
                          return (
                            <option key={label.id} value={label?.id}>
                              {label?.session}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {timeTableValidator.message("session_id", term_id, "required")}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <SelectGroup
                    label="Term"
                    children={
                      <select
                        defaultValue={active_term_session?.id}
                        name="term_id"
                        value={term_id}
                        onChange={handleTitleChange}
                      >
                        <option value="">--- Select ---</option>
                        {selectedSessionTerms?.map((label) => {
                          return (
                            <option key={label.key} value={label?.id}>
                              {label?.term}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {timeTableValidator.message("term_id", term_id, "required")}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <SelectGroup
                    label="Day of week"
                    children={
                      <select name="day" value={day} onChange={handleTitleChange}>
                        <option value="">--- Select ---</option>
                        {Weekday?.map((label) => {
                          return <option key={label.key}>{label?.text}</option>;
                        })}
                      </select>
                    }
                  />
                  {timeTableValidator.message("day", day, "required")}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <SelectGroup
                    label="Type"
                    children={
                      <select
                        name="type"
                        value={type}
                        onChange={handleSelectChange}
                      >
                        <option value="">--- Select ---</option>
                        <option value="activity">Activity</option>
                        <option value="subject">Subject</option>
                      </select>
                    }
                  />
                  {timeTableValidator.message("type", type, "required")}
                </Grid>
                {showActivity && (
                  <>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <InputGroup
                        label="Title"
                        margin="normal"
                        inputType="text"
                        inputName="title"
                        value={title}
                        onChange={handleTitleChange}
                      />
                      {timeTableValidator.message("title", title, "required")}
                    </Grid>
                  </>
                )}
                {showSubject && (
                  <>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <SelectGroup
                        label="Subject"
                        children={
                          <select
                            name="subject_id"
                            value={subject_id}
                            onChange={handleSubjectChange}
                          >
                            <option value="">--- Select ---</option>
                            {allClassSubject?.map((subject) => {
                              return (
                                <option key={subject?.subject_id} value={subject?.subject_id}>
                                  {subject?.subject_name}
                                </option>
                              );
                            })}
                          </select>
                        }
                      />
                      {timeTableValidator.message("subject_id", subject_id, "required")}
                    </Grid>
                  </>
                )}
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup
                    label="Start Time"
                    margin="normal"
                    inputType="time"
                    inputName="start_time"
                    value={start_time}
                    onChange={handleTitleChange}
                  />
                  {timeTableValidator.message("start_time", start_time, "required")}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup
                    label="End Time"
                    margin="normal"
                    inputType="time"
                    inputName="end_time"
                    value={end_time}
                    onChange={handleTitleChange}
                  />
                  {timeTableValidator.message("end_time", end_time, "required")}
                </Grid>
              </Grid>
            </>
          }
          modalFooter={
            <>
              <div className={classes.modalFooterBtn}>
                {/* <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Add Another Period" /> */}
                <Button
                  variant="primary"
                  buttonSize="full"
                  color="btndefault"
                  onClick={handleAddAllPeriods}
                  buttonName="Add to timetable"
                />
              </div>
            </>
          }
          isopen={addtimetableModal}
          closeModal={handleTimeTableModal}
        />

        {/* EDIT TIMETABLE */}
        <Modal
          title={`Period Details: ${currentEvent?.extendedProps?.subject_name || currentEvent?.title}`}
          modalContent={
            <>
              <Grid container spacing={2} className="input-padding">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <SelectGroup label="Class" children={
                    <select defaultValue={currentEvent?.extendedProps?.class_id}>
                      <option value="">--- Select ---</option>
                      {classData?.map((label) => {
                        return (
                          <option key={label.key} value={label?.id}>{label?.class_name}</option>
                        );
                      })}
                    </select>
                  }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <SelectGroup label="Term" children={
                    <select defaultValue={currentEvent?.extendedProps?.term_id}>
                      <option value="">--- Select ---</option>
                      {termsData?.map((label) => {
                        return (
                          <option key={label?.id} value={label?.id}>{label?.term}</option>
                        );
                      })}
                    </select>
                  }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <SelectGroup
                    label="Type"
                    children={
                      <select
                        name="type"
                        defaultValue={currentEvent?.extendedProps?.type}
                        value={type}
                        onChange={handleSelectChange}
                      >
                        <option value="">--- Select ---</option>
                        <option value="activity">Activity</option>
                        <option value="subject">Subject</option>
                      </select>
                    }
                  />
                  {timeTableValidator.message("type", type, "required")}
                </Grid>
                {currentEvent?.extendedProps?.type === "activity" && (
                  <>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup
                        label="Title"
                        margin="normal"
                        defaultValue={currentEvent?.title}
                        inputType="text"
                        inputName="title"
                        value={title}
                        onChange={handleTitleChange}
                      />
                      {timeTableValidator.message("title", title, "required")}
                    </Grid>
                  </>
                )}
                {currentEvent?.extendedProps?.type === "subject" && (
                  <>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <SelectGroup
                        label="Subject"
                        children={
                          <select
                            name="subject_id"
                            defaultValue={currentEvent?.extendedProps?.subject_id}
                            value={subject_id}
                            onChange={handleSubjectChange}
                          >
                            <option value="">--- Select ---</option>
                            {allClassSubject?.map((subject) => {
                              return (
                                <option key={subject?.subject_id} value={subject?.subject_id}>
                                  {subject?.subject_name}
                                </option>
                              );
                            })}
                          </select>
                        }
                      />
                      {timeTableValidator.message("subject_id", subject_id, "required")}
                    </Grid>
                  </>
                )}
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <SelectGroup label="Day of week" children={
                    <select defaultValue={currentEvent?.extendedProps?.day}>
                      <option value="">--- Select ---</option>
                      {Weekday?.map((label) => {
                        return (
                          <option key={label?.key}>{label?.text}</option>
                        );
                      })}
                    </select>
                  }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup defaultValue={editTimeTable?.start_time} label="Start Time" inputType="time" inputName="start_time" onChange={handleTimeChange} />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup defaultValue={editTimeTable?.end_time} label="End Time" inputType="time" inputName="end_time" onChange={handleTimeChange} />
                </Grid>
              </Grid>

            </>}
          modalFooter={
            <>
              <div className={classes.modalFooterBtn}>
                <Button variant="danger" buttonSize="full" color="btndefault" buttonName="Delete Period" onClick={handleDeletePeriod} isLoading={deleting} />
                <Button
                  variant="primary"
                  buttonSize="full"
                  color="btndefault"
                  isLoading={isEditing}
                  onClick={handleEditPeriod}
                  buttonName="Edit Period"
                />
              </div>
            </>
          }
          isopen={openEditModal}
          closeModal={handleCloseEditModal}
        />
        <ToastContainer />
      </>
    }
    />
  );
};

export default AllClasses;
