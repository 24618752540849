import { TableSearch } from "../../../components/Table/TableActions";
import NotFounditem from "../../error/NotFound";
import nosearch from "../../../assets/images/no-search.svg";
import { httprequest } from "../../../data/api";
import Table from "../../../components/Table/Table";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import PageLoader from "../../../components/PageLoader";
import Modal from "../../../components/Modal";
import { ToastContainer, toast } from "react-toastify";
import useStyles from "../styles";
import Button from "../../../components/Button";
import Layout from "../../../components/Layout";
import BreadCrumb from "../../../components/BreadCrumb";
import InputGroup from "../../../components/FormComponent/InputGroup";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import { Grid } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import useValidator from "../../../hooks/useValidator";
// import { Grade } from "../../../utils/DropDown";
import ButtonCount from "../../../components/ButtonCount";

const CLASSES_ENDPOINT = "/api/v1/class";
const CLASS_SUBJECT = "/api/v1/class-subject/";
const GET_A_SUBJECT = "/api/v1/subjects/";
const TERM_ENDPOINT = "/api/v1/school-plan/term";
const GET_SESSIONS = "/api/v1/school-plan/session";
const GET_SUBJECT_GRADES = "/api/v1/grade/";
const GET_All_STUDENTS = "/api/v1/class/students/";
const GRADING_SYSTEM = "/api/v1/grading-system";

// --| Dummy Data
// import StudentReportData from "../../../dummy_data/studentReportData.json";

const SubjectResultDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const [classesData, setClassData] = useState([]);
  const [value, setValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [currentRow, setCurrentRow] = useState({});
  const [isPageFetching, setIsPageFetching] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [testArr, setTestArr] = useState([""]);
  const [addsubjectModal, setSubjectModal] = useState(false);
  const [allClassSubject, setAllClassSubject] = useState([]);
  const [classDetail, setClassDetail] = useState([]);
  const [activeSession, setActiveSession] = useState([]);
  const [selectedSessionTerms, setSelectedSessionTerms] = useState([]);
  const [selectedSession, setSelectedSession] = useState("");
  const [allSessions, setAllSessions] = useState([]);
  const [aselectedTerm, setASelectedTerm] = useState("");
  const [classGrades, setClassGrades] = useState([]);
  const [allStudent, setAllStudents] = useState([]);
  const [resultCreating, setResultCreating] = useState(false);
  const [resultEditing, setResultEditing] = useState(false);
  const [resultDeleting, setResultDeleting] = useState(false);
  const [aSubjectDetail, setASubjectDetail] = useState([]);
  const [allGradingSystem, setAllGradingSystem] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [editselectedGrade, setEditSelectedGrade] = useState([]);
  const [calculatedGrade, setCalculatedGrade] = useState(null);
  const [editcalculatedGrade, setEditCalculatedGrade] = useState(null);
  const [filterStatus, setFilterStatus] = useState("");
  const [editResultModal, setEditResultModal] = useState(false);
  const [studentResultValidator, showStudentResultValidator] = useValidator();
  const [editstudentResultValidator, showEditStudentResultValidator] = useValidator();
  const location = useLocation();

  // Extract "type" from the query string
  const queryParams = new URLSearchParams(location.search);
  const class_id = { classId: queryParams.get("class_id") };
  const ClassID = class_id?.classId;

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const [subjectForm, setSubjectForm] = useState({
    subject_id: id,
    class_id: ClassID,
    student_id: "",
    CA_scores: { CA1: "" },
    term_id: "",
    grade: "",
    grading_system_id: "",
    total: "",
    exam_score: "",
    CA_total: ""
  });

  const [editsubjectForm, setEditSubjectForm] = useState({
    subject_id: id,
    class_id: ClassID,
    student_id: "",
    CA_scores: {},
    grade: "",
    total: "",
    grading_system: "",
    exam_score: "",
    CA_total: ""
  });

  const handleEditResultModal = (row) => {
    setCurrentRow(row);
    setEditResultModal(true);
    setEditSubjectForm({
      ...editsubjectForm,
      subject_id: id,
      class_id: ClassID,
      student_id: row?.student?.id,
      CA_scores: row?.CA_scores,
      grade: row?.grade,
      total: row?.total,
      exam_score: row?.exam_score,
      CA_total: row?.CA_total,
      grading_system: row?.grading_system
    });
    setEditSelectedGrade(row?.grading_system);
  };

  // Handle Subject Modal
  const handleAddSubjectModal = () => {
    setSubjectModal(!addsubjectModal);
  };

  const handleCloseAddResultModal = () => {
    setSubjectModal(!addsubjectModal);
    setTestArr([""]);
    setSubjectForm({ ...subjectForm, CA_scores: { CA1: "" }, CA_total: "", grade: "", total: "", grading_system_id: "", exam_score: "", student_id: "" });
  };

  const handleCloseEditResultModal = () => {
    setEditResultModal(!editResultModal);
    setEditSelectedGrade([]);
  };

  // CA SCores Input
  const handleTestScoreChange = (e, index) => {
    const updatedScores = [...testArr];
    updatedScores[index] = e.target.value;

    // This Map array to object format for CA_scores
    const CA_scores = updatedScores.reduce((acc, score, idx) => {
      acc[`CA${idx + 1}`] = Number(score) || 0;

      return acc;
    }, {});

    // This Calculate the total and average for CA scores
    const totalScores = updatedScores.reduce((sum, score) => sum + (Number(score) || 0), 0);
    const numberOfCAs = updatedScores.length;
    const CA_total = numberOfCAs > 0 ? totalScores / numberOfCAs : 0;

    // Calculate the overall total score (CA_total + exam_score)
    const examScore = parseFloat(subjectForm.exam_score) || 0;
    const totalScore = CA_total + examScore;

    // Calculate the grade based on the updated total score
    let grade = "";
    if (selectedGrade?.grading) {
      const gradeDetails = selectedGrade?.grading.find(
        (grade) => totalScore >= grade.min && totalScore <= grade.max
      );
      grade = gradeDetails ? gradeDetails?.grade : "";
    }

    // Update state
    setTestArr(updatedScores);
    setSubjectForm({
      ...subjectForm,
      CA_scores,
      CA_total: CA_total.toFixed(2),
      total: totalScore.toFixed(2),
      grade
    });
  };

  // handle EDit Test Scores
  const handleEditTestScoreChange = (e, index) => {
    const updatedCA_scores = { ...editsubjectForm.CA_scores };

    // Update the specific CA score based on the index
    const key = `CA${index + 1}`;
    updatedCA_scores[key] = Number(e.target.value) || 0;

    // Calculate the total scores and CA_total
    const totalScores = Object.values(updatedCA_scores).reduce(
      (sum, score) => sum + score,
      0
    );
    const numberOfCAs = Object.keys(updatedCA_scores).length;
    const CA_total = numberOfCAs > 0 ? totalScores / numberOfCAs : 0;

    // Calculate the overall total score (CA_total + exam_score)
    const examScore = parseFloat(editsubjectForm.exam_score) || 0;
    const totalScore = CA_total + examScore;

    // Retrieve the selected grading system
    const selectedGradingSystem = editsubjectForm?.grading_system
      ? allGradingSystem.find((gradeSystem) => gradeSystem?.id === editsubjectForm.grading_system?.id)
      : null;

    // Determine the grade based on the total score
    let grade = "";
    if (selectedGradingSystem && selectedGradingSystem.grading) {
      const gradeDetails = selectedGradingSystem?.grading.find(
        (grade) => totalScore >= grade.min && totalScore <= grade.max
      );
      grade = gradeDetails ? gradeDetails?.grade : "";
    }

    // Update the editsubjectForm state
    setEditSubjectForm({
      ...editsubjectForm,
      CA_scores: updatedCA_scores,
      CA_total: CA_total.toFixed(2),
      total: totalScore.toFixed(2),
      grade
    });

    // Update editcalculatedGrade
    setEditCalculatedGrade(grade);
  };

  const AddAdditionalTestScore = () => {
    const newSubject = "";
    setTestArr([...testArr, newSubject]);
  };

  // Add CA to Edit
  const AddAdditionalEditTestScore = () => {
    // Get the current CA scores object
    const currentCAScores = { ...editsubjectForm.CA_scores };

    // Next CA
    const nextCAKey = `CA${Object.keys(currentCAScores).length + 1}`;

    // Add the new CA with a default value
    currentCAScores[nextCAKey] = 0;

    // Calculate the new total score (you can adjust this calculation as needed)
    const totalScore = Object.values(currentCAScores).reduce((sum, score) => sum + score, 0);
    const New_CA_total = (totalScore / Object.keys(currentCAScores).length);
    const examScore = parseFloat(editsubjectForm.exam_score) || 0;
    const overallTotal = New_CA_total + examScore;

    // Update the state with the new CA score and the recalculated total score
    setEditSubjectForm({
      ...editsubjectForm,
      CA_scores: currentCAScores,
      CA_total: New_CA_total,
      total: overallTotal.toFixed(2)
    });
  };

  // Delete A Test Score
  const handleDeleteTestScore = (index) => {
    // Filter out the selected test score
    const newTestArr = testArr.filter((_item, ind) => ind !== index);

    // Refill the CA_scores object
    const updatedCA_scores = newTestArr.reduce((acc, score, idx) => {
      acc[`CA${idx + 1}`] = score || 0;

      return acc;
    }, {});

    // Calculate the total and average score
    const totalScore = newTestArr.reduce((sum, score) => sum + Number(score || 0), 0);
    const averageScore = newTestArr.length > 0 ? totalScore / newTestArr.length : 0;
    const examScore = parseFloat(subjectForm.exam_score) || 0;
    const overallScore = examScore + totalScore;

    // Update state
    setTestArr(newTestArr);
    setSubjectForm({
      ...subjectForm,
      CA_scores: updatedCA_scores,
      CA_total: averageScore.toFixed(2),
      total: overallScore.toFixed(2)
    });
  };

  // Delete Edit Test Score
  const handleDeleteEditTestScore = (key) => {
    // Create a shallow copy of the CA_scores object
    const updatedCA_scores = { ...editsubjectForm.CA_scores };

    // Remove the selected CA score using the key
    delete updatedCA_scores[key];

    // Convert updatedCA_scores back to an array of scores for calculations
    const scoresArray = Object.values(updatedCA_scores).map((score) => Number(score) || 0);

    // Calculate the total and average scores
    const totalScore = scoresArray.reduce((sum, score) => sum + score, 0);
    const averageScore = scoresArray.length > 0 ? totalScore / scoresArray.length : 0;

    // Reindex the CA_scores keys
    const reindexedCA_scores = scoresArray.reduce((acc, score, idx) => {
      acc[`CA${idx + 1}`] = score;

      return acc;
    }, {});

    // Add the exam_score to the total score
    const examScore = parseFloat(editsubjectForm.exam_score) || 0;
    const overallTotalScore = averageScore + examScore;

    // Update the editsubjectForm state
    setEditSubjectForm({
      ...editsubjectForm,
      CA_scores: reindexedCA_scores,
      CA_total: averageScore.toFixed(2),
      total: overallTotalScore.toFixed(2)
    });
  };

  // Close Delete Modal
  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  // Get A Subject Detail
  const handleGetASubjectById = () => {
    httprequest.get(`${GET_A_SUBJECT}${id}`).then((res) => {
      setASubjectDetail(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // Handle Get all Grading System
  const handleGetAllGradingSystem = () => {
    httprequest.get(GRADING_SYSTEM).then((res) => {
      setAllGradingSystem(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data?.message);
    });
  };

  // HandleGetClassByID
  const handleGetClassById = () => {
    httprequest.get(`${CLASSES_ENDPOINT}/${ClassID}`).then((res) => {
      setClassDetail(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // Get Subject result
  const handleGetSubjectResult = async (termId) => {
    setIsPageFetching(true);
    try {
      const res = await httprequest.get(`${GET_SUBJECT_GRADES}${id}/${class_id?.classId}?term_id=${termId}`);
      setIsPageFetching(false);
      setClassGrades(res?.data?.data);
      setSearchResult(res?.data?.data);
    } catch (err) {
      setIsPageFetching(false);
      toast.error(err?.response?.message);
    }
  };

  // Create Add Student Result
  const handlePostStudentResult = () => {
    if (studentResultValidator.allValid()) {
      setResultCreating(true);
      httprequest.post(GET_SUBJECT_GRADES, { ...subjectForm, term_id: aselectedTerm }).then((res) => {
        setResultCreating(false);
        toast.success(res?.data?.message);
        handleGetSubjectResult(aselectedTerm);
        handleCloseAddResultModal();
      }).catch((err) => {
        setResultCreating(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      showStudentResultValidator(true);
    }
  };

  // EDit Add Student Result
  const handleEditStudentResult = () => {
    if (editstudentResultValidator.allValid()) {
      setResultEditing(true);
      httprequest.patch(`${GET_SUBJECT_GRADES}${currentRow?.id}`, editsubjectForm).then((res) => {
        setResultEditing(false);
        toast.success(res?.data?.message);
        handleGetSubjectResult(aselectedTerm);
        handleCloseEditResultModal();
      }).catch((err) => {
        setResultEditing(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      showEditStudentResultValidator(true);
    }
  };

  // handle Delete Subject Result
  const handleDeleteStudentResult = () => {
    setResultDeleting(true);
    httprequest.delete(`${GET_SUBJECT_GRADES}${currentRow?.id}`).then((res) => {
      setResultDeleting(false);
      toast.success(res?.data?.message);
      handleGetSubjectResult(aselectedTerm);
      handleCloseDeleteModal();
    }).catch((err) => {
      setResultDeleting(false);
      toast.error(err?.response?.data?.message);
    });
  };

  // Handle get student in a class
  const handleGetAllStudents = () => {
    httprequest.get(`${GET_All_STUDENTS}${class_id?.classId}`).then((res) => {
      setAllStudents(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getAllTerms = (Session_ID) => {
    httprequest
      .get(TERM_ENDPOINT)
      .then((res) => {
        setSelectedSessionTerms(res?.data?.data.filter((selected_term) => selected_term?.session_id === Session_ID));
      });
    // .catch(() => {});
  };

  // Handle Get All Classes
  const getAllSessions = async () => {
    try {
      const res = await httprequest.get(GET_SESSIONS);
      setAllSessions(res?.data?.data);
      setActiveSession(res?.data?.data?.find((active_session) => active_session?.current_session === true));
    } catch (err) {
      toast.error(err?.response?.message);
    }
  };

  // Find Active Term in an Current Session
  const active_term_session = activeSession?.terms?.find((found_active_term) => found_active_term?.is_current === true);

  // Handles the change event when a new tab is selected
  const handleTermChange = (event) => {
    const selectedTermId = event.target.value;
    setASelectedTerm(selectedTermId);
    handleGetSubjectResult(selectedTermId);
  };

  // Handle Session Change
  const handleSessionChange = (e) => {
    const Session_ID = e.target.value;
    // setASelectedSession(e.target.value);
    getAllTerms(Session_ID);
  };

  useEffect(() => {
    if (active_term_session?.id) {
      setASelectedTerm(active_term_session.id);
    }
  }, [active_term_session]);

  useEffect(() => {
    if (activeSession?.id) {
      setSelectedSession(activeSession?.id);
      getAllTerms(activeSession?.id);
      // eslint-disable-next-line no-console
      console.log(selectedSession, "selectedSession");
    }
  }, [activeSession]);

  useEffect(() => {
    if (active_term_session?.id) {
      const params = {
        term_id: active_term_session.id
      };
      // setSubjectForm({ ...subjectForm, term_id: active_term_session.id });
      handleGetSubjectResult(params?.term_id);
    }
  }, [active_term_session]);

  // Get All Classes
  const getAllClasses = () => {
    setIsPageFetching(true);
    httprequest
      .get(CLASSES_ENDPOINT)
      .then((res) => {
        const physicalClasses = res?.data?.data?.filter(
          (data) => data?.class_type === "physical"
        );
        setIsPageFetching(false);
        setClassData(physicalClasses);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // Get All Subject For A Class
  const getAllSubjectForClass = async (class_value) => {
    try {
      const res = await httprequest.get(`${CLASS_SUBJECT}${class_value}`);
      setAllClassSubject(res?.data?.data);
    } catch (err) {
      toast.error(err?.response?.data);
    }
  };

  // On mount renders all classes
  useEffect(() => {
    getAllSessions();
    getAllClasses();
    handleGetAllStudents();
    handleGetAllGradingSystem();
  }, []);

  useEffect(() => {
    if (class_id?.classId) {
      getAllSubjectForClass(class_id?.classId);
    }
  }, [class_id?.classId]);

  const handleClassChange = (event, newValue) => {
    setValue(newValue);
    getAllSubjectForClass(value);
  };

  // Handle TextChange
  const handleTextChange = (e) => {
    const { name, value } = e.target;

    // Update the form state
    const updatedForm = { ...subjectForm, [name]: value };

    // CA_total and exam_score to calculate the total score
    const caTotal = parseFloat(updatedForm.CA_total) || 0;
    const examScore = parseFloat(updatedForm.exam_score) || 0;

    // Update the total score
    updatedForm.total = caTotal + examScore;

    if (updatedForm?.total > 100) {
      toast.error("Total score cannot exceed 100!");
    }

    // Calculate the grade based on the updated total score
    let grade = "";
    if (selectedGrade?.grading) {
      const gradeDetails = selectedGrade.grading.find(
        (grade) => updatedForm.total >= grade.min && updatedForm.total <= grade.max
      );
      grade = gradeDetails ? gradeDetails.grade : "";

      // Update the calculated grade
      setCalculatedGrade(grade);
    }

    // Update the grade in the form
    updatedForm.grade = grade;

    // Set the updated form state
    setSubjectForm(updatedForm);
  };

  // handle EDit InputChange
  const handleEditTextChange = (e) => {
    const { name, value } = e.target;

    // Update the form state
    const updatedForm = { ...editsubjectForm, [name]: value };

    // CA_total and exam_score to calculate the total score
    const caTotal = parseFloat(updatedForm.CA_total) || 0;
    const examScore = parseFloat(updatedForm.exam_score) || 0;

    // Update the total score
    updatedForm.total = caTotal + examScore;

    if (updatedForm?.total > 100) {
      toast.error("Total score cannot exceed 100!");
    }

    // Calculate the grade based on the updated total score
    let grade = "";
    if (selectedGrade?.grading) {
      const gradeDetails = selectedGrade.grading.find(
        (grade) => updatedForm.total >= grade.min && updatedForm.total <= grade.max
      );
      grade = gradeDetails ? gradeDetails.grade : "";

      // Update the calculated grade
      setEditCalculatedGrade(grade);
    }

    // Update the grade in the form
    updatedForm.grade = grade;

    // Set the updated form state
    setEditSubjectForm(updatedForm);
  };

  // Handle Select Grade system
  const handleGradeChange = (e) => {
    const selectedId = e.target.value;
    const selectedGradingSystem = allGradingSystem.find(
      (gradeSystem) => gradeSystem?.id === selectedId
    );

    setSelectedGrade(selectedGradingSystem);

    if (selectedGradingSystem && subjectForm?.total !== null) {
      const gradeDetails = selectedGradingSystem.grading.find(
        (grade) => subjectForm.total >= grade.min && subjectForm.total <= grade.max
      );

      if (gradeDetails) {
        setCalculatedGrade(gradeDetails?.grade);
        // Update the grade in the subjectForm state
        setSubjectForm((prevForm) => ({
          ...prevForm,
          grade: gradeDetails?.grade,
          grading_system_id: selectedId
        }));
      } else {
        toast.error("Total does not fall within any grade range.");
        // Optionally clear the grade if no range matches
        setSubjectForm((prevForm) => ({
          ...prevForm,
          grade: "",
          grading_system_id: ""
        }));
      }
    }
  };

  // Handle Select Grade System for Edit Modal
  const handleEditGradeChange = (e) => {
    const selectedId = e.target.value || editsubjectForm?.grading_system?.id;
    const selectedGradingSystem = allGradingSystem.find(
      (gradeSystem) => gradeSystem?.id === selectedId
    );

    setEditSelectedGrade(selectedGradingSystem);

    if (selectedGradingSystem && editsubjectForm?.total !== null) {
      const gradeDetails = selectedGradingSystem.grading.find(
        (grade) => editsubjectForm.total >= grade.min && editsubjectForm.total <= grade.max
      );

      if (gradeDetails) {
        setEditCalculatedGrade(gradeDetails?.grade);
        // Update the grade in the subjectForm state
        setEditSubjectForm((prevForm) => ({
          ...prevForm,
          grade: gradeDetails?.grade,
          grading_system: selectedId
        }));
      } else {
        toast.error("Total does not fall within any grade range.");
        // Optionally clear the grade if no range matches
        setEditSubjectForm((prevForm) => ({
          ...prevForm,
          grade: "",
          grading_system: ""
        }));
      }
    }
  };

  const columns = [
    {
      name: "Student Names",
      selector: row => `${row?.student?.first_name} ${row?.student?.last_name}` || "--/--"
    },
    {
      name: "CA 1",
      // eslint-disable-next-line no-undefined
      selector: row => (row?.CA_scores?.CA1 !== undefined ? row?.CA_scores?.CA1 : "--/--")
    },
    {
      name: "CA 2",
      // eslint-disable-next-line no-undefined
      selector: row => (row?.CA_scores?.CA2 !== undefined ? row?.CA_scores?.CA2 : "--/--")
    },
    {
      name: "CA Score",
      selector: row => row?.CA_total || "--/--"
    },
    {
      name: "Exam Score",
      selector: row => row?.exam_score || "--/--"
    },
    {
      name: "Total",
      selector: row => row?.total || "--/--"
    },
    {
      name: "Grade",
      selector: row => row?.grade || "--/--"
    },
    {
      name: "Date",
      selector: row => row?.created_at.substring(0, 10) || "--/--"
    },
    {
      name: "",
      center: "true",
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button className="table-action-icon"
            onClick={() => handleEditResultModal(row)}
          >
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  // Handle Filter for All School Examination
  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilterStatus(value);

    if (value.includes("-")) {
      // Extract the min and max scores from the value
      const [min, max] = value.split("-").map(Number);

      // Filter data based on the score range
      const filteredByRange = classGrades?.filter((data) => {
        const totalScore = parseFloat(data?.total || "0");

        return totalScore >= min && totalScore <= max;
      });

      setSearchResult(filteredByRange);
    } else {
      // Reset to all data if no valid range is selected
      setSearchResult(classGrades);
    }
  };

  // --| Filter Student table using name, email and class
  const handleSearchStudentResult = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");

    const filteredByScore = filterStatus
      ? classGrades.filter((data) => data.total.toLowerCase() === filterStatus.toLowerCase())
      : classGrades;
    // --| Filter data by partial match onchange in the search input box
    const result = filteredByScore?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                                    data?.total
                                      ?.toLowerCase()
                                      .includes(word.toLowerCase()) ||
                                    data?.exam_score
                                      ?.toLowerCase()
                                      .includes(word.toLowerCase()) ||
                                      data?.grade
                                        ?.toLowerCase()
                                        .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  useEffect(() => {
    if (classesData?.length > 0 && !value) {
      const class_value = classesData[0]?.id;
      setValue(class_value);
    }
  }, [classesData]);

  useEffect(() => {
    getAllClasses();
    handleGetASubjectById();
    handleGetClassById();
  }, []);

  // eslint-disable-next-line no-console
  console.log(editsubjectForm, "editsubjectForm");

  // eslint-disable-next-line no-console
  console.log(editselectedGrade, "editselectedGrade");

  return (
    <Layout children={
      <div>
        <div className="breadcrumb-container">
          <div>
            <BreadCrumb link_one="/class-activities/subjects" link_one_name="My Subjects" active={aSubjectDetail?.name} description="See academic result of each student taking this subject" />
          </div>
          <div>
            <Button
              onClick={handleAddSubjectModal}
              variant="primary"
              buttonSize="fluid"
              color="btndefault"
              buttonName="Add Student Result"
            />
          </div>
        </div>
        <div className="page-content">
          <div className="action_div flex-wrap-items-600">
            <div className={classes.contentpadding}>
              <ButtonCount func={() => navigate("")} title={`No of Results in ${classDetail?.class_name}`} count={classGrades?.length} />
            </div>
            <div className="flex gap-10 align-items-end margin-top-5 margin-bottom-14">
              <div className="margin-bottom-10">
                {isPageFetching ? <Icon icon="svg-spinners:270-ring-with-bg" width="20" height="20" style={{ color: "#858585" }} />
                  : <Icon onClick={() => handleGetSubjectResult(active_term_session?.id)} icon="hugeicons:reload" width="20" height="20" style={{ color: "#858585" }} />}
              </div>
              <div>
                {activeSession?.id && (
                  <SelectGroup
                    children={
                      <select
                        defaultValue={activeSession?.id}
                        onChange={handleSessionChange}
                        name="session"
                      >
                        <option value="">---Select---</option>
                        {allSessions?.map((sesh) => {
                          return (
                            <option key={sesh?.id} value={sesh?.id}>
                              {sesh?.session}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                )}
              </div>
              <div>
                {active_term_session?.id && (
                  <SelectGroup
                    children={
                      <select onChange={handleTermChange} name="term" value={aselectedTerm}>
                        <option value="">---Select---</option>
                        {selectedSessionTerms?.map((data) => {
                          return (
                            <option key={data?.id} value={data?.id}>
                              {data?.term}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                )}
              </div>
            </div>
          </div>
          {isPageFetching ? (
            <PageLoader />
          ) : classGrades.length > 0 ? (
            <Table
              data={searchResult}
              columns={columns}
              subHeader={true}
              pagination
              subHeaderComponent={
                searchResult?.length > 0 && (
                  <div className="action_div">
                    <TableSearch
                      placeholder="Search here..."
                      searchTableFunc={handleSearchStudentResult}
                    />
                    <div>
                      <SelectGroup
                        children={
                          <select name="type" onChange={handleFilterChange}>
                            <option value="">-- Sort By--</option>
                            <option value="90-100">90 - 100</option>
                            <option value="80-89">80 - 89</option>
                            <option value="70-79">70 - 79</option>
                            <option value="60-69">60 - 69</option>
                            <option value="50-59">50 - 59</option>
                            <option value="40-49">40 - 49</option>
                            <option value="30-39">30 - 39</option>
                            <option value="20-29">20 - 29</option>
                          </select>
                        }
                      />
                    </div>
                  </div>
                )}
            />
          ) : (
            <div className={classes.notfound}>
              <NotFounditem
                img={nosearch}
                title="No Result Found"
                subtitle="No Subject Result has been added to the list."
              />
            </div>
          )}
          <div className="flex justify-items-space Blockspacing">
            <div className={classes.contentpadding}>
              <ButtonCount func={() => navigate("")} title={`No of Students in ${classDetail?.class_name}`} count={allStudent?.length} />
            </div>
            <div className={classes.employee}>
              <p>Class Teacher: </p>
              <h4 onClick={() => navigate("/employees")}>{classDetail?.class_teacher?.name || "---/---"}</h4>
            </div>
          </div>
        </div>
        {/* Delete Modal */}
        <Modal
          title={`Delete ${currentRow?.student?.last_name} ${currentRow?.student?.first_name} Result`}
          subtitle={
            <div>Are you sure you want to delete <span className="modal-name-highlight">{currentRow?.student?.last_name} {currentRow?.student?.first_name}</span>Result? Deleting is a permanent action
            </div>}
          modalContent={
            <>

            </>
          }
          modalFooter={
            <>
              <Button variant="danger" onClick={handleDeleteStudentResult} isLoading={resultDeleting} buttonName="Delete Result" buttonSize="full" color="btndefault" />
            </>
          }
          isopen={deleteModal}
          closeModal={handleCloseDeleteModal}
        />
        {/* Add Results */}
        <Modal
          title="Add Result"
          subtitle={
            <div>
                Manually add your student’s result.
            </div>
          }
          modalContent={
            <>
              <Grid container spacing={2} className="input-padding">
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <SelectGroup
                    label="Class"
                    required
                    children={
                      <select disabled value={ClassID} name="class_id" onChange={handleClassChange}>
                        <option value="">--- Select ---</option>
                        {classesData?.map((class_id) => {
                          return (
                            <option key={class_id.id} value={class_id?.id}>
                              {class_id?.class_name}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {studentResultValidator.message("Class", subjectForm?.class_id, "required")}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <SelectGroup
                    label="Subject"
                    required
                    children={
                      <select disabled value={id} name="subject_id" onChange={handleTextChange}>
                        <option value="">--- Select ---</option>
                        {allClassSubject?.map((subject) => {
                          return (
                            <option key={subject.subject_id} value={subject?.subject_id}>
                              {subject?.subject_name}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {studentResultValidator.message("Subject", subjectForm?.subject_id, "required")}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <SelectGroup
                    label="Students"
                    required
                    children={
                      <select name="student_id" onChange={handleTextChange}>
                        <option value="">--- Select ---</option>
                        {allStudent?.map((student) => {
                          return (
                            <option key={student.student_id} value={student?.student_id}>
                              {`${student?.student?.last_name} ${student?.student.first_name}`}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {studentResultValidator.message("Student", subjectForm?.student_id, "required")}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    {testArr?.map((item, index) => (
                      <Grid key={index} item lg={6} md={6} sm={12} xs={12}>
                        <InputGroup
                          label={`CA ${index + 1}`}
                          margin="normal"
                          required
                          placeHolder="Enter score"
                          inputType="text"
                          onChange={(e) => handleTestScoreChange(e, index)}
                          value={item}
                        />
                        {studentResultValidator.message(
                          `CA ${index + 1}`,
                          subjectForm?.CA_scores?.[`CA${index + 1}`],
                          "required"
                        )}
                        {testArr?.length > 1 && (
                          <p
                            className="delete-input"
                            onClick={() => handleDeleteTestScore(index)}
                          >
                            <Icon icon="zondicons:minus-solid" />
                              Remove this CA
                          </p>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {testArr?.length < 2 && (
                    <div
                      className={`${classes.mail} flex align-items-center`}
                      onClick={AddAdditionalTestScore}
                    >
                      <Icon icon="octicon:feed-plus-16" width="16" height="16" />
                      <p>Add another CA</p>
                    </div>
                  )}
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <InputGroup
                    label="Total CA Score"
                    margin="normal"
                    inputType="text"
                    required
                    inputName="CA_total"
                    value={subjectForm?.CA_total}
                    disabled
                  />
                  {studentResultValidator.message("CA Total", subjectForm?.CA_total, "required")}
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <InputGroup
                    label="Exam Score"
                    margin="normal"
                    inputType="text"
                    required
                    inputName="exam_score"
                    onChange={handleTextChange}
                  />
                  {studentResultValidator.message("Exam Score", subjectForm?.exam_score, "required")}
                </Grid>
                <Grid item lg={4} md={12} sm={12} xs={12}>
                  <InputGroup
                    label="Total Score"
                    margin="normal"
                    inputType="text"
                    required
                    inputName="total"
                    value={subjectForm?.total || 0}
                    disabled
                  />
                  {studentResultValidator.message("Total Score", subjectForm?.total, "required")}
                </Grid>
                <Grid item lg={9} md={12} sm={12} xs={12}>
                  <SelectGroup
                    label="Grade System"
                    required
                    children={
                      <select name="grading_system_id" onChange={handleGradeChange}>
                        <option value="">--- Select ---</option>
                        {allGradingSystem?.map((grade_system) => {
                          return (
                            <option key={grade_system?.id} value={grade_system?.id}>
                              {grade_system?.title}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                </Grid>
                <Grid item lg={3} md={12} sm={12} xs={12}>
                  <SelectGroup
                    label="Grade"
                    required
                    children={
                      <select disabled value={calculatedGrade || ""} name="grade" onChange={handleTextChange}>
                        <option value="">--- Select ---</option>
                        {selectedGrade?.grading?.map((grade_type) => {
                          return (
                            <option key={grade_type?.grade} value={grade_type?.grade}>
                              {grade_type?.grade}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {studentResultValidator.message("Grade", subjectForm?.grade, "required")}
                </Grid>
              </Grid>
            </>
          }
          modalFooter={
            <>
              <div className={classes.modalFooterBtn}>
                <Button
                  variant="primary"
                  buttonSize="full"
                  color="btndefault"
                  buttonName="Add Result"
                  onClick={handlePostStudentResult}
                  isLoading={resultCreating}
                />
              </div>
            </>
          }
          isopen={addsubjectModal}
          closeModal={handleCloseAddResultModal}
        />
        {/* Edit Results */}
        <Modal
          title={`Edit ${currentRow?.student?.last_name} ${currentRow?.student?.first_name} Result`}
          subtitle={
            <div>
                Manually Edit your student’s result.
            </div>
          }
          modalContent={
            <>
              <Grid container spacing={2} className="input-padding">
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <SelectGroup
                    label="Class"
                    required
                    children={
                      <select disabled value={ClassID} name="class_id" onChange={handleClassChange}>
                        <option value="">--- Select ---</option>
                        {classesData?.map((class_id) => {
                          return (
                            <option key={class_id.id} value={class_id?.id}>
                              {class_id?.class_name}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {editstudentResultValidator.message("Class", editsubjectForm?.class_id, "required")}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <SelectGroup
                    label="Subject"
                    required
                    children={
                      <select disabled value={id} name="subject_id" onChange={handleTextChange}>
                        <option value="">--- Select ---</option>
                        {allClassSubject?.map((subject) => {
                          return (
                            <option key={subject.subject_id} value={subject?.subject_id}>
                              {subject?.subject_name}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {editstudentResultValidator.message("Subject", editsubjectForm?.subject_id, "required")}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <SelectGroup
                    label="Students"
                    required
                    children={
                      <select value={currentRow?.student?.id} disabled name="student_id" onChange={handleTextChange}>
                        <option value="">--- Select ---</option>
                        {allStudent?.map((student) => {
                          return (
                            <option key={student.student_id} value={student?.student_id}>
                              {`${student?.student?.last_name} ${student?.student.first_name}`}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {editstudentResultValidator.message("Student", editsubjectForm?.student_id, "required")}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    {Object.entries(editsubjectForm?.CA_scores || {}).map(([key, value], index) => (
                      <Grid key={index} item lg={6} md={6} sm={12} xs={12}>
                        <InputGroup
                          label={key}
                          margin="normal"
                          required
                          placeHolder="Enter score"
                          inputType="text"
                          onChange={(e) => handleEditTestScoreChange(e, index)}
                          defaultValue={value}
                        />
                        {editstudentResultValidator.message(
                          key,
                          editsubjectForm?.CA_scores?.[key],
                          "required"
                        )}
                        {Object.keys(editsubjectForm?.CA_scores || {}).length > 1 && (
                          <p
                            className="delete-input"
                            onClick={() => handleDeleteEditTestScore(key)}
                          >
                            <Icon icon="zondicons:minus-solid" />
                              Remove this CA
                          </p>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {Object.keys(editsubjectForm?.CA_scores || {}).length < 2 && (
                    <div
                      className={`${classes.mail} flex align-items-center`}
                      onClick={AddAdditionalEditTestScore}
                    >
                      <Icon icon="octicon:feed-plus-16" width="16" height="16" />
                      <p>Add another CA</p>
                    </div>
                  )}
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <InputGroup
                    label="Total CA Score"
                    margin="normal"
                    inputType="text"
                    required
                    inputName="CA_total"
                    value={editsubjectForm?.CA_total}
                    disabled
                  />
                  {editstudentResultValidator.message("CA Total", editsubjectForm?.CA_total, "required")}
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <InputGroup
                    label="Exam Score"
                    margin="normal"
                    inputType="text"
                    required
                    defaultValue={editsubjectForm?.exam_score}
                    inputName="exam_score"
                    onChange={handleEditTextChange}
                  />
                  {editstudentResultValidator.message("Exam Score", editsubjectForm?.exam_score, "required")}
                </Grid>
                <Grid item lg={4} md={12} sm={12} xs={12}>
                  <InputGroup
                    label="Total Score"
                    margin="normal"
                    inputType="text"
                    required
                    inputName="total"
                    value={editsubjectForm?.total || 0}
                    disabled
                  />
                  {editstudentResultValidator.message("Total Score", editsubjectForm?.total, "required")}
                </Grid>
                <Grid item lg={9} md={12} sm={12} xs={12}>
                  <SelectGroup
                    label="Grade System"
                    required
                    children={
                      <select value={editsubjectForm?.grading_system?.id} name="grading_system" onChange={handleEditGradeChange}>
                        <option value="">--- Select ---</option>
                        {allGradingSystem?.map((grade_system) => {
                          return (
                            <option key={grade_system?.id} value={grade_system?.id}>
                              {grade_system?.title}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {editstudentResultValidator.message("Grade System", editsubjectForm?.grading_system, "required")}
                </Grid>
                <Grid item lg={3} md={12} sm={12} xs={12}>
                  <SelectGroup
                    label="Grade"
                    required
                    children={
                      <select disabled value={editcalculatedGrade || editsubjectForm?.grade} name="grade">
                        <option value="">--- Select ---</option>
                        {editselectedGrade?.grading?.map((grade_type) => {
                          return (
                            <option key={grade_type?.grade} value={grade_type?.grade}>
                              {grade_type?.grade}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {editstudentResultValidator.message("Grade", editsubjectForm?.grade, "required")}
                </Grid>
              </Grid>
            </>
          }
          modalFooter={
            <>
              <div className={classes.modalFooterBtn}>
                <Button
                  variant="primary"
                  buttonSize="full"
                  color="btndefault"
                  buttonName="Edit Result"
                  onClick={handleEditStudentResult}
                  isLoading={resultEditing}
                />
              </div>
            </>
          }
          isopen={editResultModal}
          closeModal={handleCloseEditResultModal}
        />
        <ToastContainer />
      </div>
    }
    />
  );
};

export default SubjectResultDetails;
