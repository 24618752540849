import useStyles from "./styles";
import { useEffect, useState } from "react";
import { Logout } from "@mui/icons-material";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import SettingsImg from "../../assets/images/settings.svg";
// import SchoolLogo from "../../assets/images/logoSch.svg";
import { ToastContainer } from "react-toastify";
import MenuLogo from "../../assets/images/dashboadlogo.svg";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import ApplicantExamination from "./components/ApplicantExamination";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import ApplicantResult from "./components/ApplicantResult";
import ApplicantPassword from "./components/Password";
import ApplicantDashboard from "./components/ApplicantDashboard";
import DrawerComp from "./components/Drawer";
import { axiosrequest } from "../../data/api";
import { useUserState } from "../../context/UserContext";
// import TabPanel from "@mui/lab/TabPanel";

const APPLICANT_API = "/api/v1/applicant/profile";

const Studentoverview = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [applicantProfile, setApplicantProfile] = useState({});
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { appLogo } = useUserState();

  const getApplicantProfile = () => {
    // --| Get Applicant profile and pass it to context
    axiosrequest
      .get(APPLICANT_API).then((res) => {
        setApplicantProfile(res?.data?.data);
      })
      .catch(() => {

      });
  };
  // const [openDrawer, setOpenDrawer] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    // setOpenDrawer(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("enrol-auth");
    navigate("/auth/applicant");
  };

  const Initial = applicantProfile?.first_name?.split("")[0].toUpperCase();

  // const handleTabClick = (tabIndex) => {
  //   setSelectedTab(tabIndex);
  // };

  useEffect(() => {
    if (!localStorage.getItem("enrol-auth")) {
      navigate("/auth/applicant");
    }
  }, []);

  // --| get applicant profile on page load
  useEffect(() => {
    getApplicantProfile();
  }, []);

  // --| Responsive Bar
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  //   For Vertical Tab
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`
    };
  }

  return (
    <div className={`${classes.studentcontainer}`}>
      <div className={`${classes.contheader} padding-left-20 flex gap-8 justify-items-space align-items-center`}>
        <div><img src={applicantProfile?.branding?.brand_logo?.file_url || appLogo} alt="" className={classes.logo} /></div>
        {isMatch ? (
          <>
            <DrawerComp value={value} handleChange={handleChange} />
          </>
        ) : (
          <div className={classes.footer}>
            <div className={classes.footercontainer}>
              <div className={classes.initials}>{Initial}</div>
              <div className={classes.footertextwrapper}>
                <p className={classes.name}>{applicantProfile?.first_name}{" "}{applicantProfile?.last_name}</p>
                <p className={classes.email}>{applicantProfile?.email}</p>
              </div>
            </div>
            <div className={classes.logout} onClick={handleLogout}>
              <Logout />
            </div>
          </div>
        )}
      </div>
      <Grid className={classes.overviewgridcont} container>
        {isMatch ? null : (
          <>
            <Grid item lg={2} md={2} sm={12} xs={12}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider", padding: "8px 0px", border: "1px solid #E4E7EC", borderRadius: "9px", marginTop: "24px", marginLeft: "20px", background: "white" }}
              >
                <Tab sx={{ paddingLeft: "16px" }} label={<div className="flex gap-10 justify-item-left"><img src={MenuLogo} alt="" /><h5 className={`${classes.ForFonts} margin-block-3`}>Dashboard</h5></div>} {...a11yProps(0)} />
                <Tab label={<div className="flex gap-10 justify-item-left"><Icon icon="mingcute:paper-line" width="20" height="20" style={{ color: "#6C737F" }} /><h5 className={`${classes.ForFonts} margin-block-3`}>Examination</h5></div>} {...a11yProps(1)} />
                <Tab label={<div className="flex gap-10 justify-item-left"><Icon icon="pajamas:search-results" width="20" height="20" style={{ color: "#6C737F" }} /><h5 className={`${classes.ForFonts} margin-block-3`}>Results</h5></div>} {...a11yProps(2)} />
                <Tab label={<div className="flex gap-10 justify-item-left"><img src={SettingsImg} alt="" /><h5 className={`${classes.ForFonts} margin-block-3`}>Settings</h5></div>} {...a11yProps(3)} />
              </Tabs>
            </Grid>
          </>
        )}
        <Grid item lg={10} md={10} sm={12} xs={12}>
          <TabPanel value={value} index={0}>
            <ApplicantDashboard profile={applicantProfile} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ApplicantExamination />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ApplicantResult />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ApplicantPassword />
          </TabPanel>
        </Grid>
      </Grid>
      <ToastContainer />
    </div>
  );
};

export default Studentoverview;
