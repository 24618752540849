/* eslint-disable prefer-template */
import Layout from "../../../components/Layout";
import useStyles from "../styles";
import Button from "../../../components/Button";
import PageTitle from "../../../components/PageTitle";
import BreadCrumb from "../../../components/BreadCrumb";
import { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { Icon } from "@iconify/react";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Modal from "../../../components/Modal";
import { Grid } from "@mui/material";
import InputGroup from "../../../components/FormComponent/InputGroup";
import TextAreaGroup from "../../../components/FormComponent/TextAreaGroup";
import Badge from "../../../components/Badge";
import { httprequest } from "../../../data/api";
import { useParams, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import WidgetTitle from "../../dashboard/components/WidgetTitle/WidgetTitle";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
import AssignmentDetailText from "../../assignment/components/AssignmentDetailText";

const ASSIGNMENT_ENDPOINT = "/api/v1/student/assignment";

const AssignmentDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [messageModal, setMessageModal] = useState(false);
  const [addreportModal, setReportModal] = useState(false);
  const [assignmentData, setAssignmentData] = useState([]);
  const [value, setValue] = useState("1");
  const [assignmentAttendees, setAssignmentAttendees] = useState([]);
  const [attendeeFilter, setAttendeeFilter] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columns = [
    {
      name: "Student Name",
      selector: row => row?.student?.name
    },
    {
      name: "Class Name",
      selector: row => row?.class?.name
    },
    {
      name: "Status",
      selector: row => <Badge status={row?.status} />
    },
    {
      name: "Submission",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.submission_id} className="table-action-container">
          <Link to={`/assignment/submission/${row?.submission_id}`} className="table-action-icon">
            <Icon icon="fluent:eye-16-regular" />
          </Link>
        </div>
      )
    }
  ];

  const handleMessageModal = () => {
    setMessageModal(!messageModal);
  };

  const handleReportModal = () => {
    setReportModal(!addreportModal);
  };

  const getAssignmentById = () => {
    httprequest.get(`${ASSIGNMENT_ENDPOINT}`).then((res) => {
      setAssignmentData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };
  // eslint-disable-next-line no-console
  console.log(assignmentData, "assignmentData");

  const getAssignmentRecipients = () => {
    httprequest.get(`${ASSIGNMENT_ENDPOINT}/students/${id}`).then((res) => {
      setAssignmentAttendees(res?.data?.data);
      setAttendeeFilter(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // --| Filter Attendees table using name
  const handleSearchAssignmentByClass = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = assignmentAttendees?.filter((data) => valArray?.every(
      (word) => data?.student_name
        ?.toLowerCase()
        .includes(word.toLowerCase())
    )
    );
    setAttendeeFilter(result);
  };

  useEffect(() => {
    if (value === "1") {
      getAssignmentById();
    } else {
      getAssignmentRecipients();
    }
  }, [value]);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/assignments"
                link_one_name="Assignments"
                active={`${assignmentData?.subject?.name} Assignment`}
                description="See the details of this asignment"
              />
            </div>
          </div>
          <div className="page-content">
            <PageTitle className={classes.inline}
              title={`${assignmentData?.title}`}
              subtitle={`Created By:${assignmentData?.created_by?.name} | Class: ${assignmentData?.class?.name} `}
            />
            <TabContext value={value}>
              {assignmentData.length > 0 && (
                <div className="tablist-container">
                  <Tabs indicatorColor="none" className="overide-tablist" onChange={handleChange} aria-label="">
                    <Tab label="Assignment Details" value="1" />
                    {/* <Tab label="Attendees" value="2" /> */}
                  </Tabs>
                </div>
              )}
              <TabPanel value="1" className="tabpanel-overide">
                <Grid container spacing={1}>
                  <div className="key-pair-container">
                    <Grid container spacing={1}>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <AssignmentDetailText title="Subject Name" subtitle={assignmentData?.subject?.name || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <AssignmentDetailText title="Term" subtitle={assignmentData?.term?.name || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <AssignmentDetailText title="Created By" subtitle={assignmentData?.created_by?.name || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <AssignmentDetailText title="Created at" subtitle={assignmentData?.created_at?.substring(0, 10) || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <AssignmentDetailText title="Title" subtitle={assignmentData?.title || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <AssignmentDetailText title="Publish Date" subtitle={assignmentData?.publish_date?.substring(0, 10) || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <AssignmentDetailText title="Submission Date" subtitle={assignmentData?.submission_date?.substring(0, 10) || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <AssignmentDetailText title="Updated At" subtitle={assignmentData?.updated_at?.substring(0, 10) || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <AssignmentDetailText
                          title="Status"
                          subtitle={
                            <div className="subtitle-container">
                              {<Badge status={assignmentData?.status} /> || "-----"}
                            </div>
                          }
                        />
                      </Grid>
                      <Grid item lg={2} md={4} sm={6} xs={12}>
                        <AssignmentDetailText title="Instruction" subtitle={assignmentData?.instruction || "-----"} />
                      </Grid>
                    </Grid>
                  </div>
                  {/* <div className="key-pair-container">
                    <div className="key-pair-content">
                      <p className="key-pair-name">Status</p>
                      <h3 className="key-pair-value"> <Badge status={assignmentData?.status} /></h3>
                    </div>
                  </div> */}
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <WidgetTitle
                      title="Questions"
                    />
                    <Grid container spacing={2}>
                      {assignmentData?.questions?.map((item, i) => {
                        const hasImageOption = item?.options?.some(option => typeof option === "object" && option.file_url);

                        return (
                          <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
                            <div className="question-preview-container">
                              <h3 className="question-preview-heading">
                                Question {item?.question_index}
                              </h3>
                              <div className={classes.question}>
                                <h3 className="question-preview-heading">
                                  {item?.question} {item?.required ? <span className="input-required">*</span> : null}
                                  {item?.file && (
                                    <div className={classes.quesdisplay}>
                                      <img src={item?.file?.file_url} alt="" />
                                    </div>
                                  )}
                                </h3>

                                {item?.type === "multiple_choice" ? (
                                  hasImageOption ? (
                                    <Grid container spacing={2}>
                                      {item?.options?.map((option, i) => {
                                        const isImageOption = typeof option === "object" && option.file_url;

                                        return (
                                          <Grid item lg={6} md={6} sm={12} xs={12} key={i}>
                                            <div className="options-preview">
                                              <input type="radio" checked={assignmentData?.questions[item?.question_index - 1]?.answer?.includes(i)} name={item?.question_index} disabled />
                                              {isImageOption ? (
                                                <div className={classes.optiondisplay}>
                                                  <img src={option.file_url} alt={`Option ${i + 1}`} />
                                                </div>
                                              ) : (
                                                <label>{option}</label>
                                              )}
                                            </div>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  ) : (
                                    <>
                                      {item?.options?.map((option, i) => (
                                        <div key={i} className="options-preview">
                                          <input type="radio" checked={assignmentData?.questions[item?.question_index - 1]?.answer?.includes(i)} name={item?.question_index} disabled />
                                          <label>{option}</label>
                                        </div>
                                      ))}
                                    </>
                                  )
                                ) : item?.type === "checkbox" ? (
                                  hasImageOption ? (
                                    <Grid container spacing={2}>
                                      {item?.options?.map((option, i) => {
                                        const isImageOption = typeof option === "object" && option.file_url;

                                        return (
                                          <Grid item lg={6} md={6} sm={12} xs={12} key={i}>
                                            <div className="options-preview">
                                              <input type="checkbox" checked={assignmentData?.questions[item?.question_index - 1]?.answer?.includes(i)} name={item?.question_index} disabled />
                                              {isImageOption ? (
                                                <div className={classes.optiondisplay}>
                                                  <img src={option.file_url} alt={`Option ${i + 1}`} />
                                                </div>
                                              ) : (
                                                <label>{option}</label>
                                              )}
                                            </div>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  ) : (
                                    <>
                                      {item?.options?.map((option, i) => (
                                        <div key={i} className="options-preview">
                                          <input type="checkbox" checked={assignmentData?.questions[item?.question_index - 1]?.answer?.includes(i)} name={item?.question_index} />
                                          <label>{option}</label>
                                        </div>
                                      ))}
                                    </>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </Grid>
                        );
                      })}

                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                <Table data={attendeeFilter} columns={columns} subHeader={true} pagination subHeaderComponent={
                  assignmentData?.length > 0 && (
                    <TableSearch placeholder="Search here..." searchTableFunc={handleSearchAssignmentByClass} />
                  )
                }
                />
              </TabPanel>
            </TabContext>
            <ToastContainer />
          </div>
          {/* Send Report Modal */}
          <Modal
            title="Send Student Report"
            subtitle={
              <div>You are about to share the computed result sheets to the parent/guardian of Ojo William.
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Parent/Guardian Email"
                      margin="normal"
                      placeHolder="Enter guardian mail"
                      inputType="text"
                    />
                  </Grid>
                  <div className={`${classes.mail} flex align-items-center`}>
                    <Icon icon="fluent:mail-12-filled" width="24" height="24" />
                    <p>Use an alternative email</p>
                  </div>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="primaryoutline" buttonSize="full" color="btnfontprimary" buttonName="Cancel" />
                  <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Send Report" />
                </div>
              </>
            }
            isopen={addreportModal}
            closeModal={handleReportModal}
          />
          {/* Send Broadcast Modal */}
          <Modal
            title="Message Parent/Guardian"
            subtitle={
              <div>Send a message to the parents of the students in this class
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Parent Name"
                      margin="normal"
                      placeHolder="Parent Name"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Parent/Guardian Email"
                      margin="normal"
                      placeHolder="Parent Email"
                      inputType="email"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextAreaGroup
                      label="Broadcast Message"
                      margin="normal"
                      placeholder="Enter the message you will like to share with the parents."
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <label className={`${classes.label}`}>Additional Information</label>
                    <div className={classes.pickercontainer}>
                      <input type="file" className={classes.picker} />

                      <div className={classes.uploadflex}>
                        <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                        <div>
                          <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                          <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.</h6>
                        </div>
                      </div>
                      <div className={classes.rightpicker}>
                        <div className={classes.uploadbutton}>Upload</div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="primaryoutline" buttonSize="full" color="btnfontprimary" buttonName="Cancel" />
                  <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Broadcast" />
                </div>
              </>
            }
            isopen={messageModal}
            closeModal={handleMessageModal}
          />
        </>
      }
    />
  );
};

export default AssignmentDetail;
