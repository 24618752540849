import { useEffect, useState } from "react";
// import TabContext from "@mui/lab/TabContext";
import { Grid } from "@mui/material";
// import Tab from "@mui/material/Tab";
import SubmittedImg from "../../../assets/images/submittedimg.svg";
// import Tabs from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
import { toast, ToastContainer } from "react-toastify";
import PageTitle from "../../../components/PageTitle";
import Modal from "../../../components/Modal";
import useStyles from "../styles";
// import Button from "../../../components/Button";
import NoExamFound from "../../../assets/images/noexam.svg";
import ApplicantExaminationCard from "./ApplicantExaminationCard";
import { axiosrequest } from "../../../data/api";
import PageLoader from "../../../components/PageLoader";

const APPLICANTS_EXAM = "/api/v1/applicant/exams";

const ApplicantExamination = () => {
  const classes = useStyles();
  // const [value, setValue] = useState("");
  const [submittedModal, setSubmittedModal] = useState(false);
  const [noExamModal, setNoExamModal] = useState(false);
  const [exams, setExams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const handleTabChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const handleSubmittedModal = () => {
    setSubmittedModal(!submittedModal);
  };

  // get all Applicant Exam
  const getAllApplicantExam = () => {
    setIsLoading(true);
    axiosrequest.get(APPLICANTS_EXAM).then((res) => {
      setExams(res?.data?.data);
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      toast.error(err.response.data);
    });
  };

  useEffect(() => {
    getAllApplicantExam();
  }, []);

  const handleNoExamModal = () => {
    setNoExamModal(!noExamModal);
  };

  return (
    <div className={`${classes.border} page-content`}>
      <PageTitle
        title="All Examinations accessible to you"
        subtitle="These are all the examinations you can take in this enrolment"
        // button={
        //   <div className="flex gap-10">
        //     <Button onClick={handleNoExamModal} variant="primary" buttonSize="fluid" color="btndefault" buttonName="Check for Exam" />
        //   </div>
        // }
      />
      <div className="page-content height-full">
        <Grid container spacing={2}>
          {isLoading ? (
            <div className="padding-top-56 margin-auto">
              <PageLoader />
            </div>
          ) : (
            <>
              {exams && exams.length > 0 ? (
                exams.map((exam, index) => (
                  <Grid item lg={4} md={4} sm={12} xs={12} key={index}>
                    <ApplicantExaminationCard
                      examtitle={exam?.subject}
                      examdesc={exam?.description || "Attempt this Exam"}
                      duration={`${exam?.duration || "duration"} mins`}
                      examlink={`/entrance-examination/${exam?.id}`}
                    />
                  </Grid>
                ))
              ) : (
                <div className={classes.noexamcont}>
                  <div className={classes.noexamimg}>
                    <img src={NoExamFound} alt="Not Found" />
                  </div>
                  <div>
                    <p className={classes.nocodeheader}>No Examination Found</p>
                    <p className={classes.nocodesentence}>The school admin has not uploaded any exam for you to see.</p>
                  </div>
                </div>
              )}
            </>
          )}
        </Grid>
        <ToastContainer />
        {/* <div className="pagination-container">
          <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
        </div> */}
      </div>
      {/* Exam Submitted MOdal */}
      <Modal
        title="Great Job"
        subtitle=""
        modalContent={
          <>
            <div>
              <div className={classes.forerrorimg}>
                <img src={SubmittedImg} alt="Submitted Image" />
              </div>
              <div>
                <p className={classes.nocodeheader}>Your exam has been submitted</p>
                <p className={classes.nocodesentence}>Go to the result section to see your result</p>
              </div>
            </div>
          </>
        }
        modalFooter={
          <>
          </>
        }
        isopen={submittedModal}
        closeModal={handleSubmittedModal}
      />
      {/* No Exam Found MOdal */}
      <Modal
        title="Check For Your Exam"
        subtitle=""
        modalContent={
          <>
            <div>
              <div className={classes.noexamimg}>
                <img src={NoExamFound} alt="Not Found" />
              </div>
              <div>
                <p className={classes.nocodeheader}>No Examination Found</p>
                <p className={classes.nocodesentence}>The school admin has not uploaded any exam for you to see.</p>
              </div>
            </div>
          </>
        }
        modalFooter={
          <>
          </>
        }
        isopen={noExamModal}
        closeModal={handleNoExamModal}
      />
    </div>
  );
};

export default ApplicantExamination;
