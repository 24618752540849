import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import InputGroup from "../../../components/FormComponent/InputGroup";
import logo from "../../../assets/images/logo.svg";
import signupBG from "../../../assets/images/userSignUp.jpg";
import { httprequest } from "../../../data/api";
import Button from "../../../components/Button";
import useValidator from "../../../hooks/useValidator";
import { ToastContainer, toast } from "react-toastify";
import NotFounditem from "../../error/NotFound";
import { jwtDecode } from "jwt-decode";
import { Icon } from "@iconify/react";

// --| Context files
const EMPLOYEE_SIGNUP_ENDPOINT = "/api/v1/auth/user-signup";

const Signup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInvitePresent, setInvitePresent] = useState(false);
  // const [isValidInvite, setisValidInvite] = useState(false);
  const customValidator = {
    password: {
      message:
        "Password should be at least 8 characters, contain a lowercase, uppercase and a number",
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(
          val,
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\w\W]{8,}$/
        );
      },
      required: true
    }
  };
  const [signupValidator, showValidationMessage] = useValidator({}, customValidator);
  const { id } = useParams();
  const decoded = jwtDecode(id);

  const [signUpForm, setSignupForm] = useState({
    first_name: "",
    last_name: "",
    password: "",
    email: "",
    role_type: "",
    school_id: ""
    // job_title: "",
  });

  useEffect(() => {
    if (id) {
      setInvitePresent(true);

      setSignupForm({ ...signUpForm,
        email: decoded?.email,
        first_name: decoded?.first_name,
        last_name: decoded?.last_name,
        role_type: decoded?.role_type,
        school_id: decoded?.school_id
        // job_title: decoded?.job_title
      });
    } else {
      setInvitePresent(false);
    }
  }, [id]);

  const navigate = useNavigate();

  const { first_name, last_name, password, email } = signUpForm;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignupForm({ ...signUpForm, [name]: value });
  };

  const handleSignup = (e) => {
    e.preventDefault();
    // const passwordValidation = {
    //   message: "Password should be at least 8 characters, contain a lowercase, uppercase, and a number",
    //   rule: (val) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(val),
    //   required: true
    // };
    // if (!passwordValidation.rule(password)) {
    //   showValidationMessage(true);
    //   // toast.error(passwordValidation.message);

    //   return;
    // }
    if (signupValidator.allValid()) {
      setIsLoading(true);
      httprequest.patch(EMPLOYEE_SIGNUP_ENDPOINT, signUpForm).then(() => {
        setIsLoading(false);
        toast.success("Account Created Successfully");
        setTimeout(() => navigate("/auth/login"), 1000);
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      showValidationMessage(true);
    }
  };

  const [viewSecretKeys, setViewSecretKeys] = useState({
    secret_key: false
  });

  const toggleSecretKey = (key) => {
    setViewSecretKeys((prevState) => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  return (
    <>
      {isInvitePresent ? (
        <div className="auth-wrapper">
          <div className="auth-container">
            <div className="auth-flex">
              <div className="auth-bg-container">
                <img src={signupBG} className="auth-bg-img" alt="login BG" />
              </div>
              <div className="auth-form-container">
                <div className="auth-logo-container">
                  <img src={logo} alt="logo" className="auth-logo" />
                </div>
                <div className="auth-form">
                  <React.Fragment>
                    <div className="auth-heading black900">Signup</div>
                    <div className="auth-subheading black900">
                Put in your preferred email and password to sign up now on
                Shelf21 for team management.
                    </div>
                    <Grid container spacing={2} className="input-padding">
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <InputGroup
                          label="First Name"
                          value={first_name}
                          onChange={handleChange}
                          margin="normal"
                          inputName="first_name"
                          inputType="text"
                          disabled={decoded.first_name}
                        />
                        {signupValidator.message("first_name", first_name, "required")}
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <InputGroup
                          label="Last Name"
                          value={last_name}
                          onChange={handleChange}
                          margin="normal"
                          inputName="last_name"
                          inputType="text"
                          disabled={decoded.last_name}
                        />
                        {signupValidator.message("last_name", last_name, "required")}
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <InputGroup
                          label="Email Address"
                          value={email}
                          disabled
                          onChange={handleChange}
                          margin="normal"
                          inputName="email"
                          inputType="email"
                        />
                        {signupValidator.message("email", email, "required")}
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <InputGroup
                          label="Password"
                          value={password}
                          onChange={handleChange}
                          inputName="password"
                          inputType={viewSecretKeys.secret_key ? "text" : "password"}
                        />
                        <div className="password-eye-pass" onClick={() => toggleSecretKey("secret_key")}>
                          {viewSecretKeys.secret_key ? (
                            <Icon icon="fluent:eye-16-regular" color="black" />
                          ) : (
                            <Icon icon="iconamoon:eye-off-light" color=" black" />
                          )}
                        </div>
                        <div style={{ marginTop: "-17px" }}>
                          {signupValidator.message("password", password, "required|password")}
                        </div>
                      </Grid>
                    </Grid>
                    <div className="auth-form-buttons">
                      <Button buttonName="Create an Account" variant="primary" buttonSize="full" onClick={handleSignup} isLoading={isLoading} color="btndefault" />
                    </div>
                  </React.Fragment>
                  <div className="auth-divider">
                    <hr />
                    <span className="brown900">Or</span>
                  </div>
                  <div className="brown900 auth-account-button">
              Already have an account ?
                    <Link to="/auth/login" className="app-primary">Login</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      ) : (<NotFounditem />)}
    </>
  );
};

export default Signup;
