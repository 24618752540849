import { TableSearch } from "../../../components/Table/TableActions";
import NotFounditem from "../../error/NotFound";
import nosearch from "../../../assets/images/no-search.svg";
import { httprequest } from "../../../data/api";
import TabContext from "@mui/lab/TabContext";
import Table from "../../../components/Table/Table";
import { Icon } from "@iconify/react";
import Tab from "@mui/material/Tab";
import { useEffect, useState } from "react";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PageLoader from "../../../components/PageLoader";
import Modal from "../../../components/Modal";
import { ToastContainer, toast } from "react-toastify";
import useStyles from "../styles";
import Button from "../../../components/Button";
import Layout from "../../../components/Layout";
import BreadCrumb from "../../../components/BreadCrumb";
import InputGroup from "../../../components/FormComponent/InputGroup";
import useValidator from "../../../hooks/useValidator";
import { useParams, useNavigate } from "react-router-dom";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import { Grid } from "@mui/material";
import ButtonCount from "../../../components/ButtonCount";

// const CLASSES_ENDPOINT = "/api/v1/class";
const CLASS_SUBJECT = "/api/v1/class-subject/";
const GET_SUBJECT_GRADES = "/api/v1/grade/";
const GET_SESSIONS = "/api/v1/school-plan/session";
const TERM_ENDPOINT = "/api/v1/school-plan/term";
const GET_All_STUDENTS = "/api/v1/class/students/";
const SUBJECT_CLASSES = "/api/v1/subjects/";
const GRADING_SYSTEM = "/api/v1/grading-system";

// --| Dummy Data
// import SubjectResult from "../../../dummy_data/SubjectResult.json";

const AllSubjectResultDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  // const [classesData, setClassData] = useState([]);
  const [value, setValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  // const [currentRow, setCurrentRow] = useState({});
  const [isPageFetching, setIsPageFetching] = useState(false);
  // const [deleteModal, setDeleteModal] = useState(false);
  // const [subjectArr, setSubjectArr] = useState([""]);
  const [classDetail, setClassDetail] = useState([]);
  // const [classResultFetching, setClassResultFetching] = useState(false);
  const [addsubjectModal, setSubjectModal] = useState(false);
  const [allGradingSystem, setAllGradingSystem] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [allClassSubject, setAllClassSubject] = useState([]);
  const [resultCreating, setResultCreating] = useState(false);
  const [allStudent, setAllStudents] = useState([]);
  const [classGrades, setClassGrades] = useState([]);
  const [selectedSession, setSelectedSession] = useState("");
  const [aselectedTerm, setASelectedTerm] = useState("");
  const [allSessions, setAllSessions] = useState([]);
  const [aSubjectDetail, setASubjectDetail] = useState([]);
  const [selectedSessionTerms, setSelectedSessionTerms] = useState([]);
  const [activeSession, setActiveSession] = useState([]);
  const [testArr, setTestArr] = useState([""]);
  const [calculatedGrade, setCalculatedGrade] = useState(null);
  const [studentResultValidator, showStudentResultValidator] = useValidator();
  const [subjectForm, setSubjectForm] = useState({
    subject_id: id,
    class_id: "",
    student_id: "",
    CA_scores: { CA1: "" },
    term_id: "",
    grade: "",
    grading_system_id: "",
    total: "",
    exam_score: "",
    CA_total: ""
  });

  // const handleDeleteModal = (row) => {
  //   setCurrentRow(row);
  //   setDeleteModal(!deleteModal);
  // };

  const handleAddSubjectModal = () => {
    setSubjectModal(!addsubjectModal);
  };

  const handleCloseAddResultModal = () => {
    setSubjectModal(!addsubjectModal);
    setTestArr([""]);
    setSubjectForm({ ...subjectForm, CA_scores: { CA1: "" }, CA_total: "", grade: "", total: "", grading_system_id: "", exam_score: "", student_id: "" });
  };

  // const handleSubjectChange = (e, index) => {
  //   const { value } = e.target;
  //   const allSubjects = [...subjectArr];
  //   allSubjects[index] = value;
  //   setSubjectArr(allSubjects);
  //   setSubjectForm({ ...subjectForm, name: allSubjects });
  // };

  // const AddAdditionalSubject = () => {
  //   const newSubject = "";
  //   setSubjectArr([...subjectArr, newSubject]);
  //   setSubjectForm({ ...subjectForm, name: [...subjectArr, newSubject] });
  // };

  // const handleDeleteSubjectItem = (index) => {
  //   const newSubject = subjectArr.filter((_item, ind) => ind !== index);
  //   setSubjectArr(newSubject);
  //   setSubjectForm({ ...subjectForm, name: newSubject });
  // };

  // const handleCloseDeleteModal = () => {
  //   setDeleteModal(false);
  // };

  // Handle Get all Grading System
  const handleGetAllGradingSystem = () => {
    httprequest.get(GRADING_SYSTEM).then((res) => {
      setAllGradingSystem(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data?.message);
    });
  };

  // CA SCores Input
  const handleTestScoreChange = (e, index) => {
    const updatedScores = [...testArr];
    updatedScores[index] = e.target.value;

    // This Map array to object format for CA_scores
    const CA_scores = updatedScores.reduce((acc, score, idx) => {
      acc[`CA${idx + 1}`] = Number(score) || 0;

      return acc;
    }, {});

    // This Calculate the total and average for CA scores
    const totalScores = updatedScores.reduce((sum, score) => sum + (Number(score) || 0), 0);
    const numberOfCAs = updatedScores.length;
    const CA_total = numberOfCAs > 0 ? totalScores / numberOfCAs : 0;

    // Calculate the overall total score (CA_total + exam_score)
    const examScore = parseFloat(subjectForm.exam_score) || 0;
    const totalScore = CA_total + examScore;

    // Calculate the grade based on the updated total score
    let grade = "";
    if (selectedGrade?.grading) {
      const gradeDetails = selectedGrade?.grading.find(
        (grade) => totalScore >= grade.min && totalScore <= grade.max
      );
      grade = gradeDetails ? gradeDetails?.grade : "";
    }

    // Update state
    setTestArr(updatedScores);
    setSubjectForm({
      ...subjectForm,
      CA_scores,
      CA_total: CA_total.toFixed(2),
      total: totalScore.toFixed(2),
      grade
    });
  };

  // Handle Select Grade system
  const handleGradeChange = (e) => {
    const selectedId = e.target.value;
    const selectedGradingSystem = allGradingSystem.find(
      (gradeSystem) => gradeSystem?.id === selectedId
    );

    setSelectedGrade(selectedGradingSystem);

    if (selectedGradingSystem && subjectForm?.total !== null) {
      const gradeDetails = selectedGradingSystem.grading.find(
        (grade) => subjectForm.total >= grade.min && subjectForm.total <= grade.max
      );

      if (gradeDetails) {
        setCalculatedGrade(gradeDetails?.grade);
        // Update the grade in the subjectForm state
        setSubjectForm((prevForm) => ({
          ...prevForm,
          grade: gradeDetails?.grade,
          grading_system_id: selectedId
        }));
      } else {
        toast.error("Total does not fall within any grade range.");
        // Optionally clear the grade if no range matches
        setSubjectForm((prevForm) => ({
          ...prevForm,
          grade: "",
          grading_system_id: ""
        }));
      }
    }
  };

  const handleDeleteTestScore = (index) => {
    // Filter out the selected test score
    const newTestArr = testArr.filter((_item, ind) => ind !== index);

    // Refill the CA_scores object
    const updatedCA_scores = newTestArr.reduce((acc, score, idx) => {
      acc[`CA${idx + 1}`] = score || 0;

      return acc;
    }, {});

    // Calculate the total and average score
    const totalScore = newTestArr.reduce((sum, score) => sum + Number(score || 0), 0);
    const averageScore = newTestArr.length > 0 ? totalScore / newTestArr.length : 0;
    const examScore = parseFloat(subjectForm.exam_score) || 0;
    const overallScore = examScore + totalScore;

    // Update state
    setTestArr(newTestArr);
    setSubjectForm({
      ...subjectForm,
      CA_scores: updatedCA_scores,
      CA_total: averageScore.toFixed(2),
      total: overallScore.toFixed(2)
    });
  };

  const AddAdditionalTestScore = () => {
    const newSubject = "";
    setTestArr([...testArr, newSubject]);
  };

  // Handle get student in a class
  const handleGetAllStudents = () => {
    httprequest.get(`${GET_All_STUDENTS}${value}`).then((res) => {
      setAllStudents(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // const getAllClasses = () => {
  //   setIsPageFetching(true);
  //   httprequest
  //     .get(CLASSES_ENDPOINT)
  //     .then((res) => {
  //       const physicalClasses = res?.data?.data?.filter(
  //         (data) => data?.class_type === "physical"
  //       );
  //       setIsPageFetching(false);
  //       setClassData(physicalClasses);
  //     })
  //     .catch((err) => {
  //       toast.error(err?.response?.data);
  //     });
  // };

  const getAllSubjectForClass = async (class_value) => {
    try {
      const res = await httprequest.get(`${CLASS_SUBJECT}${class_value}`);
      setAllClassSubject(res?.data?.data);
    } catch (err) {
      toast.error(err?.response?.data);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    getAllSubjectForClass(value);
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target.value;
    setSubjectForm({ ...subjectForm, [name]: value });
  };
  const columns = [
    {
      name: "Student Names",
      selector: row => `${row?.student?.first_name} ${row?.student?.last_name}` || "--/--"
    },
    {
      name: "CA 1",
      // eslint-disable-next-line no-undefined
      selector: row => (row?.CA_scores?.CA1 !== undefined ? row?.CA_scores?.CA1 : "--/--")
    },
    {
      name: "CA 2",
      // eslint-disable-next-line no-undefined
      selector: row => (row?.CA_scores?.CA2 !== undefined ? row?.CA_scores?.CA2 : "--/--")
    },
    {
      name: "CA Score",
      selector: row => row?.CA_total || "--/--"
    },
    {
      name: "Exam Score",
      selector: row => row?.exam_score || "--/--"
    },
    {
      name: "Total",
      selector: row => row?.total || "--/--"
    },
    {
      name: "Grade",
      selector: row => row?.grade || "--/--"
    },
    {
      name: "Date",
      selector: row => row?.created_at.substring(0, 10) || "--/--"
    }
    // {
    //   name: "",
    //   center: "true",
    //   sortable: false,
    //   cell: (row) => (
    //     <div key={row?.id} className="table-action-container">
    //       <button className="table-action-icon">
    //         <Icon icon="heroicons:pencil-square-solid" />
    //       </button>
    //       <button
    //         onClick={() => handleDeleteModal(row)}
    //         className="table-action-icon delete-icon"
    //       >
    //         <Icon icon="mingcute:delete-2-line" />
    //       </button>
    //     </div>
    //   )
    // }
  ];

  const getAllTerms = (Session_ID) => {
    httprequest
      .get(TERM_ENDPOINT)
      .then((res) => {
        setSelectedSessionTerms(res?.data?.data.filter((selected_term) => selected_term?.session_id === Session_ID));
      });
    // .catch(() => {});
  };

  // HandleGetClassByID
  // const handleGetClassById = () => {
  //   httprequest.get(`${SUBJECT_CLASSES}${id}/classes`).then((res) => {
  //     setClassDetail(res?.data?.data);
  //   }).catch((err) => {
  //     toast.error(err?.response?.data);
  //   });
  // };

  // Handle Get All Classes
  const getAllSessions = async () => {
    try {
      const res = await httprequest.get(GET_SESSIONS);
      setAllSessions(res?.data?.data);
      setActiveSession(res?.data?.data?.find((active_session) => active_session?.current_session === true));
    } catch (err) {
      toast.error(err?.response?.message);
    }
  };

  // Get Subject result
  const handleGetSubjectResult = async (termId) => {
    setIsPageFetching(true);
    try {
      const res = await httprequest.get(`${GET_SUBJECT_GRADES}${id}/${value}?term_id=${termId}`);
      setIsPageFetching(false);
      setClassGrades(res?.data?.data);
      setSearchResult(res?.data?.data);
    } catch (err) {
      setIsPageFetching(false);
      toast.error(err?.response?.message);
    }
  };

  // Get A Subject Detail
  const handleGetASubjectById = () => {
    httprequest.get(`${SUBJECT_CLASSES}${id}`).then((res) => {
      setASubjectDetail(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // Find Active Term in an Current Session
  const active_term_session = activeSession?.terms?.find((found_active_term) => found_active_term?.is_current === true);

  // Handles the change event when a new tab is selected
  const handleTermChange = (event) => {
    const selectedTermId = event.target.value;
    setASelectedTerm(selectedTermId);
    handleGetSubjectResult(selectedTermId);
  };

  // Create Add Student Result
  const handlePostStudentResult = () => {
    if (studentResultValidator.allValid()) {
      setResultCreating(true);
      httprequest.post(GET_SUBJECT_GRADES, { ...subjectForm, term_id: aselectedTerm }).then((res) => {
        setResultCreating(false);
        toast.success(res?.data?.message);
        handleGetSubjectResult(aselectedTerm);
        handleCloseAddResultModal();
      }).catch((err) => {
        setResultCreating(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      showStudentResultValidator(true);
    }
  };

  // Handle Session Change
  const handleSessionChange = (e) => {
    const Session_ID = e.target.value;
    // setASelectedSession(e.target.value);
    getAllTerms(Session_ID);
  };

  // --| Filter Student table using name, email and class
  const handleSearchInventory = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = searchResult?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                                    data?.description
                                      ?.toLowerCase()
                                      .includes(word.toLowerCase()) ||
                                    data?.category
                                      ?.toLowerCase()
                                      .includes(word.toLowerCase()) ||
                                      data?.status
                                        ?.toLowerCase()
                                        .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  useEffect(() => {
    if (active_term_session?.id) {
      setASelectedTerm(active_term_session.id);
    }
  }, [active_term_session]);

  useEffect(() => {
    if (activeSession?.id) {
      setSelectedSession(activeSession?.id);
      getAllTerms(activeSession?.id);
      // eslint-disable-next-line no-console
      console.log(selectedSession, "selectedSession");
    }
  }, [activeSession]);

  useEffect(() => {
    if (active_term_session?.id) {
      const params = {
        term_id: active_term_session.id
      };
      // setSubjectForm({ ...subjectForm, term_id: active_term_session.id });
      handleGetSubjectResult(params?.term_id);
    }
  }, [active_term_session]);

  useEffect(() => {
    getAllSessions();
    handleGetAllStudents();
    // handleGetClassById();
    handleGetAllGradingSystem();
    handleGetASubjectById();
  }, []);

  useEffect(() => {
    const fetchClassDetail = async () => {
      // setClassResultFetching(true);
      try {
        const res = await httprequest.get(`${SUBJECT_CLASSES}${id}/classes`);
        // setClassResultFetching(false);
        setClassDetail(res?.data?.data);

        if (res?.data?.data?.length > 0 && !value) {
          const classValue = res?.data?.data[0]?.id;
          setValue(classValue);
        }
      } catch (err) {
        // setClassResultFetching(false);
        toast.error(err?.response?.data);
      }
    };

    fetchClassDetail();
  }, [id, value]);

  // eslint-disable-next-line no-console
  console.log(value, "classDetail");

  // eslint-disable-next-line no-console
  console.log(classDetail, "classDetail");

  return (
    <Layout children={
      <div>
        <div className="breadcrumb-container">
          <div>
            <BreadCrumb link_one="/class-activities/subjects" link_one_name="My Subjects" active={aSubjectDetail?.name} description="See academic result of each student taking a subject for each classes" />
          </div>
          <div>
            <Button
              onClick={handleAddSubjectModal}
              variant="primary"
              buttonSize="fluid"
              color="btndefault"
              buttonName="Add Student Result"
            />
          </div>
        </div>
        <TabContext value={value}>
          <div className="tablist-container margin-bottom">
            <Tabs
              indicatorColor="none"
              className="overide-tablist"
              onChange={handleChange}
              aria-label=""
            >
              {classDetail?.map((data, i) => {
                return (
                  <Tab key={i} value={data?.id} label={data?.class_name} />
                );
              })}
            </Tabs>
          </div>
          <div className="page-content">
            <div className="action_div flex-wrap-items-600">
              <div className={classes.contentpadding}>
                <ButtonCount func={() => navigate("")} title="No of Results" count={classGrades?.length} />
              </div>
              <div className="flex gap-10 align-items-end margin-top-5 margin-bottom-14">
                <div className="margin-bottom-10">
                  {isPageFetching ? <Icon icon="svg-spinners:270-ring-with-bg" width="20" height="20" style={{ color: "#858585" }} />
                    : <Icon onClick={() => handleGetSubjectResult(active_term_session?.id)} icon="hugeicons:reload" width="20" height="20" style={{ color: "#858585" }} />}
                </div>
                <div>
                  {activeSession?.id && (
                    <SelectGroup
                      children={
                        <select
                          defaultValue={activeSession?.id}
                          onChange={handleSessionChange}
                          name="session"
                        >
                          <option value="">---Select---</option>
                          {allSessions?.map((sesh) => {
                            return (
                              <option key={sesh?.id} value={sesh?.id}>
                                {sesh?.session}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  )}
                </div>
                <div>
                  {active_term_session?.id && (
                    <SelectGroup
                      children={
                        <select onChange={handleTermChange} name="term" value={aselectedTerm}>
                          <option value="">---Select---</option>
                          {selectedSessionTerms?.map((data) => {
                            return (
                              <option key={data?.id} value={data?.id}>
                                {data?.term}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            {classDetail?.map((data, i) => (
              <TabPanel className="tabpanel-overide" key={i} value={data?.id}>
                {isPageFetching ? (
                  <PageLoader />
                ) : searchResult.length > 0 ? (
                  <Table
                    data={searchResult}
                    columns={columns}
                    subHeader={true}
                    pagination
                    subHeaderComponent={
                      searchResult?.length > 0 && (
                        <TableSearch
                          placeholder="Search here..."
                          searchTableFunc={handleSearchInventory}
                        />
                      ) }
                  />
                ) : (
                  <div className={classes.notfound}>
                    <NotFounditem
                      img={nosearch}
                      title="No Result"
                      subtitle="No result has been added to the list."
                    />
                  </div>
                )}
              </TabPanel>
            ))}
          </div>
        </TabContext>
        {/* Delete Modal */}
        {/* <Modal
          title="Delete Result"
          subtitle={
            <div>Are you sure you want to delete <span className="modal-name-highlight">{currentRow?.title}</span>. Deleting is a permanent action`
            </div>}
          modalContent={
            <>

            </>
          }
          modalFooter={
            <>
              <Button variant="danger" buttonName="Delete Result" buttonSize="full" color="btndefault" />
            </>
          }
          isopen={deleteModal}
          closeModal={handleCloseDeleteModal}
        /> */}
        {/* Add Results */}
        <Modal
          title="Add Result"
          subtitle={
            <div>
                Manually add your student’s result.
            </div>
          }
          modalContent={
            <>
              <Grid container spacing={2} className="input-padding">
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <SelectGroup
                    label="Class"
                    required
                    children={
                      <select disabled value={value} name="class_id">
                        <option value="">--- Select ---</option>
                        {classDetail?.map((class_id) => {
                          return (
                            <option key={class_id.id} value={class_id?.id}>
                              {class_id?.class_name}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {studentResultValidator.message("Class", subjectForm?.class_id, "required")}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <SelectGroup
                    label="Subject"
                    required
                    children={
                      <select disabled value={id} name="subject_id" onChange={handleTextChange}>
                        <option value="">--- Select ---</option>
                        {allClassSubject?.map((subject) => {
                          return (
                            <option key={subject.subject_id} value={subject?.subject_id}>
                              {subject?.subject_name}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {studentResultValidator.message("Subject", subjectForm?.subject_id, "required")}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <SelectGroup
                    label="Students"
                    required
                    children={
                      <select name="student_id" onChange={handleTextChange}>
                        <option value="">--- Select ---</option>
                        {allStudent?.map((student) => {
                          return (
                            <option key={student.student_id} value={student?.student_id}>
                              {`${student?.student?.last_name} ${student?.student.first_name}`}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {studentResultValidator.message("Student", subjectForm?.student_id, "required")}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    {testArr?.map((item, index) => (
                      <Grid key={index} item lg={6} md={6} sm={12} xs={12}>
                        <InputGroup
                          label={`CA ${index + 1}`}
                          margin="normal"
                          required
                          placeHolder="Enter score"
                          inputType="text"
                          onChange={(e) => handleTestScoreChange(e, index)}
                          value={item}
                        />
                        {studentResultValidator.message(
                          `CA ${index + 1}`,
                          subjectForm?.CA_scores?.[`CA${index + 1}`],
                          "required"
                        )}
                        {testArr?.length > 1 && (
                          <p
                            className="delete-input"
                            onClick={() => handleDeleteTestScore(index)}
                          >
                            <Icon icon="zondicons:minus-solid" />
                              Remove this CA
                          </p>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {testArr?.length < 2 && (
                    <div
                      className={`${classes.mail} flex align-items-center`}
                      onClick={AddAdditionalTestScore}
                    >
                      <Icon icon="octicon:feed-plus-16" width="16" height="16" />
                      <p>Add another CA</p>
                    </div>
                  )}
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <InputGroup
                    label="Total CA Score"
                    margin="normal"
                    inputType="text"
                    required
                    inputName="CA_total"
                    value={subjectForm?.CA_total}
                    disabled
                  />
                  {studentResultValidator.message("CA Total", subjectForm?.CA_total, "required")}
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                  <InputGroup
                    label="Exam Score"
                    margin="normal"
                    inputType="text"
                    required
                    inputName="exam_score"
                    onChange={handleTextChange}
                  />
                  {studentResultValidator.message("Exam Score", subjectForm?.exam_score, "required")}
                </Grid>
                <Grid item lg={4} md={12} sm={12} xs={12}>
                  <InputGroup
                    label="Total Score"
                    margin="normal"
                    inputType="text"
                    required
                    inputName="total"
                    value={subjectForm?.total || 0}
                    disabled
                  />
                  {studentResultValidator.message("Total Score", subjectForm?.total, "required")}
                </Grid>
                <Grid item lg={9} md={12} sm={12} xs={12}>
                  <SelectGroup
                    label="Grade System"
                    required
                    children={
                      <select name="grading_system_id" onChange={handleGradeChange}>
                        <option value="">--- Select ---</option>
                        {allGradingSystem?.map((grade_system) => {
                          return (
                            <option key={grade_system?.id} value={grade_system?.id}>
                              {grade_system?.title}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                </Grid>
                <Grid item lg={3} md={12} sm={12} xs={12}>
                  <SelectGroup
                    label="Grade"
                    required
                    children={
                      <select disabled value={calculatedGrade || ""} name="grade" onChange={handleTextChange}>
                        <option value="">--- Select ---</option>
                        {selectedGrade?.grading?.map((grade_type) => {
                          return (
                            <option key={grade_type?.grade} value={grade_type?.grade}>
                              {grade_type?.grade}
                            </option>
                          );
                        })}
                      </select>
                    }
                  />
                  {studentResultValidator.message("Grade", subjectForm?.grade, "required")}
                </Grid>
              </Grid>
            </>
          }
          modalFooter={
            <>
              <div className={classes.modalFooterBtn}>
                <Button
                  variant="primary"
                  buttonSize="full"
                  color="btndefault"
                  buttonName="Add Result"
                  onClick={handlePostStudentResult}
                  isLoading={resultCreating}
                />
              </div>
            </>
          }
          isopen={addsubjectModal}
          closeModal={handleCloseAddResultModal}
        />
        <ToastContainer />
      </div>
    }
    />
  );
};

export default AllSubjectResultDetails;
