/* eslint-disable no-console */
import { useState, useEffect, useRef } from "react";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import useStyles from "../studentapplication/styles";
import { Grid } from "@mui/material";
import PageTitle from "../../components/PageTitle";
import InputGroup from "../../components/FormComponent/InputGroup";
import TextAreaGroup from "../../components/FormComponent/TextAreaGroup";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Select from "react-select";
import { Icon } from "@iconify/react";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import noupload from "../../assets/images/noupload.png";
import nocomment from "../../assets/images/nocomment.png";

const ENROLMENT_ENDPOINT = "/api/v1/enrolment";
const COMMENTS_ENDPOINT = "/api/v1/application";
const APPLICANT_ENROLMENT = "/api/v1/enrolment/application/";

const ApplicationDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [downloadModal, setDownloadModal] = useState(false);
  const [applicationsData, setApplicationsData] = useState({});
  const [newComment, setNewComment] = useState("");
  const [inviteModal, setInviteModal] = useState(false);
  const [formData, setFormData] = useState({
    venue: "",
    examDate: "",
    examTime: ""
  });
  const [convertModal, setConvertModal] = useState(false);
  const [sessionOptions, setSessionOptions] = useState([]);
  const [selectedSession, setSelectedSession] = useState("");
  const [rejectModal, setRejectModal] = useState(false);
  const [comments, setComments] = useState([]);
  const [rejectComment, setRejectComment] = useState("");
  const [replyComment, setReplyComment] = useState("");
  const [replyToCommentId, setReplyToCommentId] = useState(null);

  const commentsEndRef = useRef(null);
  const scrollToBottom = () => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleRejectCommentChange = (e) => {
    setRejectComment(e.target.value);
  };

  const getApplicantInEnrolment = () => {
    httprequest.get(`${ENROLMENT_ENDPOINT}/application/${id}`)
      .then((res) => {
        setApplicationsData(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const profile = applicationsData || {};
  const fetchComments = () => {
    httprequest
      .get(`${COMMENTS_ENDPOINT}/${id}/comments`)
      .then((res) => {
        const fetchedComments = res?.data?.data || [];

        // Combine comments and replies into a single array
        const combinedCommentsAndReplies = fetchedComments.flatMap(comment => {
          const commentEntry = { ...comment, type: "comment" };
          const replyEntries = comment.replies.map(reply => ({ ...reply, type: "reply", parentCommentId: comment.id }));

          return [commentEntry, ...replyEntries];
        });

        // Sort by the created_at field (chronologically)
        combinedCommentsAndReplies.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );

        setComments(combinedCommentsAndReplies);
        scrollToBottom();
      })
      .catch(() => {
        toast.error("Failed to fetch comments");
      });
  };

  const handleRejectModal = () => {
    setRejectModal(!rejectModal);
  };

  const handleConvertModal = () => {
    setConvertModal(!convertModal);
  };

  const handleExamDataChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleDownloadModal = () => {
    setDownloadModal(!downloadModal);
  };
  const handleInviteModal = () => {
    setInviteModal(!inviteModal);
  };

  const handleCloseInviteModal = () => {
    setInviteModal(false);
  };

  const handleInvite = () => {
    const applicationIds = [profile.id];
    const payload = {
      application_ids: applicationIds,
      exam_invite: true,
      exam_info: {
        date: formData.examDate,
        venue: formData.venue,
        time: formData.examTime
      }
    };

    httprequest.patch(`${APPLICANT_ENROLMENT}${profile.id}`, payload)
      .then(() => {
        toast.success("Invitations sent successfully");
        getApplicantInEnrolment();
        handleCloseInviteModal();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleDownload = () => {
    const url = profile?.uploads?.[0]?.file_url;

    if (url) {
      const link = document.createElement("a");
      link.href = url;  // Directly use the file URL for download
      link.download = "downloaded_file.pdf";  // Set a default filename
      link.target = "_blank";  // Open in a new tab for testing
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error("No file available for download.");
    }
  };
  const handleAddComment = () => {
    const applicationId = profile.id;
    if (!applicationId) {
      toast.error("No applications selected to add a comment.");

      return;
    }

    // Retrieve existing comments
    // const existingComments = profile.administrator_comment || [];

    // Append the new comment to the existing comments
    const updatedComments = newComment;
    if (updatedComments === "") {
      toast.error("Enter Comment.");

      return;
    }

    const payload = { comment: updatedComments, application_id: applicationId };

    httprequest.post("/api/v1/application/comments/admin", payload)
      .then(() => {
        toast.success("Comment added successfully");
        setNewComment("");
        fetchComments();
        setComments();
      })
      .catch((err) => {
        toast.error(err?.response?.data || "Failed to add comment");
      });
  };
  const handleReplyComment = () => {
    const replyId = replyToCommentId;
    if (!replyId || !replyComment) {
      toast.error("No comment or reply text provided.");

      return;
    }

    const payload = { reply: replyComment, comment_id: replyId };
    httprequest.patch("/api/v1/application/comments/admin", payload)
      .then(() => {
        toast.success("Reply added successfully");
        setReplyComment("");
        setReplyToCommentId(null);
        fetchComments();
      })
      .catch((err) => {
        toast.error(err?.response?.data || "Failed to add reply");
      });
  };

  const handleReplyClick = (commentId) => {
    setReplyToCommentId(commentId);
    setReplyComment("");
  };
  const handleCancelReply = () => {
    setReplyToCommentId(null);
    setReplyComment("");
  };

  const rejectApplication = () => {
    const applicationId = [profile?.id]; // Get the application ID

    if (!applicationId) {
      toast.error("No application selected to reject.");

      return;
    }
    if (!rejectComment) {
      toast.error("Please add a comment before rejecting.");

      return;
    }

    const payload = { application_status: "rejected", rejection_comment: rejectComment, application_ids: applicationId };

    httprequest.patch("/api/v1/enrolment/applications/batch", payload)
      .then(() => {
        toast.success("Application reject successfully");
        handleRejectModal();
        setRejectComment("");
        handleRejectModal();
        getApplicantInEnrolment();
      })
      .catch((err) => {
        toast.error(err?.response?.data || "Failed to reject application");
      });
  };
  const convertApplication = () => {
    const application_ids = [profile.id];
    const session_id = selectedSession?.value;

    if (application_ids.length === 0) {
      toast.error("No applications selected to convert.");

      return;
    }

    const payload = { application_ids, session_id };

    httprequest.post("/api/v1/enrolment/applications/convert", payload)
      .then(() => {
        setConvertModal(false);
        toast.success("Application converted successfully");
      })
      .catch((err) => {
        toast.error(err?.response?.data || "Failed to convert application");
      });
  };

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await httprequest.get("/api/v1/school-plan/session");
        const session = response?.data?.data;
        setSessionOptions(session);
      } catch (error) {
        console.error("Error fetching session data:", error);
      }
    };

    fetchSessions();
  }, []);
  useEffect(() => {
    if (id) {
      fetchComments();
      getApplicantInEnrolment();
    }
  }, [id]);

  const handleSessionOptionChange = (selectedOption) => {
    setSelectedSession(selectedOption);
  };

  const formatTime = (dateString) => {
    const dateObject = new Date(dateString);

    const day = dateObject.getUTCDate().toString().padStart(2, "0");
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0");
    const hours = dateObject.getUTCHours().toString().padStart(2, "0");
    const minutes = dateObject.getUTCMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}  ${month}/${day}`; // Format: DD/MM
  };

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/enrolments/applications"
                link_one_name="Application"
                active="Application Information"
                description={
                  <>
                    <div>See all the details about applications in it</div>
                  </>}
              />
            </div>
          </div>
          <div className={`${classes.border} page-content`}>
            <PageTitle
              title={`${profile?.first_name}'s Dashboard`}
              subtitle="These are some of the details on your application form"
            />
            <Grid container spacing={4}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <div className={`${classes.border} padding-16`}>
                  <h4>Display Picture</h4>
                  <div className={classes.profilecontainer}>
                    <div className={classes.imageviewer} style={{ backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                      <img src={profile?.display_pic?.file_url} />
                    </div>
                  </div>
                  <div className={classes.applicationdetails}>
                    <h6>Application Number</h6>
                    <h4>{profile?.application_no}</h4>
                  </div>
                </div>
              </Grid>

              <Grid item lg={8} md={8} sm={12} xs={12}>
                <div className={`${classes.border} padding-16`}>
                  <Grid container spacing={2} className={classes.profileform}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup disabled inputType="text" label="First name" inputName="first_name" value={profile?.first_name} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup disabled inputType="text" label="Last name" inputName="last_name" value={profile?.last_name} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup disabled inputType="email" label="Email address" inputName="email" value={profile?.email} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup disabled inputType="text" label="Class Applying to" value={profile?.class?.name} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup disabled inputType="tel" label="Contact" inputName="phone" value={profile?.phone} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup disabled inputType="text" label="Home Address" inputName="address" value={profile?.address} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup
                        disabled
                        inputType="text"
                        label="Guardian First Name"
                        inputName="guardian_first_name"
                        value={profile?.guardian_info?.first_name || ""}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup
                        disabled
                        inputType="text"
                        label="Guardian Last Name"
                        inputName="guardian_last_name"
                        value={profile?.guardian_info?.last_name || ""}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup
                        inputType="email"
                        disabled
                        label="Guardian Email"
                        inputName="guardian_email"
                        value={profile?.guardian_info?.email || ""}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup
                        inputType="tel"
                        label="Guardian Phone"
                        disabled
                        inputName="guardian_phone"
                        value={profile?.guardian_info?.phone || ""}
                      />
                    </Grid>
                  </Grid>
                  <ToastContainer />
                </div>
              </Grid>
            </Grid>

            <div className="margin-top-40 text-align-left full-width">
              <div>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="applicant_comment">

                      <div className="text-align-left flex">
                        <Icon
                          icon="material-symbols:upload"
                          width="20"
                          height="20"
                          style={{ color: "#111111" }}
                        />
                        <div>
                          <h4>Uploads</h4>
                        </div>
                      </div>

                      {profile?.uploads?.length > 0 ? (
                        profile?.uploads?.map((upload, index) => (
                          <div key={index}>
                            <div className={`${classes.pickercontain} action_div`}>
                              <div className="overflow_x">
                                <h2 className={`${classes.uploadtitles} overflow_x`}>
                                  {upload?.title}
                                </h2>
                              </div>
                              <p
                                onClick={() => {
                                  handleDownloadModal(upload);
                                }}
                                className="upload_p"
                              >
                            View
                              </p>
                            </div>
                          </div>

                        ))
                      ) : (
                        <div className="no_upload">
                          <img src={noupload} />
                          <p>No uploads available</p>
                        </div>
                      )}

                    </div>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="applicant_comment">
                      <div className="text-align-left flex">
                        <Icon
                          icon="iconamoon:comment-fill"
                          width="20"
                          height="20"
                          style={{ color: "#111111" }}
                        />
                        <div>
                          <h4>Comments</h4>
                        </div>
                      </div>

                      <>
                        {Array.isArray(comments) && comments.length > 0 ? (
                          <div className="comment_div application_comment">
                            {comments.map((item, index) => (
                              <div key={index} className={`admin_comment ${item.ref_type}`}>
                                {item.type === "comment" ? (
                                  <div className="comment_main">

                                    <div className="apply comment_container">
                                      <p className=" no_margin">
                                        <strong>{item.user.name}</strong>
                                      </p>
                                      <span className="admin_comment_span">{item.comment}
                                        <div className="comment_time justify_end">{formatTime(item.created_at)}</div>

                                      </span>
                                      {item.ref_type === "applicant" && (
                                        <span
                                          className="reply"
                                          onClick={() => handleReplyClick(item.id)}
                                        >
                         Reply
                                        </span>

                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className={`reply_container_${comments.find(comment => comment.id === item.parentCommentId)?.ref_type}`}>
                                    <div className="reply_main">
                                      <p className="reply-user  no_margin">
                                        <strong>{item.user.name}</strong>
                                      </p>
                                      <div className="original-comment">
                                        <div className="reply_com">
                                          <p className="original-comment-user no_margin">
                                            <strong>{comments.find(comment => comment.id === item.parentCommentId)?.user.name}</strong>
                                          </p>
                                          <span>
                                            {comments.find(comment => comment.id === item.parentCommentId)?.comment}
                                          </span>
                                        </div>
                                        <span>
                                          {item.reply}
                                        </span>
                                        <div className="comment_time">{formatTime(item.created_at)}</div>
                                      </div>

                                      {comments.find(comment => comment.id === item.parentCommentId)?.ref_type === "admin" && (
                                        <span
                                          className="reply justify_end"
                                          onClick={() => handleReplyClick(comments.find(comment => comment.id === item.parentCommentId)?.id)}
                                        >
            Reply
                                        </span>
                                      )}

                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="no_upload">
                            <img src={nocomment} />
                            <p>No comment available</p>
                          </div>
                        )}

                        {/* Default comment submission area, hidden when replying */}
                        {!replyToCommentId && (
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextAreaGroup
                              margin="normal"
                              inputType="text"
                              inputName="newComment"
                              value={newComment}
                              onChange={(e) => setNewComment(e.target.value)} // Handle input change
                            />
                            <button className="upload_comment flex justify_end" onClick={handleAddComment}>
          Submit Comment <Icon icon="zondicons:send" />
                            </button>
                          </Grid>
                        )}

                        {/* Reply form, shown only when replying to a comment */}
                        {replyToCommentId && (
                          <div className="reply_div">
                            <div className="selected-comment-to-reply">
                              <div>
                                <span>
                                  {comments.find(comment => comment.id === replyToCommentId)?.user.name}
                                </span>
                                <p className="no_margin">
                                  {comments.find(comment => comment.id === replyToCommentId)?.comment}
                                  {comments.find(comment => comment.id === replyToCommentId)?.replies.reply}
                                </p>
                              </div>
                              <button className="cancel_reply" onClick={handleCancelReply}>
                                <Icon icon="ic:outline-cancel" />
                              </button>
                            </div>

                            {/* Reply text area */}
                            <TextAreaGroup
                              margin="normal"
                              inputType="text"
                              inputName="replyComment"
                              value={replyComment}
                              onChange={(e) => setReplyComment(e.target.value)}
                            />
                            <button className="upload_comment flex justify_end" onClick={handleReplyComment}>
          Reply Comment <Icon icon="zondicons:send" />
                            </button>
                          </div>
                        )}
                      </>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="margin-top-40">
                <Grid container spacing={2} className="input-padding align-items-center justify-content-center ">
                  {profile.status === "accepted" ? (
                    null
                  ) : (
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      <Button
                        variant="successoutline"
                        buttonSize="full"
                        color="btnsuccess"
                        buttonName="Convert Application"
                        onClick={handleConvertModal}
                      />
                    </Grid>) }

                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <Button
                      className="border-radius-40"
                      variant="primary"
                      buttonSize="full"
                      color="btndefault"
                      buttonName="invite for examination"
                      onClick={handleInviteModal}
                    />
                  </Grid>
                  {profile.status === "rejected" ? (
                    null
                  ) : (
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      <Button
                        className="border-radius-40"
                        variant="dangeroutline"
                        buttonSize="full"
                        color="btndanger"
                        buttonName="Reject Application"
                        onClick={handleRejectModal}

                      />
                    </Grid>
                  ) }
                </Grid>
              </div>
            </div>
            <Modal
              title="Invite Student"
              subtitle={
                <div>Invite student for examination
                </div>}
              modalContent={
                <>
                  <Grid container className="input-padding">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className="multiple-select">
                        <InputGroup
                          inputType="text"
                          label="Venue"
                          inputName="venue"
                          value={formData.venue}
                          onChange={handleExamDataChange}
                          required
                        />
                        {/* <SelectGroup
                          label="Select Venue"
                          children={
                            <select
                              name="venue"
                              value={formData.venue}
                              onChange={handleExamDataChange}
                            >
                              <option disabled value="">
                    --Select Options---
                              </option>
                              <option value="online">Online</option>
                              <option value="physical">Physical</option>
                            </select>
                          }
                        /> */}
                      </div>
                      <div className="input-padding">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <InputGroup
                            inputType="date"
                            label="Exam Date"
                            inputName="examDate"
                            value={formData.examDate}
                            onChange={handleExamDataChange}
                            required
                          />
                        </Grid>
                      </div>
                      <div className="input-padding">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <InputGroup
                            inputType="time"
                            label="Exam Time"
                            inputName="examTime"
                            value={formData.examTime}
                            onChange={handleExamDataChange}
                            required
                          />
                        </Grid>
                      </div>
                    </Grid>

                  </Grid>
                </>
              }
              modalFooter={
                <>
                  <div className={classes.modalFooterBtn}>
                    <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Invite For Examination" onClick={handleInvite} />
                  </div>
                </>
              }
              isopen={inviteModal}
              closeModal={handleInviteModal}
            />
            <Modal
              title="View Uploads"
              subtitle={
                <div>View file uploaded by the candidate.
                </div>}
              modalContent={
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                      <Viewer fileUrl={profile?.uploads?.[0]?.file_url || " No file"} />
                    </Worker>
                  </Grid>
                </>
              }
              modalFooter={
                <>
                  <div className={classes.modalFooterBtn}>
                    <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Download Files" onClick={handleDownload} />
                  </div>
                </>
              }
              isopen={downloadModal}
              closeModal={handleDownloadModal}
            />
            {/* //Convert icon modal */}
            <Modal
              title="Convert Application"
              subtitle={<div><p>Are you sure you want to convert this applicant into a student?</p>
                <Grid container className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="multiple-select">
                      <p className="input-picker-title">Select Session</p>
                      <Select
                        name="session-option"
                        options={sessionOptions.map((option) => ({
                          label: option.session,
                          value: option.id
                        }))}
                        value={selectedSession}
                        onChange={handleSessionOptionChange}
                      />
                    </div>
                  </Grid>
                </Grid> </div>}
              modalContent={
                <></>

              }
              modalFooter={
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Button
                      variant="success"
                      buttonSize="full"
                      color="btndefault"
                      buttonName="Convert"
                      onClick={convertApplication}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Button
                      variant="dangeroutline"
                      buttonSize="full"
                      color="btndanger"
                      buttonName="Cancel"
                      onClick={() => setConvertModal(false)}
                    />
                  </Grid>
                </Grid>
              }
              isopen={convertModal}
              closeModal={() => setConvertModal(false)}
            />
            <Modal
              title="Confirm Rejection"
              subtitle="Are you sure you want to reject this application?"
              modalContent={
                <>
                  <div className="input-padding">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextAreaGroup
                        inputType="text"
                        label="Add Comment"
                        inputName="reject_comment"
                        required
                        value={rejectComment}
                        onChange={handleRejectCommentChange}
                      />
                    </Grid>
                  </div>

                </>
              }
              modalFooter={
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Button
                      variant="danger"
                      buttonSize="full"
                      color="btndefault"
                      buttonName="Reject All"
                      onClick={rejectApplication}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Button
                      variant="dangeroutline"
                      buttonSize="full"
                      color="btndanger"
                      buttonName="Cancel"
                      onClick={handleRejectModal}
                    />
                  </Grid>
                </Grid>

              }
              isopen={rejectModal}
              closeModal={handleRejectModal}

            />

            <ToastContainer />
          </div>
        </>
      }
    />
  );
};

export default ApplicationDetails;
