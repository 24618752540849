import { Link } from "react-router-dom";
// import styles from "./SiteMap.module.css";
// import logo from "../../assets/images/logo.svg";
import useStyles from "./styles";
import { useUserState } from "../../context/UserContext";
import { accessViewSubjectsByClass, accessCreateEvent, accessGetEventRSVP, accessViewAllApplications, accessAllSchoolPlans, accessViewEnrolmentApplicants, accessViewAllLeaveOptions, accessViewLeaveRequestDetails, accessViewClassDetailsById, accessCreateStudent, accessViewAllEmployees, accessCreateGuardian, accessViewAllLeaveRequests, accessSubjectTeacher } from "../../data/roles";
const SiteMap = () => {
  // const navigate = useNavigate();
  const { appLogo, userRole } = useUserState();
  const classes = useStyles();
  const isSchoolAdmin = userRole.role === "school_admin";
  const isTeacher = userRole.role === "teacher";

  return (
    <div className={`${classes.container}`}>
      <div className={`${classes.sitecontent}`}>
        <div className={`${classes.sitemapheader}`}>
          <div>
            <div className={`${classes.shelflogo}`}>
              <img src={appLogo} alt="" />
              {process.env.REACT_APP_ENVIRONMENT === "development" ? (
                <span className={classes.environment}>Dev</span>
              ) : process.env.REACT_APP_ENVIRONMENT === "staging" ? (
                <span className={classes.environment}>staging</span>
              ) : process.env.REACT_APP_ENVIRONMENT === "sandbox" ? (
                <span className={classes.environment}>sandbox</span>
              ) : null}
            </div>

          </div>

        </div>
        <div className="main_site_map">
          <div className="site_map"><h4>Site Map</h4>
            <p>For easy navigation, these are quick links to take you to different pages on the app</p>
            <Link to="/dashboard">Dashboard</Link>
            {accessViewSubjectsByClass(userRole) && <Link to={isSchoolAdmin ? "/admin/class-activities/classes" : "/myclasses"}>Add Class</Link>
            }
            {accessCreateStudent(userRole) && <Link to="/users/students">Manage Students</Link> }

            {accessViewAllEmployees(userRole) && <Link to="/users/employees">Manage Teachers</Link>}
          </div>
          {accessCreateGuardian(userRole) &&
            <div className="site_map">
              <h4>Guardian</h4>
              {accessCreateGuardian(userRole) && <Link to="/users/guardians">Manage Parents</Link>}
              {accessCreateGuardian(userRole) &&
            <>
              <Link to="/users/guardians">Send Braoadcasts</Link>
              <Link to="/users/guardians">Send Reports to Parents</Link>
            </>}
              {accessCreateEvent(userRole) || accessGetEventRSVP(userRole) &&
            <>
              <Link to="/events">Events</Link>
              <Link to="/events">Create Events</Link>
            </>}

            </div>
          }
          <div className="site_map">
            {/* <h4 className={classes.sitemapsubheader}>Personnel Management</h4> */}
            {/* <Link to="/goals">Performance Evaluation</Link> */}
            {/* <Link>Goal Planning</Link>
            <Link>Review and Performance Evaluation</Link> */}
            <h4 className={classes.sitemapsubheader}>Leave Administration</h4>
            {accessViewLeaveRequestDetails(userRole) && (
              <Link to={isTeacher ? "/welfare" : "/manage/leave"}>Leave Management</Link>
            )}

            {accessViewAllLeaveRequests(userRole) && <Link to="/manage/leavee">Add Leave Option</Link>}
            {accessViewAllLeaveRequests(userRole) && <Link to="/manage/leave">See Leave Request</Link>}

            {accessViewAllLeaveOptions(userRole) && (
              <Link to={isTeacher ? "/welfare" : "/manage/leave"}>See Leave Schedule</Link>
            )}

            {/* <h4>Knowledge Base</h4>
            <Link to="/resources">Knowledge Base</Link> */}
            {/* <Link>Add New Info</Link>
            <Link>Edit Knowledge Base</Link>
            <Link>View Document</Link> */}
          </div>
          <div className="site_map">
            <h4 className={classes.sitemapsubheader}>Profiles</h4>
            <Link to="/profile">Organisational Profile</Link>
            {/* <Link>Send Employee Invite</Link> */}
            <Link to="/profile">Organogram</Link>
            <Link to="/profile">Contact Information</Link>
            <Link to="/profile">Banking Details</Link>
          </div>
          <div className="site_map">
            <h4 className={classes.sitemapsubheader}>Students</h4>
            {accessSubjectTeacher(userRole) && <Link to={isTeacher ? "/mysubjects" : "/class-activities/subjects"}>Subjects</Link>
            }
            {accessViewSubjectsByClass(userRole) &&
            <>
              <Link to="/class-activities/subjects">Create Subjects</Link>
              {/* <Link to="">Assign Teacher</Link> */}
              <Link to="/class-activities/subjects">Assign Subjects</Link>
            </>}
            {accessSubjectTeacher(userRole) && <Link to={isTeacher ? "/myclasses" : "/class-activities/classes"}>Classes</Link>

            }
            {accessViewClassDetailsById(userRole) &&
            <>
              <Link to="/class-activities/classes">Create Classes</Link>
              <Link to="/class-activities/classes">View TimeTable</Link>
            </>}

          </div>
          <div className="site_map">
            {accessAllSchoolPlans(userRole) &&
            <>
              <h4 className={classes.sitemapsubheader}>Planning</h4>
              <Link to="/schoolplans">School Plan</Link>
              <Link to="/schoolplans">Create School Plan</Link>
            </>}

            {/* <Link>View School Calendar</Link> */}
            {/* <h4>Resource Management</h4>
            <Link to="/inventory">Inventory</Link>
            <Link to="/inventory">Create Inventory</Link>
            <Link>Archives</Link>
            <Link to="/procurements">Procurement</Link>
            <Link to="/procurements">Add Contractors</Link>
            <Link>Manage Contractors</Link>
            <Link to="/procurements/new">Create Purchase Order</Link>
            <Link to="/contractors">View Purchases</Link> */}
          </div>
          <div className="site_map">
            <h4 className={classes.sitemapsubheader}>Examinations</h4>
            <Link to={isTeacher ? "/examinations" : "/school-examination/examination"}>Examination</Link>
            {/* <Link>Set Grading System</Link> */}
            <Link to={isTeacher ? "/examinations" : "/school-examination/examination"}>View Exam Papers</Link>
            {accessViewAllApplications(userRole) || accessViewEnrolmentApplicants(userRole) && <h4 className={classes.sitemapsubheader}>Student Application</h4> }
            {accessViewAllApplications(userRole) || accessViewEnrolmentApplicants(userRole) &&
            <>
              <Link to="/enrolments/applications">Student Application</Link>
              <Link to="/enrolments/applications">Open Enrollment</Link>
              <Link to="/enrolments/applications">View Application</Link>
            </>}

            {/* <Link>Archive Application</Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteMap;
